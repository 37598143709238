import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ObservationModel} from '../../../operation/model/observation-model';
import {ObservationService} from '../../services/observation.service';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {DialogBase} from "../../../shared/abstractions/dialog-base";

@Component({
  selector: 'app-edit-observation',
  templateUrl: './edit-observation.component.html',
  styleUrls: ['./edit-observation.component.scss']
})
export class EditObservationComponent extends DialogBase implements OnInit, OnDestroy {
  public observation: ObservationModel = {};
  private index: number;

  constructor(public observationService: ObservationService, @Inject(MAT_DIALOG_DATA) data) {
    super();
    this.loadData(data);
  }

  ngOnInit(): void {
  }

  public loadData(data): void {
    this.observation = {
      id: data.observation.id,
      author: data.observation.author,
      creation_datetime: data.observation.creation_datetime,
      description: data.observation.description,
      id_op: data.observation.id_op,
      name: data.observation.name,
      section: data.observation.section,
      surname: data.observation.surname,
    };
    this.index = data.index;
  }

  public cancel(): void {
    this.observationService.closeObservation$.next();
  }

  public editObservation(): void {
    this.isCalled = true;
    this.observationService.editObservation(this.observation)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value.result == 'true') {
          this.observationService.editObservationList$.next();
        }
        this.cancel();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
