import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TableService} from '../services/table.service';

@Directive({
  selector: '[appTableCount]'
})
export class CountDirective implements OnInit {

  /**
   * @var appTableCount : Table ID
   */
  @Input() appTableCount: string;

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    private translate: TranslateService,
    private tableService: TableService
  ) {
    this._renderer.addClass(this._el.nativeElement, 'seigos-table-count');
  }

  ngOnInit() {
    if (this.appTableCount && typeof this.tableService.data[this.appTableCount] !== "undefined") {
      this.translate.get('global.table.results').subscribe((trans) => {
        let text = this._renderer.createText('(' + this.tableService.data[this.appTableCount]?.meta.total + ' ' + trans + ')');
        this._renderer.appendChild(this._el.nativeElement, text);
      });
    }
  }

}
