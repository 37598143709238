import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {GenericSidebarItem} from "../../models/generic-sidebar/generic-sidebar-item";
import * as constant from "../../../app.constant";

@Component({
  selector: 'app-generic-sidebar',
  templateUrl: './generic-sidebar.component.html',
  styleUrls: ['./generic-sidebar.component.scss']
})
export class GenericSidebarComponent implements OnInit, OnChanges {
  @Input() items: GenericSidebarItem[];
  @Input() linkItems: GenericSidebarItem[];
  @Input() buttonItems: GenericSidebarItem[];
  @Input() activeItem: GenericSidebarItem;
  @Input() isOpen: boolean;

  @Output() itemClicked: EventEmitter<number> = new EventEmitter<number>();

  @ContentChild('content', {static: true})
  contentTemplate: TemplateRef<any>;

  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;


  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.items = changes.items.currentValue;
    // this.activeItem = changes.activeItem.currentValue;
  }

  ngOnInit(): void {
  }


  itemClick(item: GenericSidebarItem) {
    this.itemClicked.emit(item.index);
  }

  toggleSidebar() {

  }
}
