<div class="header">
  <div>
    <img width="23" src="../../../../assets/icons/operation-upload.svg">
    <span>{{'operations-update.title' | translate}}</span>
  </div>
  <img width="18" (click)="cancel()" class="right" src="../../../../assets/icons/close.svg">
</div>

<div class="department">
  <h5>{{'operations-update.department' | translate}}</h5>
  <mat-select class="select-form" placeholder="{{ 'vigilance.field' | translate }}">
  </mat-select>
</div>
<div class="dialog-content">
  <accordion [isAnimated]="true" [closeOthers]="true">
    <accordion-group [ngClass]="{'selected': selecting[0]}" (isOpenChange)="openAccordion($event,0)">
      <div class="heading" accordion-heading>
        <div>
          <div>
            <span><span class="title">{{'operations-update.step' | translate}} 1:</span> {{'operations-update.collecting' | translate}}</span>
          </div>
          <div>1000 {{'operations-update.from' | translate}} 10000</div>
        </div>
        <div>
          <div class="triangle"></div>
        </div>
      </div>
      <div class="content">
        <ul>
          <li *ngFor="let m of messages; let i = index">
            <div>
              <span>{{m.datetime}} - {{m.message}}</span>
            </div>
            <div>
              {{m.title}}
            </div>
          </li>
        </ul>
      </div>
    </accordion-group>
    <app-progress [total]="10000" [progress]="1000"></app-progress>
    <accordion-group [ngClass]="{'selected': selecting[1]}" (isOpenChange)="openAccordion($event,1)">
      <div class="heading" accordion-heading>
        <div>
          <div>
            <span><span class="title">{{'operations-update.step' | translate}} 2:</span> {{'operations-update.grouping' | translate}}</span>
          </div>
          <div>99 {{'operations-update.from' | translate}} 159</div>
        </div>
        <div>
          <div class="triangle"></div>
        </div>
      </div>
      <div class="content">
        <ul>
          <li *ngFor="let m of messages; let i = index">
            <div>
              <span>{{m.datetime}} - {{m.message}}</span>
            </div>
            <div>
              {{m.title}}
            </div>
          </li>
        </ul>
      </div>
    </accordion-group>
    <app-progress [total]="150" [progress]="99"></app-progress>
    <accordion-group [ngClass]="{'selected': selecting[2]}" (isOpenChange)="openAccordion($event,2)">
      <div class="heading" accordion-heading>
        <div>
          <div>
            <span><span class="title">{{'operations-update.step' | translate}} 3:</span> {{'operations-update.merging' | translate}}</span></div>
          <div>3 from 78</div>
        </div>
        <div>
          <div class="triangle"></div>
        </div>
      </div>
      <div class="content">
        <ul>
          <li *ngFor="let m of messages; let i = index">
            <div>
              <span>{{m.datetime}} - {{m.message}}</span>
            </div>
            <div>
              {{m.title}}
            </div>
          </li>
        </ul>
      </div>
    </accordion-group>
    <app-progress [total]="78" [progress]="3"></app-progress>
    <accordion-group [ngClass]="{'selected': selecting[3]}" (isOpenChange)="openAccordion($event,3)">
      <div class="heading" accordion-heading>
        <div>
          <div>
            <span><span class="title">{{'operations-update.step' | translate}} 4:</span> {{'operations-update.transferring' | translate}}</span></div>
          <div>500 from 10000</div>
        </div>
        <div>
          <div class="triangle"></div>
        </div>
      </div>
      <div class="content">
        <ul>
          <li *ngFor="let m of messages; let i = index">
            <div>
              <span>{{m.datetime}} - {{m.message}}</span>
            </div>
            <div>
              {{m.title}}
            </div>
          </li>
        </ul>
      </div>
    </accordion-group>
    <app-progress [total]="10000" [progress]="500"></app-progress>
  </accordion>
</div>


<div class="buttons">
  <button class="cancel" (click)="cancel()">{{ 'housing.cancel' | translate | uppercase }}</button>
  <button class="hide-button" (click)="cancel()">{{'operations-update.hide' | translate | uppercase}}</button>
  <button class="add" (click) ="update()">{{'operations-update.update' | translate | uppercase}}</button>
</div>
