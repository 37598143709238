import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Filter} from '../../interfaces/filter';

@Component({
  selector: 'app-table-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterComponent {

  @Input() filters: Filter[] = [];
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("menuTrigger") menuTrigger: ElementRef;

  constructor() { }

  onChange(event, filter)
  {
    filter.selected = event.checked;
    this.onFilterChange.emit(this.filters);
  }

  onClick(event)
  {
    event.stopPropagation();
  }

}
