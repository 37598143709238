import * as config from "../config/config";

export const DASHBOARD_CHARTS_ANIMATION = config.use_chart_animation;
export const STATISTICS_CHARTS_ANIMATION = config.use_chart_animation;
export const DASHBOARD_MAPS_DROPDOWN = config.dashboard_maps_dropdown;

export const ACTIVE_ENVIRONMENT = {
  base_url: config.base_url,
  api_url: config.api_url,
  report_api_url: config.report_api_url,
  cdn_url: config.base_url,
};
export const ACTIVE_PROJECT = {
  sidebar_logo: config.sidebar_logo,
  dashboard_map: config.dashboard_map,
  dashboard_great_map: config.dashboard_great_map,
  //dashboard_title: config.dashboard_title
};

export const IS_SPOT_PROJECT = config.is_spot_project;

export const ENCRYPTION_KEY = "HV88AoGsd68JSm8m6aaB";

export const map_imgs = config.map_imgs;
export const map_city_imgs = config.map_city_imgs;
export const space_name = config.space_name;

export const langs = config.langs;
export const default_lang = config.default_lang;
