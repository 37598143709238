<div [ngClass]="{'sidebar-hide': !isOpen}" class="wrapper">
  <div class="left-sidebar">
    <div class="logo-list">
      <!--      <img (click)="toggleSidebar()" alt="menu-button" src="{{cdn_url}}/assets/icons/menu-button.svg" width="24px">-->
      <!--      <span>Edit</span>-->
      <ul *ngIf="linkItems" class="links">
        <li (click)="itemClick(linkItem)" *ngFor="let linkItem of linkItems" class="list-item">
          <div [ngClass]="{'active-item': activeItem?.index === linkItem?.index}" class="title-item">
            <span> {{linkItem.text}} </span>
            <ng-container *ngIf="linkItem.tooltipText">
              <img *ngIf="activeItem?.index === linkItem?.index; else inactive" alt=""
                   src="../../../../assets/icons/tab-tooltip-active.svg">
              <ng-template #inactive>
                <img alt="" src="../../../../assets/icons/tab-tooltip.svg">
              </ng-template>
              <div *ngIf="linkItem?.tooltipText" class="hoverable">
                {{ linkItem?.tooltipText }}
              </div>
            </ng-container>
          </div>
        </li>
      </ul>
    </div>
    <ul *ngIf="buttonItems" class="buttons">
      <li (click)="itemClick(buttonItem)" *ngFor="let buttonItem of buttonItems">
        <app-spot-button
          [blockStyles]="{
          'width': '100%',
          'padding': '4px 12px',
          'margin-left': '0'}"
          [isActive]="activeItem?.index === buttonItem?.index"
          [text]="buttonItem.text"
        >

        </app-spot-button>
      </li>
    </ul>
  </div>
  <div class="content">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</div>
