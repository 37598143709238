import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AdvancedSearchModel} from '../model/advanced-search-model';
import {HttpClientService} from "../../shared/services/http-client.service";

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AdvancedSearchService {
  public searchModel: AdvancedSearchModel;
  public isFiltersReset: boolean;
  public isDashboardFilters: boolean;
  public setOperationType$ = new Subject<string>();

  constructor(private httpClient: HttpClientService) {
  }


  public setup() {
    if (!this.searchModel) {
      this.searchModel = {
        page: 1,
        show: 50,
        orderby: 'spot_operation.id',
        order: 'desc',
        operationType: 'all',
        advancedsearch: {}
      };
    }
  }

  public resetFilters() {
    this.searchModel.advancedsearch = {};

  }

  public addFilterForce(property, value) {
    this.searchModel.advancedsearch[property] = value;
    this.isFiltersReset = true;
  }

  public checkFiltersResetInitial(){

  }
  public checkFiltersReset() {
    if (this.isDashboardFilters) {
      this.isDashboardFilters = false;
      return;
    }
    if (this.isFiltersReset) {
      this.resetFilters();
      this.isFiltersReset = false;
    }
  }

  public getRequestParams() {
    return {
      page: this.searchModel.page,
      show: this.searchModel.show,
      orderby: this.searchModel.orderby,
      order: this.searchModel.order,
      advancedsearch: this.searchModel.advancedsearch,
      operations: this.searchModel.operations,
      search: this.searchModel.search
    };
  }

  public getOperationsByFilter(filters: any): Observable<any> {
    return this.httpClient.post('/operations/getOperationsWithFilters', { filters: filters });
  }
}
