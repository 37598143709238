import {Component, Input, OnInit} from '@angular/core';
import {CheckboxService} from '../../services/checkbox.service';
import {TableService} from '../../services/table.service';

@Component({
  selector: 'app-table-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() table_id: string;
  @Input() row_id: number = 0;
  @Input() check_all: boolean = false;

  public input: any;
  public indeterminate: boolean = false;

  constructor(
    private checkboxService: CheckboxService,
    private tableService: TableService
  ) {

  }

  ngOnInit() {
    if (this.row_id > 0) {
      this.input = this.tableService.checkboxes[this.table_id].find((v) => v.id == this.row_id);
    } else {
      this.input = {
        value: false
      }
    }

    if (this.check_all) {
      this.tableService.OnCheckChange.subscribe(this.updateCheckAllInput.bind(this))
    }
  }

  onChange(value) {
    if (this.check_all) {
      this.checkboxService.OnCheckAll.next({ value, table_id: this.table_id });
    } else {
      this.tableService.OnCheckChange.next();
    }
    // console.log(this.input, this.checkboxService.values[this.table_id])
    // this.checkboxService.onCheck.next({ event: { value, row_id: this.row_id }, table_id: this.table_id })
  }

  updateCheckAllInput()
  {
    let state = true;
    let total = 0;
    for (let i of this.tableService.checkboxes[this.table_id]) {
      state = i.value ? state : i.value;
      total += i.value ? 1 : 0;
    }

    this.input.value = state;
    this.indeterminate = total > 0 && total < this.tableService.checkboxes[this.table_id].length
  }

}
