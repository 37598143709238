import { Component, OnInit } from '@angular/core';
import { TaskService } from '../../services/task.service';
import {Observable} from 'rxjs';
import {TaskModel} from '../../models/task.model';
import {TaskFormComponent} from '../task-form/task-form.component';
import { MatDialog} from '@angular/material/dialog';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {Filter} from '../../../global-components/table/interfaces/filter';
import {CheckboxService} from '../../../global-components/table/services/checkbox.service';
import {TableService} from '../../../global-components/table/services/table.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {

  public tasks: TaskModel[];
  private filters = {
    order: {
      order_by: 'id',
      order_way: 'asc'
    },
    search: ""
  };

  public priority_filters = [];
  public status_filters = [];

  constructor(
    private taskService: TaskService,
    private dialog: MatDialog,
    private ngxLoader: NgxUiLoaderService,
    private translate: TranslateService,
    private checkboxService: CheckboxService,
    private tableService: TableService
  ) {
    this.translate.get(['task.priority.low', 'task.priority.medium', 'task.priority.high']).subscribe((trans) => {
      this.priority_filters = [
        { name: trans['task.priority.low'], value: 'low' },
        { name: trans['task.priority.medium'], value: 'medium' },
        { name: trans['task.priority.high'], value: 'high' },
      ]
    })

    this.taskService.getStatuses().subscribe((statuses) => {
      this.status_filters = statuses.data.map((status) => ({ ...status, value: status.id }));
    })
  }

  ngOnInit(): void {
    this.initTasks();
    this.tableService.onDataChange.subscribe((values) => {
      if (values.table_id == "task") {
        this.tasks = values.data
      }
    });
  }

  private initTasks()
  {
    this.tableService.setData('task', { filters: this.filters });
  }

  public order(order_by)
  {
    if (this.filters.order.order_by == order_by) {
      this.filters.order.order_way = this.filters.order.order_way == "asc" ? "desc" : "asc";
    } else {
      this.filters.order.order_by = order_by
      this.filters.order.order_way = "asc"
    }

    this.initTasks()
  }

  public filter(type: string, value: Filter[] | string)
  {
    if (typeof value == "object") {
      this.filters[type] = value.filter((v) => v.selected).map((v) => v.value)
    } else {
      this.filters[type] = value;
    }

    this.initTasks()
  }

  public editTask(task): void {
    const dialogRef = this.dialog.open(TaskFormComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      // disableClose: true,
      data: {
        task: task,
        operationId: 0,
        id: task.id
      }
    });

    dialogRef.afterClosed().subscribe((task) => {
      if (task !== 0 && typeof task !== 'undefined') {
        for (let index in this.tasks) {
          if (this.tasks[index].id == task.id) {
            this.tasks[index] = task;
            return;
          }
        }
      }
    })
  }

  public deleteTask(task): void {
    Swal({
      title: "Suppression d'une tâche",
      text: "Voulez-vous vraiment supprimer cette tâche ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('operation-list.cancel'),
      confirmButtonText: this.translate.instant('operation-list.ok')
    }).then((result) => {
      if (result.value) {
        this.taskService.deleteTask(task.id)
          .subscribe(resp => {
            if (resp.result == 1) {
              this.tasks = this.tasks.filter(t => t.id != task.id);
            }
          });
      }
    })
  }

}
