import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { DomSanitizer,Meta,Title } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import { ENCRYPTION_KEY } from '../app.constant';

@Injectable({
  providedIn: 'root'
})

export class UtilService {
    static instance: UtilService;
	isBrowser: boolean = false;
    testv: any = 'Cerc Guyane';

    constructor(private router: Router, public dataService:DataService, private sanitizer: DomSanitizer, private meta: Meta, private title: Title, @Inject(PLATFORM_ID) private platformId) {
        UtilService.instance = this;
    	this.isBrowser = isPlatformBrowser(platformId);
    }

    loadConstants() {
        var constants = this.dataService.getConstants();
        return constants;
    }

    getDevice() {
        if(this.isBrowser) {
            var b = navigator.userAgent.toLowerCase();
            return { device: /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(b) ? "tablet" : /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(b) ? "phone" : "desktop"};
        } else {
            return { device: "desktop" };
        }
    }

    checkUserLoggedIn() {
        if(this.getLocalStorage('id') && this.getLocalStorage('token')) {
            return true;
        } else {
            return false;
        }
    }

    getSelectedLanguage() {
        if(this.getLocalStorage('language')) {
            return this.getLocalStorage('language');
        } else {
            return 'french';
        }
    }

    getPagination(page, totalitems, limit) {
        //defaults

        var adjacents = 1;
        if(!page) page = 1;

        //other vars
        var prev = page - 1;                                  //previous page is page - 1
        var next = page + 1;                                  //next page is page + 1
        var totalpages = Math.ceil(totalitems / limit);      //totalpages is = total items / items per page, rounded up.
        var lpm1 = totalpages - 1;                            //last page minus 1
        var allPages = [];

        //pages
        if (totalpages < (7 + (adjacents * 2))) {
            for (var counter = 1; counter <= totalpages; counter++) {
                allPages.push(counter);
            }
        } else if(totalpages >= (7 + (adjacents * 2))) {
            //close to beginning; only hide later pages
            if(page < (1 + (adjacents * 3))) {
                for (var counter = 1; counter < 4 + (adjacents * 2); counter++) {
                    allPages.push(counter);
                }
                allPages.push('...');
                allPages.push(lpm1);
                allPages.push(totalpages);
            } else if((totalpages - (adjacents * 2)) > page && page > (adjacents * 2)) {
                allPages.push(1);
                allPages.push(2);
                allPages.push('...');
                for (var counter = page - adjacents; counter <= page + adjacents; counter++) {
                    allPages.push(counter);
                }
                allPages.push('...');
                allPages.push(lpm1);
                allPages.push(totalpages);
            } else {
                allPages.push(1);
                allPages.push(2);
                allPages.push('...');

                for (var counter = totalpages - (1 + (adjacents * 3)); counter <= totalpages; counter++) {
                    allPages.push(counter);
                }
            }
        }
        return allPages;
    }

    validateUserSession(response) {
        if(response == "Invalid User/Token") {
            this.clearLocalStorage();
            this.router.navigate(['/']);
        }
    }

    getUserRole() {
        return this.getLocalStorage('role');
    }

    getDateFormat() {
        return 'dd/MM/yyyy';
    }

    calculateEstimateCost(mi_resultat_marche_montant) {
        if(mi_resultat_marche_montant && !isNaN(mi_resultat_marche_montant)) {
            if(mi_resultat_marche_montant < 100000) {
                return '< 100 000 €';
            } else if(mi_resultat_marche_montant >= 100000 && mi_resultat_marche_montant < 199999) {
                return '≥ 100 000 € ≤ 199 999 €';
            } else if(mi_resultat_marche_montant >= 200000 && mi_resultat_marche_montant < 399999) {
                return '≥ 200 000 € ≤ 399 999 €';
            } else if(mi_resultat_marche_montant >= 400000 && mi_resultat_marche_montant < 999999) {
                return '≥ 400 000 € ≤ 999 999 €';
            } else if(mi_resultat_marche_montant >= 1000000 && mi_resultat_marche_montant < 1999999) {
                return '≥ 1 000 000 € ≤ 1 999 999 €';
            } else if(mi_resultat_marche_montant >= 2000000 && mi_resultat_marche_montant < 3999999) {
                return '≥ 2 000 000 € ≤ 3 999 999 €';
            } else if(mi_resultat_marche_montant >= 4000000 && mi_resultat_marche_montant < 7999999) {
                return '≥ 4 000 000 € ≤ 7 999 999 €';
            } else if(mi_resultat_marche_montant >= 8000000 && mi_resultat_marche_montant < 11999999) {
                return '≥ 8 000 000 € ≤ 11 999 999 €';
            } else {
                return '≥ 12 000 000 €';
            }
        } else {
            return '0';
        }
    }

    numberFormat(number) {
        number = (typeof number == "string") ? number.replace(",", ".") : number;
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(parseFloat(number));
    }

    numberFormatWithoutCurrency(number) {
        number = (typeof number == "string") ? number.replace(",", ".") : number;
        return new Intl.NumberFormat('fr-FR').format(parseFloat(number));
    }

    validateDate(inputDate) {
        var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

        // Match the date format through regular expression
        if(inputDate.match(dateformat)) {
            // Extract the string into month, date and year
            var pdate = inputDate.split('/');

            var dd = parseInt(pdate[0]);
            var mm  = parseInt(pdate[1]);
            var yy = parseInt(pdate[2]);

            // Create list of days of a month [assume there is no leap year by default]
            var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
            if(mm == 1 || mm > 2) {
                if (dd > ListofDays[mm-1]) {
                    return false;
                }
            }

            if(mm == 2) {
                var lyear = false;
                if((!(yy % 4) && yy % 100) || !(yy % 400)) {
                    lyear = true;
                }
                if((lyear==false) && (dd>=29)) {
                    alert('Format de date invalide !');
                    return false;
                }
                if((lyear==true) && (dd>29)) {
                    alert('Format de date invalide !');
                    return false;
                }
            }

            return true;
        } else {
            return false;
        }
    }

    formatAmount(amount) {
        if(amount && typeof amount === "string") {
            amount = amount.replace(/\s/g, "");
        }

        if(amount && typeof amount === "string") {
            amount = amount.replace(",", ".");
            amount = amount.replace("€", "");
        }

        return amount;
    }

    replaceAll(input, replace, replacewith) {
        var i = 0, length = input.length;
        for (i; i < length; i++) {
            input = input.replace(replace, replacewith);
        }
        return input;
    }

    stringGen(len) {
        var text = "";
        var charset = "abcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < len; i++) {
            text += charset.charAt(Math.floor(Math.random() * charset.length));
        }

        return text;
    }

    checkPasswordStrength(passwordValue) {
        const lowerLetters = /[a-z]+/.test(passwordValue);
        const upperLetters = /[A-Z]+/.test(passwordValue);
        const numbers = /[0-9]+/.test(passwordValue);
        const length = (passwordValue && passwordValue.length > 6) ? true : false;

        if(lowerLetters && upperLetters && numbers && length) {
            return true;
        } else {
            return false;
        }
    }

    public setLocalStorage(name, value) {
        if(typeof value === "string") {
            localStorage.setItem(name, CryptoJS.AES.encrypt(value, ENCRYPTION_KEY).toString());
        } else {
            localStorage.setItem(name, value);
        }
    }

    public getLocalStorage(name) {
        if(localStorage.getItem(name)) {
            if(typeof localStorage.getItem(name) === "string") {
                return CryptoJS.AES.decrypt(localStorage.getItem(name), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
            } else {
                return localStorage.getItem(name);
            }
        } else {
            return null;
        }
    }

    public deleteLocalStorage(name) {
        localStorage.removeItem(name);
    }

    public clearLocalStorage() {
        localStorage.clear();
    }

    public checkIfExistsInArray(array, value): boolean {
        let exists = false;
        let items = [];

        if(array) {
            items = array.split(",");
        }

        if(items && items.length > 0) {
            items.forEach(item => {
                if(value === item) {
                    exists = true;
                }
            });
        }

        return exists;
    }

  frenchFormat(value: string, ...args: number[]): string {
    if (!value) {
      return value;
    }
    let res;
    if (value.length < 7) {
      res = this.FrenchAmountFormat((Number(value) / 1000).toFixed(args[0]).toLocaleString()).concat(' K €');
    } else {
      res = this.FrenchAmountFormat((Number(value) / 1000000).toFixed(args[0]).toLocaleString()).concat(' M €');
    }
    for (let i = 0; i < res.length; i++) {
      let code = res.charCodeAt(i);
      if (code === 8239) {
        res = res.split('');
        res[i] = ' ';
        res = res.join('');
      }
    }

    return res;
  }

  public FrenchAmountFormat(number) {
    return Intl.NumberFormat('fr-FR', { useGrouping: true }).format(parseFloat(number));
  }
}
