import {StatusDateCreating} from "./status-date";

export enum Status {
  'A valider' = 'A valider',
  Archivee = 'Archivee',
  Projetee = 'Projetee',
  Vivante = 'Vivante',
  Conflit = 'Conflit',
  'Vivante en conflit' = 'Viv Conflit',
  'Projetee en Conflit' = 'Proj Conflit'
}

export enum OperationStatus {
  'À valider' = 'A valider',
  Archivée = 'Archivee',
  Projetée = 'Projetee',
  Vivante = 'Vivante',
  Conflit = 'Conflit',
  'Viv Conflit' = 'Viv Conflit',
  'Projetee en Conflit' = 'Proj Conflit',
  'BOAMP' = 'BOAMP'
}
export enum OperationStatusReversed {
  'A valider' = 'À valider',
  Archivee = 'Archivée',
  Projetee = 'Projetée',
  Vivante = 'Vivante',
  Conflit = 'Conflit',
  'Viv Conflit' = 'Viv Conflit',
  'Proj Conflit' = 'Projetee en Conflit',
  'BOAMP' = 'BOAMP'
}
export namespace Status {
  export function values() {
    return Object.keys(Status).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
export namespace OperationStatus {
  export function values() {
    return Object.keys(OperationStatus).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }

  export function getValue(key) {
    return Object.keys(OperationStatus).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue' && OperationStatus[type] == key);
  }
}
