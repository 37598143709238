<span>
  <img #menuTrigger [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" class="general-sort" src="/assets/icons/general-sort-table.svg" width="14">

  <mat-menu #menu="matMenu">
    <li mat-menu-item *ngFor="let filter of filters">
      <mat-checkbox color="primary" (click)="onClick($event)" (change)="onChange($event, filter)">
        {{ filter.name }}
      </mat-checkbox>
    </li>
  </mat-menu>
</span>
