import {Injectable} from '@angular/core';
import {HttpClientService} from '../../shared/services/http-client.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserRegistrationModel} from '../models/user-registration-model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private httpClientService: HttpClientService, private router: Router) {
  }

  public register(user: UserRegistrationModel): Observable<any> {
    return this.httpClientService.post('/users/register', user);
  }

}
