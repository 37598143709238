export class HousingModel {
  public housingId?: string;
  public name?: string;
  public code_ape?: string;
  public company_name?: string;
  public price?: number;
  public deleted?: string;
  public fk_codeAPE?: string;
  public overspend?: number;
  public main_housing_id?: string;
  public op_id?: string;
}
