export enum SettingsTables {
  'Financement' = 'funding',
  'Sous-secteur' = 'subsector',
  'Localité' = 'location',
  'Allotissement' = 'housing',
  'Maître d\'ouvrage' = 'client',
  'Entreprise' = 'enterprise',
  'Destinataires' = 'recipient',
  'Phase' = 'phase',
  'Statut des tâches' = 'task-status'
}

export namespace SettingsTables {
  export function values() {
    return Object.keys(SettingsTables).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue'
    );
  }

  export function getValue(key) {
    return Object.keys(SettingsTables).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue' && SettingsTables[type] == key)[0];
  }
}
