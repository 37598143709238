import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {VigilanceService} from '../../services/vigilance.service';
import {VigilanceModel} from '../../models/vigilance-model';
import {takeUntil} from 'rxjs/operators';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogBase} from "../../../shared/abstractions/dialog-base";

@Component({
  selector: 'app-add-vigilance',
  templateUrl: './add-vigilance.component.html',
  styleUrls: ['./add-vigilance.component.scss']
})
export class AddVigilanceComponent extends DialogBase implements OnInit, OnDestroy {
  public vigiliance: VigilanceModel = {};
  private id: number;

  constructor(public vigilianceService: VigilanceService, @Inject(MAT_DIALOG_DATA) data) {
    super();
    this.loadData(data);
  }

  ngOnInit(): void {
  }

  public loadData(data): void {
    this.id = data.id;
  }

  public cancel(): void {
    this.vigilianceService.closeVigilance$.next();
  }

  public addVigiliance(): void {
    this.isCalled = true;
    this.vigiliance.operation_id = this.id.toString();
    this.vigilianceService.addVigilance(this.vigiliance)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value.status == 'success') {
          this.vigiliance.id = value.result;
          this.vigilianceService.addVigilanceList$.next(this.vigiliance);
        }
        this.cancel();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
