<div class="filters">
    <div class="heading">
        <p>{{ 'applied-filters.title' | translate }}</p>
        <img (click)="closeFilter()" src="../../../assets/icons/close.svg" width="15" alt="">
    </div>
    <div class="scrollable">
        <div class="wrapper" *ngFor="let filter of filters">
            <div class="name">
                {{filter?.name}} <span *ngIf="filter?.count">({{filter?.count }})</span>
            </div>
            <div class="value">
                {{filter?.value}}
            </div>
        </div>
    </div>
</div>
