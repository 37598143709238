<div class="left-sidebar">
  <div class="logo">
    <a href="https://www.cercguyane.fr" target="_blank">
      <img src="{{cdn_url}}/assets/icons/{{logo_url}}" alt="Logo"/>
    </a>
  </div>

  <ul>
    <li (click)="setActiveMenuItem('dashboard'); redirectLink('/dashboard')"
        class="statistic">
      <div [ngClass]="{'active-item': activeMenuItem === 'dashboard'}" class="title-item">
        <img *ngIf="activeMenuItem==='dashboard';else inactiveDashboard"
             alt="" src="{{cdn_url}}/assets/icons/sidebar-dashboard-purple.svg" width="19px">
        <ng-template #inactiveDashboard>
          <img alt="" src="{{cdn_url}}/assets/icons/sidebar-dashboard-inactive.svg" width="19px">
        </ng-template>
        <span><a [routerLink]="['/dashboard']">{{'left-sidebar.dashboard' | translate}}</a></span>
      </div>
    </li>

    <li>
      <app-accordion class="white-bg menu" [isOpen]="true">
        <div class="title-item">
          <img *ngIf="activeMenuItem==='operations';else inactiveOp"
               alt="" src="{{cdn_url}}/assets/icons/operations-menu-purple.svg" width="19px">
          <ng-template #inactiveOp>
            <img alt="" src="{{cdn_url}}/assets/icons/operations-inactive.svg" width="19px">
          </ng-template>
          <span><a [routerLink]="['/operations']">{{ 'left-sidebar.operations' | translate }}</a></span>
        </div>
        <div class="panel-content">
          <div (click)="setActiveMenuItem('operations'); redirectLink('/operations', 'all')" class="sub-menu" *ngIf="!authService.isUserClient()">
            {{ 'left-sidebar.all' | translate }}
          </div>
          <div (click)="setActiveMenuItem('operations'); redirectLink('/operations', 'user')"
               *ngIf="authService.isUserClient()"
               class="sub-menu">
            {{ 'left-sidebar.my-operations' | translate }}
          </div>
          <div (click)="setActiveMenuItem('operations'); redirectLink('/operations/inprogress')" class="sub-menu">
            {{ 'left-sidebar.ongoing' | translate }}
          </div>
          <div (click)="setActiveMenuItem('vigilance'); redirectLink('/vigilances')"
               *ngIf="authService.isUserAdmin()"
               class="sub-menu">
            {{ 'left-sidebar.vigilance' | translate }}
          </div>
        </div>
      </app-accordion>
    </li>

    <li (click)="setActiveMenuItem('statistics'); redirectLink('/statistics')" *ngIf="!authService.isUserEnterprise()"
        class="statistic">
      <div [ngClass]="{'active-item': activeMenuItem === 'statistics'}" class="title-item">
        <img *ngIf="activeMenuItem==='statistics';else inactiveStat" alt="" src="{{cdn_url}}/assets/icons/statistic-menu-purple.svg" width="19px">
        <ng-template #inactiveStat>
          <img alt="" src="{{cdn_url}}/assets/icons/statistic-menu-inactive.svg" width="19px">
        </ng-template>
        <span><a [routerLink]="['/statistics']">{{ 'left-sidebar.statistics' | translate }}</a></span>
      </div>
    </li>

    <li (click)="setActiveMenuItem('rapports')">
      <app-accordion class="white-bg menu" [isOpen]="true" *ngIf="!authService.isUserClient()">
        <div class="title-item">
          <img *ngIf="activeMenuItem==='rapports';else inactiveDs" alt="" src="{{cdn_url}}/assets/icons/data-stream-menu-purple.svg" width="19px">
          <ng-template #inactiveDs>
            <img alt="" src="{{cdn_url}}/assets/icons/data-stream-menu-inactive.svg" width="19px">
          </ng-template>
          <span>{{ 'left-sidebar.report' | translate }}</span>
        </div>
        <div class="panel-content">
          <div (click)="redirectLink('/newsletters/template')" *ngIf="authService.isUserAdmin()" class="sub-menu">
            <span><a [routerLink]="['/newsletters/template']">{{ 'left-sidebar.datastream' | translate }}</a></span>
          </div>
        </div>
      </app-accordion>
    </li>

    <li (click)="setActiveMenuItem('plus')">
      <app-accordion class="white-bg menu" [isOpen]="true">
        <div class="title-item">
          <span>Plus</span>
        </div>
        <div class="panel-content">
          <div (click)="setActiveMenuItem('plus'); redirectLink('/guide')" class="sub-menu">
            {{ 'left-sidebar.guide' | translate }}
          </div>
          <div *ngIf="!appState.isSpotProject" (click)="setActiveMenuItem('plus'); redirectLink('/enterprises')" class="sub-menu">
            {{ 'left-sidebar.enterprises' | translate }}
          </div>
          <div *ngIf="!appState.isSpotProject" (click)="setActiveMenuItem('plus'); redirectLink('/clients')" class="sub-menu">
            {{ 'left-sidebar.clients' | translate }}
          </div>
        </div>
      </app-accordion>
    </li>
  </ul>
</div>