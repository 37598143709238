export enum Visibility {
  Privée = '1',
  Public = '2'
}

export namespace Visibility {
  export function values() {
    return Object.keys(Visibility).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue'
    );
  }

  export function getValue(key) {
    return Object.keys(Visibility).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue' && Visibility[type] == key);
  }
}


