import {UntypedFormGroup} from '@angular/forms';
import {FundingObjectModel} from '../models/funding-object-model';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {IConfig, NON_WHITESPACE_REGEX} from './IConfig';
import {HttpClientService} from '../../shared/services/http-client.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

export class FundingConfig implements IConfig {
  name = 'funding';
  form = new UntypedFormGroup({});
  model = new FundingObjectModel();
  fields: FormlyFieldConfig[];
  columns = ['id', 'funding'];
  defaultHttpParams = {orderby: 'financial_organisation', order: 'desc'};

  constructor(private http: HttpClientService, private translate: TranslateService) {
    this.translate.get(['settings.funding', 'settings.funding-help']).subscribe(value => {
      this.fields = [
        {
          key: 'financial_organisation',
          type: 'input',
          name: 'funding',
          templateOptions: {
            label: value['settings.funding'],
            placeholder: value['settings.funding-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        }
      ];
    });
  }

  getData(params = null): Observable<any> {
    if (!params) {
      params = this.defaultHttpParams;
    }
    return this.http.post('/financial/index', params).pipe(map(value => value.data));
  }

  addItem(): Observable<boolean> {
    return this.http.post('/financial/addfinancial', this.model).pipe(map(value => value.success));
  }

  editItem(): Observable<boolean> {
    return this.http.post('/financial/editfinancial', this.model).pipe(map(value => value.success));
  }

  getItemData(id): Observable<any> {
    return this.http.post('/financial/getfinancial', {id: id}).pipe(map(value => value.data));
  }

  deleteItem(id): Observable<boolean> {
    return this.http.post('/financial/delete', {id: id}).pipe(map(value => value.success));
  }

  setOrder(field) {
    // check if the field is ID
    if (field === 'id') {
      this.setOrderParameter(field);
    } else {
      // otherwise get name from the form fields
      const name = this.fields.find(value => value.name === field)?.key.toString();
      if (name) {
        this.setOrderParameter(name);
      } else {
        this.setOrderParameter(field);
      }
    }
  }

  private setOrderParameter(newParameter) {
    if (this.defaultHttpParams.orderby === newParameter) {
      this.setOrderType();
    } else {
      this.defaultHttpParams.orderby = newParameter;
    }
  }

  private setOrderType() {
    if (this.defaultHttpParams.order === 'desc') {
      this.defaultHttpParams.order = 'asc';
    } else {
      this.defaultHttpParams.order = 'desc';
    }
  }
}
