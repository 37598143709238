import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientService} from './http-client.service';
import {map} from "rxjs/operators";

@Injectable()
export class UserService {
  constructor(private httpClient: HttpClientService) {

  }

  public getClientUsers(getAll = false): Observable<any> {
    let params = {};
    if (getAll) {
      params = {search: 'all'};
    }
    return this.httpClient.post('/users/getuserlistforclient', params).pipe(
      map(value => value.user)
    );
  }
}
