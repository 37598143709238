export class TaskModel {
  id: number;
  id_operation: number;
  id_phase: number;
  phase: string;
  name: string;
  operation_name: string;
  description: string;
  priority: "low" | "medium" | "high";
  id_task_status: number;
  status: string;
  status_color: string;
  assigned_to: number;
  fullname: string;
  deadline_at: Date;
}
