import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import { UpdateOperationListComponent } from './components/update-operation-list/update-operation-list.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';


@NgModule({
  declarations: [UpdateOperationListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AccordionModule
  ]
})
export class UpdateOperationsModule {
}
