import {Injectable} from '@angular/core';
import {HttpClientService} from '../../shared/services/http-client.service';
import {Observable} from 'rxjs';
import {UserAdvancedSearchService} from './user-advanced-search.service';
import {UsersTableModel} from '../models/users-table-model';
import {map} from "rxjs/operators";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {TranslateService} from "@ngx-translate/core";
import {DropdownModel} from "../../shared/models/dropdown-model";

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  public dropdownSettings: IDropdownSettings = {};

  constructor(private http: HttpClientService, private translate: TranslateService, private userAdvancedSearch: UserAdvancedSearchService) {
    this.translate.get(['common.select-all', 'common.unselect-all', 'common.search']).subscribe(value => {
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: value['common.select-all'],
        unSelectAllText: value['common.unselect-all'],
        searchPlaceholderText: value['common.search'],
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
    });
  }

  public getUsersList(): Observable<UsersTableModel> {
    let url = '/users/index';
    return this.http.post(url, this.userAdvancedSearch.searchModel);
  }

  public getClientUsers(getAll = false): Observable<any> {
    let params = {};
    if (getAll) {
      params = {search: 'all'};
    }
    return this.http.post('/users/getuserlistforclient', params).pipe(
      //map(value => value.user.slice(0, 100))
      map(value => value.user)
    );
  }

  public getClientEnterpriseByRole(role): Observable<DropdownModel[]> {
    let params = {
      role: role
    };

    return this.http.post('/users/getcliententerprisebyrole', params).pipe(
      map(value => value.data)
    );
  }



  public getUser(id) {
    let params = {
      id: id
    };
    return this.http.post('/users/getuser', params);
  }

  public editUser(user) {
    delete user.user;
    delete user.token;
    return this.http.post('/users/editnewuser', user);
  }

  public deleteUser(id) {
    let params = {
      id: id
    };
    return this.http.post('/users/delete', params);
  }

  addUser(user: any) {
    return this.http.post('/users/addnewuser', user);
  }
}
