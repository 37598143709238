<div class="wrapper">
  <div class="header">
    <div>
      <img width="32" src="../../../assets/icons/add-file.svg">
      <span>{{'operation-list.grant' | translate}}</span>
    </div>
    <img width="18" class="right" src="../../../../assets/icons/close.svg" (click)="cancel()">
  </div>
  <div class="operations">
    <h4>{{'operation-list.grant-help' | translate}}</h4>
    <ul>
      <li *ngFor="let op of operations">
        {{op.name}}
      </li>
    </ul>
  </div>
  <div class="flex">
    <div>
      <h5>{{'add-operation.user-access' | translate}}</h5>
      <mat-select class="select-form" multiple [(ngModel)]="data.permision_client"
                  placeholder="{{ 'add-operation.user-access-help' | translate }}">
        <mat-option *ngFor="let item of users$ | async" [value]="item.id">
          {{ item.name || '-'}} {{item.surname || '-'}}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="buttons">
    <button class="cancel" (click)="cancel()">{{'housing.cancel' | translate}}</button>
    <button class="add" (click)="grantAccess()">{{'funding.save' | translate}}</button>
  </div>

</div>
