import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TaskRoutingModule} from './task-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {UserManagementService} from "../user-management/services/user-management.service";
import {UserAdvancedSearchService} from "../user-management/services/user-advanced-search.service";
import { TaskFormComponent } from './components/task-form/task-form.component';
import { TaskComponent } from './components/task/task.component';


@NgModule({
  declarations: [TaskFormComponent, TaskComponent],
  imports: [
    CommonModule,
    TaskRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [UserManagementService, UserAdvancedSearchService],
  exports: [TaskFormComponent, TaskComponent]
})
export class TaskModule {
}
