import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import * as constant from '../../app.constant';
import Swal from 'sweetalert2';

import {ActivatedRoute, Router} from '@angular/router';
import { MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ActivityService} from "../../activity/services/activity.service";
import {ActivityModel} from "../../activity/models/activity-model";
import {AdvancedActivityService} from "../../activity/services/advanced-activity.service";
import {AppStateService} from "../../shared/services/app-state.service";
import { LoginService } from 'app/login/service/login.service';
import { AuthService } from 'app/shared/services/auth.service';
import { AdvancedSearchService } from 'app/operation/service/advanced-search-service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Output() public showSideBarEmitter = new EventEmitter<boolean>();
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  public type: string;
  public isLoaded = true;
  public title = '';
  private unsubscribe$ = new Subject<void>();
  public notifications: ActivityModel[];


  constructor(private loginService: LoginService,
              public authService: AuthService,
              private router: Router,
              private dialog: MatDialog,
              private advancedSearchService: AdvancedSearchService,
              private changeDetector: ChangeDetectorRef,
              private ngZone: NgZone,
              private translate: TranslateService,
              private activityService: ActivityService,
              public advancedActivityService: AdvancedActivityService,
              public appStateService: AppStateService) {
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.getQueryparams();
    this.loadNotifications();
    if (!this.advancedSearchService.searchModel) {
      this.advancedSearchService.setup();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getQueryparams(): void {
    this.isLoaded = false;
    this.advancedSearchService.setOperationType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((type: string) => {
        this.isLoaded = false;
        if (type == null || type == "all") {
          this.type = "All";
        } else {
          this.type = "User";
        }
        this.ngZone.runGuarded(this.changeDetector.markForCheck.bind(this.changeDetector));
        this.isLoaded = true;

      });
  }

  public showSideBar(): void {
    if (this.router.url.includes('operations/edit') || this.router.url.includes('operations/add')) {
      this.appStateService.toggleOperationsAddEditSidebar$.emit();
      this.showSideBarEmitter.emit(false);
      return;
    }
    this.showSideBarEmitter.emit();
  }

  public redirectLink(link: string): void {
    this.router.navigate([link]);
  }

  // Todo
  public logOut(): void {
    Swal({
      title: "Se déconnecter?",
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'cancel',
      confirmButtonText: 'ok'
    }).then((result) => {
      if (result.value) {
        this.loginService.logOut();
      }
    });
  }

  // Todo
  public search(): void {
    let e = this.appStateService.activeSearch;
    const dialogRef = this.dialog.open(e, {
      height: 'auto',
      maxHeight: '90vh',
      width: '1000px',
      maxWidth: '1000px',
      disableClose: true
    });
  }


  private loadNotifications() {
    this.activityService.getUnreadNotificationsList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.notifications = value?.notifications?.slice(0, 50);
        this.advancedActivityService.notificationsCount = value?.notifications?.length;
      });
  }

  private initializeSubscriptions() {
    this.title = this.appStateService.activeHeader;
    this.appStateService.setHeader
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.title = value;
      });
    this.activityService.refreshNotifications
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.loadNotifications();
      });
  }

  ngAfterContentChecked(): void {
    this.checkHeaderButtons();
  }

  checkHeaderButtons() {
    let elHeader = document.getElementById('my-header');
    if (elHeader.clientWidth < 500) {
      if (!elHeader.classList.contains('small-buttons')) {
        elHeader.classList.add('small-buttons');
      }
    } else {
      if (elHeader.classList.contains('small-buttons')) {
        elHeader.classList.remove('small-buttons');
      }
    }
  }
}
