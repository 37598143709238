<app-generic-modal
  (close)="cancel()"
  (done)="editObservation()"
  [headerText]="'observations.title-edit' | translate"
  [iconName]="'add-observation.svg'"
  [isCalled]="isCalled"
>
  <div class="flex flex-1">
    <h5>{{ 'observations.message' | translate }}</h5>
    <textarea [(ngModel)]="observation.description" placeholder="{{ 'observations.message-helper' | translate }}"
              type="text">
    </textarea>
  </div>
</app-generic-modal>
