export enum Vigilance {
  Financier = 'financier',
  Foncier = 'foncier',
  Juridique = 'juridique',
  Administratif = 'administratif',
  Autres = 'autres',
  Technique = 'technique'
}

export namespace Vigilance {
  export function values() {
    return Object.keys(Vigilance).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

export enum VigilanceOperations {
  financier = 'Financier',
  foncier = 'Foncier',
  juridique = 'Juridique',
  administratif = 'Administratif',
  autres = 'Autres',
  technique = 'Technique'
}

export namespace VigilanceOperations {
  export function values() {
    return Object.keys(VigilanceOperations).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

