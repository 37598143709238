export enum StepperValues {
  Red = 'red',
  Green = 'green',
  Gray = 'gray'
}

export enum StepperValuesNumbers {
  Red = -1,
  Green = 1,
  Gray = 0
}
