import {FinancialDetailsModel} from '../../operation/model/financial-details-model';
import {HousingDetailsModel} from '../../operation/model/housing-details-model';
import {ObservationModel} from '../../operation/model/observation-model';
import {ValidationModel} from "../../validation/models/validation-model";

export class ReportModel {

  constructor() {
    this.id = '1';
    this.name = 'Lycée Macouria, construction de batiment de 900 places';
    this.creation_date = '01/01/2021';
    this.last_modification_date = '01/01/2021';

    this.client = 'Ctg (Public)';
    this.linkedoperations = ['3625, 2736, 3625, 2736'];
    this.description = 'Travaux de batiment pour la construction d’un lycee de 900 places, ravaux de batiment pour la construction d’un lycee de 900 places, ravaux de batiment pour la construction d’un lycee de 900 places, ravaux de batiment pour la construction d’un lycee de 900 places, ravaux de batiment pour la construction d’un lycee de 900 places, ravaux de batiment pour la construction d’un lycee de 900 places';
    this.location_name = 'Sample data';
    this.gen_type_work = 'Sample data';
    this.sector = 'Sample data';
    this.estimated_cost = 'Sample 12 M €';

    this.pi_ca_ao_date = '01/01/2021';
    this.pi_ca_ao_date_reelle = true;
    this.pi_ca_selection_date = '2021-12-23';
    this.pi_ca_selection_date_reelle = true;
    this.pi_ca_prestataire = 'Y. LETIRAN';

    this.pi_mo_ao_date = '01/01/2021';
    this.pi_mo_ao_date_reelle = false;
    this.pi_mo_selection_date = '01/01/2021';
    this.pi_mo_selection_date_reelle = true;
    this.pi_mo_prestataire = 'Y. LETIRAN';

    this.mi_ao_travaux_date = '01/01/2021';
    this.mi_ao_travaux_date_reelle = true;

    this.mi_ordre_service_date = '2021-12-23';
    this.mi_ordre_service_date_reelle = false;

    this.mi_livraison_date = '2022-07-08';
    this.mi_livraison_date_reelle = false;

    // procedure ?
    this.financial = [
      {
        financial_organisation: 'Europe 1',
        type: 'DRES 1',
        submission_date: '01/01/2021',
        awarding_date: '01/01/2021',
        amount: '5000 M € '
      },
      {
        financial_organisation: 'Autres',
        type: 'Pland\'urgence',
        submission_date: '01/01/2021',
        awarding_date: '01/01/2021',
        amount: '36000000,00 €'
      },
      {
        financial_organisation: 'CTG',
        type: 'DRES',
        submission_date: '01/01/2021',
        awarding_date: '01/01/2021',
        amount: '50 M € '
      }
    ];

    // this.total_amount = '95000000,00 €'; // Not ready

    this.housing = [
      {
        name: 'AssainissementEU/EP',
        company_name: 'Test Name',
        price: 1
      },
      {
        name: 'Réseauxsouples',
        company_name: 'Test Name',
        price: 1
      },
      {
        name: 'test/EP',
        company_name: 'Test Name',
        price: 1
      }, {
        name: 'first/EP',
        company_name: 'Test Name',
        price: 1
      },
      {
        name: 'Union/EP',
        company_name: 'Test Name',
        price: 1
      },
      {
        name: 'Arrign/EP',
        company_name: 'Test Name',
        price: 1
      },
      {
        name: 'Testing/EP',
        company_name: 'Test Name',
        price: 1
      }
    ];
    this.total_price = '45000,00 €';

    this.observations = [
      {
        description: 'Test observation 1',
        creation_date: '01/01/2021',
        name: 'Jacques Dumas'
      },
      {
        description: 'Test observation 2',
        creation_date: '01/01/2021',
        name: 'Jean Dupont'
      },
      {
        description: 'Test observation 3',
        creation_date: '01/01/2021',
        name: 'Jacques Dumas'
      },
      {
        description: 'Test observation 4',
        creation_date: '01/01/2021',
        name: 'Jean Dupont'
      }
    ];

    this.vigilance = [
      {
        actors: "Région",
        date: "2021-09-07",
        description: "Marchés non attribués, interruption de la procédure. Pose des questions sur le maintien du programme.",
        domaine: "administratif",
        resolved: false
      },
      {
        actors: "Région",
        date: "2021-09-07",
        description: "Marchés non attribués, interruption de la procédure. Pose des questions sur le maintien du programme.",
        domaine: "administratif",
        resolved: true
      },
      {
        actors: "Région",
        date: "2021-09-09",
        description: "Marchés non attribués, interruption de la procédure. Pose des questions sur le maintien du programme.",
        domaine: "administratif",
        resolved: false
      },
      {
        actors: "Région",
        date: "2021-09-10",
        description: "Marchés non attribués, interruption de la procédure. Pose des questions sur le maintien du programme.",
        domaine: "administratif",
        resolved: true
      },
    ]

    this.validations = [];
    this.incomes = [];
    this.expenses = [];
  }

  id: string;
  name: string;
  creation_date: string;
  last_modification_date: string;
  client_logo_src: string;
  progress?: string; // ?
  client: string;
  linkedoperations: string[];
  description: string;
  location_name: string;
  gen_type_work: string;
  sector: string;
  estimated_cost: string;
  pi_ca_ao_date: string;
  pi_ca_ao_date_reelle: boolean;
  pi_ca_selection_date: string;
  pi_ca_selection_date_reelle: boolean;
  pi_ca_prestataire: string;
  pi_mo_ao_date: string;
  pi_mo_ao_date_reelle: boolean;
  pi_mo_selection_date: string;
  pi_mo_selection_date_reelle: boolean;
  pi_mo_prestataire: string;
  mi_ao_travaux_date: string;
  mi_ao_travaux_date_reelle: boolean;
  mi_ordre_service_date: string;
  mi_ordre_service_date_reelle: boolean;
  mi_livraison_date: string;
  mi_livraison_date_reelle: boolean;
  financial: FinancialDetailsModel[]; // any temp
  // type: string;
  // submission_date: string;
  // awarding_date: string;
  amount: string;
  vigilance: {
    domaine: string,
    resolved: boolean,
    description: string,
    actors: string,
    date: string
  }[]
  validations: ValidationModel[];
  incomes: Income[];
  expenses: Expense[];


  housing: HousingDetailsModel[]; // any - temp
  total_price: string;
  observations: ObservationModel[];


}

export interface Income {
  id: number;
  buyer: string;
  description: string;
  paid_at: Date;
  amount: number;
}
export interface Expense {
  id: number;
  seller: string;
  description: string;
  paid_at: Date;
  amount: number;
}
