import {EventEmitter, Injectable} from '@angular/core';
import {UserSearchModel} from '../models/user-search-model';

@Injectable()
export class UserAdvancedSearchService {
  public searchModel: UserSearchModel;
  public applyFilter$ = new EventEmitter<void>();

  public setup() {
    if (!this.searchModel) {
      this.searchModel = {
        page: 1,
        show: 25,
        orderby: 'name',
        order: 'desc'
      };
    }
  }
}
