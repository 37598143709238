import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainHeaderComponent} from './main-header/main-header.component';
import {LeftSidebarComponent} from './left-sidebar/left-sidebar.component';
import {SharedModule} from '../shared/shared.module';
import {AppliedFiltersComponent} from './applied-filters/applied-filters.component';
import {ActivityModule} from "../activity/activity.module";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [MainHeaderComponent, LeftSidebarComponent, AppliedFiltersComponent],
  imports: [
    CommonModule,
    SharedModule,
    ActivityModule,
    RouterModule
  ],
  exports: [MainHeaderComponent, LeftSidebarComponent, AppliedFiltersComponent]
})
export class ComonModule {
}
