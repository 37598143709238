import {EventEmitter, Injectable} from '@angular/core';
import {VigilanceSearchModel} from "../models/vigilance-search-model";

@Injectable({
  providedIn: 'root'
})
export class VigilanceSearchService {
  public searchModel: VigilanceSearchModel;
  public applyFilter$ = new EventEmitter<void>();
  constructor() { }

  public setup() {
    if (!this.searchModel) {
      this.searchModel = {
        page: 1,
        show: 50,
        order: 'desc',
        orderby: 'id',
      };
    }
  }
}
