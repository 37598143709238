import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as constant from '../../app.constant';
import { AuthService } from './auth.service';

@Injectable()
export class HttpClientService {
    private api_url = constant.ACTIVE_ENVIRONMENT.api_url;
    private node_api_url = constant.ACTIVE_ENVIRONMENT.report_api_url;

    constructor(private http: HttpClient, private router: Router, private authService: AuthService) {
    }

    private setUserParams() {
        if (!this.authService.isTokenValid()) {
            this.router.navigate(['/login']);
        }
        let params = {
          user: this.authService.getLocalStorage('id'),
          token: this.authService.getLocalStorage('token'),
          space_name: constant.space_name
        };
        return params;
    }

    private setHeaders(): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        return headers;
    }

    public post(url: string, httpParams?): Observable<any> {
        return this.http.post(
            this.api_url.concat(url),
            { params: {...this.setUserParams(), ...httpParams} },
            { headers: this.setHeaders() });
    }

    public node(httpType, uri: string, httpParams = null, headers = {}): Observable<any> {
      if (httpType.toUpperCase() === "GET") {
        return this.http.get(
          this.node_api_url.concat(uri),
          { headers: this.setHeaders() }
        );
      } else {
        return this.http[httpType.toLowerCase()](
          this.node_api_url.concat(uri),
          httpParams
        );
      }
    }

    public postPure(url: string, httpParams?): Observable<any>{
      return this.http.post(url, httpParams);
    }

    public postText(url: string, httpParams?): Observable<any> {
      return this.http.post(
        this.api_url.concat(url),
        { params: {...this.setUserParams(), ...httpParams} },
        { responseType: "text", headers: this.setHeaders() });
    }

  public postFormData(url: string, formData?): Observable<any> {
    formData.append('user', this.authService.getLocalStorage('id'));
    formData.append('token', this.authService.getLocalStorage('token'));
    formData.append('space_name', constant.space_name);
    return this.http.post(this.api_url.concat(url), formData);
    }
}
