<div class="spinner-block">
  <ngx-ui-loader [fgsColor]="'#543bff'"
                 [overlayColor]="'#EFEDFD'"
                 fgsSize="75"
                 fgsType="ball-spin-clockwise-fade-rotating"
                 loaderId="op_details"></ngx-ui-loader>
  <div *ngIf="isLoaded" class="operation-details">
    <div class="operation-header">
      <img *ngIf="operation?.operationdata?.sector == 'LOG'" alt="operation-details"
           class="main-operation-icon" src="{{ cdn_url }}/assets/icons/table-log.svg" width="32px">
      <img *ngIf="operation?.operationdata?.sector == 'BNR'" alt="operation-details"
           class="main-operation-icon" src="{{ cdn_url }}/assets/icons/table-bnr.svg" width="32px">
      <img *ngIf="operation?.operationdata?.sector == 'TP'" alt="operation-details"
           class="main-operation-icon" src="{{ cdn_url }}/assets/icons/table-tp.svg" width="32px">

      <div class="center">
        <h2>{{ operation?.operationdata?.name }}</h2>
        <div class="info-icons">
          <div *ngIf="operation?.files?.length" class="icon">
            <img alt="operation-details-1"
                 src="{{ cdn_url }}/assets/icons/operation-details-files.svg"
                 title="Pièces jointes" width="9">
          </div>
          <div *ngIf="operation?.vigilance?.length" class="icon">
            <img alt="operation-details-2"
                 src="{{ cdn_url }}/assets/icons/operation-details-vigilance.svg"
                 title="Points de vigilance" width="17">
            <span>{{'dashboard.vigilance-short' | translate}}</span>
          </div>
          <div *ngIf="operation?.observations?.length" class="icon">
            <img alt="operation-details-3"
                 src="{{ cdn_url }}/assets/icons/operation-details-observation.svg"
                 title="Observations" width="18">
            <span>{{'observations.title-short' | translate}}</span>

          </div>
        </div>

      </div>
      <div>

        <app-spot-button [height]="'22'" [ngClass]="{'observer-icon' : this?.operation?.is_watching}"
                         [text]="'operation-details.watch-subscribe' | translate"
                         [width]="'32'"
                         class="hover-content">

          <div class="popup-block">
            <app-popup>
              <div class="content">
                <div (click)="addWatchCurrentUser(authService.getLocalStorage('id'))" class="header">
                  <img alt="operation-details-1" src="{{ cdn_url }}/assets/icons/operation-details-files.svg"
                       title="Pièces jointes"
                       width="9">

                  <span
                    *ngIf="!operation?.is_watching; else not_observe">{{'operation-details.watch-subscribe' | translate}}</span>
                  <ng-template #not_observe>
                    <span>
                      {{'operation-details.watch-unsubscribe' | translate}}
                    </span>
                  </ng-template>
                </div>
                <div *ngIf="authService.isUserAdmin()" class="observers">
                  <h5>{{'operation-details.watch-observers' | translate}}</h5>
                  <div>
                    <ul>
                      <li *ngFor="let user of (watchOperationUsers$ | async)?.data as users; let i = index">
                        <span>{{(user.name | titlecase) + ', ' + (user.surname | titlecase)}}</span>
                        <img (click)="deleteWatchOperationUser(user.user_id)"
                             alt="close"
                             src="{{ cdn_url }}/assets/icons/close-operation.svg" width="10px">
                      </li>
                    </ul>
                  </div>
                </div>
                <div *ngIf="authService.isUserAdmin() && activeUsersList$ | async as users" class="add-observers">
                  <ng-multiselect-dropdown
                    [settings]="multipleDropdownSettings"
                    (onDeSelect)="deleteWatchOperationUser($event)"
                    (onSelect)="addWatchOperationUser($event)"
                    [(ngModel)]="watchUsersIds"
                    [data]="users"
                    placeholder="{{'operation-details.watch-add' | translate}}">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </app-popup>
          </div>
        </app-spot-button>
        <app-spot-button (click)="editOperation()"
                         *ngIf="operation?.can_edit"
                         [height]="'22'"
                         [text]="'operation-details.edit' | translate"
                         [width]="'32'"></app-spot-button>
        <!--        <img width="18" (click)="editOperation()" *ngIf="operation?.can_edit"-->
        <!--             src="{{ cdn_url }}/assets/icons/edit-table.svg" alt="">-->

      </div>
      <div class="right">
        <img (click)="closeOperation()" alt="close" class="close-icon"
             src="{{ cdn_url }}/assets/icons/close-operation.svg" width="18px">
      </div>
    </div>
    <div class="overflow" id="overflow">
      <div class="operation-stepper">
        <div *ngFor="let step of stepper; let i=index; let f=first; let l=last" [class]="step" [ngClass]="{'gi-5': l, 'gi-1': f}"
             class="step-content grid-item">
          <span *ngIf="!f" class="step-line step-{{stepper[i-1]}}"></span>
          <span class="check-step">
                    <img *ngIf="step === 'green'" alt="check-step"
                         src="{{ cdn_url }}/assets/icons/check-step-purple.svg"
                         width="34">
                    <img *ngIf="step === 'red'" alt="" height="34" src="{{ cdn_url }}/assets/icons/red-step.svg"
                         width="35">
                    <span>{{i + 1}}</span>
                </span>
          <span *ngIf="!l" class="step-line"></span>
        </div>
        <div class="caption" [ngClass]="{'gi-5-t': l, 'gi-1': f}" *ngFor="let phase of phases | async; let f=first; let l=last">{{ phase.name }}</div>
      </div>
      <div class="steps-text">

      </div>
      <app-accordion [class]="['accordion-item-padding']" [isOpen]="true">
        <div class="title-item">
          <div>
            <img alt="" src="../../../../assets/icons/general-informations.svg" width="24">
            <span>{{ 'operation-details.general-information' | translate }}</span>
            <span *ngIf="checkChangedSection('general')"
                  class="validate-span">{{'operation-details.to-validate' | translate}}</span>
          </div>
        </div>
        <div class="panel-content">
          <div class="wrapper">
            <div class="flex-3 flex">
              <div *ngFor="let item of generalInformation; let i = index;">
                <h5 class="grey subtitle">{{item?.name}}</h5>
                <span [ngClass]="{'old-value': item?.newvalue}" class="p1">{{item?.oldvalue || '-'}}</span>
                <span *ngIf="item?.newvalue" class="new-value p1">{{item?.newvalue}}</span>
              </div>
            </div>
          </div>
          <div *ngIf="isLogementShow" class="wrapper">
            <h5 class="sub-heading">{{ 'operation-details.logement' | translate }}</h5>
            <div class="flex-5 flex">
              <div *ngFor="let item of housing; let i = index;">
                <h5 class="grey subtitle">{{item?.name}}</h5>
                <span [ngClass]="{'old-value': item?.newvalue}" class="p1">{{item?.oldvalue || '-'}}</span>
                <span *ngIf="item?.newvalue" class="new-value p1">{{item?.newvalue}}</span>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="sub-heading">{{ 'operation-details.linked-operations' | translate }}</div>
            <div *ngFor="let operation of operation?.linkedoperations" class="p1">
              <span (click)="goToOperationsDetails(operation?.id)" class="span-link">{{operation?.name}}</span>
            </div>
          </div>
        </div>
      </app-accordion>

      <app-accordion [class]="['accordion-item-padding']">
        <div class="title-item">
          <div>
            <img alt="" src="../../../../assets/icons/intelectual-services.svg" width="24">
            <span>{{ 'operation-details.intellectual-services' | translate }}</span>
            <span *ngIf="checkChangedSection('architectural')"
                  class="validate-span">{{'operation-details.to-validate' | translate}}</span>
          </div>
        </div>
        <div class="panel-content">
          <div class="wrapper">
            <h5 class="sub-heading">{{ 'operation-details.architect-competition' | translate }}</h5>
            <div class="flex flex-2">
              <ng-container *ngFor="let item of architectural; let i = index;">
                <div class="grey subtitle">{{item?.name}}</div>

                <div *ngIf="item?.name != 'Source'" class="p1">
                  <span [ngClass]="{'old-value': item?.newvalue}" class="p1">{{item?.oldvalue || '-'}}</span>
                  <span *ngIf="item?.newvalue" class="new-value p1">{{item?.newvalue}}</span>
                  <span [ngClass]="{'old-value': item?.newstatus}" class="caption">
                      {{item?.oldstatus}}
                  </span>
                  <span *ngIf="item?.newstatus" class="caption new-value">
                      {{item?.newstatus}}
                  </span>
                </div>

                <div *ngIf="item?.name === 'Source'" class="p1">
                  <a [href]="item?.oldvalue" [ngClass]="{'old-value': item?.newvalue}" class="p1"
                     target="_blank">{{item?.oldvalue}}</a>
                  <a *ngIf="item?.newvalue" [href]="item?.newvalue" class="new-value p1"
                     target="_blank">{{item?.newvalue}}</a>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="wrapper">
            <h5 class="sub-heading">{{ 'operation-details.subject-mastery' | translate }}</h5>
            <div class="flex flex-2">
              <ng-container *ngFor="let item of mastery; let i = index;">
                <div class="grey subtitle">{{item?.name}}</div>
                <div *ngIf="item?.name != 'Source'" class="p1">
                  <span [ngClass]="{'old-value': item?.newvalue}" class="p1">{{item?.oldvalue || '-'}}</span>
                  <span *ngIf="item?.newvalue" class="new-value p1">{{item?.newvalue}}</span>
                  <span [ngClass]="{'old-value': item?.newstatus}" class="caption">
                      {{item?.oldstatus }}
                  </span>
                  <span *ngIf="item?.newstatus" class="caption new-value">
                      {{item?.newstatus}}
                  </span>
                </div>

                <div *ngIf="item?.name === 'Source'" class="p1">
                  <a [href]="item?.oldvalue" [ngClass]="{'old-value': item?.newvalue}" class="p1"
                     target="_blank">{{item?.oldvalue || '-'}}</a>
                  <a *ngIf="item?.newvalue" [href]="item?.newvalue" class="new-value p1"
                     target="_blank">{{item?.newvalue}}</a>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </app-accordion>

      <app-accordion [class]="['accordion-item-padding']">
        <div class="title-item">
          <div>
            <img alt="" src="../../../../assets/icons/operation-implementation.svg" width="24">
            <span>{{ 'operation-details.operational-implementation' | translate }}</span>
            <span *ngIf="checkChangedSection('tenders')"
                  class="validate-span">{{'operation-details.to-validate' | translate}}</span>
          </div>
        </div>
        <div class="panel-content">
          <div class="wrapper">
            <h5 class="sub-heading">{{ 'operation-details.call-for-tenders' | translate }}</h5>
            <div class="flex flex-2">
              <ng-container *ngFor="let item of tenders; let i = index;">
                <div class="grey subtitle">{{item?.name}}</div>
                <div *ngIf="item?.name != 'Source'" class="p1">
                  <span [ngClass]="{'old-value': item?.newvalue}" class="p1">{{item?.oldvalue || '-'}}</span>
                  <span *ngIf="item?.newvalue" class="new-value p1">{{item?.newvalue}}</span>
                  <span [ngClass]="{'old-value': item?.newstatus}" class="caption">
                      {{item?.oldstatus}}
                  </span>
                  <span *ngIf="item?.newstatus" class="caption new-value">
                      {{item?.newstatus}}
                  </span>
                </div>

                <div *ngIf="item?.name === 'Source'" class="p1">
                  <a [href]="item?.oldvalue" [ngClass]="{'old-value': item?.newvalue}" class="p1"
                     target="_blank">{{item?.oldvalue || '-'}}</a>
                  <a *ngIf="item?.newvalue" [href]="item?.newvalue" class="new-value p1"
                     target="_blank">{{item?.newvalue}}</a>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="wrapper">
            <h5 class="sub-heading">{{ 'operation-details.market-result' | translate }}</h5>
            <div class="flex flex-2">
              <ng-container *ngFor="let item of market; let i = index;">
                <div class="grey subtitle">{{item?.name}}</div>
                <div *ngIf="item?.name != 'Source'" class="p1">
                  <span [ngClass]="{'old-value': item?.newvalue}" class="p1">{{item?.oldvalue || '-'}}</span>
                  <span *ngIf="item?.newvalue" class="new-value p1">{{item?.newvalue}}</span>
                  <span [ngClass]="{'old-value': item?.newstatus}" class="caption">
                      {{item?.oldstatus}}
                  </span>
                  <span *ngIf="item?.newstatus" class="caption new-value">
                      {{item?.newstatus}}
                  </span>
                </div>

                <div *ngIf="item?.name === 'Source'" class="p1">
                  <a [href]="item?.oldvalue" [ngClass]="{'old-value': item?.newvalue}" class="p1"
                     target="_blank">{{item?.oldvalue || '-'}}</a>
                  <a *ngIf="item?.newvalue" [href]="item?.newvalue" class="new-value p1"
                     target="_blank">{{item?.newvalue}}</a>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="wrapper">
            <h5 class="sub-heading">{{ 'operation-details.housing' | translate }}</h5>
            <div class="flex flex-2 list">
              <ng-container *ngFor="let house of operation?.housing; let i = index">
                <div [ngClass]="{'new-value': !house?.main_housing_id}" class="left">
                  <img alt="" src="{{ cdn_url }}/assets/icons/housing.svg" width="24">
                  <div class="p1 inline">
                    <div class="p1">
                    <span *ngIf="this.getChangedHousingValue(house.housingId, 'name')"
                          class="p1 old-value">{{this.getChangedHousingValue(house.housingId, 'name') || '-'}}</span>
                      <span *ngIf="house?.deleted!='1'"
                            [ngClass]="{'new-value': this.getChangedHousingValue(house.housingId, 'name')}" class="p1">
                        {{house?.name || '-'}}
                      </span>
                    </div>
                    <div class="p2">
                      <span *ngIf="this.getChangedHousingValue(house.housingId, 'code_ape')"
                            class="p1 old-value">{{house?.code_ape || '-'}}</span>
                      <span *ngIf="house?.deleted!='1'"
                            [ngClass]="{'new-value': this.getChangedHousingValue(house.housingId, 'code_ape')}"
                            class="p1">{{this.getChangedHousingValue(house.housingId, 'code_ape')}}</span>
                    </div>
                  </div>
                </div>
                <div [ngClass]="{'new-value': !house?.main_housing_id}" class="align-right">
                  <div class="p1">
                  <span *ngIf="this.getChangedHousingValue(house.housingId, 'company_name')"
                        class="p1 old-value">{{  this.getChangedHousingValue(house.housingId, 'company_name') || '-'}}</span>
                    <span *ngIf="house?.deleted!='1'"
                          [ngClass]="{'new-value': this.getChangedHousingValue(house.housingId, 'company_name')}"
                          class="p1">
                      {{house?.company_name || '-'}}
                    </span>
                  </div>
                  <div class="p1">
                  <span *ngIf="this.getChangedHousingValue(house.housingId, 'price')" class="p1 old-value">
                    {{frenchFormat(this.getChangedHousingValue(house.housingId, 'price')) || '-'}}
                  </span>
                    <span *ngIf="house?.deleted!='1'"
                          [ngClass]="{'new-value': this.getChangedHousingValue(house.housingId, 'price')}"
                          class="p1">
                      {{frenchFormat(house?.price)}}
                    </span>
                  </div>
                </div>

              </ng-container>
            </div>
          </div>
          <div class="wrapper">
            <h5 class="sub-heading">{{ 'operation-details.mi_ordre_service' | translate }}</h5>
            <div class="flex flex-2">
              <div class="grey subtitle">
                {{ 'operation-details.mi_ordre_service_date' | translate }}
              </div>
              <div class="info">
              <span [ngClass]="{'old-value': operation?.getchanged?.mi_ordre_service_date}"
                    class="p1">{{operation?.operationdata?.mi_ordre_service_date  | date: 'dd/MM/yyyy' || '-'}}</span>
                <span *ngIf="operation?.getchanged?.mi_ordre_service_date"
                      class="new-value p1">{{operation?.getchanged?.mi_ordre_service_date | date: 'dd/MM/yyyy'}}</span>
                <span *ngIf="operation?.operationdata?.mi_ordre_service_date" class="caption">
                            {{ 'operation-details.preview-date' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <h5 class="sub-heading">Reception</h5>
            <div class="flex flex-2">
              <div class="grey subtitle">
                {{ 'operation-details.mi_reception_date' | translate }}
              </div>
              <div class="info">
              <span [ngClass]="{'old-value': operation?.getchanged?.mi_livraison_date}"
                    class="p1">{{operation?.operationdata?.mi_livraison_date | date: 'dd/MM/yyyy' || '-'}}</span>
                <span *ngIf="operation?.getchanged?.mi_livraison_date"
                      class="new-value p1">{{operation?.getchanged?.mi_livraison_date | date: 'dd/MM/yyyy'}}</span>
                <span *ngIf="operation?.operationdata?.mi_livraison_date"
                      class="caption">{{ 'operation-details.preview-date' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </app-accordion>
      <app-accordion [class]="['accordion-item-padding']" *ngIf="incomes.length > 0 || expenses.length > 0">
        <div class="title-item">
          <div>
            <img alt="" src="../../../../assets/icons/further-information.svg" width="24">
            <span> {{ 'operation-details.incomes-expenses' | translate }}</span>
            <!--            <span *ngIf="checkChangedSection('permit')" class="validate-span">{{'operation-details.to-validate' | translate}}</span>-->
          </div>
        </div>
        <div class="panel-content">
          <div class="wrapper" *ngIf="ie_total?.incomes && ie_total?.expenses && ie_total?.balance">
            <table style="width: 100%; font-size: 1.7rem;" class="text-center">
              <thead>
              <tr>
                <th>{{ 'operations-details.in' | translate }}</th>
                <th>{{ 'operations-details.out' | translate }}</th>
                <th>{{ 'operations-details.balance' | translate }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="income_amount">+ {{ ie_total.incomes | financial:1 }}</td>
                <td class="expense_amount">- {{ ie_total.expenses | financial:1  }}</td>
                <td class="balance_amount">{{ ie_total.balance.sign }} {{ ie_total.balance.value | financial:1  }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="wrapper spinner-block" *ngIf="incomes.length > 0">
            <h4 class="sub-heading">{{'operation-details.incomes.title' | translate }}</h4>
            <ngx-ui-loader [fgsColor]="'#543bff'"
                           [overlayColor]="'#EFEDFD'"
                           fgsSize="75"
                           fgsType="ball-spin-clockwise-fade-rotating"
                           loaderId="table_income"></ngx-ui-loader>
            <table appTable class="table-fixed">
              <thead appThead>
              <tr appTr>
                <th class="pl-5" appTh appOrdering (onOrdering)="order('income', 'buyer')">{{ 'operation-details.incomes.buyer' | translate }}</th>
                <th appTh appOrdering (onOrdering)="order('income', 'description')">{{ 'operation-details.incomes.description' | translate }}</th>
                <th class="text-center" appTh appOrdering (onOrdering)="order('income', 'paid_at')">{{ 'operation-details.incomes.paid_at' | translate }}</th>
                <th class="text-right pr-5" appTh appOrdering (onOrdering)="order('income', 'amount')">{{ 'operation-details.incomes.amount' | translate }}</th>
              </tr>
              </thead>
              <tbody appTbody>
              <tr appTr *ngFor="let income of incomes">
                <td class="pl-5" appTd>{{ income.buyer }}</td>
                <td appTd>{{ income.description }}</td>
                <td class="text-center" appTd>{{ income.paid_at | date: 'dd/MM/yyyy' }}</td>
                <td appTd class="income_amount text-right pr-5">{{ income.amount | financial:1 }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="wrapper spinner-block" *ngIf="expenses.length > 0">
            <h4 class="sub-heading">{{'operation-details.expenses.title' | translate }}</h4>
            <ngx-ui-loader [fgsColor]="'#543bff'"
                           [overlayColor]="'#EFEDFD'"
                           fgsSize="75"
                           fgsType="ball-spin-clockwise-fade-rotating"
                           loaderId="table_expense"></ngx-ui-loader>
            <table appTable class="table-fixed">
              <thead appThead>
              <tr appTr>
                <th class="pl-5" appTh appOrdering (onOrdering)="order('expense', 'seller')">{{ 'operation-details.expenses.seller' | translate }}</th>
                <th appTh appOrdering (onOrdering)="order('expense', 'description')">{{ 'operation-details.expenses.description' | translate }}</th>
                <th class="text-center" appTh appOrdering (onOrdering)="order('expense', 'paid_at')">{{ 'operation-details.expenses.paid_at' | translate }}</th>
                <th class="text-right pr-5" appTh appOrdering (onOrdering)="order('expense', 'amount')">{{ 'operation-details.expenses.amount' | translate }}</th>
              </tr>
              </thead>
              <tbody appTbody *ngIf="expenses.length > 0">
              <tr appTr *ngFor="let expense of expenses">
                <td class="pl-5" appTd>{{ expense.seller }}</td>
                <td appTd>{{ expense.description }}</td>
                <td class="text-center" appTd>{{ expense.paid_at | date: 'dd/MM/yyyy' }}</td>
                <td appTd class="expense_amount text-right pr-5">{{ expense.amount | financial:1 }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </app-accordion>
      <app-accordion [class]="['accordion-item-padding']">
        <div class="title-item">
          <div>
            <img alt="" src="../../../../assets/icons/further-information.svg" width="24">
            <span>{{ 'operation-details.further-information' | translate }}</span>
            <!--            <span *ngIf="checkChangedSection('permit')" class="validate-span">{{'operation-details.to-validate' | translate}}</span>-->
          </div>
        </div>
        <div class="panel-content">
          <div class="wrapper">
            <h5 class="sub-heading">{{'operation-details.build-permission' | translate }}</h5>
            <div class="flex flex-2">
              <ng-container *ngFor="let item of permit; let i = index;">
                <div class="grey subtitle">{{item?.name}}</div>
                <div class="p1">{{item?.value || '-'}}</div>
              </ng-container>
            </div>
          </div>
          <div class="wrapper">
            <h5 class="sub-heading"> {{ 'operation-details.funding' | translate }}</h5>
            <div>
              <ng-container *ngFor="let item of funding; let i = index">
                <div [ngClass]="{'new-value': !item?.main_financial_id}" class="funding-block">
                  <div class="flex flex-2">
                    <div class="grey subtitle">№</div>
                    <div class="p1">{{i + 1 || '-'}}</div>
                  </div>
                  <div class="flex flex-2">
                    <div class="grey subtitle">{{'operation-details.financier' | translate}}</div>
                    <div class="p1">
                     <span [ngClass]="{'old-value': this.getChangedFundingValue(item.id, 'financial_organisation')}"
                           class="p1">{{item?.financial_organisation || '-'}}</span>
                      <span *ngIf="this.getChangedHousingValue(item.id, 'financial_organisation')"
                            class="new-value p1">{{this.getChangedFundingValue(item.id, 'financial_organisation')}}</span>
                    </div>
                  </div>
                  <div class="flex flex-2">
                    <div class="grey subtitle">{{'operation-details.type-of-financier' | translate}}</div>
                    <div class="p1">
                    <span [ngClass]="{'old-value': this.getChangedFundingValue(item.id, 'type')}"
                          class="p1">{{item?.type || '-'}}</span>
                      <span *ngIf="this.getChangedHousingValue(item.id, 'type')"
                            class="new-value p1">{{this.getChangedFundingValue(item.id, 'type')}}</span>
                    </div>
                  </div>
                  <div class="flex flex-2">
                    <div class="grey subtitle">{{'operation-details.date-depot-financement' | translate}}</div>
                    <div class="p1">
                    <span [ngClass]="{'old-value': this.getChangedFundingValue(item.id, 'submission_date')}"
                          class="p1">{{item?.submission_date || '-'}}</span>
                      <span *ngIf="this.getChangedHousingValue(item.id, 'submission_date')"
                            class="new-value p1">{{this.getChangedFundingValue(item.id, 'submission_date')}}</span>
                    </div>
                  </div>
                  <div class="flex flex-2">
                    <div class="grey subtitle">{{'operation-details.date-grant-financement' | translate}}</div>
                    <div class="p1">
                    <span [ngClass]="{'old-value': this.getChangedFundingValue(item.id, 'awarding_date')}"
                          class="p1">{{item?.awarding_date || '-'}}</span>
                      <span *ngIf="this.getChangedHousingValue(item.id, 'awarding_date')"
                            class="new-value p1">{{this.getChangedFundingValue(item.id, 'awarding_date')}}</span>
                    </div>
                  </div>
                  <div class="flex flex-2">
                    <div class="grey subtitle">{{'operation-details.amount-financement' | translate}}</div>
                    <div class="p1">
                    <span [ngClass]="{'old-value': this.getChangedFundingValue(item.id, 'amount')}"
                          class="p1">{{item?.amount || '-'}}</span>
                      <span *ngIf="this.getChangedHousingValue(item.id, 'amount')"
                            class="new-value p1">{{this.getChangedFundingValue(item.id, 'amount')}}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="wrapper">
            <h5 class="sub-heading"> {{ 'operation-details.attachements' | translate }}</h5>
            <div class="flex flex-2 list">
              <ng-container *ngFor="let item of operation?.files; let i = index">
                <div>
                  <img alt="" src="{{ cdn_url }}/assets/icons/attachment.svg" width="15">
                  <div class="p1 inline">
                    <div (click)="operationService.loadFile(item?.name)"
                         class="p1 blue file-name">{{item?.name || '-'}}</div>
                    <div class="p2">{{item?.description || '-'}}</div>
                  </div>
                </div>
                <div class="align-right">
                  <div class="p1">{{item?.date | date: 'dd/MM/yyyy' || '-'}}</div>
                  <div class="p1 blue">{{item?.admin || '-'}} </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="wrapper">
            <h5 class="sub-heading"> {{ 'operation-details.georeference' | translate }}</h5>
            <div class="flex flex-2">
              <ng-container *ngFor="let item of georeferencing; let i = index">
                <div class="grey subtitle">{{item?.name}}</div>
                <div class="p1">{{item?.value || '-'}}</div>
              </ng-container>
            </div>
          </div>
        </div>
      </app-accordion>
      <app-accordion [class]="['accordion-item-padding']">
        <div class="title-item">
          <div>
            <img alt="" src="../../../../assets/icons/vigilant-point.svg" width="24">
            <span>{{ 'operation-details.vigilance' | translate }}</span>
          </div>
        </div>
        <div class="panel-content">
          <div class="wrapper">
            <div class="flex flex-2 list">
              <ng-container *ngFor="let item of operation?.vigilance; let i = index">
                <div class="left">
                  <img *ngIf="item?.resolved == 0" alt="" src="{{ cdn_url }}/assets/icons/operation-conflict.svg"
                       width="32">
                  <img *ngIf="item?.resolved == 1" alt="" src="{{ cdn_url }}/assets/icons/operaion-no-conflict.svg"
                       width="32">
                  <div class="p1 inline">
                    <div class="p1">{{item?.domaine || '-' | titlecase}}
                      <span *ngIf="item?.resolved == 0" class="not-resolved">({{ 'dashboard.vigilance-unresolved' | translate }})</span>
                      <span *ngIf="item?.resolved == 1" class="resolved">({{ 'dashboard.vigilance-resolved' | translate }})</span></div>
                    <div class="p2">
                      <span>{{item?.description}}</span>
                      <!--                      <span *ngIf="item?.resolved == 1">Oui</span>-->
                    </div>
                  </div>
                </div>
                <div class="align-right">
                  <div class="p1">{{item?.actors || '-'}} </div>
                  <div class="p1">{{item?.date | date: 'dd/MM/yyyy' || '-'}}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </app-accordion>

      <!-- START CIRCUIT DE VALIDATION 
      <app-accordion [class]="['accordion-item-padding']">
        <div class="title-item">
          <div>
            <img alt="" src="../../../../assets/icons/operation-upload.svg" width="24">
            <span>{{ 'operation-details.validation' | translate }}</span>
          </div>
        </div>
        <div class="panel-content">
          <div class="wrapper spinner-block">
            <ngx-ui-loader [fgsColor]="'#543bff'"
                           [overlayColor]="'#EFEDFD'"
                           fgsSize="75"
                           fgsType="ball-spin-clockwise-fade-rotating"
                           loaderId="op_details_validations"></ngx-ui-loader>
            <div class="flex flex-2 list">
              <ng-container >
                <table class="table">
                  <tr *ngFor="let item of operation?.validations; let i = index">
                    <td class="align-middle text-center">
                      <img [ngClass]="{invisible: item.valid == '0'}" alt="" src="{{ cdn_url }}/assets/icons/check-step-purple.svg"
                           width="32">
                    </td>
                    <td>
                      <div class="p1">
                        {{item?.name || '-' | titlecase}}
                      </div>
                      <div class="p2">
                        <span *ngIf="item.valid == '1'">{{ 'operations-details.validations.valid_at' | translate }} {{item?.valid_at | date:'dd/MM/yyyy'}}</span>
                        <span *ngIf="item.valid == '0'">{{ 'operations-details.validations.to_validate' | translate }}</span>
                      </div>
                    </td>
                    <td class="align-middle">
                      {{item?.fullname}}
                    </td>
                    <td class="align-middle text-right">
                      <img [src]="getImgUrl(item.signature)" height="40" *ngIf="item.valid == '1'">
                      <ng-container *ngIf="item.valid == '0'">
                        <ng-container *ngIf="authService.getLocalStorage('id') == item.id_user">
                          <app-spot-button [height]="'20'"
                                           [text]="'operations-details.validations.validate_step' | translate"
                                           [width]="'32'"
                                           class="hover-content" (click)="validateStep()"></app-spot-button>
                        </ng-container>
                      </ng-container>
                    </td>
                  </tr>
                </table>

              </ng-container>
            </div>
          </div>
        </div>
      </app-accordion>
      -->
      <!-- END CIRCUIT DE VALIDATION -->

      <app-accordion [class]="['accordion-item-padding']">
        <div class="title-item">
          <div>
            <img alt="" src="../../../../assets/icons/observations.svg" width="24"> <span>{{ 'operation-details.observations' | translate }}</span>
          </div>
        </div>
        <div class="panel-content">
          <div class="wrapper">
            <div *ngFor="let item of operation?.observations; let i = index" class="mb-4 flex list">
              <div class="left" style="width: 100%">
                <div *ngIf="item?.name && item?.surname" class="mr-4">
<!--                    {{item?.name.substring(0, 1)}} {{item?.surname.substring(0, 1)}}-->
                  <img [src]="api_url + '/uploads/' + item.image" class="img-circle">
                </div>
                <div style="width: 100%">
                  <div class="p1 mb-2 d-flex justify-content-between">
                    <span>{{ item?.name || '-' }} {{ item?.surname }}</span>
                    <span>{{ item?.creation_date | date:'dd/MM/yyyy HH:mm' }}</span>
                  </div>
                  <div class="p2">
                    {{ item?.description || '-' }}
                  </div>
                </div>
              </div>
<!--              <div class="align-right">-->
<!--                <div class="p1"> </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </app-accordion>
    </div>
  </div>
</div>
