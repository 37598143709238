import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {HousingService} from "../../services/housing-service";
import {OperationService} from "../../../operation/service/operation.service";
import {HousingModel} from "../../models/housing-model";
import {ApeCodeModel} from "../../../operation/model/ape-code-model";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DialogBase} from "../../../shared/abstractions/dialog-base";

@Component({
  selector: 'app-add-housing-inline',
  templateUrl: './add-housing-inline.component.html',
  styleUrls: ['./add-housing-inline.component.scss']

})
export class AddHousingInlineComponent extends DialogBase implements OnDestroy, OnChanges {
  @Input() codes: ApeCodeModel[];
  @Input() triggerOnChanges: number = 0;
  public house: HousingModel = new HousingModel();
  private isEdit = false;
  private index: number;

  constructor(public translate: TranslateService, private housingService: HousingService, private operationService: OperationService,
  ) {
    super();
    this.housingService.sendHousing
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value.house) {
          this.house = {
            housingId: value.house.housingId,
            name: value.house.name,
            code_ape: value.house.code_ape,
            company_name: value.house.company_name,
            price: value.house.price,
            fk_codeAPE: value.house.fk_codeAPE,
            overspend: value.house.overspend,
            main_housing_id: value.house?.main_housing_id
          };
        }
        this.index = value.index;
        this.isEdit = value.isEdit;

      });
  }


  ngOnChanges(): void {
    this.housingService.componentInitialized.next();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public cancel(): void {
    this.housingService.closeHousing.next();
  }

  public addHouse(): void {

    this.isCalled = true;
    this.housingService.passHousing.next({
      house: this.house,
      isEdit: this.isEdit,
      index: this.index
    });
    this.cancel();
  }
}
