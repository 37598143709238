<div class="wrapper">
    <div class="header">
        <div>
            <img width="32" src="../../../assets/icons/add-file.svg">
            <span>{{'operation-list.import-csv' | translate}}</span>
        </div>
        <img width="18" class="right" src="../../../../assets/icons/close.svg" (click)="cancel()">
    </div>
    <div class="flex">
        <div class="draggable drop-zone" (dragleave)="onDragOver($event)" (drop)="onDrop($event)"
            (dragover)="onDragOver($event)" (click)="file.click()">
            <img alt="" height="30px" src="/assets/icons/attach-file.svg">
            <p class="drop-files-label">{{'file.choose' | translate}}</p>
            <input (change)="onUploadFiles($event)" #file type="file" class="d-none" accept="text/csv">
        </div>
    </div>

    <div class="buttons">
        <button class="cancel" (click)="cancel()">{{'housing.cancel' | translate}}</button>
    </div>

</div>