<div class="container-fluid container-body d-flex flex-column">
  <div class="container my-5">
    <div>
      <form [formGroup]="form">
        <div class="card">
          <div class="card-header">
            <i class="bi bi-search"></i> Les appels d'offres dans votre région
          </div>
          <div class="card-body filters">
            <div class="row">
              <div class="col-12 col-lg-4 mb-4">
                <ng-container>
                  <span><i class="bi bi-geo-alt"></i> Département</span>
                  <ng-multiselect-dropdown [(ngModel)]="filter.departments"
                                           [settings]="dropdownSettings"
                                           [data]="departments_formatted"
                                           formControlName="departments"
                                           placeholder="Département">
                  </ng-multiselect-dropdown>
                </ng-container>
              </div>
              <div class="col-12 col-lg-4 mb-4">
                <ng-container>
                  <span><i class="bi bi-key"></i> Mots clés</span>
                  <input [(ngModel)]="filter.keywords" matInput formControlName="keywords"
                         placeholder="Mots clés"
                         type="text">
                </ng-container>
              </div>
              <div class="col-12 col-lg-4 mb-4">
                <ng-container>
                  <span><i class="bi bi-journal-text"></i> Type de marché</span>
                    <mat-select [(ngModel)]="filter.type_marche" multiple formControlName="type_marche"
                      placeholder="Type de marché">
                    <mat-option *ngFor="let key of marketTypes.values()" [value]="marketTypes[key]">
                      {{ key }}
                    </mat-option>
                  </mat-select>
                </ng-container>
              </div>
              <div class="col-12 col-lg-4 mb-4">
                <ng-container>
                  <span><i class="bi bi-tag"></i> Domaine d'activités</span>
                  <ng-multiselect-dropdown [(ngModel)]="filter.categories"
                                           [settings]="dropdownSettings"
                                           [data]="categories$ | async"
                                           formControlName="categories"
                                           placeholder="Domaine d'activités"
                                           (onSelect)="onCategoryChange()"
                                           (onDeSelect)="onCategoryChange()"
                                           (onSelectAll)="onCategoryChange()">
                  </ng-multiselect-dropdown>
                </ng-container>
              </div>
              <div class="col-12 col-lg-4 mb-4">
                <ng-container>
                  <span><i class="bi bi-tag"></i> Sous-domaine d'activités</span>
                  <ng-multiselect-dropdown [(ngModel)]="filter.subcategories"
                                           [settings]="dropdownSettings"
                                           [data]="subcategories$ | async"
                                           formControlName="subcategories"
                                           placeholder="Sous-domaine d'activités"
                                           [disabled]="!(subcategories$ | async)">
                  </ng-multiselect-dropdown>
                </ng-container>
              </div>
              <div class="col-6 col-lg-2 mb-4">
                <ng-container>
                  <span><i class="bi bi-calendar"></i> Date</span>
                  <input (click)="publish_date_start.open()" [(ngModel)]="filter.publish_date_start"
                         [matDatepicker]="publish_date_start"
                         formControlName="publish_date_start"
                         matInput placeholder="De">
                  <mat-datepicker #publish_date_start></mat-datepicker>
                </ng-container>
              </div>
              <div class="col-6 col-lg-2 mb-4">
                <ng-container>
                  <span>&nbsp;</span>
                  <input (click)="publish_date_end.open()" [(ngModel)]="filter.publish_date_end"
                         [matDatepicker]="publish_date_end"
                         formControlName="publish_date_end"
                         matInput placeholder="Jusqu'à">
                  <mat-datepicker #publish_date_end></mat-datepicker>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <button type="submit" class="spot-button spot-secondary-button" (click)="onReset()">
              Réinitialiser les filtres
            </button>
            <button type="submit" class="ml-4 spot-button spot-primary-button" (click)="onSubmit()">
              Rechercher
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="container my-5 flex-fill">
    <div class="spinner-block">
      <ngx-ui-loader fgsType="ball-spin-clockwise-fade-rotating"
                     fgsSize="75"
                     [overlayColor]="'#EFEDFD'"
                     [fgsColor]="'#543bff'"
                     loaderId="on_search"></ngx-ui-loader>

      <h2 *ngIf="num_rows !== null">{{ num_rows | frenchNumber }} résultat(s)</h2>
      <div class="card my-5" *ngFor="let operation of operations">
        <div class="card-header d-flex">
          <h3 class="mr-3 align-self-center"><i class="bi bi-archive"></i></h3>
          <h3>{{ operation.name }}</h3>
<!--          <a href="https://france.seigos.fr" target="_blank" class="spot-button spot-primary-button flex-shrink-0 align-self-center ml-4">-->
<!--            Accéder aux détails-->
<!--          </a>-->
        </div>
        <div class="card_body">
          <table class="table">
            <tr>
              <td><span class="legend-table"><i class="bi bi-briefcase"></i> Maîtrise d'ouvrage :</span> {{ operation.client_name }}</td>
              <td><span class="legend-table"><i class="bi bi-tag"></i> Categorie :</span> {{ operation.categorie }}</td>
            </tr>
            <tr>
              <td><span class="legend-table"><i class="bi bi-credit-card-2-front"></i> Avis N° : </span> <a style="color: #007bff;" [href]="'https://france.seigos.fr/operations?type=all&operationid='+operation.id+'&operation='+operation.id" target="_blank">{{ operation.ext_id }}</a></td>
              <td><span class="legend-table"><i class="bi bi-tag"></i></span> {{ operation.subCategorie }}</td>
            </tr>
            <tr>
              <td><span class="legend-table" *ngIf="operation.procedure"><i class="bi bi-journal-text"></i></span> {{ operation.procedure }}</td>
<!--              <td><span class="legend-table"><i class="bi bi-geo-alt"></i></span> {{ operation.city }} <span *ngIf="operation.department.trim()">({{ operation.department.trim() }})</span></td>-->
              <td><span class="legend-table"><i class="bi bi-calendar"></i> Date de publication</span> : {{ operation.date_announcement | date: 'dd/MM/yyyy' }}</td>
<!--            </tr>-->
<!--            <tr>-->
<!--              <td><span class="legend-table"><i class="bi bi-clock-history"></i> Date de clôture :</span> {{ operation.deadline_date ? (operation.deadline_date | date: 'dd/MM/yyyy') : '&#45;&#45;' }}</td>-->
            </tr>
          </table>
        </div>
      </div>


      <div *ngIf="num_rows !== null" class="operation-table-pagination" id="pagination">
        <span class="pages-count">{{ 'operation-list.items-per-page' | translate }}</span>
        <span (click)="changeLimit(50);" [ngClass]="{'active' : limit === 50 }"
              class="pages-count">50</span>
        <span (click)="changeLimit(100);" [ngClass]="{'active' : limit === 100 }"
              class="pages-count">100</span>
        <span (click)="changeLimit(500);" [ngClass]="{'active' : limit === 500 }"
              class="pages-count">500</span>

        <div class="right-pagination">
          <span>Page {{ page }} / {{ max_page }}</span>
          <img (click)="previousPage()" [ngClass]="{ 'disable': page === 1 }"
               alt="errow-right" class="previous-page" src="/assets/icons/errow-right.svg"
               width="35">
          <img (click)="nextPage()"
               [ngClass]="{ 'disable': page === max_page }"
               alt="errow-right" src="/assets/icons/errow-right.svg" width="35">
        </div>
    </div>
  </div>
</div>
