import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxDirective } from './directives/checkbox.directive';
import { TextDirective } from './directives/text.directive';
import { SearchComponent } from './components/search/search.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    CheckboxDirective,
    TextDirective,
    SearchComponent
  ],
  exports: [
      CheckboxDirective,
      TextDirective,
      SearchComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule
  ]
})
export class FormModule { }
