import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UsersListComponent} from "../user-management/components/users-list/users-list.component";
import {VigilanceListComponent} from "./components/vigilances-list/vigilance-list.component";


const routes: Routes = [
  { path: '', component: VigilanceListComponent }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]

})
export class VigilancePointRoutingModule {
}
