import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FundingRoutingModule } from './funding-routing.module';
import { AddFundingComponent } from './add-funding/add-funding.component';
import { EditFundingComponent } from './edit-funding/edit-funding.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FundingService } from './service/funding-service';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [AddFundingComponent, EditFundingComponent],
  imports: [
    CommonModule,
    FundingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule
  ],
  providers: [FundingService],
  exports: [AddFundingComponent, EditFundingComponent]
})
export class FundingModule { }
