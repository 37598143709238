<app-generic-modal
  (close)="close()"
  (done)="filter()"
  [headerText]="'activity-list.search-title' | translate"
  [iconName]="'seach-table.svg'"
  [isCalled]="false"
>
  <div class="content">
    <div class="flex-2">
      <div>
        <span>{{'activity-list.date' | translate}}</span>
        <input (click)="date.open()" [(ngModel)]="search.date" [matDatepicker]="date"
               matInput placeholder="{{'activity-list.date-helper' | translate}}">
        <mat-datepicker #date></mat-datepicker>
      </div>
      <div>
        <span>{{'activity-list.type' | translate}}</span>
        <mat-select [(ngModel)]="search.type" multiple name="tender"
                    placeholder="{{'activity-list.type-helper' | translate}}">
          <mat-option *ngFor="let item of activityTypes.values()" [value]="activityTypes[item]">
            {{ item }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="flex-2">
      <div>
        <span>{{'activity-list.id' | translate}}</span>
        <input [(ngModel)]="search.operationId" matInput placeholder="{{'activity-list.id-helper' | translate}}"
               type="text">
      </div>
      <div>
        <span>{{'activity-list.user' | translate}}</span>
        <input [(ngModel)]="search.username" matInput placeholder="{{'activity-list.user-helper' | translate}}"
               type="text">
      </div>
    </div>
    <div class="flex">
      <div>
        <span>{{'activity-list.message' | translate}}</span>
        <input [(ngModel)]="search.summary" matInput placeholder="{{'activity-list.message-helper' | translate}}"
               type="text">
      </div>
    </div>
  </div>

</app-generic-modal>
