<app-cards [ngClass]="{'hidden': showRight}"></app-cards>
<div (resized)="calculateTableHeigth()" class="main">
  <div [ngClass]="{'half tablet-width': showRight}" class="full-width">
    <div class="table-heading" id="top-corner">
      <div [ngClass]="{'hide-mobile': isSearchVisible}">
        <h2 *ngIf="isLoaded">
          {{ 'operation-list.operation' | translate }}

          <span *ngIf="advancedSearchService.searchModel.operations == 'user'">
                      - Mes opérations
                    </span>
          <span *ngIf="operationType === 'inprogress'">
                        - Vivantes
                    </span>
          <span *ngIf="operationType === 'waiting'">
                        - En attente
                    </span>
          <span *ngIf="operationType === 'completed'">
                        - Archivées
                    </span>
          <span *ngIf="operationType === 'planned'">
                        - Projetées
                    </span>
          <span *ngIf="operationType === 'boamp'">
                        - BOAMP
                    </span>
          <span *ngIf="advancedSearchService.searchModel.operationType == 'ongoing'">
                        - En cours
                    </span>
          <span *ngIf="advancedSearchService.searchModel.operationType == 'deleted'">
                        - Supprimées
                    </span>
        </h2>
        <div *ngIf="isLoaded" class="subtitle">
          {{ ' ( ' + (operationsModel?.allcount | frenchNumber: 1)  }} {{ 'operation-list.results' | translate }} /
          {{ operationsModel?.totalAmount | financial: 1  }} )
        </div>
        <div *ngIf="!isLoaded" class="subtitle">
          ( {{ 'operation-list.results' | translate }} / M € )
        </div>
      </div>

      <div class="right-table-menu">
        <input *ngIf="isSearchVisible" [formControl]="search" placeholder="{{'common.rapid' | translate}}"
               type="text">
        <img (click)="closeSearch()" *ngIf="isSearchVisible" alt="close"
             class="close-search" src="{{ cdn_url }}/assets/icons/close.svg" width="15px">
        <img (click)="showSearch()" *ngIf="!isSearchVisible" alt="search"
             class="search" src="{{ cdn_url }}/assets/icons/seach-table.svg" width="18px">
        <div *ngIf="isLoaded" [toggleItem]="toggle" class="toggle" toggle>
<!--          <img src="{{ cdn_url }}/assets/icons/operations-table-menu.svg" width="25">-->
          <div class="spot-button spot-light-button">Options
          <div class="triangle">

          </div>
          </div>
          <ul #toggle class="menu">
            <li (click)="addOperation()"
                *ngIf="!authService.isUserEnterprise()">{{ 'operation-list.add' | translate }}</li>
            <li (click)="deleteOperation()"
                *ngIf="authService.isUserAdmin() && operationType != 'deleted'">{{ 'operation-list.delete' | translate }}</li>
            <li (click)="validateOperation()"
                *ngIf="authService.isUserAdmin() && isSelectedOperationToValidate() && (operationType == 'waiting' || operationType == 'boamp' || operationType == null  ) ">{{ 'operation-list.validate' | translate }}</li>
            <li (click)="grantAccess()"
                *ngIf="authService.isUserAdmin() && operationType != 'deleted'">{{ 'operation-list.grant-access' | translate }}</li>
            <li (click)="restoreOperation()"
                *ngIf="authService.isUserAdmin() && operationType == 'deleted'">{{ 'operation-list.restore' | translate }}</li>
            <li (click)="downloadReport()"
                *ngIf="authService.isUserAdmin() && operationType != 'deleted'">{{ 'operation-list.export-xls' | translate }}</li>
            <li (click)="exportPdfReport()">{{ 'operation-list.pdf-report' | translate }}</li>
            <li (click)="importOperations()">{{ 'operation-list.import-csv' | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
    <table class="op-table">
      <thead id="head">
      <tr>
        <th><input #checkAll (click)="checkAllOperations(checkAll.checked)" id="check-all" type="checkbox"></th>
        <th #sector (click)="sortColumn('spot_operation.sector', sector)"
            *ngIf="!marketProjectColumn; else marketHeader">
          {{ 'operation-list.sector' | translate }}
          <span>
                            <img alt="" class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                            <img alt="" class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                        </span>
        </th>
        <ng-template #marketHeader>
          <th #sector (click)="sortColumn('spot_operation.type_marche', sector)">
            {{ 'operation-list.market-type' | translate }}
            <span>
                            <img alt="" class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                            <img alt="" class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                        </span>
          </th>
        </ng-template>
        <th #description>{{ 'operation-list.general-information' | translate }}
          <span>
                            <img alt="" class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                            <img alt="" class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                        </span>
          <span [toggleItem]="toggle" class="toggle" toggle>
                            <img alt="general-sort" class="general-sort"
                                 src="{{ cdn_url }}/assets/icons/general-sort-table.svg"
                                 width="14">
                            <ul #toggle class="menu">
                                <li
                                  (click)="sortColumn('spot_operation.id', description);">{{ 'operation-list.id' | translate }}</li>
                                <li
                                  (click)="sortColumn('spot_client_enterprise.name', description);">{{ 'operation-list.client' | translate }}</li>
                                <li
                                  (click)="sortColumn('spot_operation.status', description);">{{ 'operation-list.status' | translate }}</li>
                                <li
                                  (click)="sortColumn('spot_operation.name', description);">{{ 'operation-list.name' | translate }}</li>
                            </ul>
                        </span>
        </th>
        <th #montant
            (click)="sortColumn('mi_resultat_marche_montant', montant)">{{ 'operation-list.amount' | translate }}
          <span>
                            <img alt="" class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                            <img alt="" class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                        </span>
        </th>
        <th #date (click)="sortColumn('spot_operation.mi_ao_travaux_date', date)"
            *ngIf="!showRight">{{ 'operation-list.date-ao' | translate }}
          <span>
                            <img alt="" class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                            <img alt="" class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                        </span>
        </th>
        <th #location (click)="sortColumn('spot_operation.location', location)"
            *ngIf="!showRight">{{ 'operation-list.location' | translate }}
          <span>
                            <img alt="" class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                            <img alt="" class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
                        </span>
        </th>

        <th *ngIf="!showRight"></th>
      </tr>
      </thead>
      <div class="spinner-block">
        <ngx-ui-loader [fgsColor]="'#543bff'"
                       [overlayColor]="'#EFEDFD'"
                       fgsSize="75"
                       fgsType="ball-spin-clockwise-fade-rotating"
                       loaderId="op_list"></ngx-ui-loader>
        <tbody #myBody id="body">
        <tr #row (click)="operationDetailsClick(operation?.id, $event, checkbox, row)"
            *ngFor="let operation of operationsModel?.operations; let i = index"
            [ngClass]="{'odd': i % 2 == 1}"
        >
          <td><input #checkbox (click)="checkOperation(checkbox.checked, operation?.id)" class="check-operation"
                     type="checkbox"></td>
          <td *ngIf="!marketProjectColumn; else marketTypeColumn" class="sector">
            <div class="sector-content">
              <img alt=""
                   class="{{operation?.sector?.toLowerCase()}}"
                   src="{{ cdn_url }}/assets/icons/table-{{operation?.sector?.toLowerCase()}}-purple.svg"
                   width="32">
              <div>
                {{ operation?.sector }}
              </div>
            </div>
          </td>
          <ng-template #marketTypeColumn>
            <td class="sector {{operation?.type_marche?.toLowerCase()}}">
              <div class="sector-content">
                <img src="{{ cdn_url }}/assets/icons/table-{{operation?.type_marche?.toLowerCase()}}-purple.svg" width="40">
                <div>
                  {{ operation?.type_marche }}
                </div>
              </div>

            </td>
          </ng-template>
          <td>
            <div class="truncate_name" title="{{operation?.name}}">
              {{ operation?.name}}
            </div>
            <div class="general-info"
                 title="{{operation?.client_name}} - {{ operation?.id }} - {{ operation?.status }}">
              {{ operation?.client_name }} - {{ operation?.id }} - {{ status[operation?.status] }}
            </div>
          </td>
          <td *ngIf="operation?.formatted_amount; else emptySum">{{ operation?.formatted_amount | financial: 1}}</td>
          <ng-template #emptySum>
            <td>-</td>
          </ng-template>
          <td
            *ngIf="!showRight && operation.mi_ao_travaux_date!='0000-00-00'; else undated">{{ operation?.mi_ao_travaux_date  | date: 'dd/MM/yyyy'}}</td>
          <ng-template #undated>
            <td *ngIf="!showRight">-</td>
          </ng-template>
          <td *ngIf="!showRight && operation?.location_name!=null; else location">
            <div class="location" [title]="operation?.location_name">
              {{ operation?.location_name }}
            </div>
          </td>
          <ng-template #location>
            <td *ngIf="!showRight" >
              <div class="location" [title]="operation?.location">
                {{ operation?.location }}
              </div>
            </td>
          </ng-template>

          <td *ngIf="!showRight">
            <img (click)="editOperation(operation?.id)" *ngIf="operation.can_edit"
                 alt="edit" src="{{ cdn_url }}/assets/icons/edit-table.svg"
                 width="24px">
          </td>
        </tr>
        </tbody>
      </div>
    </table>
    <div class="operation-table-pagination" id="pagination">
      <span class="pages-count">{{ 'operation-list.items-per-page' | translate }}</span>
      <span (click)="selectPage(50)" [ngClass]="{'active' : advancedSearchService?.searchModel?.show === 50 }"
            class="pages-count">50</span>
      <span (click)="selectPage(100)" [ngClass]="{'active' : advancedSearchService?.searchModel?.show === 100 }"
            class="pages-count">100</span>
      <span (click)="selectPage(500)" [ngClass]="{'active' : advancedSearchService?.searchModel?.show === 500 }"
            class="pages-count">500</span>

      <div class="right-pagination">
        <span *ngIf="isLoaded" class="mobile-none">
            {{ operationsModel?.startfrom }} - {{ setTableItemsEndCount() }}
          {{ 'operation-list.of' | translate }} {{ operationsModel?.allcount | numeric }}
        </span>
        <span *ngIf="!isLoaded" class="mobile-none">
            0 - 0 {{ 'operation-list.of' | translate }} 0
        </span>
        <img (click)="previousPage()" [ngClass]="{ 'disable': advancedSearchService.searchModel?.page === 1 }"
             alt="errow-right" class="previous-page" src="{{ cdn_url }}/assets/icons/errow-right.svg"
             width="35">
        <img (click)="nextPage()"
             [ngClass]="{ 'disable': operationsModel?.pages === advancedSearchService.searchModel?.page }"
             alt="errow-right" src="{{ cdn_url }}/assets/icons/errow-right.svg" width="35">
      </div>
    </div>
  </div>
  <div [ngClass]="{'right': !showRight}" class="half overlap shadow">
    <app-operation-details (close)="closeOperation()"></app-operation-details>
  </div>
</div>
