import {Component, OnInit} from '@angular/core';
import {AppliedFilterModel} from '../../shared/models/applied-filter-model';
import {TranslateService} from '@ngx-translate/core';
import {format, parse} from 'date-fns';
import {StatusDateReverse} from "../../shared/enums/status-date";
import {Visibility} from "../../shared/enums/visibility";
import {OperationVisibility} from "../../shared/enums/operation-visibility";
import {TypeTender} from "../../shared/enums/type-tender";
import {AdvancedActivityService} from "../../activity/services/advanced-activity.service";
import {AppStateService} from "../../shared/services/app-state.service";
import {AdvancedSearchComponent} from "../../shared/components/advanced-search/advanced-search.component";
import {ActivitySearchComponent} from "../../activity/components/activity-search/activity-search.component";
import {ActivityType} from "../../shared/enums/activity-type";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import { AdvancedSearchService } from 'app/operation/service/advanced-search-service';

@Component({
  selector: 'app-applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss']
})
export class AppliedFiltersComponent implements OnInit {
  public filters: AppliedFilterModel[] = [];
  public statuses = StatusDateReverse;
  public dateFormat = 'dd/MM/yyyy';
  public unsubscribe$ = new Subject<void>();

  constructor(private advancedSearchService: AdvancedSearchService, private translate: TranslateService, private advancedActivityService: AdvancedActivityService,
              private appState: AppStateService) {
  }

  ngOnInit(): void {
    this.onAdvancedSearchApplied();
  }

  private buildOperationsFilterData(): void {
    this.filters = [];
    this.translate.get('applied-filters').pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        let filter = this.advancedSearchService.searchModel.advancedsearch;
        this.addFilterValue(this.translate.instant('applied-filters.operation-name'), filter.operationname);
        this.addFilterValue(this.translate.instant('applied-filters.operation-id'), filter.operationid);
        this.addFilterValue(this.translate.instant('applied-filters.status'), filter.status);
        this.addFilterValue(this.translate.instant('applied-filters.customer'), filter.customerNames);
        this.addFilterValue(this.translate.instant('applied-filters.location'), filter.locationNames);
        this.addFilterValue(this.translate.instant('operation-list.category'), filter.categoryNames);
        this.addFilterValue(this.translate.instant('operation-list.subcategory'), filter.subcategoryNames);
        this.addFilterValue(this.translate.instant('applied-filters.sector'), filter.sector);
        // this.addFilterValue(this.translate.instant('operation-list.category'), filter.category);
        // this.addFilterValue(this.translate.instant('operation-list.subcategory'), filter.subcategory);
        this.addFilterValue(this.translate.instant('applied-filters.vigilance'), filter.vigilance);
        this.addFilterValue(this.translate.instant('applied-filters.sub-sector'), filter.subsector);
        this.addEnumValues(this.translate.instant('operation-details.client-type'), filter.visibility, Visibility);
        this.addEnumValues(this.translate.instant('operation-details.visibility'), filter.operationvisibility, OperationVisibility);
        this.addFilterValue(this.translate.instant('applied-filters.type_marche'), filter.type_marche);
        if (filter.has_observation) {
          // TODO
          this.addFilterValue(this.translate.instant('applied-filters.observations'), filter.has_observation ? this.translate.instant('applied-filters.with-observations') : this.translate.instant('applied-filters.without-observations'));
        }
        if (filter.has_housing) {
          this.addFilterValue(this.translate.instant('applied-filters.housing'), filter.has_housing ? this.translate.instant('applied-filters.with-housing') : this.translate.instant('applied-filters.without-housing'));
        }
        if (filter.mi_ao_travaux_date_from?._d || filter.mi_ao_travaux_date_to?._d) {
          // show '?' instead of date from if date is not set
          const startDate = filter?.mi_ao_travaux_date_from?._d ? format(filter?.mi_ao_travaux_date_from?._d, this.dateFormat) : '?';
          // show '?' instead of date to if date is not set
          const endDate = filter?.mi_ao_travaux_date_to?._d ? format(filter?.mi_ao_travaux_date_to?._d, this.dateFormat) : '?';
          this.addFilterValue(this.translate.instant('operation-details.call-for-tenders-date'),
            [startDate, endDate], true);
          this.addFilterValue(this.translate.instant('operation-details.call-for-tenders-type'), this.statuses[filter.mi_ao_travaux_date_reelle]);
        }
        if (filter.mi_ordre_service_date_from?._d || filter.mi_ordre_service_date_to?._d) {
          // show '?' instead of date from if date is not set
          const startDate = filter?.mi_ordre_service_date_from?._d ? format(filter?.mi_ordre_service_date_from?._d, this.dateFormat) : '?';
          // show '?' instead of date to if date is not set
          const endDate = filter?.mi_ordre_service_date_to?._d ? format(filter?.mi_ordre_service_date_to?._d, this.dateFormat) : '?';
          this.addFilterValue(this.translate.instant('operation-details.market-result-date'),
            [startDate, endDate], true);
          this.addFilterValue(this.translate.instant('operation-details.market-result-type'), this.statuses[filter.mi_ordre_service_date_reelle]);
        }
        if (filter.mi_resultat_marche_date_from?._d || filter.mi_resultat_marche_date_to?._d) {
          // show '?' instead of date from if date is not set
          const startDate = filter?.mi_resultat_marche_date_from?._d ? format(filter?.mi_resultat_marche_date_from?._d, this.dateFormat) : '?';
          // show '?' instead of date to if date is not set
          const endDate = filter?.mi_resultat_marche_date_to?._d ? format(filter?.mi_resultat_marche_date_to?._d, this.dateFormat) : '?';
          this.addFilterValue(this.translate.instant('operation-details.mi_ordre_service-date'),
            [startDate, endDate], true);
          this.addFilterValue(this.translate.instant('operation-details.mi_ordre_service-type'), this.statuses[filter.mi_resultat_marche_date_reelle]);
        }
        if (filter.mi_livraison_date_from?._d || filter.mi_livraison_date_to?._d) {
          // show '?' instead of date from if date is not set
          const startDate = filter?.mi_livraison_date_from?._d ? format(filter?.mi_livraison_date_from?._d, this.dateFormat) : '?';
          // show '?' instead of date to if date is not set
          const endDate = filter?.mi_livraison_date_to?._d ? format(filter?.mi_livraison_date_to?._d, this.dateFormat) : '?';
          this.addFilterValue(this.translate.instant('operation-details.reception-date'),
            [startDate, endDate], true);
          this.addFilterValue(this.translate.instant('operation-details.reception-type'), this.statuses[filter.mi_livraison_date_reelle]);
        }

        this.addNumberValues(this.translate.instant('applied-filters.estimated-cost'), [filter.mi_resultat_marche_montant_from, filter.mi_resultat_marche_montant_to]);
        this.addFilterValue(this.translate.instant('applied-filters.housing-name'), filter.housing_name);
        this.addFilterValue(this.translate.instant('applied-filters.housing-company-name'), filter.housing_company_name);
        this.addNumberValues(this.translate.instant('applied-filters.housing-price'), filter.housing_price);
        if (filter.housing_date_attribution?._d) {
          this.addFilterValue(this.translate.instant('applied-filters.housing-attribution-date'), format(filter?.housing_date_attribution?._d, this.dateFormat));
        }
        this.addEnumValues(this.translate.instant('operation-details.tender'), filter.tender, TypeTender);
      });
  }

  private buildActivityFilterData() {
    this.filters = [];
    let filter = this.advancedActivityService.searchModel.advancedsearch;
    if (filter.date) {
      let dt = parse(filter.date, 'yyyy-MM-dd', new Date());
      this.addFilterValue(this.translate.instant('activity-list.date'), format(dt, this.dateFormat));
    }
    this.addEnumValues(this.translate.instant('activity-list.type'), filter.type, ActivityType);
    this.addFilterValue(this.translate.instant('activity-list.id'), filter.operationId);
    this.addFilterValue(this.translate.instant('activity-list.user'), filter.username);
    this.addFilterValue(this.translate.instant('activity-list.message'), filter.summary);

  }

  private addFilterValue(name: string, value: string | string[], isDate: boolean = false): void {
    if (typeof (value) === "string" && value !== "") {
      this.filters.push({name, value, count: this.countFilters(value)});
    } else if (Array.isArray(value) && !value.every(v => !v)) {
      let valueString = value.join(', ');
      if (isDate) {
        valueString = value.join(' - ');
      }
      this.filters.push({name, value: valueString, count: value.length});
    }
  }

  private addNumberValues(name: string, value: string | string[] | number): void {
    if (typeof (value) === 'string' && value !== '') {
      this.filters.push({name, value: Number.parseInt(value).toLocaleString() + ' €', count: this.countFilters(value)});
    } else if (typeof (value) === 'number') {
      this.filters.push({name, value: value.toLocaleString() + ' €', count: this.countFilters(value.toLocaleString())});
    } else if (Array.isArray(value) && !value.every(v => !v)) {
      const valueString = value.map(v => {
        const vInt = Number.parseInt(v);
        if (Number.isNaN(vInt)) {
          return '';
        } else {
          return vInt.toLocaleString();
        }
      }).join(' € - ');
      this.filters.push({name, value: valueString + ' €', count: value.length});
    }
  }

  private addEnumValues(name: string, value: string | string[], enumType): void {
    if (typeof (value) === 'string' && value !== '') {
      this.filters.push({name, value: enumType.getValue(value), count: value.length});
    } else if (Array.isArray(value)) {
      const values = value.map(v => enumType.getValue(v));
      const valueString = values.join(', ');
      this.filters.push({name, value: valueString, count: value.length});
    }
  }

  private countFilters(filter: string): number {
    return filter.split(',').length;
  }

  private onAdvancedSearchApplied(): void {
    this.appState.applySearch$.subscribe(() => {
      if (this.appState.activeSearch == AdvancedSearchComponent) {
        this.buildOperationsFilterData();
      } else if (this.appState.activeSearch == ActivitySearchComponent) {
        this.buildActivityFilterData();
      }
    });
  }

  public closeFilter(): void {
    this.appState.closeSearch$.emit();
    if (this.appState.activeSearch == AdvancedSearchComponent) {
      this.clearOperationAdvancedSearch();
    } else if (this.appState.activeSearch == ActivitySearchComponent) {
      this.clearActivityAdvancedSearch();
    }
    this.appState.applyFilters$.emit();
  }

  private clearOperationAdvancedSearch() {
    this.advancedSearchService.searchModel.advancedsearch = {
      show: 50
    };
    // this.advancedSearchService.applyFilter$.emit();
  }

  private clearActivityAdvancedSearch() {
    this.advancedActivityService.searchModel.advancedsearch = {
      show: 50
    };
    // this.advancedActivityService.applyFilter$.emit();
  }


}
