<div class="main">
  <div class="full-width">
    <div class="table-heading">
      <h2>{{'activity-list.title' | translate}}</h2>
      <div class="subtitle" *ngIf="isLoaded">
        <span>
           ( {{notificationTable?.totalRecord | frenchNumber: 1}} {{ 'operation-list.results' | translate }} )
        </span>
      </div>
      <div class="right-table-menu">
        <input *ngIf="isSearchVisible" placeholder="Rapid Search..." type="text" [formControl]="search">
        <img class="close-search" *ngIf="isSearchVisible" (click)="closeSearch()"
             src="{{ cdn_url }}/assets/icons/close.svg" alt="close" width="15px">
        <img class="search" *ngIf="!isSearchVisible" (click)="showSearch()"
             src="{{ cdn_url }}/assets/icons/seach-table.svg" alt="search" width="18px">
      </div>
    </div>
    <div class="table-block">
      <table>
        <thead>
        <tr>
          <th #date (click)="sortColumn('date', date)">{{ 'activity-list.date' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #type (click)="sortColumn('type', type)">{{ 'activity-list.type' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #summary (click)="sortColumn('summary', summary)">{{ 'activity-list.message' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #username (click)="sortColumn('username', username)">{{ 'activity-list.user' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #operationId
              (click)="sortColumn('operationId', operationId)">{{ 'activity-list.id' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
        </tr>
        </thead>
        <div class="spinner-block">
          <ngx-ui-loader fgsType="ball-spin-clockwise-fade-rotating"
                         [overlayColor]="'#EFEDFD'"
                         [fgsColor]="'#543bff'"
                         fgsSize="75"
                         loaderId="activity_list"></ngx-ui-loader>
          <tbody id="body">
          <tr #row *ngFor="let notification of notificationTable?.notifications">
            <td>
              {{notification.date || '-'}}
            </td>
            <td>
              <div>
                {{notification.type || '-'}}
              </div>
            </td>
            <td>
              <div [innerHTML]="notification.summary || '-'">

              </div>
            </td>
            <td>{{notification.username || '-'}} {{notification.surname || '-'}}</td>
            <td>{{notification.operationId || '-'}}</td>
          </tr>
          </tbody>
        </div>
      </table>
    </div>

    <div id="pagination" class="operation-table-pagination">
      <span class="pages-count">{{ 'operation-list.items-per-page' | translate }}</span>
      <span [ngClass]="{'active' : advancedActivity?.searchModel?.show === 50 }"
            class="pages-count" (click)="selectPage(50)">50</span>
      <span [ngClass]="{'active' : advancedActivity?.searchModel?.show === 100 }"
            class="pages-count" (click)="selectPage(100)">100</span>
      <span [ngClass]="{'active' : advancedActivity?.searchModel?.show === 500 }"
            class="pages-count" (click)="selectPage(500)">500</span>

      <div class="right-pagination">
                <span class="mobile-none" *ngIf="isLoaded">
                    {{notificationTable?.startfrom}} - {{ setTableItemsEndCount() }}
                  of {{notificationTable?.totalRecord}}
                </span>
        <span class="mobile-none" *ngIf="!isLoaded">
                    0 - 0 {{ 'operation-list.of' | translate }} 0
                </span>
        <img class="previous-page" (click)="previousPage()"
             [ngClass]="{ 'disable': advancedActivity.searchModel?.page === 1 }"
             src="{{ cdn_url }}/assets/icons/errow-right.svg" alt="errow-right"
             width="35">
        <img (click)="nextPage()"
             [ngClass]="{ 'disable': notificationTable?.pages === advancedActivity.searchModel?.page }"
             src="{{ cdn_url }}/assets/icons/errow-right.svg" alt="errow-right" width="35">
      </div>
    </div>
  </div>
</div>
