export enum UserRoles {
  Admin = "admin",
  Enterprise = "enterprise",
  Client = "client"
}

export enum UserRolesTranslate {
  Administrateur = 'admin',
  Entreprise = 'enterprise',
  'Maître d\'ouvrage' = 'client'
}

export enum UserRolesReverse {
  admin = 'Administrateur',
  enterprise = 'Entreprise',
  client = 'Maître d\'ouvrage',
  waiting = 'Attendre'
}

export namespace UserRoles {
  export function values() {
    return Object.keys(UserRoles).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}


export namespace UserRolesTranslate {
  export function values() {
    return Object.keys(UserRolesTranslate).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

export namespace UserRolesReverse {
  export function values() {
    return Object.keys(UserRolesReverse).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
