import {EventEmitter, Injectable} from '@angular/core';
import {EnterprisesSearchModel} from "../models/enterprises-search-model";
import {ISearchService} from "../../shared/abstractions/ISearchService";

@Injectable({
  providedIn: 'root'
})
export class EnterprisesSearchService implements ISearchService{
  public searchModel: EnterprisesSearchModel;
  public applyFilter$ = new EventEmitter<void>();

  constructor() {
  }

  public setup() {
    if (!this.searchModel) {
      this.searchModel = {
        page: 1,
        show: 50,
        order: 'desc',
        orderby: 'id',
      };
    }
  }

}
