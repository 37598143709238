export enum OperationVisibility {
  Privée = '1',
  Public = '2'
}

export namespace OperationVisibility {
  export function values() {
    return Object.keys(OperationVisibility).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue' && type !== 'mapToDropdown'
    );
  }

  export function getValue(key) {
    return Object.keys(OperationVisibility).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue' && OperationVisibility[type] == key && type !== 'mapToDropdown');
  }

  export function mapToDropdown() {
    return Object.keys(OperationVisibility).filter(type => type !== 'values' && type !== 'mapToDropdown' && type !== 'getValue').map(value => {
      return {label: value, value: OperationVisibility[value]};
    });
  }
}
