import { MapDetailsCityData } from "app/dashboard/models/map-data/map-details-city-data";
import {MapDetailsRegionData} from "../app/dashboard/models/map-data/map-details-region-data";
// Space name
// This variable is sent to the api to retrieve the correct config file (config.{space_name}.php)
export const space_name = "nouvel"

// BASE URL OF THE FRONT
export const base_url = "https://nouvel.spotguyane.fr";
// PHP API URL
export const api_url = "https://nouvel.spotguyane.fr/api";
// NODE API URL (used to generate the report)
export const report_api_url = "https://pdf.seigos.fr:10010";

// Sidebar logo name
export const sidebar_logo = "spot.png";

// Dashboard
// Dashboard map image names
export const dashboard_map = "guyane-map.svg";
export const dashboard_great_map = "guyane-detailed-map.svg";

export const dashboard_maps_dropdown = false;

// Use chart animation
export const use_chart_animation = false;

// All languages available on the app : /src/assets/i18n/*.json
export const langs = ["fr-FR", "en-EN"];
// Default language used by the app
export const default_lang = "fr-FR";

// Is a spot project?
export const is_spot_project = true;
/**
 * Map images used on the dashboard->map-details-modal, some examples are written below
 * Just uncomment the images you need to use
 *
 * The id represents the identifier of the department in the "spot_department_france" table
 */
export const map_imgs: MapDetailsRegionData[] =

  [
  /**
   * NOUVELLE-AQUITAINE
   */
    // {text: 'Carte Nouvelle-Aquitaine', src: 'nouvelle-aquitaine-map.svg', color: ''},
    // {text: 'Charente (16)', src: 'DEP_charente.svg', color: '#cdd9ef', id: 1277},
    // {text: 'Charente-Maritime (17)', src: 'DEP_charente_maritime.svg', color: '#c8e5cb', id: 1278},
    // {text: 'Corrèze (19)', src: 'DEP_corrèze.svg', color: '#e8e3c0', id: 1280},
    // {text: 'Creuse (23)', src: 'DEP_creuse.svg', color: '#fbd8e7', id: 1285},
    // {text: 'Dordogne (24)', src: 'DEP_dordogne.svg', color: '#c9ebf9', id: 1286},
    // {text: 'Gironde (33)', src: 'DEP_gironde.svg', color: '#ffefca', id: 1295},
    // {text: 'Landes (40)', src: 'DEP_landes.svg', color: '#f0e6a4', id: 1302},
    // {text: 'Lot-et-Garonne (47)', src: 'DEP_lot_et_garonne.svg', color: '#d4ece5', id: 1309},
    // {text: 'Pyrénées-Atlantiques (64)', src: 'DEP_pyrenees_atlantiques.svg', color: '#e9d7cf', id: 1326},
    // {text: 'Deux-Sèvres (79)', src: 'DEP_deux_sevres.svg', color: '#e9d7cf', id: 1341},
    // {text: 'Vienne (86)', src: 'DEP_vienne.svg', color: '#a7deee', id: 1348},
    // {text: 'Haute-Vienne (87)', src: 'DEP_haute_vienne.svg', color: '#fbd8e7', id: 1349},

  /**
   * BEARN
   */
   // {text: 'Carte Béarn', src: 'bearn-map.svg', color: ''},
   // {text: 'CC du Béarn des Gaves', src: 'CC_Bearn_de_Gaves.svg', color: '#EC7063',id:1260},
   // {text: 'CC de la Vallée d\'Ossau', src: 'CC_Vallee_Ossau.svg', color: '#309CD3',id:1261},
   // {text: 'CA Pau Béarn Pyrénées', src: 'CA_Pau_Bearn_Pyrenees.svg', color: '#FDBD03',id:1253},
   // {text: 'CC des Luys Béarn', src: 'CC_Luys_en_Bearn.svg', color: '#9C8CF4',id:1259},
   // {text: 'CC Pays de Nay', src: 'CC_Pays_de_Nay.svg', color: '#EABFFA',id:1258},
   // {text: 'CC du Haut Béarn', src: 'CC_Haut_Bearn.svg', color: '#27C86A',id:1256},
   // {text: 'CC Lacq Orthez', src: 'CC_Lacq-Orthez.svg', color: '#F5E042',id:1254},
   // {text: 'CC Adour Madiran', src: 'CC_Adour_Madiran.svg', color: '#6B60EA',id:12},
   // {text: 'CC du Nord Est Béarn', src: 'CC_Nord_Est_Bearn.svg', color: '#CCA6F6',id:1255},

    /**
     * GUYANE
     */
     {text: 'Carte Guyane', src: 'guyane-detailed-map.svg', color: ''},
     {text: 'CC Ouest Guyanais', src: 'cc_ouest_guyanais.svg', color: '#00c593'},
     {text: 'CC des Savanes', src: 'cc_savanes.svg', color: '#ff687d'},
     {text: 'CA Centre Litoral', src: 'ca_centre_litoral.svg', color: '#88c8fe'},
     {text: 'CC Est Guyanais', src: 'cc_est_guyanais.svg', color: '#b96ac0'},

    /**
     * MARTINIQUE
     */
    // {text: 'Carte Martinique', src: 'martinique-detailed-map.svg', color: ''},
    // {text: 'CA du Sud', src: 'martinique_CA-sud.svg', color: '#E9DAC7'},
    // {text: 'CA du Centre', src: 'martinique_CA-centre.svg', color: '#F9C5D0'},
    // {text: 'CA du Nord', src: 'martinique-CA-nord.svg', color: '#D4E7B5'},

    /**
     * COTE D'IVOIRE
     */
    // {text: 'Carte côte d\'ivoire', src: 'cote-ivoire.png', color: ''},
    // {text: 'Abidjan', src: 'abidjan.png', color: ''},
    // {text: 'Agneby-Tiassa', src: 'agneby-tiassa.png', color: ''},
    // {text: 'Bafing', src: 'bafing.png', color: ''},
    // {text: 'Bagoué', src: 'bagoue.png', color: ''},
    // {text: 'Bélier', src: 'belier.png', color: ''},
    // {text: 'Béré', src: 'bere.png', color: ''},
    // {text: 'Bounkani', src: 'bounkani.png', color: ''},
    // {text: 'Cavally', src: 'cavally.png', color: ''},
    // {text: 'Folon', src: 'folon.png', color: ''},
    // {text: 'Gbèkè', src: 'gbeke.png', color: ''},
    // {text: 'Gbôklè', src: 'gbokle.png', color: ''},
    // {text: 'Gôh', src: 'goh.png', color: ''},
    // {text: 'Gontougo', src: 'gontougo.png', color: ''},
    // {text: 'Grands-Ponts', src: 'grands-ponts.png', color: ''},
    // {text: 'Guémon', src: 'guemon.png', color: ''},
    // {text: 'Hambol', src: 'hambol.png', color: ''},
    // {text: 'Haut-Sassandra', src: 'haut-sassandra.png', color: ''},
    // {text: 'Iffou', src: 'iffou.png', color: ''},
    // {text: 'Indénié-Djuablin', src: 'indenie-djuablin.png', color: ''},
    // {text: 'Kabadougou', src: 'kabadougou.png', color: ''},
    // {text: 'Loh-Djiboua', src: 'loh-djiboua.png', color: ''},
    // {text: 'Marahoué', src: 'marahoue.png', color: ''},
    // {text: 'Mé', src: 'me.png', color: ''},
    // {text: 'Moronou', src: 'moronou.png', color: ''},
    // {text: 'Nawa', src: 'nawa.png', color: ''},
    // {text: 'N\'zi', src: 'n-zi.png', color: ''},
    // {text: 'Poro', src: 'poro.png', color: ''},
    // {text: 'San-Pedro', src: 'san-pedro.png', color: ''},
    // {text: 'Sud-Comoé', src: 'sud-comoe.png', color: ''},
    // {text: 'Tchologo', src: 'tchologo.png', color: ''},
    // {text: 'Tonkpi', src: 'tonkpi.png', color: ''},
    // {text: 'Worodougou', src: 'worodougou.png', color: ''},
    // {text: 'Yamoussoukro', src: 'yamoussoukro.png', color: ''}
  ];

  export const map_city_imgs: MapDetailsCityData[] =

    [
      {text: 'Apatou', src: 'Apatou.svg'},
      {text: 'Awala-Yalimapo', src: 'Awala-Yalimapo.svg'},
      {text: 'Camopi', src: 'Camopi.svg'},
      {text: 'Cayenne', src: 'Cayenne.svg'},
      {text: 'Grand-Santi', src: 'Grand-Santi.svg'},
      {text: 'Iracoubo', src: 'Iracoubo.svg'},
      {text: 'Kourou', src: 'Kourou.svg'},
      {text: 'Macouria', src: 'Macouria.svg'},
      {text: 'Mana', src: 'Mana.svg'},
      {text: 'Maripasoula', src: 'Maripasoula.svg'},
      {text: 'Matoury', src: 'Matoury.svg'},
      {text: 'Montsinéry-Tonnegrande', src: 'Montsinéry-Tonnegrande.svg'},
      {text: 'Ouanary', src: 'Ouanary.svg'},
      {text: 'Papaichton', src: 'Papaichton.svg'},
      {text: 'Régina', src: 'Régina.svg'},
      {text: 'Remire-Montjoly', src: 'Remire-Montjoly.svg'},
      {text: 'Roura', src: 'Roura.svg'},
      {text: 'Saül', src: 'Saül.svg'},
      {text: 'Saint-Elie', src: 'Saint-Elie.svg'},
      {text: 'Saint-Georges', src: 'Saint-Georges.svg'},
      {text: 'Saint-Laurent-du-Maroni', src: 'Saint-Laurent-du-Maroni.svg'},
      {text: 'Sinnamary', src: 'Sinnamary.svg'}
    ];
