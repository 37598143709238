import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-spot-button',
  templateUrl: './spot-button.component.html',
  styleUrls: ['./spot-button.component.scss']
})
export class SpotButtonComponent implements OnInit, AfterViewInit {
  @Input() text: string;
  @Input() height: string;
  @Input() width: string;
  @Input() blockStyles: any;
  @Input() spanStyles: any;
  @Input() isActive: boolean;

  @ViewChild('spotButton')
  button: ElementRef;

  @ViewChild('spotButtonSpan')
  span: ElementRef;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    if (this.blockStyles && this.button) {
      Object.keys(this.blockStyles).forEach(value => {
        this.button.nativeElement.style[value] = this.blockStyles[value];
      });
    }

    if (this.spanStyles && this.span) {
      Object.keys(this.spanStyles).forEach(value => {
        this.span.nativeElement.style[value] = this.spanStyles[value];
      });
    }
    // this.button.nativeElement.styles = this.styles;
  }
}
