import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { OperationListComponent } from './operation/components/operation-list/operation-list.component';
import { NgModule } from '@angular/core';
import { RegistrationComponent } from './registration/registration.component';
import { AdvancedSearchComponent } from "./operation/components/advanced-search/advanced-search.component";
import {AdminGuard} from './shared/guards/admin.guard';
import {SeigosGuard} from "./shared/guards/seigos.guard";

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'operations-search', component: AdvancedSearchComponent },
  { path: 'report', loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule) },
  { path: '', component: MainComponent, children: [
    { path: 'operations', loadChildren: () => import('./operation/operation-routing.module').then((m) => m.OperationRoutingModule), },
    { path: 'operations/:type', component: OperationListComponent },
    { path: 'statistics', loadChildren: () => import('./statistics/statistics.module').then((m) => m.StatisticsModule), },
    { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule) },
    { path: 'activity', loadChildren: () => import('./activity/activity.module').then((m) => m.ActivityModule), canActivate: [AdminGuard] },
    { path: 'users', loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule), canActivate: [AdminGuard] },
    { path: 'newsletters', loadChildren: () => import('./datastream/datastream.module').then((m) => m.DatastreamModule) },
    { path: 'conflicts', loadChildren: () => import('./conflict-reports/conflict-reports.module').then((m) => m.ConflictReportsModule) },
    { path: 'vigilances', loadChildren: () => import('./vigilance-point/vigilance-point.module').then((m) => m.VigilancePointModule), canActivate: [AdminGuard] },
    { path: 'tasks', loadChildren: () => import('./task/task.module').then((m) => m.TaskModule), canActivate: [AdminGuard] },
    { path: 'collect', loadChildren: () => import('./import/import.module').then((m) => m.ImportModule), canActivate: [AdminGuard] },
    { path: 'settings', loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule), canActivate: [AdminGuard] },
    { path: 'guide', loadChildren: () => import('./user-guide/user-guide.module').then((m) => m.UserGuideModule) },
    { path: 'enterprises', loadChildren: () => import('./enterprises/enterprises.module').then((m) => m.EnterprisesModule), canActivate: [SeigosGuard] },
    { path: 'clients', loadChildren: () => import('./clients/clients.module').then((m) => m.ClientsModule), canActivate: [SeigosGuard] },
    { path: 'plans', loadChildren: () => import('./plans/plans.module').then((m) => m.PlansModule), canActivate: [SeigosGuard] }
  ]},
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {
}
