import { Directive } from '@angular/core';

@Directive({
  selector: '[appInputCheckbox]'
})
export class CheckboxDirective {

  constructor() { }

}
