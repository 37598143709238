import {Visibility} from "./visibility";

export enum StatusDate {
  'Date réelle' = 'true',
  'Date prévue' = 'false'
}

export namespace StatusDate {
  export function values() {
    return Object.keys(StatusDate).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

export enum StatusDateReverse {
  'true' = 'Date réelle',
  'false' = 'Date prévue'
}

export namespace StatusDateReverse {
  export function values() {
    return Object.keys(StatusDateReverse).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

export enum StatusDateCreating {
  'Date réelle' = 1,
  'Date prévue' = 0
}

export namespace StatusDateCreating {
  export function getValue(key) {
    return Object.keys(StatusDateCreating).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue' && StatusDateCreating[type] == key);
  }
  export function values() {
    return Object.keys(StatusDateCreating).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue'
    );
  }
}
