<div class="header">
  <div>
    <img width="32" src="../../../../assets/icons/add-housing.svg">
    <span>{{ 'housing.edit-housing' | translate }}</span>
  </div>
  <img width="18" class="right" src="../../../../assets/icons/close.svg" (click)="cancel()">
</div>
<div class="flex flex-2">
  <div>
    <h5>{{ 'housing.lot' | translate }}</h5>
    <input placeholder="{{ 'housing.lot-helper' | translate }}" type="text" [(ngModel)]="house.name">
  </div>
  <div>
    <h5>{{ 'housing.apeCode' | translate }}</h5>
    <mat-select class="select-form" name="code_ape" [(ngModel)]="house.fk_codeAPE" placeholder="{{ 'housing.apeCode-helper' | translate }}">
      <mat-option *ngFor="let item of codes" [value]="item.id">
        {{ item.code }} - {{item.description}}
      </mat-option>
    </mat-select>
  </div>
  <div>
    <h5>{{ 'housing.company' | translate }}</h5>
    <input placeholder="{{ 'housing.company-helper' | translate }}" type="text" [(ngModel)]="house.company_name">
  </div>
  <div>
    <h5>{{ 'housing.amount' | translate }}</h5>
    <input placeholder="0.00 €" type="number" [(ngModel)]="house.price">
  </div>
</div>
<div class="buttons">
  <button class="cancel" (click)="cancel()">{{ 'housing.cancel' | translate }}</button>
  <button class="add" (click)="saveHouse()">{{ 'housing.save' | translate }}</button>
</div>
