<div class="advanced-search">
  <div class="header">
    <h2>
      <img alt="" src="../../../../assets/icons/seach-table.svg">
      {{ 'header.advanced-search' | translate }}
    </h2>
    <img (click)="closeDialog()" alt="" src="{{ cdn_url }}/assets/icons/close.svg" width="18">
  </div>

  <app-accordion [class]="['accordion-item-padding']" [isHeightOverflow]="true" [isOpen]="true">
    <div class="title-item">
      <div>
        <h5 class="sub-heading">
          {{ 'operation-details.general-information' | translate }}
        </h5>
      </div>
    </div>
    <div class="panel-content">
      <div class="wrapper">
        <div class="flex">
          <div>
            <span>{{ 'operation-details.title' | translate }}</span>
            <input [(ngModel)]="search.operationname" matInput name="operationname"
                   placeholder="{{ 'operation-details.title-help' | translate }}"
                   type="text">
          </div>
          <div>
            <span>{{ 'operation-details.id' | translate }}</span>
            <input [(ngModel)]="search.operationid" matInput name="operationid"
                   placeholder="{{ 'operation-details.id-help' | translate }}"
                   type="number">
          </div>
          <div>
            <span>{{ 'operation-details.status' | translate }}</span>
            <mat-select [(ngModel)]="search.status" multiple
                        name="status" placeholder="{{ 'operation-details.status-helpsearch' | translate }}">
              <mat-option *ngFor="let item of status.values()" [value]="status[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
          <div>
            <span>{{ 'operation-details.client-name' | translate }}</span>
            <ng-multiselect-dropdown [(ngModel)]="search.customers"
                                     [data]="clientUsers$ | async"
                                     [settings]="dropdownSettings" name="customer"
                                     placeholder="{{ 'operation-details.client-name-help' | translate }}">
            </ng-multiselect-dropdown>
          </div>
          <div>
            <span>{{ 'operation-details.locality' | translate }}</span>
            <ng-multiselect-dropdown [(ngModel)]="search.locations"
                                     [data]="cities$ | async"
                                     [settings]="dropdownSettings"
                                     name="location"
                                     placeholder="{{ 'operation-details.locality-helpsearch' | translate }}">
            </ng-multiselect-dropdown>
          </div>
          <div>
            <span>{{ 'operation-details.sector' | translate }}</span>
            <mat-select [(ngModel)]="search.sector" multiple
                        name="sector" placeholder="{{ 'operation-details.sector-helpsearch' | translate }}">
              <mat-option *ngFor="let item of sectors.values()" [value]="sectors[item]">
                {{item}}
              </mat-option>
            </mat-select>
          </div>
          <div>
            <span>{{ 'operation-details.vigilance' | translate }}</span>
            <mat-select [(ngModel)]="search.vigilance" multiple
                        name="vigilance"
                        placeholder="{{ 'operation-details.vigilance-helpsearch' | translate }}">
              <mat-option *ngFor="let item of vigilance.values()" [value]="vigilance[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
          <div *ngIf="appState.isSpotProject; else typeTenderRef">
            <span>{{ 'operation-details.sub-sector' | translate }}</span>
            <mat-select [(ngModel)]="search.subsector" multiple
                        name="subsector"
                        placeholder="{{ 'operation-details.sub-sector-helpsearch' | translate }}">
              <mat-option *ngFor="let item of subsector.values()" [value]="subsector[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
          <div *ngIf="authService.isUserAdmin()">
            <span>{{ 'operation-details.visibility' | translate }}</span>
            <mat-select [(ngModel)]="search.operationvisibility"
                        multiple
                        name="operationvisibility"
                        placeholder="{{ 'operation-details.visibility-help-text' | translate }}">
              <mat-option *ngFor="let item of operationvisibility.values()" [value]="operationvisibility[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
          <div>
            <span>{{ 'operation-details.client-type' | translate }}</span>
            <mat-select [(ngModel)]="search.visibility" multiple
                        name="visiblity"
                        placeholder="{{ 'settings.client-type-helpsearch' | translate }}">
              <mat-option *ngFor="let item of visibility.values()" [value]="visibility[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
          <div *ngIf="!appState.isSpotProject">
            <span>{{ 'operation-list.market-type' | translate }}</span>
            <mat-select [(ngModel)]="search.type_marche" multiple
                        name="type_marche" placeholder="{{ 'operation-list.market-type-helpsearch' | translate }}">
              <mat-option *ngFor="let item of marketTypes.values()" [value]="marketTypes[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
          <div>
            <div class="checkbox-block">
              <mat-checkbox [(ngModel)]="search.has_observation" name="has_observation" value="1"></mat-checkbox>
              <span>{{ 'operation-details.observations' | translate }}</span>
            </div>
            <div class="checkbox-block">
              <mat-checkbox [(ngModel)]="search.has_housing" name="has_housing" value="1"></mat-checkbox>
              <span>{{ 'operation-details.housing' | translate }}</span>
            </div>
          </div>
          <div>
            <span>{{ 'operation-list.category' | translate }}</span>
<!--            <mat-select [(ngModel)]="search.category" multiple-->
<!--                     (selectionChange)="categoryClick()"   name="category" placeholder="{{ 'operation-list.category-helpsearch' | translate }}">-->
<!--              <mat-option *ngFor="let item of categories$ | async" [value]="item">-->
<!--                {{ item.name }}-->
<!--              </mat-option>-->
<!--            </mat-select>-->
            <ng-multiselect-dropdown [(ngModel)]="search.categories"
                                     [data]="categories$ | async"
                                     [settings]="dropdownSettings" name="customer"
                                     (ngModelChange)="categoryClick()"
                                     placeholder="{{ 'operation-list.category-helpsearch' | translate }}">
            </ng-multiselect-dropdown>
          </div>
          <div>
            <span>{{ 'operation-list.subcategory' | translate }}</span>
            <mat-select [(ngModel)]="search.subcategory" multiple
                        name="subcategory" placeholder="{{ 'operation-list.subcategory-helpsearch' | translate }}">
              <mat-option *ngFor="let item of subCategories$ | async" [value]="item">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </div>
          <div>

          </div>
          <div>

          </div>
        </div>
      </div>
    </div>
  </app-accordion>
  <app-accordion *ngIf="authService.isUserAdmin()" [class]="['accordion-item-padding']">
    <div class="title-item">
      <div>
        <h5 class="sub-heading">
          {{ 'operation-details.housing' | translate }}
        </h5>
      </div>
    </div>
    <div class="panel-content">
      <div class="wrapper">

        <div class="flex">
          <div>
            <span>{{ 'applied-filters.housing-name' | translate }}</span>
            <input [(ngModel)]="search.housing_name" matInput name="housingname" placeholder="Entrez le nom du logement"
                   type="text">
          </div>
          <div>
            <span>{{ 'applied-filters.housing-company-name' | translate }}</span>
            <input [(ngModel)]="search.housing_company_name" matInput name="companyname"
                   placeholder="Entrez le nom de l'entreprise"
                   type="text">
          </div>
          <div>
            <span>{{ 'applied-filters.housing-attribution-date' | translate }}</span>
            <input (click)="attribution_date.open()" [(ngModel)]="search.housing_date_attribution"
                   [matDatepicker]="attribution_date"
                   matInput placeholder="Choisissez la date d'attribution">
            <mat-datepicker #attribution_date></mat-datepicker>
          </div>
          <div>
            <span>{{ 'applied-filters.housing-price' | translate }}</span>
            <input [(ngModel)]="search.housing_price" matInput name="housingprice" placeholder="Entrez le prix"
                   type="number">
          </div>
        </div>
      </div>
    </div>
  </app-accordion>
  <app-accordion *ngIf="authService.isUserAdmin()" [class]="['accordion-item-padding']" [isOpen]="true">
    <div class="title-item">
      <div>
        <h5 class="sub-heading">
          {{ 'operation-details.state-of-work' | translate }}
        </h5>
      </div>
    </div>
    <div class="panel-content">
      <div class="wrapper">

        <div class="flex">
          <div>
            <span>{{ 'operation-details.call-for-tenders' | translate }}</span>
            <input (click)="mi_ao_travaux_date_from.open()" [(ngModel)]="search.mi_ao_travaux_date_from"
                   [matDatepicker]="mi_ao_travaux_date_from" class="datefield"
                   matInput placeholder="De">
            <mat-datepicker #mi_ao_travaux_date_from></mat-datepicker>
            <input (click)="mi_ao_travaux_date_to.open()" [(ngModel)]="search.mi_ao_travaux_date_to"
                   [matDatepicker]="mi_ao_travaux_date_to" class="datefield second"
                   matInput placeholder="à">
            <mat-datepicker #mi_ao_travaux_date_to></mat-datepicker>
            <mat-select [(ngModel)]="search.mi_ao_travaux_date_reelle" name="mi_ao_travaux_date_reelle"
                        placeholder="{{ 'operation-details.date-reelle-help' | translate }}">
              <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
          <div>
            <span>{{ 'operation-details.market-result' | translate }}</span>
            <input (click)="mi_ordre_service_date_from.open()" [(ngModel)]="search.mi_ordre_service_date_from"
                   [matDatepicker]="mi_ordre_service_date_from" class="datefield"
                   matInput placeholder="De">
            <mat-datepicker #mi_ordre_service_date_from></mat-datepicker>
            <input (click)="mi_ordre_service_date_to.open()" [(ngModel)]="search.mi_ordre_service_date_to"
                   [matDatepicker]="mi_ordre_service_date_to" class="datefield second"
                   matInput placeholder="à">
            <mat-datepicker #mi_ordre_service_date_to></mat-datepicker>
            <mat-select [(ngModel)]="search.mi_ordre_service_date_reelle" name="mi_ordre_service_date_reelle"
                        placeholder="{{ 'operation-details.date-reelle-help' | translate }}">
              <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
          <div>
            <span>{{ 'operation-details.mi_ordre_service' | translate }}</span>
            <input (click)="mi_resultat_marche_date_from.open()" [(ngModel)]="search.mi_resultat_marche_date_from"
                   [matDatepicker]="mi_resultat_marche_date_from" class="datefield"
                   matInput placeholder="De">
            <mat-datepicker #mi_resultat_marche_date_from></mat-datepicker>
            <input (click)="mi_resultat_marche_date_to.open()" [(ngModel)]="search.mi_resultat_marche_date_to"
                   [matDatepicker]="mi_resultat_marche_date_to" class="datefield second"
                   matInput placeholder="à">
            <mat-datepicker #mi_resultat_marche_date_to></mat-datepicker>
            <mat-select [(ngModel)]="search.mi_resultat_marche_date_reelle" name="mi_resultat_marche_date_reelle"
                        placeholder="{{ 'operation-details.date-reelle-help' | translate }}">
              <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
          <div>
            <span>{{ 'operation-details.reception' | translate }}</span>
            <input (click)="mi_livraison_date_from.open()" [(ngModel)]="search.mi_livraison_date_from"
                   [matDatepicker]="mi_livraison_date_from" class="datefield"
                   matInput placeholder="De">
            <mat-datepicker #mi_livraison_date_from></mat-datepicker>
            <input (click)="mi_livraison_date_to.open()" [(ngModel)]="search.mi_livraison_date_to"
                   [matDatepicker]="mi_livraison_date_to" class="datefield second"
                   matInput placeholder="à">
            <mat-datepicker #mi_livraison_date_to></mat-datepicker>
            <mat-select [(ngModel)]="search.mi_livraison_date_reelle" name="mi_livraison_date_reelle"
                        placeholder="{{ 'operation-details.date-reelle-help' | translate }}">
              <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                {{ item }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div>
  </app-accordion>
  <app-accordion [class]="['accordion-item-padding']">
    <div class="title-item">
      <div>
        <h5 class="sub-heading">
          {{ 'applied-filters.estimated-cost' | translate }}
        </h5>
      </div>
    </div>
    <div class="panel-content">
      <div class="wrapper">

        <div class="flex">
          <div>
            <span>{{ 'applied-filters.enter-price' | translate }}</span>
            <input [(ngModel)]="search.mi_resultat_marche_montant_from" class="datefield" matInput
                   name="mi_resultat_marche_montant_from" placeholder="Min"
                   type="number">
            <input [(ngModel)]="search.mi_resultat_marche_montant_to" class="datefield second" matInput
                   name="mi_resultat_marche_montant_to"
                   placeholder="Max"
                   type="number">
          </div>
          <ng-container *ngIf="appState.isSpotProject">
            <ng-container *ngTemplateOutlet="typeTenderRef"></ng-container>
          </ng-container>
        </div>

      </div>
    </div>
  </app-accordion>

  <div class="footer buttons-block">
    <span (click)="resetFilter()"
          class="spot-button spot-cancel-button">{{ 'applied-filters.reset' | translate }}</span>
    <div>
      <span (click)="goToStatistic()" *ngIf="authService.isUserAdmin()"
            class="spot-button spot-secondary-button">{{ 'applied-filters.statistic-search' | translate }}</span>
      <span (click)="filterOperations()"
            class="spot-button spot-primary-button">{{ 'applied-filters.search' | translate }}</span>
    </div>
  </div>
</div>
<ng-template #typeTenderRef>
  <div [ngClass]="{'margin-0-auto': appState.isSpotProject}">
    <span>{{ 'operation-details.tender' | translate }}</span>
    <mat-select [(ngModel)]="search.tender" multiple
                name="tender" placeholder="{{ 'operation-details.tender-help' | translate }}">
      <mat-option *ngFor="let item of typeTender.values()" [value]="typeTender[item]">
        {{ item }}
      </mat-option>
    </mat-select>
  </div>
</ng-template>
