import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from './http-client.service';
import {map} from "rxjs/operators";

@Injectable()
export class CityService {
    constructor(private httpClient: HttpClientService) {
    }

    public getCityList(): Observable<any> {
        return this.httpClient.post('/city/citylist')
          .pipe(map(value => value.data));
    }
}
