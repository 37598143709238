export class ValidationModel {
  id: number;
  name: string;
  valid: string|boolean = false;
  valid_at: string = "";
  fullname: string;
  signature: string;
  id_user: string;
  id_operation: string;
}
