<app-generic-modal
  (close)="cancel()"
  (done)="saveTask()"
  [headerText]="'task.title' | translate"
  [iconName]="'add-vigilance.svg'"
  [isCalled]="isCalled"
>
  <div class="flex flex-1" *ngIf="users">
    <div *ngIf="task.operation_name">
      <h5>{{ 'task.table.operation' | translate }}</h5>
      <p style="font-size: inherit">
        <a (click)="cancel();" [routerLink]="'/operations/edit/'+task.id_operation">
          {{ task.operation_name }}
        </a>
      </p>
    </div>
    <div>
      <h5>{{ 'task.name' | translate }}</h5>
      <input [(ngModel)]="task.name" placeholder="{{ 'task.name-helper' | translate }}" type="text">
    </div>
    <div>
      <h5>{{ 'task.description' | translate }}</h5>
      <textarea [(ngModel)]="task.description" placeholder="{{ 'task.description-helper' | translate }}"></textarea>
    </div>
    <div>
      <h5>{{ 'task.user' | translate }}</h5>
      <mat-select [(ngModel)]="selected_user" class="select-form" name="id_user"
                  [placeholder]="'task.user' | translate">
        <mat-option *ngFor="let user of users" [value]="user">
          {{ user.name }} {{ user.surname }}
        </mat-option>
      </mat-select>
    </div>
    <div>
      <h5>{{ 'task.priority-label' | translate }}</h5>
      <mat-select [(ngModel)]="task.priority" class="select-form" name="priority"
                  [placeholder]="'task.priority-label' | translate">
        <mat-option *ngFor="let priority of taskService.priorities" [value]="priority">
          {{ 'task.priority.'+priority | translate }}
        </mat-option>
      </mat-select>
    </div>
    <div>
      <h5>{{ 'task.status' | translate }}</h5>
      <mat-select [(ngModel)]="selected_status" class="select-form" name="id_task_status"
                  [placeholder]="'task.status' | translate">
        <mat-option *ngFor="let status of statuses" [value]="status">
          {{ status.name }}
        </mat-option>
      </mat-select>
    </div>
    <div>
      <h5>{{ 'task.phase' | translate }}</h5>
      <mat-select [(ngModel)]="selected_phase" class="select-form" name="id_phase"
                  [placeholder]="'task.phase' | translate">
        <mat-option *ngFor="let phase of phases" [value]="phase">
          {{ phase.name }}
        </mat-option>
      </mat-select>
    </div>
    <div>
      <h5>{{ 'task.deadline_at' | translate }}</h5>
      <input [(ngModel)]="task.deadline_at" placeholder="{{ 'task.deadline_at-helper' | translate }}" type="date">
    </div>
  </div>
</app-generic-modal>
