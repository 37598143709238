<div class="main">
  <div class="full-width">
    <div class="table-heading">
      <h2>{{'vigilance.title' | translate}}</h2>
      <div class="subtitle" *ngIf="isLoaded">
        <span>
           ( {{items?.allcount | frenchNumber: 1}} {{ 'operation-list.results' | translate }} )
        </span>
      </div>
      <div class="right-table-menu">
        <input *ngIf="isSearchVisible" placeholder="Rapid Search..." type="text" [formControl]="search">
        <img class="close-search" *ngIf="isSearchVisible" (click)="closeSearch()"
             src="{{ cdn_url }}/assets/icons/close.svg" alt="close" width="15px">
        <img class="search" *ngIf="!isSearchVisible" (click)="showSearch()"
             src="{{ cdn_url }}/assets/icons/seach-table.svg" alt="search" width="18px">
      </div>
    </div>
    <div class="table-block">
      <table>
        <thead>
        <tr>
          <th #domaine (click)="sortColumn('domaine', domaine)">{{ 'vigilance.field' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #id (click)="sortColumn('id', id)">{{ 'operation-list.id' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #name (click)="sortColumn('name', name)">{{ 'vigilance.designation' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #description (click)="sortColumn('description', description)">{{ 'vigilance.name' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #actors (click)="sortColumn('actors', actors)">{{ 'vigilance.actors' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #resolved (click)="sortColumn('resolved', resolved)">{{ 'vigilance.resolved' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #date (click)="sortColumn('date', date)">{{ 'vigilance.date' | translate }}
            <span>
            <img class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
            <img class="desc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8" alt="">
          </span>
          </th>
          <th #empty></th>
        </tr>
        </thead>
        <div class="spinner-block">
          <ngx-ui-loader fgsType="ball-spin-clockwise-fade-rotating"
                         [overlayColor]="'#EFEDFD'"
                         [fgsColor]="'#543bff'"
                         fgsSize="75"
                         loaderId="vigilance_list"></ngx-ui-loader>
          <tbody id="body">
          <tr #row *ngFor="let notification of items?.list; let i = index">
            <td>
              {{notification.domaine || '-'}}
            </td>
            <td (click)="goToOperationDetails(notification)">
              {{notification.operation_id || '-'}}
            </td>
            <td (click)="goToOperationDetails(notification)">
              {{notification.name || '-'}}
            </td>
            <td>
              {{notification.description || '-'}}
            </td>
            <td>
              {{notification.actors || '-'}}
            </td>
            <td *ngIf="notification.resolved == '0'; else resolved_true">
              Non
            </td>
            <ng-template #resolved_true>
              <td>
                Oui
              </td>
            </ng-template>
            <td>
              {{notification.date || '-'}}
            </td>
            <td>
              <img src="{{ cdn_url }}/assets/icons/edit-table-2.svg" width="20px" alt="edit"
                   (click)="editVigilance(notification, i)">
              <img src="{{ cdn_url }}/assets/icons/delete-table.svg" width="15px" alt="delete"
                   (click)="removeVigilance(notification.id, i)">
            </td>
          </tr>
          </tbody>
        </div>
      </table>
    </div>
    <div id="pagination" class="operation-table-pagination">
      <span class="pages-count">{{ 'operation-list.items-per-page' | translate }}</span>
      <span [ngClass]="{'active' : vigilanceSearch?.searchModel?.show === 50 }"
            class="pages-count" (click)="selectPage(50)">50</span>
      <span [ngClass]="{'active' : vigilanceSearch?.searchModel?.show === 100 }"
            class="pages-count" (click)="selectPage(100)">100</span>
      <span [ngClass]="{'active' : vigilanceSearch?.searchModel?.show === 500 }"
            class="pages-count" (click)="selectPage(500)">500</span>

      <div class="right-pagination">
                <span class="mobile-none" *ngIf="isLoaded">
                    {{items?.startfrom}} - {{ setTableItemsEndCount() }}
                  of {{items?.allcount}}
                </span>
        <span class="mobile-none" *ngIf="!isLoaded">
                    0 - 0 {{ 'operation-list.of' | translate }} 0
                </span>
        <img class="previous-page" (click)="previousPage()"
             [ngClass]="{ 'disable': vigilanceSearch.searchModel?.page === 1 }"
             src="{{ cdn_url }}/assets/icons/errow-right.svg" alt="errow-right"
             width="35">
        <img (click)="nextPage()"
             [ngClass]="{ 'disable': items?.pages === vigilanceSearch.searchModel?.page }"
             src="{{ cdn_url }}/assets/icons/errow-right.svg" alt="errow-right" width="35">
      </div>
    </div>
  </div>
</div>
