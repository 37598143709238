<div class="menu">
  <ul *ngIf="notifications">
    <li *ngFor="let notification of notifications; let i = index">
      <div class="circle">
        <span>{{notification?.username?.toUpperCase()[0]}}{{notification?.surname?.toUpperCase()[0]}}</span>
      </div>
      <div class="content">
        <div>
          {{notification.username | titlecase}} {{notification.surname | titlecase}}
        </div>
        <div class="truncate" data-title="{{notification.summary}}" [innerHTML]="notification.summary.substr(0,300)">
        </div>
        <div>
          {{notification.date  | date: 'dd MMM yyyy - hh:mm aa'}}
        </div>
      </div>
      <div class="read-button">
        <i class="bi bi-bookmark-check" (click)="readNotification(notification)"></i>
      </div>
    </li>
  </ul>
  <div class="act-buttons">
    <a [routerLink]="['/activity']">Show all</a>
  </div>

</div>

