import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'financial'
})
export class FinancialPipe implements PipeTransform {

  transform(value: string, ...args: number[]): string {
    if (!value) {
      return value;
    }
    let res;
    if (value.length < 7) {
      res = this.frenchFormat((Number(value) / 1000).toFixed(args[0]).toLocaleString()).concat(' K €');
    } else {
      res = this.frenchFormat((Number(value) / 1000000).toFixed(args[0]).toLocaleString()).concat(' M €');
    }
    for (let i = 0; i < res.length; i++) {
      let code = res.charCodeAt(i);
      if (code === 8239) {
        res = res.split('');
        res[i] = ' ';
        res = res.join('');
      }
    }

    return res;
  }

  private frenchFormat(number) {
    return Intl.NumberFormat('fr-FR', { useGrouping: true }).format(parseFloat(number));
  }
}
