<div class="main-header" id="my-header">
  <img (click)="showSideBar()" alt="menu-button" src="{{cdn_url}}/assets/icons/menu-button.svg" width="24px">
  <h2>  {{title}} </h2>
  <div class="right-menu">
    <div class="buttons-block">
      <div (click)="search()" class="spot-button spot-primary-button">
        <img alt="menu-button" src="{{cdn_url}}/assets/icons/advanced-search-white.svg" width="16px">
        <span>{{ 'header.advanced-search' | translate }}</span>

      </div>
    </div>

    <div *ngIf="authService.isUserAdmin()" class="hover">
      <img alt="menu-button" src="{{cdn_url}}/assets/icons/settings-menu.svg" width="24px">
      <ul class="menu">
        <li (click)="redirectLink('/users')" *ngIf="authService.isUserAdmin()"> {{ 'header.users' | translate }}</li>
        <li (click)="redirectLink('/settings')"
            *ngIf="authService.isUserAdmin()"> {{ 'left-sidebar.settings' | translate }}</li>
      </ul>
    </div>
    <div *ngIf="!authService.isUserEnterprise() && notifications" class="hover">
      <img (click)="redirectLink('/activity')" alt="menu-button" src="{{cdn_url}}/assets/icons/notification-menu.svg"
           width="20px">
      <div *ngIf="advancedActivityService.notificationsCount" class="notification-count">
        {{advancedActivityService.notificationsCount}}
      </div>
      <app-activity-dialog [notifications]="notifications"></app-activity-dialog>
    </div>
    <div class="hover">
      <div alt="menu-button" class="profile-link" width="40px">
        <span>{{authService.getUserInitials()}}</span>
        <div>
          <ul class="menu">
            <li>{{authService.getUserFullName()}}</li>
            <li (click)="logOut()"> {{ 'header.logout' | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
