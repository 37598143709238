import {AfterViewChecked, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ListComponentModel} from "../../../shared/models/list-component-model";
import {HeaderTitles} from "../../../shared/enums/header-titles";
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from "rxjs/operators";
import {VigilanceSearchService} from "../../services/vigilance-search.service";
import {VigilanceService} from "../../services/vigilance.service";
import {AppStateService} from "../../../shared/services/app-state.service";
import {VigilanceTableModel} from "../../models/vigilance-table-model";
import {Observable} from "rxjs";
import {EditVigilanceComponent} from "../edit-vigilance/edit-vigilance.component";
import { MatDialog} from "@angular/material/dialog";
import {VigilanceModel} from "../../models/vigilance-model";
import {Router} from "@angular/router";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Component({
  selector: 'app-vigilances-list',
  templateUrl: './vigilance-list.component.html',
  styleUrls: ['./vigilance-list.component.scss']
})
export class VigilanceListComponent extends ListComponentModel implements OnInit, OnDestroy, AfterViewChecked {
  public items: VigilanceTableModel;

  constructor(public vigilanceSearch: VigilanceSearchService,
              public vigilanceService: VigilanceService,
              private appState: AppStateService,
              private dialog: MatDialog,
              private router: Router,
              private ngxLoader: NgxUiLoaderService) {
    super();
  }

  ngOnInit(): void {
    this.initFilters();
    this.searchData();
    this.getItemsList();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewChecked() {
    this.calculateTableHeigth();
  }

  public initFilters() {
    this.appState.setHeaderText(HeaderTitles.Vigilance);
    this.vigilanceSearch.setup();
    this.editVigilanceInList();
    this.closeVigilanceModal();
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    setTimeout(() => {
      this.calculateTableHeigth();
    }, 300);
  }

  public searchData() {
    this.search.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((res) => this.searchAction(res))
      ).subscribe((resp) => {
      this.isLoaded = true;
      this.items = resp;
      this.vigilanceSearch.searchModel.page = resp.pageId;
    });
  }

  private searchAction(res): Observable<any> {
    this.isLoaded = false;
    this.vigilanceSearch.searchModel.search = res;
    this.vigilanceSearch.searchModel.page = 1;
    this.vigilanceSearch.searchModel.show = 50;
    return this.vigilanceService.getAllVigilantes();
  }

  private getItemsList() {
    this.isLoaded = false;
    this.ngxLoader.startLoader('vigilance_list');
    if (!this.isRequestSend) {
      this.isRequestSend = true;
      this.vigilanceService.getAllVigilantes()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp) => {
          this.isLoaded = true;
          this.isRequestSend = false;
          this.items = resp;
          this.vigilanceSearch.searchModel.page = resp.pageId;
          this.calculateTableHeigth();
          this.ngxLoader.stopLoader('vigilance_list');
        });
    }
  }

  public sortColumn(column: string, elem?: HTMLElement): void {
    if (column !== this.vigilanceSearch.searchModel.orderby) {
      this.vigilanceSearch.searchModel.orderby = column;
      this.vigilanceSearch.searchModel.order = 'asc';
      this.setActiveColumn(elem, 'asc');
    } else {
      if (this.vigilanceSearch.searchModel.order === 'asc') {
        this.vigilanceSearch.searchModel.order = 'desc';
        this.setActiveColumn(elem, 'desc');
      } else {
        this.vigilanceSearch.searchModel.order = 'asc';
        this.setActiveColumn(elem, 'asc');
      }
    }
    this.getItemsList();
  }

  public setActiveColumn(activeColumn: HTMLElement, order: string) {

    if (this.previousActiveColumn) {
      this.previousActiveColumn.classList.remove('active');
      this.previousActiveColumn.getElementsByClassName('asc')[0].classList.remove('active');
      this.previousActiveColumn.getElementsByClassName('desc')[0].classList.remove('active');
    }

    activeColumn.classList.add('active');
    activeColumn.getElementsByClassName(order)[0].classList.add('active');
    this.previousActiveColumn = activeColumn;
  }

  public showSearch(): void {
    this.isSearchVisible = true;
  }

  public selectPage(pagesCount: number): void {
    this.vigilanceSearch.searchModel.show = pagesCount;
    this.getItemsList();
  }

  public closeSearch(): void {
    this.isSearchVisible = false;
  }

  public nextPage() {
    if (this.items.pages === this.vigilanceSearch.searchModel.page) {
      return;
    }
    this.vigilanceSearch.searchModel.page = this.vigilanceSearch.searchModel.page + 1;
    this.getItemsList();
  }

  public previousPage() {
    if (this.vigilanceSearch.searchModel.page === 1) {
      return;
    }
    this.vigilanceSearch.searchModel.page = this.vigilanceSearch.searchModel.page - 1;
    this.getItemsList();
  }

  public setTableItemsEndCount(): number {
    let endCount = this.vigilanceSearch.searchModel.show * this.vigilanceSearch.searchModel.page;
    if (endCount > Number(this.items?.allcount)) {
      endCount = Number(this.items?.allcount);
    }
    return endCount;
  }

  public editVigilance(vigilance: VigilanceModel, index): void {
    const dialogRef = this.dialog.open(EditVigilanceComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      disableClose: true,
      data: {
        vigilance: vigilance,
        index: index,
        id: vigilance.operation_id
      }
    });
  }

  private editVigilanceInList(): void {
    this.vigilanceService.editVigilanceList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp => {
        this.getItemsList();
      }));
  }

  private closeVigilanceModal(): void {
    this.vigilanceService.closeVigilance$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp => {
        this.dialog.closeAll();
      }));
  }

  public removeVigilance(id, index): void {
    this.vigilanceService.deleteVigilance(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp.result == 'true') {
          this.items.list.splice(index, 1);
        }
      });
  }

  private calculateTableHeigth() {
    let top = document.getElementById("body").getBoundingClientRect().top;
    let bottom = document.getElementById("pagination").getBoundingClientRect().height;
    document.getElementById("body").style.maxHeight = `calc(100vh - ${top + bottom + 2 + 'px'})`;
    document.getElementById("body").style.height = `calc(100vh - ${top + bottom + 2 + 'px'})`;
  }

  goToOperationDetails(notification: VigilanceModel) {
    let url = `/operations`;
    this.router.navigate([url], {queryParams: {type: 'all', operation: notification.operation_id}});
  }
}
