import {Component, Input, OnInit} from '@angular/core';
import {TableService} from '../../services/table.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() public table_id: string;

  constructor(
    public tableService: TableService
  ) { }

  nextPage()
  {
    if (this.tableService.data[this.table_id]?.meta.page < this.getTotalPage()) {
      this.tableService.ChangePage.next({ table_id: this.table_id, value: "+1" });
    }
  }

  previousPage()
  {
    if (this.tableService.data[this.table_id]?.meta.page > 1) {
      this.tableService.ChangePage.next({table_id: this.table_id, value: "-1"});
    }
  }

  changeLimitPage(limit)
  {
    this.tableService.data[this.table_id]!.meta.page = 1;
    this.tableService.ChangeLimitPage.next({ table_id: this.table_id, limit });
  }

  getMaxElementsInPage()
  {
    let meta = this.tableService.data[this.table_id]?.meta;
    const max_elements = meta.limit * meta.page;

    return meta.total > max_elements ? max_elements : meta.total
  }

  getTotalPage()
  {
    let meta = this.tableService.data[this.table_id]?.meta;
    return Math.ceil(meta.total / meta.limit);
  }

}
