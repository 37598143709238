import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[accordion]'
})
export class AccordionDirective {
  @Input() accordionItem: HTMLElement;
  @Input() errowBottomItem: HTMLElement;
  @Input() errowRightItem: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('click') click() {
    this.el.nativeElement.classList.toggle('active');
    
    this.toggleIcons();
    if (this.accordionItem.style.maxHeight || this.accordionItem.classList.contains('open')) {
        this.setMaxHeight(null);
      this.accordionItem.classList.remove('open');
    } else {
        this.setMaxHeight(this.accordionItem.scrollHeight + "px");
      this.accordionItem.classList.add('open');
    }
  }

  private setMaxHeight(height: string) {
    this.renderer.setStyle(this.accordionItem, 'maxHeight', height);
  }

  private setDisplay(element: HTMLElement, display: string) {
    this.renderer.setStyle(element, 'display', display);
  }

  private toggleIcons(): void {
    if (this.errowRightItem.style.display === "none") {
      this.setDisplay(this.errowRightItem, "inline-block");
      this.setDisplay(this.errowBottomItem, "none");
    } else {
      this.setDisplay(this.errowRightItem, "none");
      this.setDisplay(this.errowBottomItem, "inline-block");
    }
  }
}
