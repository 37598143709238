import { Injectable } from '@angular/core';
import * as constant from '../../app.constant';

@Injectable()
export class DataStorageService {
    constructor() {
    }

    public getCdnUrl(): string {
        return constant.ACTIVE_ENVIRONMENT.cdn_url;
    }
}