import {Component, Inject, OnInit} from '@angular/core';
import {OperationService} from "../../service/operation.service";
import {Observable} from "rxjs";
import { MAT_DIALOG_DATA,  MatDialogRef} from "@angular/material/dialog";
import Swal from "sweetalert2";

@Component({
  selector: 'app-grant-access-modal',
  templateUrl: './grant-access-modal.component.html',
  styleUrls: ['./grant-access-modal.component.scss']
})
export class GrantAccessModalComponent implements OnInit {
  data: any = {};
  operations: any[];
  users$: Observable<any[]>;

  constructor(private operationService: OperationService, @Inject(MAT_DIALOG_DATA) data: IGrantAccessData, public dialogRef: MatDialogRef<GrantAccessModalComponent>) {
    this.operations = data.operations;
    this.data.operations = this.operations.map(value => value.id);
  }

  ngOnInit(): void {
    this.users$ = this.operationService.getActiveUsers();
  }

  grantAccess() {
    this.operationService.grantAccess(this.data).subscribe(value => {
      if (value.result == 'true') {
        Swal('Succès', 'Accès accordé', 'success');
      } else {
        Swal('Échec', "Quelque chose n'a pas fonctionné", 'error');
      }
      this.cancel();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}

interface IGrantAccessData {
  operations: any[];
}
