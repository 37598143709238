import {Injectable} from '@angular/core';
import {HttpClientService} from "../../shared/services/http-client.service";
import {BulkReportGenerateModel, ReportGenerateModel} from "../models/report-generate-model";
import * as constant from '../../app.constant';
import {ReportModel} from "../models/report-model";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ReportApiService {
  reportModel$ = new BehaviorSubject<ReportModel[]>([new ReportModel()]);

  constructor(private httpClient: HttpClientService) {
  }

  loadPdfReportData(operationIds: number[]) {
    this.httpClient.post('/reports/generatePDFReport', {op_id: operationIds})
      .subscribe((report: ReportModel[]) => this.reportModel$.next(report));
  }

  generatePdf(model: ReportGenerateModel) {
    return this.httpClient.postPure(constant.ACTIVE_ENVIRONMENT.report_api_url.concat('/report'), model);
  }

  generateBulkPdf(model: BulkReportGenerateModel) {
    return this.httpClient.postPure(constant.ACTIVE_ENVIRONMENT.report_api_url.concat('/report/bulk'), model);
  }

  getReportFinancialAmount(report: ReportModel) {
    if (!report || !report.financial) {
      return '0';
    }
    const totalSum = report?.financial?.map(value => Number.parseFloat(value.amount)).reduce((a, b) => a + b, 0);
    return totalSum.toString();
  }

  getReportIncomeAmount(report: ReportModel) {
    if (!report || !report.incomes) {
      return '0';
    }
    // @ts-ignore
    const totalSum = report?.incomes?.map(value => Number.parseFloat(value.amount)).reduce((a, b) => a + b, 0);
    return totalSum.toString();
  }

  getReportExpenseAmount(report: ReportModel) {
    if (!report || !report.expenses) {
      return '0';
    }
    // @ts-ignore
    const totalSum = report?.expenses?.map(value => Number.parseFloat(value.amount)).reduce((a, b) => a + b, 0);
    return totalSum.toString();
  }

  getReportHousingAmount(report: ReportModel) {
    if (!report || !report.housing) {
      return '0';
    }
    const totalSum = report?.housing?.map(value => +(value.price)).reduce((a, b) => a + b, 0);
    return Math.ceil(totalSum).toString();
  }

  sendPdfByEmail(email: string, pdf: any[]) {
    this.httpClient.post('/reports/sendPdfByEmail', {email, pdf})
      .subscribe(() => {});
  }

  getReportOverSpendHousingAmount(report: ReportModel) {
    if (!report || !report.housing) {
      return '0';
    }
    const totalSum = report?.housing?.map(value => value.overspend ? +(value.overspend) : 0).reduce((a, b) => a + b, 0);
    return totalSum.toString();
  }

  getReportExecutingTime(report: ReportModel) {
    if (
      !report ||
      (!report.mi_ordre_service_date) ||
      (!report.mi_livraison_date)
    ) {
      return '--'
    }

    let start = report.mi_ordre_service_date;
    let end = report.mi_livraison_date;
    return this.monthDiff(new Date(start), new Date(end))
  }

  private monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
  // November 4th, 2008 new Date(2010, 2, 12)
  // March 12th, 2010 );
  // Result: 15: December 2008, all of 2009, and Jan & Feb 2010 monthDiff( new Date(2010, 0, 1),
  // January 1st, 2010 new Date(2010, 2, 12)
  // March 12th, 2010 );
  // Result: 1: February 2010 is the only full month between them monthDiff( new Date(2010, 1, 1),
  // February 1st, 2010 new Date(2010, 2, 12)
  // March 12th, 2010 );
  // Result: 0: There are no *full* months between them
}
