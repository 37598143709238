import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableDirective } from './directives/table.directive';
import { TheadDirective } from './directives/thead.directive';
import { TbodyDirective } from './directives/tbody.directive';
import { TrDirective } from './directives/tr.directive';
import { ThDirective } from './directives/th.directive';
import { TdDirective } from './directives/td.directive';
import { CheckboxCellDirective } from './directives/checkbox-cell.directive';
import { OrderingDirective } from './directives/ordering.directive';
import { ActionDeleteDirective } from './directives/action-delete.directive';
import { ActionEditDirective } from './directives/action-edit.directive';
import { FilterComponent } from './components/filter/filter.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CountDirective } from './directives/count.directive';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { PaginationComponent } from './components/pagination/pagination.component';


@NgModule({
  declarations: [
    TableDirective,
    TheadDirective,
    TbodyDirective,
    TrDirective,
    ThDirective,
    TdDirective,
    CheckboxCellDirective,
    OrderingDirective,
    ActionDeleteDirective,
    ActionEditDirective,
    FilterComponent,
    CountDirective,
    CheckboxComponent,
    PaginationComponent
  ],
  exports: [
    TableDirective,
    TheadDirective,
    TbodyDirective,
    TrDirective,
    ThDirective,
    TdDirective,
    CheckboxCellDirective,
    OrderingDirective,
    ActionDeleteDirective,
    ActionEditDirective,
    FilterComponent,
    CountDirective,
    CheckboxComponent,
    PaginationComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatCheckboxModule,
    TranslateModule,
    FormsModule
  ]
})
export class TableModule { }
