import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {VigilanceModel} from '../../models/vigilance-model';
import {VigilanceService} from '../../services/vigilance.service';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {DialogBase} from "../../../shared/abstractions/dialog-base";

@Component({
  selector: 'app-edit-vigilance',
  templateUrl: './edit-vigilance.component.html',
  styleUrls: ['./edit-vigilance.component.scss']
})
export class EditVigilanceComponent extends DialogBase implements OnInit, OnDestroy {
  public vigiliance: VigilanceModel = {};
  private index: number;

  constructor(public vigilianceService: VigilanceService, @Inject(MAT_DIALOG_DATA) data) {
    super();
    this.loadData(data);
  }

  ngOnInit(): void {
  }

  public loadData(data): void {
    this.vigiliance = {
      actors: data.vigilance.actors,
      description: data.vigilance.description,
      id: data.vigilance.id,
      name: data.vigilance.name,
      operation_id: data.vigilance.operation_id,
      domaine: data.vigilance.domaine,
      resolved: data.vigilance.resolved,
      user_id: data.vigilance.user_id,
      date: data.vigilance.date
    };
    this.index = data.index;
  }

  public cancel(): void {
    this.vigilianceService.closeVigilance$.next();
  }

  public editVigiliance(): void {
    this.isCalled = true;
    this.vigilianceService.editVigilance(this.vigiliance)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp.result == 'true') {
          this.vigilianceService.editVigilanceList$.next();
        }
        this.cancel();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
