import {IConfig, NON_WHITESPACE_REGEX} from "./IConfig";
import {UntypedFormGroup} from "@angular/forms";
import {HttpClientService} from "../../shared/services/http-client.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {RecipientsObjectModel} from "../models/recipients-object-model";
import {TranslateService} from "@ngx-translate/core";

export class RecipientsConfig implements IConfig {
  columns = ['id', 'firstName', 'lastName', 'enterprise', 'email', 'added_by', 'date_created'];
  form = new UntypedFormGroup({});
  model = new RecipientsObjectModel();
  name = 'recipient';
  defaultHttpParams = {orderby: 'first_name', order: 'desc', show: 50, page: 1};

  fields: FormlyFieldConfig[];

  constructor(private http: HttpClientService, private translate: TranslateService) {
    this.translate.get(['settings.firstName', 'settings.firstName-help', 'settings.lastName', 'settings.lastName-help',
      'settings.email', 'settings.email-help', 'settings.enterprise', 'settings.enterprise-help']).subscribe(value => {
      this.fields = [
        {
          key: 'first_name',
          type: 'input',
          name: 'firstName',
          templateOptions: {
            label: value['settings.firstName'],
            placeholder: value['settings.firstName-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        },
        {
          key: 'last_name',
          type: 'input',
          name: 'lastName',
          templateOptions: {
            label: value['settings.lastName'],
            placeholder: value['settings.lastName-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        },
        {
          key: 'company',
          type: 'input',
          name: 'enterprise',
          templateOptions: {
            label: value['settings.enterprise'],
            placeholder: value['settings.enterprise-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        },
        {
          key: 'email_id',
          type: 'input',
          name: 'email',
          templateOptions: {
            label: value['settings.email'],
            placeholder: value['settings.email-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        }
      ];
    });
  }

  getData(params = null): Observable<any> {
    if (!params) {
      params = this.defaultHttpParams;
    }
    return this.http.post('/newsletter/getallreceivers', params).pipe(map(value => value.users));
  }

  addItem(): Observable<boolean> {
    return this.http.post('/newsletter/addreceiver', this.model).pipe(map(value => value.success));
  }

  editItem(): Observable<boolean> {
    return this.http.post('/newsletter/editreceiver', this.model).pipe(map(value => value.success));
  }

  getItemData(id): Observable<any> {
    return this.http.post('/newsletter/getreceiver', {id: id}).pipe(map(value => value.data));
  }

  deleteItem(id): Observable<boolean> {
    return this.http.post('/newsletter/deletereceiver', {id: id}).pipe(map(value => value.success));
  }

  setOrder(field) {
    // check if the field is ID
    if (field === 'id') {
      this.setOrderParameter(field);
    } else {
      // otherwise get name from the form fields
      const name = this.fields.find(value => value.name === field)?.key.toString();
      if (name) {
        this.setOrderParameter(name);
      } else {
        this.setOrderParameter(field);
      }
    }
  }

  private setOrderParameter(newParameter) {
    if (this.defaultHttpParams.orderby === newParameter) {
      this.setOrderType();
    } else {
      this.defaultHttpParams.orderby = newParameter;
    }
  }

  private setOrderType() {
    if (this.defaultHttpParams.order === 'desc') {
      this.defaultHttpParams.order = 'asc';
    } else {
      this.defaultHttpParams.order = 'desc';
    }
  }
}
