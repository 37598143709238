import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTh]'
})
export class ThDirective {

  constructor(
    private _el: ElementRef, private _renderer: Renderer2
  ) {
    this._renderer.addClass(this._el.nativeElement, 'seigos-th');
  }

}
