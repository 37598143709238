import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[toggle]'
})
export class ToggleDirective {
  @Input() toggleItem: HTMLElement;
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.listen('window', 'click', (event: Event) => {
      if (this.isOutSideClick(event)) {
        this.setDisplay('block');
      } else {
        this.setDisplay('none');
      }
    });
  }

  private isOutSideClick(event): boolean {
    return this.el.nativeElement.contains(event.target);
  }

  private setDisplay(display: string) {
    this.renderer.setStyle(this.toggleItem, 'display', display);
  }
}
