import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as constant from '../app.constant';

@Injectable({
  providedIn: 'root'
})

export class DataService {
    public base_url: string = constant.ACTIVE_ENVIRONMENT.base_url;
    public api_url: string = constant.ACTIVE_ENVIRONMENT.api_url;
    public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
    static instance: DataService;

    constructor(private http: HttpClient) { 
        DataService.instance = this;
    }
    
    getConstants() {
        let constants: any[] = [];
        constants['base_url'] = this.base_url;
        constants['api_url'] = this.api_url;
        constants['cdn_url'] = this.cdn_url;
        return constants;
    }

    userLogin(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/login/user_login', {params: params}, options).pipe(map((response: any) => response));
    }

    getLanguage(language) {
        if(!language) {
            language = "english";
        }
        return this.http.get(this.cdn_url+"/assets/languages/"+language+".json");
    }

    getCities(){
        return this.http.get(this.cdn_url+"/assets/city.json");
    }

    getGraphStatistics(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/statistics/getgraphstatistics', {params: params}, options).pipe(map((response: any) => response));
    }

    getOperationsList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/index', {params: params}, options).pipe(map((response: any) => response));
    }

    getOperationsDetails(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/getoperation', {params: params}, options).pipe(map((response: any) => response));
    }

    addOperation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/addoperation', {params: params}, options).pipe(map((response: any) => response));
    }

    logout(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/login/logout', {params: params}, options).pipe(map((response: any) => response));
    }

    getDashboardOperationsList(params, api) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url +'/dashboard/getoperationlist/'+api, {params: params}, options).pipe(map((response: any) => response));
    }

    getOperationsCount(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/dashboard/index', {params: params}, options).pipe(map((response: any) => response));
    }

    getListedOperationsTotalAmount(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url + '/operations/getAmountSum', { params: params }, options).pipe(map((response: any) => response));
    }

    getHomeNotifications(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/notification/index', {params: params}, options).pipe(map((response: any) => response));
    }

    getAllUsers(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/index', {params: params}, options).pipe(map((response: any) => response));
    }

    deleteOperation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/delete', {params: params}, options).pipe(map((response: any) => response));
    }

    getUsersList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/index', {params: params}, options).pipe(map((response: any) => response));
    }

    addUser(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/adduser', {params: params}, options).pipe(map((response: any) => response));
    }

    deleteUser(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/delete', {params: params}, options).pipe(map((response: any) => response));
    }

    getUserDetails(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/getuser', {params: params}, options).pipe(map((response: any) => response));
    }

    editUser(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/edituser', {params: params}, options).pipe(map((response: any) => response));
    }

    userRegister(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/register', {params: params}, options).pipe(map((response: any) => response));
    }

    getReport(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/reports/index', {params: params}, options).pipe(map((response: any) => response));
    }

    downloadReport(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/reports/exportcsv', {params: params}, options).pipe(map((response: any) => response));
    }

    editOperation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/editoperation', {params: params}, options).pipe(map((response: any) => response));
    }

    changeStatus(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/changestatus', {params: params}, options).pipe(map((response: any) => response));
    }

    validateOperation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/bulkeditadmin', {params: params}, options).pipe(map((response: any) => response));
    }

    getCodeApeList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/getcodeapelist', {params: params}, options).pipe(map((response: any) => response));
    }

    getAllOperationsList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/getalloperations', {params: params}, options).pipe(map((response: any) => response));
    }

    getAllObservations(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/getobservations', {params: params}, options).pipe(map((response: any) => response));        
    }

    saveObservation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/saveobservation', {params: params}, options).pipe(map((response: any) => response));  
    }

    deleteObservation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/deleteobservation', {params: params}, options).pipe(map((response: any) => response));  
    }

    getObservationsDetails(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/getobservationdetails', {params: params}, options).pipe(map((response: any) => response));  
    }

    updateObservation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/editobservation', {params: params}, options).pipe(map((response: any) => response));  
    }

    markNotificationRead(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/notification/marknotificationread', {params: params}, options).pipe(map((response: any) => response));  
    }

    getUnreadNotifications(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/notification/getunreadnotifications', {params: params}, options).pipe(map((response: any) => response));  
    }

    restoreOperation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/restore', {params: params}, options).pipe(map((response: any) => response));
    }

    uploadFiles(params) {
        return this.http.post(this.api_url+'/operations/uploadoperationfile', params).pipe(map((response: any) => response));
    }

    deleteFile(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/deleteoperationfile', {params: params}, options).pipe(map((response: any) => response));
    }

    getOperationFiles(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/getoperationfiles', {params: params}, options).pipe(map((response: any) => response));        
    }

    updateOperationStatus(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/checkstatusandestimatedcost', {params: params}, options).pipe(map((response: any) => response));        
    }

    takeDatabaseBackup(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/backup_database.php', {params: params}, options).pipe(map((response: any) => response));        
    }

    addHousing(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/housing/addhousing', {params: params}, options).pipe(map((response: any) => response));        
    }

    getHousingList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/housing/index', {params: params}, options).pipe(map((response: any) => response));        
    }

    getHousingDetails(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/housing/gethousing', {params: params}, options).pipe(map((response: any) => response));        
    }

    editHousing(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/housing/edithousing', {params: params}, options).pipe(map((response: any) => response));        
    }

    deleteHousing(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/housing/delete', {params: params}, options).pipe(map((response: any) => response));        
    }

    addFinancial(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/financial/addfinancial', {params: params}, options).pipe(map((response: any) => response));        
    }

    getFinancialList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/financial/index', {params: params}, options).pipe(map((response: any) => response));        
    }

    getFinancialDetails(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/financial/getfinancial', {params: params}, options).pipe(map((response: any) => response));        
    }

    editFinancial(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/financial/editfinancial', {params: params}, options).pipe(map((response: any) => response));        
    }

    deleteFinancial(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/financial/delete', {params: params}, options).pipe(map((response: any) => response));        
    }

    getHousingDropdown(params){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/housing/housinglist', {params: params}, options).pipe(map((response: any) => response));
    }

    getFinancialDropdown(params){
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/financial/financiallist', {params: params}, options).pipe(map((response: any) => response));
    }

    addCity(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/city/addcity', {params: params}, options).pipe(map((response: any) => response));        
    }

    getCityList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/city/citylist', {params: params}, options).pipe(map((response: any) => response));        
    }

    getCityDetails(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/city/getcity', {params: params}, options).pipe(map((response: any) => response));        
    }

    editCity(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/city/editcity', {params: params}, options).pipe(map((response: any) => response));        
    }

    deleteCity(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/city/delete', {params: params}, options).pipe(map((response: any) => response));        
    }

    getCitiesList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/city/index', {params: params}, options).pipe(map((response: any) => response));        
    }

    getUsersById(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/getusersbyid', {params: params}, options).pipe(map((response: any) => response));        
    }

    addVigilance(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/vigilance/add', {params: params}, options).pipe(map((response: any) => response));        
    }

    getVigilanceList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/vigilance/lists', {params: params}, options).pipe(map((response: any) => response));        
    }

    getVigilanceGraphStatistics(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/vigilance/statistics', {params: params}, options).pipe(map((response: any) => response));
    }

    getAllVigiliance(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/vigilance/getvigiliance', {params: params}, options).pipe(map((response: any) => response));        
    }

    deleteVigiliance(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/vigilance/deletevigilance', {params: params}, options).pipe(map((response: any) => response));        
    }

    editVigiliance(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/vigilance/edit', {params: params}, options).pipe(map((response: any) => response));        
    }

    getVigilianceDetail(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/vigilance/getvigilancedetails', {params: params}, options).pipe(map((response: any) => response));        
    }

    getSubsectorlist(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/sector/sectorlist', {params: params}, options).pipe(map((response: any) => response));        
    }

    addSector(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/sector/addsector', {params: params}, options).pipe(map((response: any) => response));        
    }

    editSector(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/sector/editsector', {params: params}, options).pipe(map((response: any) => response));        
    }

    getSectorDetail(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/sector/getsector', {params: params}, options).pipe(map((response: any) => response));        
    }

    deleteSector(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/sector/delete', {params: params}, options).pipe(map((response: any) => response));        
    }

    newsletter(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter', {params: params}, options).pipe(map((response: any) => response));        
    }

    newsletterUsers(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter/getnewsletters', {params: params}, options).pipe(map((response: any) => response));        
    }

    newsletterManagement(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter/getnewslettersmaster', {params: params}, options).pipe(map((response: any) => response));        
    }

    newsletterTemplate(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter/emailtemplate', {params: params}, options).pipe(map((response: any) => response));        
    }

    clickFunction(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter/clickonoperation', {params: params}, options).pipe(map((response: any) => response));        
    }

    getStatisticsSubsector(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/statistics/getgraphsubsector', {params: params}, options).pipe(map((response: any) => response));        
    }

    unreadNotification(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/notification/markallunreadnotifications', {params: params}, options).pipe(map((response: any) => response));        
    }

    userPassword(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/resetpassword', {params: params}, options).pipe(map((response: any) => response));        
    }

    userManagement(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/usertypemanagement', {params: params}, options).pipe(map((response: any) => response));        
    }

    getClientUser(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/getuserlistforclient', {params: params}, options).pipe(map((response: any) => response));        
    }

    resetPassword(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/resetpasswordlogin', {params: params}, options).pipe(map((response: any) => response));        
    }

    forgotPassword(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/forgetpassword', {params: params}, options).pipe(map((response: any) => response));        
    }

    passwordReset(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/passwordreset', {params: params}, options).pipe(map((response: any) => response));        
    }

    addNewUser(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/addnewuser', {params: params}, options).pipe(map((response: any) => response));        
    }

    getPureUser(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/getpureuserbystatus', {params: params}, options).pipe(map((response: any) => response));        
    }

    editNewUser(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/editnewuser', {params: params}, options).pipe(map((response: any) => response));        
    }

    getListByStatus(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/dashboard/getListByStatus', {params: params}, options).pipe(map((response: any) => response));        
    }

    getActiveUsers(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/getactiveuserlist', {params: params}, options).pipe(map((response: any) => response));        
    }

    getUsersByRole(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/getusersbyrole', {params: params}, options).pipe(map((response: any) => response));        
    }

    editClient(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/editclient', {params: params}, options).pipe(map((response: any) => response));  
    }

    newsletterReceiverList(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter/getallreceivers', {params: params}, options).pipe(map((response: any) => response));  
    }

    newsletterAddReceivers(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter/addreceiver', {params: params}, options).pipe(map((response: any) => response));  
    }

    newsletterEditReceivers(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter/editreceiver', {params: params}, options).pipe(map((response: any) => response));  
    }

    newsletterGetReceivers(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter/getreceiver', {params: params}, options).pipe(map((response: any) => response));  
    }

    newsletterDeleteReceivers(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/newsletter/deletereceiver', {params: params}, options).pipe(map((response: any) => response));  
    }

    public getUsersWatchingOperationCount(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/getuserwatchoperation', {params: params}, options).pipe(map((response: any) => response));  
    }

    public addUserWatchOperation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/adduserwatchoperation', {params: params}, options).pipe(map((response: any) => response));  
    }

    public deleteUserWatchOperation(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/deleteuserwatchoperation', {params: params}, options).pipe(map((response: any) => response));
    }

    addNewClientEnterprise(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/addnewcliententerprise', {params: params}, options).pipe(map((response: any) => response));        
    }

    editClientEnterprise(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/editcliententerprise', {params: params}, options).pipe(map((response: any) => response));        
    }

    getClientEnterpriseDetails(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/getcliententerprise', {params: params}, options).pipe(map((response: any) => response));
    }

    getClientEnterpriseByRole(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/users/getcliententerprisebyrole', {params: params}, options).pipe(map((response: any) => response));        
    }

    saveOperationEditAccess(params) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        let options = { headers: headers };

        return this.http.post(this.api_url+'/operations/saveeditaccess', {params: params}, options).pipe(map((response: any) => response));        
    }
}