<div class="container-fluid">
  <div class="d-flex justify-content-between">
    <div>
      <h2>{{ 'operation-details.tasks' | translate }}</h2>
      <p *ngIf="tasks" appTableCount="task"></p>
    </div>
    <app-search (onSearch)="filter('search', $event)"></app-search>
  </div>
  <div class="spinner-block">
    <ngx-ui-loader [fgsColor]="'#543bff'"
                   [overlayColor]="'#EFEDFD'"
                   fgsSize="75"
                   fgsType="ball-spin-clockwise-fade-rotating"
                   loaderId="table_task"></ngx-ui-loader>
    <table appTable>
      <thead appThead>
      <tr appTr>
        <th appTh appCheckboxCell><app-table-checkbox table_id="task" [check_all]="true"></app-table-checkbox></th>
        <th appTh appOrdering (onOrdering)="order('name')">{{ 'task.table.name' | translate }}</th>
        <th appTh appOrdering (onOrdering)="order('operation_name')">{{ 'task.table.operation' | translate }}</th>
        <th appTh appOrdering (onOrdering)="order('fullname')">{{ 'task.table.assigned_to' | translate }}</th>
        <th appTh appOrdering (click)="order('priority')">
          {{ 'task.table.priority' | translate }}
          <app-table-filter *ngIf="priority_filters" (onFilterChange)="filter('priority', $event)" [filters]="priority_filters"></app-table-filter>
        </th>
        <th appTh appOrdering (onOrdering)="order('status')">
          {{ 'task.table.status' | translate }}
          <app-table-filter *ngIf="status_filters" (onFilterChange)="filter('status', $event)" [filters]="status_filters"></app-table-filter>
        </th>
        <th appTh></th>
      </tr>
      </thead>
      <tbody appTbody *ngIf="tasks">
      <tr appTr *ngFor="let task of tasks" (click)="editTask(task)">
        <td appTd appCheckboxCell><app-table-checkbox table_id="task" [row_id]="task.id"></app-table-checkbox></td>
        <td appTd>{{ task.name }}</td>
        <td appTd class="operation-name">{{ task.operation_name }}</td>
        <td appTd>{{ task.fullname }}</td>
        <td appTd>{{ 'task.priority.'+task.priority | translate }}</td>
        <td appTd><span class="badge" [style]="'background-color: '+task.status_color">{{ task.status }}</span></td>
        <td appTd class="text-center align-middle">
          <span appActionEdit (onEdit)="editTask(task)"></span>
          <span appActionDelete (onDelete)="deleteTask(task)"></span>
        </td>
      </tr>
      </tbody>
    </table>
    <app-pagination table_id="task"></app-pagination>
  </div>
</div>
