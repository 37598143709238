import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app.routes';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DataService} from './services/data.service';
import {UtilService} from './services/util.service';
import {LoginComponent} from './login/login.component';
// import {Ng2GoogleChartsModule} from 'ng2-google-charts';
import {MatPaginatorIntlYukan} from './MatPaginatorIntlYukan';
import {DatePipe} from '@angular/common';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserModule} from '@angular/platform-browser';

import {OperationModule} from './operation/operation.module';
import {CoreModule} from './core/core.module';
import {SpinnerComponent} from './spinner/spinner.component';
import {ComonModule} from './common/common.module';
import {MainComponent} from './main/main.component';
import {Interceptor} from './interseptor/interseptor';
import {RegistrationComponent} from './registration/registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import {NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderHttpModule} from 'ngx-ui-loader';
import {UpdateOperationsModule} from './update-operations/update-operations.module';
import {ValidationModule} from './validation/validation.module';
import {TaskModule} from './task/task.module';
import { GlobalComponentsModule } from './global-components/global-components.module';

const loaderConfig: NgxUiLoaderConfig = {
  'bgsColor': '#5a47de',
  'bgsOpacity': 0.7,
  'bgsPosition': 'bottom-right',
  'bgsSize': 80,
  'bgsType': 'ball-scale-multiple',
  'blur': 7,
  'delay': 0,
  'fastFadeOut': true,
  'fgsColor': '#5a47de',
  'fgsPosition': 'center-center',
  'fgsSize': 70,
  'fgsType': 'ball-spin-clockwise-fade-rotating',
  'masterLoaderId': 'master',
  'overlayBorderRadius': '0',
  'overlayColor': 'rgba(0,0,0,0.35)',
  'pbColor': '#c1bce6',
  'pbDirection': 'ltr',
  'pbThickness': 2,
  'hasProgressBar': true
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    RegistrationComponent,
    SpinnerComponent
  ],
  imports: [
    // SEIGOS Modules
    OperationModule,
    UpdateOperationsModule,
    ValidationModule,
    TaskModule,
    GlobalComponentsModule,
    // ANGULAR Modules
    ComonModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    MatSelectModule,
    MatInputModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatTableModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressBarModule,
    // Ng2GoogleChartsModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatTabsModule,
    MatRadioModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    FormlyBootstrapModule,
    NgxUiLoaderModule.forRoot(loaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: false }),
  ],
  providers: [DatePipe, DataService, UtilService, {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true},
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlYukan}, MatNativeDateModule, {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR'
    }, {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]}, {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
