import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {HttpClientService} from "../../shared/services/http-client.service";

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  constructor(
    private httpClient: HttpClientService
  ) { }

  public saveValidationStep(operationId, validation)
  {
    const params = {
      id: operationId,
      validation: {
        id: validation.id ?? 0,
        id_user: validation.id_user,
        name: validation.name
      }
    };

    return this.httpClient.post('/operations/saveValidationStep', params)
  }

  public deleteValidationStep(validationId)
  {
    const params = {
      validationId
    };

    return this.httpClient.post('/operations/deleteValidationStep', params)
  }
}
