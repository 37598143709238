export enum ActivityType {
  'Connexion' = 'Connexion',
  'Tentative de connexion' = 'login_attempt',
  'Message de débogage' = 'debug_messages',
  'Opération éditée' = 'Opération éditée',
  'Déconnexion' = 'Déconnexion',
  'Opération ajoutée' = 'Opération ajoutée',
  'Opération supprimée' = 'Opération supprimée',
  'Log' = 'Log',
}

export namespace ActivityType {
  export function values() {
    return Object.keys(ActivityType).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue'
    );
  }

  export function getValue(key) {
    return Object.keys(ActivityType).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue' && ActivityType[type] == key);
  }
}


