<app-generic-modal
  (close)="cancel()"
  (done)="addFunding()"
  [headerText]="'funding.edit' | translate"
  [iconName]="'add-funding.svg'"
  [isCalled]="isCalled"
>
<div class="flex padding">
  <div>
    <h5>{{'funding.funder' | translate}}</h5>
    <input placeholder="{{'funding.funder-helper' | translate}}" type="text" [(ngModel)]="funding.financial_organisation">
  </div>
</div>
<div class="flex flex-2 padding">
  <div>
    <h5>{{'funding.type' | translate}}</h5>
    <input placeholder="{{'funding.type-helper' | translate}}" type="text" [(ngModel)]="funding.type">
  </div>
  <div>
    <h5>{{'funding.amount' | translate}}</h5>
    <input placeholder="{{'funding.amount-helper' | translate}}" type="number" min="0" (ngModelChange)="amountChangeValidate($event, inp)" #inp [(ngModel)]="funding.amount">
  </div>
  <div>
    <h5>{{'funding.filling-date' | translate}}</h5>
    <input matInput [matDatepicker]="submission_date"  placeholder="{{'funding.date-helper' | translate}}"
           [(ngModel)]="funding.submission_date" (click)="submission_date.open()">
    <mat-datepicker #submission_date></mat-datepicker>
  </div>
  <div>
    <h5>{{'funding.grant-date' | translate}}</h5>
    <input matInput [matDatepicker]="awarding_date"  placeholder="{{'funding.date-helper' | translate}}"
           [(ngModel)]="funding.awarding_date" (click)="awarding_date.open()">
    <mat-datepicker #awarding_date></mat-datepicker>
  </div>
</div>
</app-generic-modal>
