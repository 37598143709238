import {IConfig, NON_WHITESPACE_REGEX} from "./IConfig";
import {UntypedFormGroup} from "@angular/forms";
import {AllotmentObjectModel} from "../models/allotment-object-model";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {HttpClientService} from "../../shared/services/http-client.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

export class AllotmentConfig implements IConfig {
  columns = ['id', 'housing'];
  form = new UntypedFormGroup({});
  model = new AllotmentObjectModel();
  name = 'housing';
  defaultHttpParams = {orderby: 'name', order: 'desc'};
  fields: FormlyFieldConfig[];

  constructor(private http: HttpClientService, private translate: TranslateService) {
    this.translate.get(['settings.housing', 'settings.housing-help']).subscribe(value => {
      this.fields = [
        {
          key: 'name',
          type: 'input',
          name: 'housing',
          templateOptions: {
            label: value['settings.housing'],
            placeholder: value['settings.housing-help'],
            required: true,
            pattern: NON_WHITESPACE_REGEX
          }
        }
      ];
    });
  }

  getData(params = null): Observable<any> {
    if (!params) {
      params = this.defaultHttpParams;
    }
    return this.http.post('/housing/index', params).pipe(map(value => value.data));
  }

  addItem(): Observable<boolean> {
    return this.http.post('/housing/addhousing', this.model).pipe(map(value => value.success));
  }

  editItem(): Observable<boolean> {
    return this.http.post('/housing/edithousing', this.model).pipe(map(value => value.success));
  }

  getItemData(id): Observable<any> {
    return this.http.post('/housing/gethousing', {id: id}).pipe(map(value => value.data));
  }

  deleteItem(id): Observable<boolean> {
    return this.http.post('/housing/delete', {id: id}).pipe(map(value => value.success));
  }

  setOrder(field) {
    // check if the field is ID
    if (field === 'id') {
      this.setOrderParameter(field);
    } else {
      // otherwise get name from the form fields
      const name = this.fields.find(value => value.name === field)?.key.toString();
      if (name) {
        this.setOrderParameter(name);
      } else {
        this.setOrderParameter(field);
      }
    }
  }

  private setOrderParameter(newParameter) {
    if (this.defaultHttpParams.orderby === newParameter) {
      this.setOrderType();
    } else {
      this.defaultHttpParams.orderby = newParameter;
    }
  }

  private setOrderType() {
    if (this.defaultHttpParams.order === 'desc') {
      this.defaultHttpParams.order = 'asc';
    } else {
      this.defaultHttpParams.order = 'desc';
    }
  }
}
