import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../services/auth.service";
import {AppStateService} from "../services/app-state.service";

@Injectable({
  providedIn: 'root'
})
export class SeigosGuard  {
  constructor(private auth: AuthService, private state: AppStateService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.state.isSpotProject;
  }

}
