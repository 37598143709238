<app-generic-modal
  (close)="cancel()"
  (done)="addVigiliance()"
  [headerText]="'vigilance.title' | translate"
  [iconName]="'add-vigilance.svg'"
  [isCalled]="isCalled"
>


  <div class="flex flex-2">
    <div>
      <h5>{{ 'vigilance.field' | translate }}</h5>
      <mat-select [(ngModel)]="vigiliance.domaine" class="select-form" name="code_ape"
                  placeholder="{{ 'vigilance.field' | translate }}">
        <mat-option *ngFor="let item of vigilianceService.fields" [value]="item.domaine">
          {{ item.value}}
        </mat-option>
      </mat-select>
    </div>
    <div>
      <h5>{{ 'vigilance.actors' | translate }}</h5>
      <input [(ngModel)]="vigiliance.actors" placeholder="{{ 'vigilance.actors-helper' | translate }}" type="text">
    </div>
  </div>

  <div class="flex flex-1">
    <h5>{{ 'vigilance.description' | translate }}</h5>
    <textarea [(ngModel)]="vigiliance.description" placeholder="{{ 'vigilance.description-helper' | translate }}"
              type="text">
    </textarea>
  </div>
  <div class="radio-buttons-block-flex">
    <h5>{{ 'vigilance.resolved' | translate }}</h5>
    <div class="buttons-radio">
      <mat-radio-group
        [(ngModel)]="vigiliance.resolved"
        color="primary">
        <mat-radio-button [value]="'1'">Oui</mat-radio-button>
        <mat-radio-button [value]="'0'">Non</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</app-generic-modal>
