import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { OperationService } from "app/operation/service/operation.service";
import { take } from "rxjs/operators";

@Component({
    selector: "operation-import",
    templateUrl: "./operation-import.component.html",
    styleUrls: ["./operation-import.component.scss"]
})

export class OperationImportComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<OperationImportComponent>,
        private operationService: OperationService
    ) {

    }

    public ngOnInit(): void {

    }

    public cancel() {
        this.dialogRef.close();
    }

    public onUploadFiles($event) {
        let files = $event.target.files;

        if (files.length > 0) {
            for (let file of files) {
                this.uploadFile(file);
            }
        }
    }

    public onDragOver(event: any) {
        event.preventDefault();
        if (event.type == "dragover") {
            event.stopPropagation();
        } else {
            event.stopPropagation();
        }
    }

    public onDrop(event: any) {
        this.onDragOver(event);

        let files = event.target.files || event.dataTransfer.files;
        for (let file of files) {
            this.uploadFile(file);
        }
    }

    private uploadFile(file) {
        this.operationService.importOperation(file).pipe(take(1)).subscribe(response => {
            if (response?.result === "success") {
                this.dialogRef.close(true);
            }
        });
    }
}