import {UntypedFormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {Observable} from "rxjs";

export interface IConfig {
  form: UntypedFormGroup;
  name: string;
  model: any;
  fields: FormlyFieldConfig[];
  columns: string[];
  defaultHttpParams: any;

  getData(params): Observable<any>;

  getItemData(params): Observable<any>;

  addItem(): Observable<any>;

  editItem(): Observable<any>;

  deleteItem(params): Observable<any>;

  setOrder(value);
}

export const NON_WHITESPACE_REGEX = new RegExp('\\S');
