import {AfterViewChecked, ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {AdvancedActivityService} from "../../services/advanced-activity.service";
import {ActivityService} from "../../services/activity.service";
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from "rxjs/operators";
import {ActivityTableModel} from "../../models/activity-table-model";
import {AppStateService} from "../../../shared/services/app-state.service";
import {ActivitySearchComponent} from "../activity-search/activity-search.component";
import {HeaderTitles} from "../../../shared/enums/header-titles";
import {ListComponentModel} from "../../../shared/models/list-component-model";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Component({
  selector: 'app-activity-monitoring-list',
  templateUrl: './activity-monitoring-list.component.html',
  styleUrls: ['./activity-monitoring-list.component.scss']
})
export class ActivityMonitoringListComponent extends ListComponentModel implements OnInit, OnDestroy, AfterViewChecked {
  public notificationTable: ActivityTableModel;


  constructor(public advancedActivity: AdvancedActivityService,
              private activityService: ActivityService,
              private appStateService: AppStateService,
              private changeDetector: ChangeDetectorRef,
              private ngZone: NgZone,
              private ngxLoader: NgxUiLoaderService) {
    super();
  }

  ngOnInit(): void {
    this.initFilters();
    this.searchData();
    this.applyFilter();
    this.getNotificationsList();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewChecked() {
    this.calculateTableHeigth();
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    setTimeout(() => {
      this.calculateTableHeigth();
    }, 300);
  }

  public initFilters() {
    this.appStateService.setHeaderText(HeaderTitles.Activity);
    this.appStateService.activeSearch = ActivitySearchComponent;
    this.advancedActivity.setup();
    this.appStateService.applyFilters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.appStateService.activeSearch === ActivitySearchComponent) {
          this.getNotificationsList();
          this.ngZone.runGuarded(this.changeDetector.markForCheck.bind(this.changeDetector));
        }
      });
  }

  public searchData() {
    this.search.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((res) => this.searchAction(res))
      ).subscribe((resp) => {
      this.isLoaded = true;
      this.notificationTable = resp;
      this.advancedActivity.searchModel.page = resp.pageId;
    });
  }

  private searchAction(res): Observable<any> {
    this.isLoaded = false;
    this.advancedActivity.searchModel.search = res;
    this.advancedActivity.searchModel.page = 1;
    this.advancedActivity.searchModel.show = 50;
    return this.activityService.getNotificationsList();
  }

  private applyFilter(): void {
    this.advancedActivity.applyFilter$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getNotificationsList();
      });
  }

  private getNotificationsList() {
    this.isLoaded = false;
    this.ngxLoader.startLoader('activity_list');
    if (!this.isRequestSend) {
      this.isRequestSend = true;
      this.activityService.getNotificationsList()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp) => {
          this.isLoaded = true;
          this.isRequestSend = false;
          this.notificationTable = resp;
          this.advancedActivity.searchModel.page = resp.pageId;
          this.calculateTableHeigth();
          this.ngZone.runGuarded(this.changeDetector.markForCheck.bind(this.changeDetector));
          this.ngxLoader.stopLoader('activity_list');
        });
    }
  }

  public showSearch(): void {
    this.isSearchVisible = true;
  }

  public closeSearch(): void {
    this.isSearchVisible = false;
  }


  private calculateTableHeigth() {
    let top = document.getElementById("body").getBoundingClientRect().top;
    let bottom = document.getElementById("pagination").getBoundingClientRect().height;
    document.getElementById("body").style.maxHeight = `calc(100vh - ${top + bottom + 2 + 'px'})`;
    document.getElementById("body").style.height = `calc(100vh - ${top + bottom + 2 + 'px'})`;
  }

  public sortColumn(column: string, elem?: HTMLElement): void {
    if (column !== this.advancedActivity.searchModel.orderby) {
      this.advancedActivity.searchModel.orderby = column;
      this.advancedActivity.searchModel.order = 'asc';
      this.setActiveColumn(elem, 'asc');
    } else {
      if (this.advancedActivity.searchModel.order === 'asc') {
        this.advancedActivity.searchModel.order = 'desc';
        this.setActiveColumn(elem, 'desc');
      } else {
        this.advancedActivity.searchModel.order = 'asc';
        this.setActiveColumn(elem, 'asc');
      }
    }
    this.getNotificationsList();
  }

  public setActiveColumn(activeColumn: HTMLElement, order: string) {

    if (this.previousActiveColumn) {
      this.previousActiveColumn.classList.remove('active');
      this.previousActiveColumn.getElementsByClassName('asc')[0].classList.remove('active');
      this.previousActiveColumn.getElementsByClassName('desc')[0].classList.remove('active');
    }

    activeColumn.classList.add('active');
    activeColumn.getElementsByClassName(order)[0].classList.add('active');
    this.previousActiveColumn = activeColumn;
  }

  public selectPage(pagesCount: number): void {
    this.advancedActivity.searchModel.show = pagesCount;
    this.getNotificationsList();
  }

  public nextPage() {
    if (this.notificationTable.pages === this.advancedActivity.searchModel.page) {
      return;
    }
    this.advancedActivity.searchModel.page = this.advancedActivity.searchModel.page + 1;
    this.getNotificationsList();
  }

  public previousPage() {
    if (this.advancedActivity.searchModel.page === 1) {
      return;
    }
    this.advancedActivity.searchModel.page = this.advancedActivity.searchModel.page - 1;
    this.getNotificationsList();
  }

  public setTableItemsEndCount(): number {
    let endCount = this.advancedActivity.searchModel.show * this.advancedActivity.searchModel.page;
    if (endCount > Number(this.notificationTable?.totalRecord)) {
      endCount = Number(this.notificationTable?.totalRecord);
    }
    return endCount;
  }
}
