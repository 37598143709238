export enum Subsector {
  Eau = 'Eau',
  Route = 'Route',
  Amenagement = 'Amenagement',
  'Gros oeuvre' = 'Gros oeuvre',
  'Second oeuvre' = 'Second oeuvre',
  Autres = 'Autres'
}

export namespace Subsector {
  export function values() {
    return Object.keys(Subsector).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'mapToDropdown'
    );
  }

  export function mapToDropdown() {
    return Object.keys(Subsector).filter(value => value !== 'values' && value !== 'mapToDropdown').map(value => {
      return {label: value, value: Subsector[value]};
    });
  }
}
