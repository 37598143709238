import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from './table/table.module';
import { FormModule } from './form/form.module';


@NgModule({
  declarations: [],
  exports: [
    TableModule,
    FormModule
  ],
  imports: [
    CommonModule,
  ]
})
export class GlobalComponentsModule { }
