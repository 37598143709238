<app-generic-modal
  (close)="cancel()"
  (done)="addFunding()"
  [headerText]="'funding.add' | translate"
  [iconName]="'add-funding.svg'"
  [isCalled]="isCalled"
>
  <div class="flex padding">
    <div>
      <h5>{{'funding.funder' | translate}}</h5>
      <input [(ngModel)]="funding.financial_organisation" placeholder="{{'funding.funder-helper' | translate}}"
             type="text">
    </div>
  </div>
  <div class="flex flex-2 padding">
    <div>
      <h5>{{'funding.type' | translate}}</h5>
      <input [(ngModel)]="funding.type" placeholder="{{'funding.type-helper' | translate}}" type="text">
    </div>
    <div>
      <h5>{{'funding.amount' | translate}}</h5>
      <input #inp (ngModelChange)="amountChangeValidate($event, inp)" [(ngModel)]="funding.amount"
             min="0" placeholder="{{'funding.amount-helper' | translate}}" type="number">
    </div>
    <div>
      <h5>{{'funding.filling-date' | translate}}</h5>
      <input (click)="submission_date.open()" [(ngModel)]="funding.submission_date" [matDatepicker]="submission_date"
             matInput placeholder="{{'funding.date-helper' | translate}}">
      <mat-datepicker #submission_date></mat-datepicker>
    </div>
    <div>
      <h5>{{'funding.grant-date' | translate}}</h5>
      <input (click)="awarding_date.open()" [(ngModel)]="funding.awarding_date" [matDatepicker]="awarding_date"
             matInput placeholder="{{'funding.date-helper' | translate}}">
      <mat-datepicker #awarding_date></mat-datepicker>
    </div>
  </div>
</app-generic-modal>
