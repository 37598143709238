export enum WorkType {
  Neuf = 'neuf',
  Entretien = 'entretien'
}

export namespace WorkType {
  export function values() {
    return Object.keys(WorkType).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
