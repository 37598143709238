import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  public search: string;

  public isSearchVisible = false;

  constructor() { }

  public onSearchChange()
  {
    this.onSearch.emit(this.search);
  }
}
