<div class="grid-container">
  <div class=" ">
    <h5>{{ 'housing.lot' | translate }}</h5>
    <input placeholder="{{ 'housing.lot-helper' | translate }}" type="text" [(ngModel)]="house.name">
  </div>
  <div class=" ">
    <h5>{{ 'housing.apeCode' | translate }}</h5>
    <mat-select class="select-form" name="code_ape" [(ngModel)]="house.fk_codeAPE"
                placeholder="{{ 'housing.apeCode-helper' | translate }}">
      <mat-option *ngFor="let item of codes" [value]="item.id">
        {{ item.code }} - {{item.description}}
      </mat-option>
    </mat-select>
  </div>
  <div class=" ">
    <h5>{{ 'housing.company' | translate }}</h5>
    <input placeholder="{{ 'housing.company-helper' | translate }}" type="text" [(ngModel)]="house.company_name">
  </div>
  <div class=" ">
    <h5>{{ 'housing.amount' | translate }}</h5>
    <input placeholder="0.00 €" type="number" [(ngModel)]="house.price">
  </div>
  <div class=" ">
    <h5>{{ 'housing.overspend' | translate }}</h5>
    <input placeholder="0.00 €" type="number" [(ngModel)]="house.overspend">
  </div>
  <div class="buttons">
    <button class="cancel" (click)="cancel()">
      <i class="bi bi-dash-circle"></i>
    </button>
    <button class="add" [disabled]="isCalled" (click)="addHouse()">
      <i class="bi bi-plus-circle-fill"></i>
    </button>
  </div>

</div>
