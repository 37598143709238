import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {HousingService} from '../../../housing/services/housing-service';
import {CityService} from '../../../shared/services/city.service';
import {UserService} from '../../../shared/services/user.service';
import {OperationService} from '../../service/operation.service';
import {IS_SPOT_PROJECT} from '../../../app.constant';
import {Vigilance} from '../../../shared/enums/vigilance';
import { MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {FundingService} from '../../../funding/service/funding-service';
import {FileService} from '../../../file-upload/service/file-service';
import {Router} from '@angular/router';
import {UntypedFormBuilder} from "@angular/forms";
import {AuthService} from "../../../shared/services/auth.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {BaseOperationManagement} from "../../abstraction/base-operation-management";
import {AppStateService} from "../../../shared/services/app-state.service";
import {SettingsService} from "../../../settings/services/settings.service";


@Component({
  selector: 'app-operation-add',
  templateUrl: './operation-add.component.html',
  styleUrls: ['./operation-add.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('200ms ease-out',
              style({height: 100, opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({height: 100, opacity: 1}),
            animate('200ms ease-in',
              style({height: 100, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class OperationAddComponent extends BaseOperationManagement implements OnInit, OnDestroy {
  public vigilance = Vigilance;
  isHousingDisable = !!this?.operationDetails?.gen_LLTS
    || !!this?.operationDetails?.gen_PTZ
    || !!this?.operationDetails?.gen_LLS
    || !!this?.operationDetails?.gen_PLS
    || !!this?.operationDetails?.gen_other;

  constructor(
    protected translate: TranslateService,
    protected userService: UserService,
    public operationService: OperationService,
    protected cityService: CityService,
    protected housingService: HousingService,
    protected fundingService: FundingService,
    protected fileService: FileService,
    protected dialog: MatDialog,
    public authService: AuthService,
    protected router: Router,
    public appState: AppStateService,
    protected settingsService: SettingsService,
    protected fb: UntypedFormBuilder
  ) {
    super(translate, userService, operationService,
      cityService, housingService, fundingService,
      fileService, dialog, authService, router, fb, appState, settingsService, 4);
  }

  ngOnInit(): void {
    this.currentTab = 1;
    this.loadDataLists();

    // Init Tabs, Form, DropDowns
    this.initPageComponents();

    this.initModalWindowsSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initModalWindowsSubscriptions() {
    // housing add/edit
    this.passHousing$().subscribe(resp => {
      this.operationService.handleAddOperationHousing(resp, this.operationDetails, this.housing);
    });
    // housing close
    this.closeHousingModal();

    // funding add
    this.addFundingToList$().subscribe(resp => {
      this.funding.push(resp);
    });

    // funding edit
    this.editFundingInList$().subscribe(resp => {
      this.funding[resp.index] = resp.funding;
    });

    // file add
    this.addFileToList$().subscribe((resp => {
      this.files.push(resp);
    }));

    this.initToCloseSubscriptions();

  }

  save(): void {
    const operation: any = {};

    // 1 tab. general
    operation.name = this.operationDetails.name;
    operation.client = this.operationDetails.client;

    operation.visibility = this.operationDetails.visibility;
    if (IS_SPOT_PROJECT) {
      operation.location = this.operationDetails.location;
    } else {
      operation.location = this.cities.find(value => value.name == this.operationDetails.location)?.id;
    }
    operation.gen_address = this.operationDetails.address;
    operation.gen_number_houses = this.operationDetails.gen_number_houses;
    operation.gen_LLTS = this.operationDetails.gen_LLTS;
    operation.gen_LLS = this.operationDetails.gen_LLS;
    operation.gen_PLS = this.operationDetails.gen_PLS;
    operation.gen_PTZ = this.operationDetails.gen_PTZ;
    operation.gen_other = this.operationDetails.gen_other;
    operation.description = this.operationDetails.description;
    operation.gen_type_work = this.operationDetails.gen_type_work;
    operation.sector = this.operationDetails.sector;
    // operation.status = null;
    // operation.status = this.operationDetails.status;
    // operation.manual_status_selection = 1;
    operation.linked_operations = this.operationDetails.linked_operations;
    operation.type_marche = this.operationDetails.type_marche;

    // 2 tab. pi-ca
    operation.pi_ca_ao_date = this.operationService.getStringDate(this.operationDetails.pi_ca_ao_date);
    operation.pi_ca_ao_date_reelle = this.operationDetails.pi_ca_ao_date_reelle;
    operation.pi_ca_source = this.operationDetails.pi_ca_source;
    operation.pi_ca_selection_date = this.operationService.getStringDate(this.operationDetails.pi_ca_selection_date);
    operation.pi_ca_selection_date_reelle = this.operationDetails.pi_ca_selection_date_reelle;
    operation.pi_ca_prestataire = this.operationDetails.pi_ca_prestataire;
    // pi-mo
    operation.pi_mo_ao_date = this.operationService.getStringDate(this.operationDetails.pi_mo_ao_date);
    operation.pi_mo_ao_date_reelle = this.operationDetails.pi_mo_ao_date_reelle;
    operation.pi_mo_source = this.operationDetails.pi_mo_source;
    operation.pi_mo_selection_date = this.operationService.getStringDate(this.operationDetails.pi_mo_selection_date);
    operation.pi_mo_selection_date_reelle = this.operationDetails.pi_mo_selection_date_reelle;
    operation.pi_mo_prestataire = this.operationDetails.pi_mo_prestataire;
    // 3 tab. mi-ao
    operation.mi_ao_travaux_date = this.operationService.getStringDate(this.operationDetails.mi_ao_travaux_date);
    operation.mi_ao_travaux_date_reelle = this.operationDetails.mi_ao_travaux_date_reelle;
    operation.procedure = this.operationDetails.procedure;
    operation.source_operational_execution = this.operationDetails.source_operational_execution;
    operation.mi_ao_deadline_date = this.operationService.getStringDate(this.operationDetails.mi_ao_deadline_date);
    // mi-resultat
    operation.mi_resultat_marche_date = this.operationService.getStringDate(this.operationDetails.mi_resultat_marche_date);
    operation.mi_resultat_marche_date_reelle = this.operationDetails.mi_resultat_marche_date_reelle;
    operation.mi_resultat_marche_montant = this.operationDetails.mi_resultat_marche_montant;
    operation.source_mi_resultat = this.operationDetails.source_mi_resultat;
    // housing
    operation.housing = this.housing;
    // order-service
    operation.mi_ordre_service_date = this.operationService.getStringDate(this.operationDetails.mi_ordre_service_date);
    operation.mi_ordre_service_date_reelle = this.operationDetails.mi_ordre_service_date_reelle;
    // reception
    operation.mi_livraison_date = this.operationService.getStringDate(this.operationDetails.mi_livraison_date);
    operation.mi_livraison_date_reelle = this.operationDetails.mi_livraison_date_reelle;

    // 4 tab.
    operation.instr_pc_date_depot = this.operationService.getStringDate(this.operationDetails.instr_pc_date_depot);
    operation.instr_pc_date_depot_reelle = this.operationDetails.instr_pc_date_depot_reelle;
    operation.instr_pc_date_attribution = this.operationService.getStringDate(this.operationDetails.instr_pc_date_attribution);
    operation.instr_pc_numero = this.operationDetails.instr_pc_numero;

    operation.funding = this.funding;
    operation.files = this.files;

    operation.gen_geolocation = this.operationDetails.gen_geolocation;
    operation.source_sig = this.operationDetails.source_sig;
    operation.permision_client = this.operationDetails.permision_client;
    operation.operation_token = this.operationToken;

    operation.housing?.forEach(el => el.code_ape = el.fk_codeAPE);

    if (this.clientUsers.length == 1) {
      operation.client = this.clientUsers[0].id;
    }

    this.operationService.parseFundingDate(operation.funding);
    this.operationService.addOperation(operation).pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
      this.router.navigateByUrl('/operations?type=all');
    });
  }

  private loadDataLists(): void {

    this.operationToken = this.operationService.generateOperationToken(50);

    // load static data for page elements
    this.loadStaticData();
  }

}
