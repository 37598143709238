export enum MarketType {
  Autre = 'autre',
  Service = 'service',
  Fournitures = 'fournitures',
  Travaux = 'travaux'
}

export namespace MarketType {
  export function values() {
    return Object.keys(MarketType).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}

export enum EditMarketType {
  Autre = 'Autre',
  Service = 'Service',
  Fournitures = 'Fournitures',
  Travaux = 'Travaux'
}

export namespace EditMarketType {
  export function values() {
    return Object.keys(EditMarketType).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
