import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[droppable]'
})
export class DragAndDropDirective {
  @HostBinding('class.file-over') fileOver: boolean;
  @Output() onFileDropped = new EventEmitter<any>();
  @Output() onMultipleFilesDropped = new EventEmitter<any>();
  @Output() onError = new EventEmitter<boolean>();

  constructor() {
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 1) {
      // this.fileError = true;
      this.onError.emit(true);
    }
    if (files.length == 1) {
      // this.fileError = false;
      this.onFileDropped.emit(files);
    }
  }
}
