import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {VigilanceModel} from '../models/vigilance-model';
import {HttpClientService} from '../../shared/services/http-client.service';
import {VigilanceSearchService} from "./vigilance-search.service";

@Injectable({
  providedIn: 'root'
})
export class VigilanceService {
  public closeVigilance$ = new Subject<void>();
  public addVigilanceList$ = new Subject<VigilanceModel>();
  public editVigilanceList$ = new Subject<any>();
  public fields = [
    {domaine: 'financier', value: 'Financier'},
    {domaine: 'foncier', value: 'Foncier'},
    {domaine: 'juridique', value: 'Juridique'},
    {domaine: 'administratif', value: 'Administratif'},
    {domaine: 'autres', value: 'Autres'},
    {domaine: 'technique', value: 'Technique'},
  ];

  constructor(private httpClient: HttpClientService, private vigilanceSearch: VigilanceSearchService) {
  }

  public getAllVigilantes() {
    return this.httpClient.post('/vigilance/lists', this.vigilanceSearch.searchModel);
  }

  public getVigilanceList(id): Observable<any> {
    const params = {
      id: id,
      orderby: 'spot_point_of_vigilance.id',
      order: 'id'
    };
    return this.httpClient.post('/vigilance/getvigiliance', params);
  }

  public addVigilance(param): Observable<any> {
    const params = {
      operation_id: param.operation_id,
      domaine: param.domaine,
      actors: param.actors,
      description: param.description,
      resolved: param.resolved
    };
    return this.httpClient.post('/vigilance/add', params);
  }

  public editVigilance(param): Observable<any> {
    const params = {
      id: param.id,
      operation_id: param.operation_id,
      domaine: param.domaine,
      actors: param.actors,
      description: param.description,
      resolved: param.resolved
    };
    return this.httpClient.post('/vigilance/edit', params);
  }

  public deleteVigilance(id): Observable<any> {
    const params = {
      id: id
    };
    return this.httpClient.post('/vigilance/deletevigilance', params);
  }
}
