import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdvancedSearchService} from '../operation/service/advanced-search-service';
import {AppStateService} from "../shared/services/app-state.service";
import {AdvancedSearchComponent} from "../shared/components/advanced-search/advanced-search.component";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  public showSidebar = true;
  public showFilters = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private advancedSearchService: AdvancedSearchService, private appState: AppStateService) {
    this.appState.toggleMainSidebar$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        Promise.resolve().then(() => this.showSideBarEmitter(value))
      });
  }

  ngOnInit(): void {
    this.onAdvancedSearchApplied();
    this.onAdvancedSearchClose();
    this.appState.activeSearch = AdvancedSearchComponent;


    if (document.body.offsetWidth < 768) {
      this.showSidebar = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public showSideBarEmitter(showSidebar: boolean = null): void {
    if (showSidebar === null) {
      this.showSidebar = !this.showSidebar;
    } else  {
      this.showSidebar = showSidebar;
    }
    this.showFilters = false;
  }

  private onAdvancedSearchApplied(): void {
    this.appState.applySearch$.subscribe(() => {
      this.showFilters = true;
      this.showSidebar = false;
    });

  }

  private onAdvancedSearchClose(): void {
    this.appState.closeSearch$.subscribe(() => {
      this.showFilters = false;
    });
  }
}
