import {Injectable} from '@angular/core';
import {FundingConfig} from '../configs/funding-config';
import {HttpClientService} from '../../shared/services/http-client.service';
import {SubSectorConfig} from "../configs/sub-sector-config";
import {IConfig} from "../configs/IConfig";
import {SettingsTables} from "../models/settings-tables";
import {Observable, Subject} from "rxjs";
import {AllotmentConfig} from "../configs/allotment-config";
import {ClientConfig} from "../configs/client-config";
import {EnterpriseConfig} from "../configs/enterprise-config";
import {LocationConfig} from "../configs/location-config";
import {RecipientsConfig} from "../configs/recipients-config";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {tap} from "rxjs/operators";
import {PhaseConfig} from "../configs/phase-config";
import {TaskStatusConfig} from "../configs/task-status-config";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  tables = SettingsTables;
  configs: IConfig[] = [
    new FundingConfig(this.http, this.translate),
    new SubSectorConfig(this.http, this.translate),
    new AllotmentConfig(this.http, this.translate),
    new ClientConfig(this.http, this.translate),
    new EnterpriseConfig(this.http, this.translate),
    new LocationConfig(this.http, this.translate),
    new RecipientsConfig(this.http, this.translate),
    new PhaseConfig(this.http, this.translate),
    new TaskStatusConfig(this.http, this.translate),
  ];
  selectedConfig: IConfig;
  selectedTable: string = this.tables.getValue(this.tables.Financement);
  selectedCount: string;
  public closeDialog = new Subject<void>();
  public editDialog = new Subject<void>();

  constructor(private http: HttpClientService, private translate: TranslateService) {
    this.setConfig();
  }

  getData(params = null): Observable<any> {
    this.setConfig();
    return this.selectedConfig.getData(params).pipe(tap(x => {
      this.selectedCount = x?.length;
    }));
  }

  setConfig() {
    this.selectedConfig = this.configs.find(value => value.name == this.tables[this.selectedTable]);
  }


  deleteItem(id) {
    if (this.selectedConfig.name == 'client' || this.selectedConfig.name == 'enterprise') {
      Swal('Warning', 'Delete client/enterprise records are not maintained by our API', 'info');
      return;
    }
    this.selectedConfig.deleteItem(id).subscribe(value => {
      if (value) {
        this.editDialog.next();
        Swal('Success', 'Item deleted', 'success');
      } else {
        Swal('Error', 'Add item error', 'error');
      }
    });
  }

  sortBy(column: string) {
    this.selectedConfig.setOrder(column);
    this.editDialog.next();
  }

  updateOperation() {
    return this.http.post('/operations/checkstatusandestimatedcost')
      .pipe(
        tap(x => {
          if (x.result == 'true') {
            Swal('Mise à jour des opérations terminée.');
          } else {
            Swal('La mise à jour des opérations a échoué.');
          }
        }));
  }

  makeBackup() {
    return this.http.post('/backup_database.php')
      .pipe(
        tap(x => {
          if (x.result == 'true') {
            Swal('Sauvegarde de données terminée.');
          } else {
            Swal('La sauvegarde des données a échoué.');
          }
        }));
  }
}
