import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
import {HttpClientService} from '../../shared/services/http-client.service';
import {ObservationModel} from '../model/observation-model';
import {OperationTableModel} from '../model/operation-table-model';
import {AdvancedSearchService} from './advanced-search-service';
import {format, parse} from 'date-fns';
import {map, tap} from "rxjs/operators";
import * as constant from '../../app.constant';
import {IS_SPOT_PROJECT} from '../../app.constant';
import {OperationActions} from "../model/operation-actions.enum";
import Swal from 'sweetalert2';

@Injectable()
export class OperationService {
  operationClick$ = new Subject<string>();
  isSpotProject = constant.IS_SPOT_PROJECT;
  detailsLoaded = new Subject<any>();
  loadRelatedOperation = new Subject<any>();
  isLoadingFinished = false;

  constructor(private advancedSearchService: AdvancedSearchService, private httpClient: HttpClientService) {
  }

  public getOperationsList(): Observable<OperationTableModel> {
    this.advancedSearchService.checkFiltersReset();
    this.advancedSearchService.searchModel.advancedsearch.show = this.advancedSearchService.searchModel.show;
    let url = '/operations/index';
    let operationType = this.advancedSearchService.searchModel.operationType;
    let opList$: Observable<any>;
    if (operationType) {
      url = '/dashboard/getoperationlist/' + operationType;
      if (this.advancedSearchService.searchModel.type) {
        this.advancedSearchService.searchModel.operations = this.advancedSearchService.searchModel.type;
      }

      opList$ = this.httpClient.post(url, this.advancedSearchService.getRequestParams());
    } else {
      opList$ = this.httpClient.post('/operations/index', this.advancedSearchService.getRequestParams());
    }
    return opList$;
  }

  public getOperation(id: string): Observable<any> {
    let params = {
      op_id: id,
      apiuse: 'get'
    };
    return this.httpClient.post('/operations/getoperation', params).pipe(tap(resp => {
      this.defineLocationManagement(resp.result.operationdata);
      if (resp?.result?.getchanged) {
        this.defineLocationManagement(resp.result.getchanged);
      }
      if (!resp.result.funding) {
        resp.result.funding = [];
      }
      if (!resp.result.housing) {
        resp.result.housing = [];
      }
      if (!resp.result.vigilance) {
        resp.result.vigilance = [];
      }
      if (!resp.result.files) {
        resp.result.files = [];
      }
    }));
  }

  public getMyOperations(id: string): Observable<any> {
    let params = {
      op_id: id,
      apiuse: 'get'
    };
    return this.httpClient.post('/operations/index', params);
  }


  public addOperation(operation): Observable<any> {
    return this.httpClient.post('/operations/addoperation', operation);
  }

  public getCardsData(): Observable<any> {
    let params = {
      operations: this.advancedSearchService.searchModel.type
    };
    return this.httpClient.post('/dashboard/index', params);
  }

  public getCardsAmountData(): Observable<any> {
    let params = {};
    return this.httpClient.post('/dashboard/getCompletedOperationsAmount', params);
  }

  public actionOperation(ids: string[], action): Observable<any> {
    switch (action) {
      case OperationActions.Delete:
        return this.deleteOperation(ids);
      case OperationActions.Restore:
        return this.restoreOperation(ids);
      case OperationActions.Validate:
        return this.validateOperation(ids);
      default:
    }
  }

  public deleteOperation(ids: string[]): Observable<any> {
    let params = {
      id: ids
    };
    return this.httpClient.post('/operations/delete', params);
  }

  public editOperation(operation): Observable<any> {
    let operationData = {...operation, ...operation.operationdata};
    delete operationData.operationdata;
    delete operationData.location_name;
    return this.httpClient.post('/operations/editoperation', operationData);
  }

  public validateOperation(ids: string[]): Observable<any> {
    let params = {
      id: ids
    };
    return this.httpClient.post('/operations/bulkeditadmin', params);
  }

  public grantAccess(params): Observable<any> {
    return this.httpClient.post('/operations/saveeditaccess', params);
  }

  public restoreOperation(ids: string[]): Observable<any> {
    let params = {
      id: ids
    };
    return this.httpClient.post('/operations/restore', params);
  }

  public downloadReport(): Observable<any> {
    return this.httpClient.post('/reports/exportcsv', {...this.advancedSearchService.searchModel, ...this.advancedSearchService.searchModel?.advancedsearch});
  }

  public getObservations(id: string): Observable<ObservationModel[]> {
    let params = {
      id: id,
      page: this.advancedSearchService.searchModel.page
    };
    return this.httpClient.post('/operations/getobservations', params);
  }

  public getCodeApeList() {
    return this.httpClient.post('/operations/getcodeapelist').pipe(map(value => value.data));
  }

  public getStringDate(dateInput) {
    if (!dateInput) {
      return null;
    }
    return dateInput._d ?
      dateInput = format(new Date(dateInput._d), 'yyyy-MM-dd') :
      dateInput = format(new Date(dateInput), 'yyyy-MM-dd');
  }

  public getObjectDate(dateInput) {
    if (!dateInput) {
      return null;
    }

    return parse(dateInput, 'yyyy-MM-dd', new Date());

  }

  public getActiveUsers() {
    return this.httpClient.post('/users/getactiveuserlist')
      .pipe(map(value => value.user));
  }

  public getWatchOperationUsers(operationId) {
    let params = {
      operation_id: operationId,
    };

    return this.httpClient.post('/operations/getuserwatchoperation', params)
      .pipe(map(value => {
        return {data: value.data, count: value?.count?.total};
      }));
  }

  public addWatchOperationUser(userId, operationId) {
    let params = {
      user_id: userId,
      operation_id: operationId,
    };
    return this.httpClient.post('/operations/adduserwatchoperation', params);

  }

  public deleteWatchOperationUser(userId, operationId) {
    let params = {
      user_id: userId,
      operation_id: operationId,
    };

    return this.httpClient.post('/operations/deleteuserwatchoperation', params);
  }

  public getAllOperationsList() {
    return this.httpClient.post('/operations/getalloperations')
      .pipe(map(value => value.data));
  }

  public generateOperationToken(N: number) {
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    return Array.apply(null, Array(N)).map(function () {
      return charset.charAt(Math.floor(Math.random() * charset.length));
    }).join('');
  }

  public parseFundingDate(fundings) {
    for (const funding of fundings) {
      const sDate = parse(funding.submission_date, 'dd/MM/yyyy', new Date());
      const aDate = parse(funding.awarding_date, 'dd/MM/yyyy', new Date());
      funding.submission_date = format(sDate, 'yyyy-MM-dd');
      funding.awarding_date = format(aDate, 'yyyy-MM-dd');
      funding.price = undefined;
    }
  }

  public loadFile(name: any) {
    window.open(`https://spotapi.yukantest.com/uploads/${name}`);
  }

  public calculateHousingAmount(operation) {
    operation.operationdata.mi_resultat_marche_montant = '0';
    let amount = 0;
    operation.housing.forEach(r => {
      if (!Number.isNaN(r.price)) {
        amount += r.price;
      }
    });
    operation.operationdata.mi_resultat_marche_montant = amount.toString();
  }

  public calculateHousingAmountWithoutOperation(housings) {
    let amount = 0;
    housings.forEach(r => {
      if (!Number.isNaN(r.price)) {
        amount += Number.parseInt(r.price);
      }
    });
    return amount.toString();
  }
  public calculateHousingOverspendWithoutOperation(housings) {
    let overspend = 0;
    housings.forEach(r => {
      if (!Number.isNaN(r.overspend)) {
        overspend += Number.parseInt(r.overspend);
      }
    });
    return overspend.toString();
  }


  private defineLocationManagement(object) {
    Object.defineProperty(object, 'op_location', {
      get(): any {
        if (IS_SPOT_PROJECT) {
          return this.location;
        } else {
          return this.location_name;
        }
      },
      set(value: any) {
        this.location = value;
        if (IS_SPOT_PROJECT) {
          this.location = value;
        } else {
          this.location_name = value;
        }
      }
    });
  }

  public handleOperationHousing(response, operation) {
    if (response.isEdit) {
      operation.housing[response.index] = response.house;
    } else {
      response.house.code_ape = response.house.fk_codeAPE;
      operation.housing.push(response.house);
    }
    operation.operationdata.mi_resultat_marche_montant = this.calculateHousingAmountWithoutOperation(operation.housing);
    operation.operationdata.overspend = this.calculateHousingOverspendWithoutOperation(operation.housing);
  }

  public handleAddOperationHousing(response, operation, housings) {
    if (response.isEdit) {
      housings[response.index] = response.house;
    } else {
      response.house.code_ape = response.house.fk_codeAPE;
      housings.push(response.house);
    }
    operation.mi_resultat_marche_montant = this.calculateHousingAmountWithoutOperation(housings);
    operation.overspend = this.calculateHousingOverspendWithoutOperation(housings);
  }

  public addSimulatedValues(operations: string[])
  {
    const params = {
      operations
    }
    this.httpClient.post('/Automation/addSimulatedValuesBulk', params).subscribe((values) => {
      Swal('Succès', "Ajouté avec succès", 'success');
    })
  }

  public getValidations(operationId)
  {
    const params = {
      "id": operationId
    }
    return this.httpClient.post('/Operations/getvalidations', params)
  }

  public validateStep(operationId)
  {
    const params = {
      "id": operationId
    }
    return this.httpClient.post('/Operations/validateStep', params)
  }

  public importOperation(file) {
    let formData = new FormData();
    formData.set('file', file);

    return this.httpClient.postFormData("/Operations/importcsv", formData);
  }
}
