import {Injectable} from '@angular/core';
import {HttpClientService} from "../../shared/services/http-client.service";
import {EnterprisesSearchService} from "./enterprises-search.service";
import {IGroupItemsService} from "../../shared/abstractions/IGroupItemsService";
import {Observable} from "rxjs";


export interface Category {
  id: string;
  name: string;
}



@Injectable({
  providedIn: 'root'
})
export class EnterprisesService implements IGroupItemsService {

  constructor(private httpClient: HttpClientService, private enterpriseSearch: EnterprisesSearchService) {
  }

  getAllItems() {
    return this.httpClient.post('/enterprises/getEnterprisesPart', this.enterpriseSearch.searchModel);
  }

  getRawItems() {
    return this.httpClient.post('/enterprises', this.enterpriseSearch.searchModel);
  }

  getCategories(): Observable<Category[]> {
    return this.httpClient.post('/enterprises/getCategoryJSON');
  }

  getSubCategories(category: Category[]): Observable<Category[]> {
    let ids = category.map(c=>c.id);
    return this.httpClient.post('/enterprises/getSubCategoryJSON', {category: ids});
  }

  loadItemInnerData(name) {
    const args = {
      name
    };
    return this.httpClient.post('/enterprises/getEnterpriseData', {...args, ...this.enterpriseSearch.searchModel});
  }

}
