import { Injectable } from '@angular/core';
import {HttpClientService} from "../../shared/services/http-client.service";
import {TaskModel} from "../models/task.model";

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  public priorities = [
    'low',
    'medium',
    'high'
  ]

  constructor(
    private httpClient: HttpClientService
  ) { }

  public saveTask(operationId, task: TaskModel)
  {
    const params = {
      id: operationId,
      task: {
        id: task.id ?? 0,
        id_operation: operationId,
        id_phase: task.id_phase,
        name: task.name,
        description: task.description,
        priority: task.priority,
        id_task_status: task.id_task_status,
        assigned_to: task.assigned_to,
        deadline_at: task.deadline_at
      }
    };

    return this.httpClient.post('/task/saveTask', params)
  }

  public deleteTask(taskId)
  {
    const params = {
      taskId
    };

    return this.httpClient.post('/task/deleteTask', params)
  }

  public getStatuses()
  {
    return this.httpClient.post('/task/getStatuses')
  }

  public getTasks(operationId: number, filters)
  {
    return this.httpClient.post('/task/getTasks', {
      id: operationId,
      filters
    })
  }
}
