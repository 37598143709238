export enum TypeTender {
  'Concours d’architecte' = 'autre',
  'Maitrise d’oeuvre' = 'service',
  'MEO AO' = 'fournitures',
  'MEO Résultats de marché' = 'travaux'
}

export namespace TypeTender {
  export function values() {
    return Object.keys(TypeTender).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue'
    );
  }

  export function getValue(key) {
    return Object.keys(TypeTender).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'getValue' && TypeTender[type] == key);
  }
}
