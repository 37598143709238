import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClientService} from '../../shared/services/http-client.service';
import {ObservationModel} from '../../operation/model/observation-model';

@Injectable({
  providedIn: 'root'
})
export class ObservationService {
  public closeObservation$ = new Subject<void>();
  public addObservationList$ = new Subject<ObservationModel>();
  public editObservationList$ = new Subject<any>();

  constructor(private httpClient: HttpClientService) {
  }

  public getObservationList(id): Observable<any> {
    const params = {
      id: id,
      orderby: 'spot_observation.id',
      order: 'id',
      page: 1
    };
    return this.httpClient.post('/operations/getobservations', params);
  }

  public addObservation(param): Observable<any> {
    const params = {
      id: param.id,
      section: 1,
      description: param.description
    };
    return this.httpClient.post('/operations/saveobservation', params);
  }

  public editObservation(param): Observable<any> {
    const params = {
      id: param.id,
      id_op: param.operation_id,
      author: param.author,
      section: param.section,
      description: param.description,
    };
    return this.httpClient.post('/operations/editobservation', params);
  }

  public deleteObservation(id): Observable<any> {
    const params = {
      id: id
    };
    return this.httpClient.post('/operations/deleteobservation', params);
  }
}
