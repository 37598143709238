import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileUploadRoutingModule } from './file-upload-routing.module';
import { AddFileComponent } from './add-file/add-file.component';
import { EditFileComponent } from './edit-file/edit-file.component';
import { FileService } from './service/file-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [AddFileComponent, EditFileComponent],
  imports: [
    CommonModule,
    FileUploadRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [FileService],
  exports: [AddFileComponent, EditFileComponent]
})
export class FileUploadModule { }
