export enum OrganizationType {
  'Collectivité' = 'Collectivité',
  'Entreprise' = 'Entreprise',
  'Service de l\'état' = 'Service de l\'état',
  'Syndicat professionnel' = 'Syndicat professionnel',
  'Autres' = 'Autres',
}

export namespace OrganizationType {
  export function values() {
    return Object.keys(OrganizationType).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }


  export function mapToDropdown() {
    return Object.keys(OrganizationType).filter(type => type !== 'values' && type !== 'mapToDropdown').map(value => {
      return {label: value, value: OrganizationType[value]};
    });
  }
}
