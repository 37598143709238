import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FundingModel } from '../models/funding-model';
import { HttpClientService } from 'app/shared/services/http-client.service';

@Injectable()
export class FundingService {
    public closeFunding = new Subject<void>();
    public addFundingList = new Subject<FundingModel>();
    public editFundingList = new Subject<any>();

    constructor(private httpClient: HttpClientService) {
    }

    public getFundingList(): Observable<any> {
        return this.httpClient.post('/housing/financiallist');
    }

    public getFunding(params): Observable<any> {
        return this.httpClient.post('/financial/index', params);
    }

    public getFundingDetails(params): Observable<any> {
        return this.httpClient.post('/financial/getfinancial', params);
    }

    public addFunding(params): Observable<any> {
        return this.httpClient.post('/financial/addfinancial', params);
    }

    public editFunding(params): Observable<any> {
        return this.httpClient.post('/financial/editfinancial', params);
    }
    public deleteFunding(params): Observable<any> {
        return this.httpClient.post('/financial/delete', params);
    }
}
