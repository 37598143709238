import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[appCheckboxCell]'
})
export class CheckboxCellDirective {

  constructor(
    private _el: ElementRef, private _renderer: Renderer2
  ) {
    this._renderer.addClass(this._el.nativeElement, 'seigos-checkbox-cell');
  }

}
