import {EventEmitter, Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import * as constant from '../../app.constant';
import {first} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  public activeSearch: any;
  public activeHeader: string;
  public setHeader = new Subject<string>();
  public setSidebarSection = new Subject<string>();
  public applyFilters$ = new EventEmitter<void>();
  public applySearch$ = new EventEmitter<void>();
  public closeSearch$ = new EventEmitter<void>();
  public toggleOperationsAddEditSidebar$ = new EventEmitter<boolean>();
  public toggleMainSidebar$ = new Subject<boolean>();
  public isSpotProject = constant.IS_SPOT_PROJECT;

  public selectedFont = 'Open Sans, sans-serif';
  public selectedWeight = '500';
  public selectedLanguage;
  public appLanguages = constant.langs;
  public appFonts = [
    'Gilroy-Medium',
    'Abel, sans-serif',
    'Roboto, sans-serif',
    'Inter, sans-serif',
    'Open Sans, sans-serif',
    'Lato, sans-serif',
    'Oswald, sans-serif',
    'Ubuntu, sans-serif'
  ];
  public appFontWeights = [
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'bold',
    'normal'
  ];

  constructor(private translate: TranslateService) {
    this.selectedLanguage = this.translate.getDefaultLang();
  }

  public setHeaderText(text) {
    this.setSidebarSection.next(text);
    this.translate.get(`left-sidebar.${text}`).pipe(first()).subscribe(value => {
      this.activeHeader = value;
      this.setHeader.next(value);
    });
  }

  updateFont() {
    document.body.setAttribute('style', `font-family: ${this.selectedFont} !important; font-weight: ${this.selectedWeight} !important`);
  }

  updateLanguage() {
    this.translate.use(this.selectedLanguage);
    localStorage.setItem('language', this.selectedLanguage);
  }
}
