import {EventEmitter, Injectable} from '@angular/core';
import {ActivitySearchModel} from "../models/activity-search-model";

@Injectable({
  providedIn: 'root'
})
export class AdvancedActivityService {
  public searchModel: ActivitySearchModel;
  public applyFilter$ = new EventEmitter<void>();
  public notificationsCount: number;

  public setup() {
    if (!this.searchModel) {
      this.searchModel = {
        page: 1,
        show: 50,
        order: 'desc',
        advancedsearch: {}
      };
    }
  }
}
