<div class="all">
  <app-generic-sidebar
    (itemClicked)="setPageTab($event)"
    [activeItem]="getActiveTabItem()"
    [buttonItems]="pageSidebarButtons"
    [isOpen]="isPageSidebarOpen"
    [linkItems]="pageSidebarLinks"
  >
    <ng-template #content>

      <div class="wrapper">
        <div class="progress-span">
          {{'operation-details.progress-bar' | translate}}
        </div>
        <div class="operation-stepper">
          <div *ngFor="let step of stepper; let i = index; let l=last" [class]="step" [ngClass]="{'no-line': l}"
               class="step-content">
            <span class="check-step">
                <img *ngIf="step === 'green'" alt="check-step" src="{{ cdn_url }}/assets/icons/check-step-purple.svg"
                     width="34">
                <img *ngIf="step === 'red'" alt="" height="34" src="{{ cdn_url }}/assets/icons/red-step.svg" width="35">
                <span>{{i + 1}}</span>
            </span>
            <span *ngIf="!l" class="step-line"></span>
          </div>
        </div>
        <div class="steps-text">
          <div class="caption" *ngFor="let phase of phases | async">{{ phase.name }}</div>
        </div>
      </div>
      <div *ngIf="isOperationLoaded && isDataLoaded" [ngClass]="{'hid': currentTab !== 1}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <form [formGroup]="form">
            <div class="wrapper">
              <h5 class="sub-heading">
                <img alt="" src="../../../../assets/icons/general-informations.svg"
                     width="22"> {{ 'operation-details.general-information' | translate }}
              </h5>
              <ng-container *ngIf="(clientUsers$ | async) as clientUsers1">


              <div [ngClass]="{'flex-2': clientUsers1?.length > 1}" class="flex">
                <div>
                  <span [ngClass]="{'dirty-label': isControlInvalid('name')}">{{ 'add-operation.designation' | translate }}*</span>
                  <input [(ngModel)]="operation.operationdata.name"
                         [ngClass]="{'dirty-form': isControlInvalid('name'), 'new-value' : isChangedFields.includes('name')}"
                         formControlName="name"
                         matInput placeholder="{{ 'operation-details.title-help' | translate }}"
                         type="text">
                  <span *ngIf="isControlInvalid('name')" class="dirty-label">
                     {{ 'add-operation.field-required' | translate }}
                    </span>
                </div>
                <div *ngIf="clientUsers1?.length > 1">
           <span [ngClass]="{'dirty-label': isControlInvalid('customer')}">
             {{ 'add-operation.project-management' | translate }} *</span>
                  <ng-multiselect-dropdown
                    [(ngModel)]="clientWrapper"
                    [settings]="dropdownSettings"
                    [data]="clientUsers1"
                    [ngClass]="{'dirty-form': isControlInvalid('customer'), 'new-value' : isChangedFields.includes('client')}"

                    formControlName="customer"
                    placeholder="{{ 'add-operation.client-name-helptext' | translate }}">

                  </ng-multiselect-dropdown>
                  <!--                  <mat-select [(ngModel)]="operation.operationdata.client"-->
                  <!--                              [ngClass]="{'dirty-form': isControlInvalid('customer'), 'new-value' : isChangedFields.includes('client')}"-->
                  <!--                              formControlName="customer"-->
                  <!--                              placeholder="{{ 'add-operation.client-name-helptext' | translate }}">-->
                  <!--                    <mat-option *ngFor="let item of clientUsers" [value]="item.id">-->
                  <!--                      {{ item.name }}-->
                  <!--                    </mat-option>-->
                  <!--                  </mat-select>-->
                  <span *ngIf="isControlInvalid('customer')" class="dirty-label">
                      {{ 'add-operation.field-required' | translate }}
                    </span>
                </div>
              </div>
              </ng-container>
              <div class="flex flex-3">
                <div>
                  <span>{{ 'operation-details.visibility' | translate }}</span>
                  <mat-select [(ngModel)]="operation.operationdata.visibility"
                              [ngClass]="{'new-value' : isChangedFields.includes('visibility')}"
                              formControlName="visibility"
                              placeholder="{{ 'operation-details.visibility-help-text' | translate }}">
                    <mat-option *ngFor="let item of operationvisibility.values()" [value]="operationvisibility[item]">
                      {{ item }}
                    </mat-option>
                  </mat-select>
                </div>
                <div>
            <span
              [ngClass]="{'dirty-label': isControlInvalid('location')}">{{ 'operation-details.locality' | translate }}
              *</span>
                  <mat-select [(ngModel)]="operation.operationdata.op_location"
                              [ngClass]="{'dirty-form': isControlInvalid('location'), 'new-value' : isChangedFields.includes('location')}"
                              formControlName="location"
                              placeholder="{{ 'operation-details.locality-help' | translate }}">
                    <mat-option *ngFor="let item of cities" [value]="item.name">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                  <span *ngIf="isControlInvalid('location')" class="dirty-label">
                        {{ 'add-operation.field-required' | translate }}
                    </span>
                </div>
                <div>
                  <span>{{ 'operation-details.address' | translate }}</span>
                  <input [(ngModel)]="operation.operationdata.gen_address"
                         [ngClass]="{'new-value' : isChangedFields.includes('gen_address')}" formControlName="address"
                         matInput
                         placeholder="{{ 'operation-details.address-help' | translate }}"
                         type="text">
                </div>
              </div>
              <div class="flex">
                <span>{{ 'operation-details.description' | translate }}</span>
                <textarea [(ngModel)]="operation.operationdata.description"
                          [ngClass]="{'new-value' : isChangedFields.includes('description')}"
                          cols="30" formControlName="description"
                          id="" placeholder="{{ 'operation-details.description-help' | translate }}"
                          rows="10"></textarea>
              </div>
              <div class="flex flex-3">
                <div>
             <span
               [ngClass]="{'dirty-label': isControlInvalid('gen_type_work')}">{{ 'operation-details.type-of-work' | translate }}
               *</span>
                  <mat-select [(ngModel)]="operation.operationdata.gen_type_work"
                              [ngClass]="{'dirty-form': isControlInvalid('gen_type_work'), 'new-value' : isChangedFields.includes('gen_type_work')}"
                              formControlName="gen_type_work"
                              placeholder="{{ 'operation-details.type-of-work-help' | translate }}">
                    <mat-option *ngFor="let item of workType.values()" [value]="workType[item]">
                      {{ item }}
                    </mat-option>
                  </mat-select>
                  <span *ngIf="isControlInvalid('gen_type_work')" class="dirty-label">
                          {{ 'add-operation.field-required' | translate }}
                      </span>
                </div>
                <div>
            <span [ngClass]="{'dirty-label': isControlInvalid('sector')}">{{ 'operation-details.sector' | translate }}</span>
                  <mat-select [(ngModel)]="operation.operationdata.sector"
                              [ngClass]="{'dirty-form': isControlInvalid('sector'), 'new-value' : isChangedFields.includes('sector')}"
                              formControlName="sector"
                              placeholder="{{ 'operation-details.sector-help' | translate }}">
                    <mat-option *ngFor="let item of sectors.values()" [value]="sectors[item]">
                      {{ item }}
                    </mat-option>
                  </mat-select>
<!--                  <span *ngIf="isControlInvalid('sector')" class="dirty-label">-->
<!--                          {{ 'add-operation.field-required' | translate }}-->
<!--                      </span>-->
                </div>
                <div>
            <span [ngClass]="{'dirty-label': isControlInvalid('status')}">{{ 'operation-details.status' | translate }}</span>
                  <mat-select (selectionChange)="selectChangeEvent($event)" [(ngModel)]="operation.operationdata.status"
                              [ngClass]="{'dirty-form': isControlInvalid('status'), 'new-value' : isChangedFields.includes('status')}"
                              disabled
                              formControlName="status"
                              placeholder="{{ 'operation-details.status-help' | translate }}">
                    <mat-option *ngFor="let item of status.values()" [value]="status[item]">
                      {{ item }}
                    </mat-option>
                  </mat-select>
                  <span *ngIf="isControlInvalid('status')" class="dirty-label">
                          {{ 'add-operation.field-required' | translate }}
                      </span>
                </div>
              </div>
              <div *ngIf="operation.operationdata.sector == 'LOG'" class="row row-edit">
                <div class="col-lg-2 col-edit">
                  <span>No log</span>
                  <input [(ngModel)]="operation.operationdata.gen_number_houses"
                         [ngClass]="{'new-value' : isChangedFields.includes('gen_number_houses')}"
                         formControlName="gen_number_houses"
                         matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>LLTS</span>
                  <input (input)="calculateHousingLog(operation.operationdata)"
                         [(ngModel)]="operation.operationdata.gen_LLTS"
                         [ngClass]="{'new-value' : isChangedFields.includes('gen_LLTS')}"
                         formControlName="gen_LLTS"
                         matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>LLS</span>
                  <input (input)="calculateHousingLog(operation.operationdata)"
                         [(ngModel)]="operation.operationdata.gen_LLS"
                         [ngClass]="{'new-value' : isChangedFields.includes('gen_LLS')}"
                         formControlName="gen_LLS"
                         matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>PLS</span>
                  <input (input)="calculateHousingLog(operation.operationdata)"
                         [(ngModel)]="operation.operationdata.gen_PLS"
                         [ngClass]="{'new-value' : isChangedFields.includes('gen_PLS')}"
                         formControlName="gen_PLS"
                         matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>PTZ</span>
                  <input (input)="calculateHousingLog(operation.operationdata)"
                         [(ngModel)]="operation.operationdata.gen_PTZ"
                         [ngClass]="{'new-value' : isChangedFields.includes('gen_PTZ')}"
                         formControlName="gen_PTZ"
                         matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>Autre</span>
                  <input (input)="calculateHousingLog(operation.operationdata)"
                         [(ngModel)]="operation.operationdata.gen_other"
                         [ngClass]="{'new-value' : isChangedFields.includes('gen_other')}"
                         formControlName="gen_other"
                         matInput
                         type="number">
                </div>
              </div>
            </div>
            <div class="main">
              <div class="wrapper">
                <h5 class="sub-heading">
                  <img alt="" src="../../../../assets/icons/asossiated-operations.svg"
                       width="20">
                  <span *ngIf="!operationService.isSpotProject; else relOperationsHeader">
            {{ 'operation-details.operations-and-market' | translate }}
            </span>
                  <ng-template #relOperationsHeader>
              <span>
                {{ 'operation-details.operations-related' | translate }}
              </span>
                  </ng-template>
                </h5>
                <div class="flex flex-2-2" id="linked-operations">
                  <div>
                    <span>{{ 'operation-details.linked-operations' | translate }}</span>
                    <ng-multiselect-dropdown [(ngModel)]="operation.linkedoperations"
                                             [data]="operations$ | async"
                                             [ngClass]="{'new-value' : isChangedFields.includes('linkedoperations')}"
                                             [settings]="multipleDropdownSettings"
                                             formControlName="linked_operations"
                                             placeholder="{{ 'operation-details.linked-operations-help' | translate }}">
                    </ng-multiselect-dropdown>
                  </div>
                  <div *ngIf="!operationService.isSpotProject">
              <span [ngClass]="{'dirty-label': isControlInvalid('type_marche')}">
                {{ 'operation-list.market-type' | translate }}*</span>
                    <mat-select [(ngModel)]="operation.operationdata.type_marche"
                                [ngClass]="{'dirty-form': isControlInvalid('type_marche'), 'new-value' : isChangedFields.includes('type_marche')}"
                                formControlName="type_marche"
                                placeholder="{{ 'operation-list.market-type-help' | translate }}">
                      <mat-option *ngFor="let item of marketType.values()" [value]="marketType[item]">
                        {{ item }}
                      </mat-option>
                    </mat-select>
                    <span *ngIf="isControlInvalid('type_marche')" class="dirty-label">
                          {{ 'add-operation.field-required' | translate }}
                      </span>
                  </div>
                </div>
                <div class="flex">
                  <ng-container *ngFor="let op of operation.linkedoperations; let i = index">
                    <div class="flex-2-around">
                      <div class="operation-name">
                        {{op.name}}
                      </div>
                      <div class="remove-op">
                  <span (click)="removeOperation(i, this.operation.linkedoperations)"
                        class="remove-button">{{ 'add-operation.remove' | translate }}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> <!-- tab1 end-->
      <!--Intellectual services-->
      <div *ngIf="isOperationLoaded && isDataLoaded" [ngClass]="{'hid': currentTab !== 2}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Architect competition-->
              <img alt="" src="../../../../assets/icons/arcitectural-competition.svg" width="22">
              {{ 'operation-details.architect-competition' | translate }}
            </h5>
            <div class="flex">
              <span>{{ 'operation-details.pi-ca-ao-date' | translate }}</span>
            </div>
            <div class="flex flex-2">
              <div>
                <input (click)="pi_ca_ao_date.open()" [(ngModel)]="operation.operationdata.pi_ca_ao_date"
                       [matDatepicker]="pi_ca_ao_date"
                       [ngClass]="{'new-value' : isChangedFields.includes('pi_ca_ao_date')}"
                       matInput placeholder="{{ 'operation-details.pi-ca-ao-date-help' | translate }}">
                <mat-datepicker #pi_ca_ao_date></mat-datepicker>
              </div>
              <div>
                <mat-select [(ngModel)]="operation.operationdata.pi_ca_ao_date_reelle"
                            [disabled]="!this.operation.operationdata.pi_ca_ao_date"
                            [ngClass]="{'disabled-form' : !this.operation.operationdata.pi_ca_ao_date, 'new-value' : isChangedFields.includes('pi_ca_ao_date_reelle')}"
                            name="pi-ca-ao-date-reelle"
                            placeholder="{{ 'operation-details.pi-ca-ao-date-reelle-help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()"
                              [disabled]="isDateStatusDisabled(operation.operationdata.pi_ca_ao_date, item)"
                              [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-ca-source' | translate }}</span>
              <div>
                <input [(ngModel)]="operation.operationdata.pi_ca_source"
                       [ngClass]="{'new-value' : isChangedFields.includes('pi_ca_source')}" matInput
                       name="pi_ca_source"
                       placeholder="{{ 'operation-details.pi-ca-source-help' | translate }}"
                       type="text">
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.ca_date_selection' | translate }}</span>
            </div>
            <div class="flex flex-2">
              <div>
                <input (click)="pi_ca_selection_date.open()" [(ngModel)]="operation.operationdata.pi_ca_selection_date"
                       [matDatepicker]="pi_ca_selection_date"
                       [ngClass]="{'new-value' : isChangedFields.includes('pi_ca_selection_date')}"
                       matInput placeholder="{{ 'operation-details.pi-ca-selection-date-help' | translate }}">
                <mat-datepicker #pi_ca_selection_date></mat-datepicker>
              </div>
              <div>
                <mat-select [(ngModel)]="operation.operationdata.pi_ca_selection_date_reelle"
                            [disabled]="!this.operation.operationdata.pi_ca_selection_date"
                            [ngClass]="{'disabled-form' : !this.operation.operationdata.pi_ca_selection_date, 'new-value' : isChangedFields.includes('pi_ca_selection_date_reelle')}"
                            name="pi_ca_selection_date_reelle"
                            placeholder="{{ 'operation-details.pi-ca-selection-date-reelle-help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()"
                              [disabled]="isDateStatusDisabled(operation.operationdata.pi_ca_selection_date, item)"
                              [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-ca-prestataire' | translate }}</span>
              <div>
                <input [(ngModel)]="operation.operationdata.pi_ca_prestataire"
                       [ngClass]="{'new-value' : isChangedFields.includes('pi_ca_prestataire')}" matInput
                       name="pi_ca_source"
                       placeholder="{{ 'operation-details.pi-ca-prestataire-help' | translate }}"
                       type="text">
              </div>
            </div>
          </div>
        </div>


        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Subject mastery - Maître d'oeuvre-->
              <img alt="" src="../../../../assets/icons/subject-mastery.svg" width="18">
              {{ 'operation-details.subject-mastery' | translate }}
            </h5>
            <div class="flex">
              <span>{{ 'operation-details.pi-mo-ao-date' | translate }}</span>
            </div>
            <div class="flex flex-2">
              <div>
                <input (click)="pi_mo_ao_date.open()" [(ngModel)]="operation.operationdata.pi_mo_ao_date"
                       [matDatepicker]="pi_mo_ao_date"
                       [ngClass]="{'new-value' : isChangedFields.includes('pi_mo_ao_date')}"
                       matInput placeholder="{{ 'operation-details.pi-mo-ao-date-help' | translate }}">
                <mat-datepicker #pi_mo_ao_date></mat-datepicker>
              </div>
              <div>
                <mat-select [(ngModel)]="operation.operationdata.pi_mo_ao_date_reelle"
                            [disabled]="!this.operation.operationdata.pi_mo_ao_date"
                            [ngClass]="{'disabled-form' : !this.operation.operationdata.pi_mo_ao_date, 'new-value' : isChangedFields.includes('pi_mo_ao_date_reelle')}"
                            name="pi_mo_ao_date_reelle"
                            placeholder="{{ 'operation-details.pi-mo-ao-date-reelle-help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()"
                              [disabled]="isDateStatusDisabled(operation.operationdata.pi_mo_ao_date, item)"
                              [value]="statusDate[item]"
                  >
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-mo-source' | translate }}</span>
              <div>
                <input [(ngModel)]="operation.operationdata.pi_mo_source"
                       [ngClass]="{'new-value' : isChangedFields.includes('pi_mo_source')}" matInput
                       name="pi_mo_source"
                       placeholder="{{ 'operation-details.pi-ca-source-help' | translate }}"
                       type="text">
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-mo-selection-date' | translate }}</span>
            </div>
            <div class="flex flex-2">
              <div>
                <input (click)="pi_mo_selection_date.open()" [(ngModel)]="operation.operationdata.pi_mo_selection_date"
                       [matDatepicker]="pi_mo_selection_date"
                       [ngClass]="{'new-value' : isChangedFields.includes('pi_mo_selection_date')}"
                       matInput placeholder="{{ 'operation-details.pi-mo-selection-date-help' | translate }}">
                <mat-datepicker #pi_mo_selection_date></mat-datepicker>
              </div>
              <div>
                <mat-select [(ngModel)]="operation.operationdata.pi_mo_selection_date_reelle"
                            [disabled]="!this.operation.operationdata.pi_mo_selection_date"
                            [ngClass]="{'disabled-form' : !this.operation.operationdata.pi_mo_selection_date, 'new-value' : isChangedFields.includes('pi_mo_selection_date_reelle')}"
                            name="pi_mo_selection_date_reelle"
                            placeholder="{{ 'operation-details.pi-mo-selection-date-reelle-help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()"
                              [disabled]="isDateStatusDisabled(operation.operationdata.pi_mo_selection_date, item)"
                              [value]="statusDate[item]"
                  >
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-mo-prestataire' | translate }}</span>
              <div>
                <input [(ngModel)]="operation.operationdata.pi_mo_prestataire"
                       [ngClass]="{'new-value' : isChangedFields.includes('pi_mo_prestataire')}" matInput
                       name="pi_mo_prestataire"
                       placeholder="{{ 'operation-details.pi-mo-prestataire-help' | translate }}"
                       type="text">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Operational execution-->
      <div *ngIf="isOperationLoaded && isDataLoaded" [ngClass]="{'hid': currentTab !== 3}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Call for tenders - Appel d'offres-->
              <img alt="" src="../../../../assets/icons/call-for-tenders.svg" width="22">
              {{ 'operation-details.call-for-tenders' | translate }}
            </h5>
            <div class="flex flex-3">
              <div>
                <span>{{ 'operation-details.mi_ao_travaux_date' | translate }}</span>
                <input (click)="mi_ao_travaux_date.open()" [(ngModel)]="operation.operationdata.mi_ao_travaux_date"
                       [matDatepicker]="mi_ao_travaux_date"
                       [ngClass]="{'new-value' : isChangedFields.includes('mi_ao_travaux_date')}"
                       matInput placeholder="{{ 'operation-details.mi_ao_travaux_date-help' | translate }}">
                <mat-datepicker #mi_ao_travaux_date></mat-datepicker>
              </div>
              <div>
                <span></span>
                <mat-select [(ngModel)]="operation.operationdata.mi_ao_travaux_date_reelle"
                            [disabled]="!this.operation.operationdata.mi_ao_travaux_date"
                            [ngClass]="{'disabled-form' : !this.operation.operationdata.mi_ao_travaux_date, 'new-value' : isChangedFields.includes('mi_ao_travaux_date_reelle')}"
                            name="mi_ao_travaux_date_reelle"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()"
                              [disabled]="isDateStatusDisabled(operation.operationdata.mi_ao_travaux_date, item)"
                              [value]="statusDate[item]"
                  >
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
              <div>
                <span>{{ 'operation-details.procedure' | translate }}</span>
                <!-- TODO: Need to define procedure dropdown list-->
                <mat-select [(ngModel)]="operation.operationdata.procedure"
                            [ngClass]="{'new-value' : isChangedFields.includes('procedure')}"
                            name="procedure"
                            placeholder="{{ 'operation-details.procedure-help' | translate }}">
                  <mat-option *ngFor="let item of procedureList.values()" [value]="procedureList[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.mi_ao_source' | translate }}</span>
              <div>
                <input [(ngModel)]="operation.operationdata.mi_ao_source"
                       [ngClass]="{'new-value' : isChangedFields.includes('mi_ao_source')}" matInput
                       name="mi_ao_source"
                       placeholder="{{ 'operation-details.mi_ao_source-help' | translate }}"
                       type="text">
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.mi_ao_deadline_date' | translate }}</span>
              <input (click)="mi_ao_deadline_date.open()" [(ngModel)]="operation.operationdata.mi_ao_deadline_date"
                     [matDatepicker]="mi_ao_deadline_date"
                     [ngClass]="{'new-value' : isChangedFields.includes('mi_ao_deadline_date')}"
                     matInput placeholder="{{ 'operation-details.mi_ao_deadline_date-help' | translate }}">
              <mat-datepicker #mi_ao_deadline_date></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Market result-->
              <img alt="" src="../../../../assets/icons/market-result.svg" width="22">
              {{ 'operation-details.market-result' | translate }}
            </h5>
            <div class="flex flex-3 ">
              <div>
                <span>{{ 'operation-details.mi_resultat_marche_date' | translate }}</span>
                <input (click)="mi_resultat_marche_date.open()"
                       [(ngModel)]="operation.operationdata.mi_resultat_marche_date"
                       [matDatepicker]="mi_resultat_marche_date"
                       [ngClass]="{'new-value' : isChangedFields.includes('mi_resultat_marche_date')}"
                       matInput
                       placeholder="{{ 'operation-details.mi_resultat_marche_date_help' | translate }}">
                <mat-datepicker #mi_resultat_marche_date></mat-datepicker>
              </div>
              <div>
                <span> </span>
                <mat-select [(ngModel)]="operation.operationdata.mi_resultat_marche_date_reelle"
                            [disabled]="!this.operation.operationdata.mi_resultat_marche_date"
                            [ngClass]="{'disabled-form' : !this.operation.operationdata.mi_resultat_marche_date, 'new-value' : isChangedFields.includes('mi_resultat_marche_date_reelle')}"
                            name="mi_resultat_marche_date_reelle"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()"
                              [disabled]="isDateStatusDisabled(operation.operationdata.mi_resultat_marche_date, item)"
                              [value]="statusDate[item]"
                  >
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
              <div>
                <span>{{ 'operation-details.mi_resultat_marche_amount' | translate }}</span>
                <input [(ngModel)]="operation.operationdata.mi_resultat_marche_montant"
                       [disabled]="operation.housing.length>0"
                       [ngClass]="{'new-value' : isChangedFields.includes('mi_resultat_marche_montant')}"
                       mask="separator"
                       matInput name="mi_resultat_marche_montant"
                       placeholder="{{ 'operation-details.mi_resultat_marche_amount_help' | translate }}"
                       suffix=" €"
                       type="text">
              </div>
            </div>
            <div class="flex">
               <span>{{ 'operation-details.overspend' | translate }}</span>
              <input [(ngModel)]="operation.operationdata.overspend"
                     [disabled]="operation.housing.length>0"
                     [ngClass]="{'new-value' : isChangedFields.includes('overspend')}"
                     mask="separator"
                     matInput name="overspend"
                     placeholder="{{ 'operation-details.overspend_help' | translate }}"
                     suffix=" €"
                     type="text">
            </div>
            <div class="flex">
              <span>{{ 'operation-details.mi_resultat_marche_source' | translate }}</span>
              <div>
                <input [(ngModel)]="operation.operationdata.mi_resultat_marche_source"
                       [ngClass]="{'new-value' : isChangedFields.includes('mi_resultat_marche_source')}"
                       matInput
                       name="{{ 'operation-details.mi_resultat_marche_source' | translate }}"
                       placeholder="{{ 'operation-details.mi_resultat_marche_source_help' | translate }}"
                       type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <div class="flex-headings-2">
              <!-- TODO: Need to define new value highlight for new housings-->
              <h5 class="sub-heading">
                <img alt="" src="../../../../assets/icons/housing.svg"
                     width="22">{{ 'operation-details.housing' | translate }}
              </h5>
              <span (click)="addHousing()" *ngIf="this.operation.housing.length>0"
                    class="green sub-heading-link">+ {{ 'housing.add-housing' | translate }}</span>
            </div>


            <span *ngIf="!operation.housing || operation.housing.length === 0">Vous n'avez pas encore d'allotissement, voulez-vous
                <span (click)="addHousing()" class="green">ajouter en?</span>
            </span>

            <div *ngIf="this.operation.housing.length !== 0" class="flex flex-2-3 list">
              <ng-container *ngFor="let house of this.operation.housing; let i = index">
                <div [ngClass]="{'new-value': !house?.main_housing_id}" class="left align-flex-start housing-left">
                  <img alt="" src="{{ cdn_url }}/assets/icons/housing.svg" width="24">
                  <div class="inline full-flex">

                    <div class="p3">
                <span *ngIf="this.getChangedHousingValueV2(house.housingId, 'name')" class="p1 old-value-span">
                  {{this.getChangedHousingValueV2(house.housingId, 'name') || '-'}}
                </span>
                      <span *ngIf="house?.deleted!='1'"
                            [ngClass]="{'new-value-span': this.getChangedHousingValueV2(house.housingId, 'name')}"
                            class="p1">
                  {{house?.name || '-'}}
                </span>
                    </div>
                    <div class="p-gray">
               <span *ngIf="this.getChangedHousingValueV2(house.housingId, 'code_ape')" class="p1 old-value-span">
                  {{ getApeCode(this.getChangedHousingValueV2(house.housingId, 'code_ape')) || '-'}}
                </span>
                      <span *ngIf="house?.deleted!='1'"
                            [ngClass]="{'new-value-span': this.getChangedHousingValueV2(house.housingId, 'code_ape')}"
                            class="p1">
                  {{getApeCode(house?.fk_codeAPE) || '-' }}
                </span>
                    </div>
                  </div>
                </div>
                <div [ngClass]="{'new-value': !house?.main_housing_id}" class="align-right">
                  <div class="flex align-end">
                    <div>
                      <div class="p3">
                <span *ngIf="this.getChangedHousingValueV2(house.housingId, 'company_name')"
                      class="p1 old-value-span">
                  {{  this.getChangedHousingValueV2(house.housingId, 'company_name') || '-'}}
                </span>
                        <span *ngIf="house?.deleted!='1'"
                              [ngClass]="{'new-value-span': this.getChangedHousingValueV2(house.housingId, 'company_name')}"
                              class="p1">
                  {{house?.company_name || '-'}}
                </span>
                      </div>
                      <div class="flex price-block">
                  <span *ngIf="this.getChangedHousingValueV2(house.housingId, 'price')" class="p1 old-value-span">
                  {{  frenchFormat(this.getChangedHousingValueV2(house.housingId, 'price')) || '-'}}
                </span>
                        <span *ngIf="house?.deleted!='1'"
                              [ngClass]="{'new-value-span': this.getChangedHousingValueV2(house.housingId, 'price')}"
                              class="p1">
                  {{frenchFormat(house?.price)}}
                </span>

                      </div>
                    </div>
                    <div class="flex-icon">
                      <img (click)="editHousing(house, i)" alt="" src="../../../../assets/icons/edit.svg" width="19">
                    </div>
                    <div class="flex-icon">
                      <img (click)="removeHousing(i, operation)" alt="" src="../../../../assets/icons/delete.svg"
                           width="14">
                    </div>
                  </div>
                </div>
                <hr class="col-xs-12 divider">
              </ng-container>
            </div>
            <div *ngIf="isAddHousingProcess" #housingPanel [@inOutAnimation]>
              <app-add-housing-inline *ngIf="codes" [triggerOnChanges]="triggerOnChanges" [codes]="codes"></app-add-housing-inline>

            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <img alt="" src="../../../../assets/icons/class-service.svg" width="22">
              {{ 'operation-details.mi_ordre_service' | translate }}
            </h5>
            <div class="flex flex-2">
              <div>
                <span>{{ 'operation-details.mi_ordre_service_date' | translate }}</span>
                <input (click)="mi_ordre_service_date.open()"
                       [(ngModel)]="operation.operationdata.mi_ordre_service_date"
                       [matDatepicker]="mi_ordre_service_date"
                       [ngClass]="{'new-value' : isChangedFields.includes('mi_ordre_service_date')}"
                       matInput placeholder="{{ 'operation-details.mi_ordre_service_date_help' | translate }}">
                <mat-datepicker #mi_ordre_service_date></mat-datepicker>
              </div>
              <div>
                <span> </span>
                <mat-select [(ngModel)]="operation.operationdata.mi_ordre_service_date_reelle"
                            [disabled]="!this.operation.operationdata.mi_ordre_service_date"
                            [ngClass]="{'disabled-form' : !this.operation.operationdata.mi_ordre_service_date, 'new-value' : isChangedFields.includes('mi_ordre_service_date_reelle')}"
                            name="mi_ordre_service_date_reelle"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()"
                              [disabled]="isDateStatusDisabled(operation.operationdata.mi_ordre_service_date, item)"
                              [value]="statusDate[item]"
                  >
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Reception-->
              <img alt="" src="../../../../assets/icons/reception.svg" width="22">
              {{ 'operation-details.mi_reception' | translate }}
            </h5>
            <div class="flex flex-2">
              <div>
                <span>{{ 'operation-details.mi_reception_date' | translate }}</span>
                <input (click)="mi_reception_date.open()" [(ngModel)]="operation.operationdata.mi_livraison_date"
                       [matDatepicker]="mi_reception_date"
                       [ngClass]="{'new-value' : isChangedFields.includes('mi_livraison_date')}"
                       matInput placeholder="{{ 'operation-details.mi_reception_date_help' | translate }}">
                <mat-datepicker #mi_reception_date></mat-datepicker>
              </div>
              <div>
                <span> </span>
                <mat-select [(ngModel)]="operation.operationdata.mi_livraison_date_reelle"
                            [disabled]="!this.operation.operationdata.mi_livraison_date"
                            [ngClass]="{'disabled-form' : !this.operation.operationdata.mi_livraison_date, 'new-value' : isChangedFields.includes('mi_livraison_date_reelle')}"
                            name="mi_reception_date_reelle"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()"
                              [disabled]="isDateStatusDisabled(operation.operationdata.mi_livraison_date, item)"
                              [value]="statusDate[item]"
                  >
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isOperationLoaded && isDataLoaded" [ngClass]="{'hid': currentTab !== 4}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <img alt="" src="../../../../assets/icons/building-permit.svg"
                   width="22"> {{ 'operation-details.build-permission' | translate }}
            </h5>
            <div class="flex flex-2">
              <div>
                <span>{{ 'operation-details.building-permission-filling-date' | translate }}</span>
                <input (click)="instr_pc_date_depot.open()" [(ngModel)]="operation.operationdata.instr_pc_date_depot"
                       [matDatepicker]="instr_pc_date_depot"
                       [ngClass]="{'new-value' : isChangedFields.includes('instr_pc_date_depot')}"
                       matInput
                       placeholder="{{ 'operation-details.building-permission-filling-date-help' | translate }}">
                <mat-datepicker #instr_pc_date_depot></mat-datepicker>
              </div>
              <div>
                <span>{{ 'operation-details.date-type' | translate }}</span>
                <mat-select [(ngModel)]="operation.operationdata.instr_pc_date_depot_reelle"
                            [disabled]="!this.operation.operationdata.instr_pc_date_depot"
                            [ngClass]="{'disabled-form' : !this.operation.operationdata.instr_pc_date_depot, 'new-value' : isChangedFields.includes('instr_pc_date_depot_reelle')}"
                            name="date_type"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()"
                              [disabled]="isDateStatusDisabled(operation.operationdata.instr_pc_date_depot, item)"
                              [value]="statusDate[item]"
                  >
                    {{ item }}
                  </mat-option>
                </mat-select>

              </div>
              <div>
                <span>{{ 'operation-details.building-permission-date-attribution' | translate }}</span>
                <input (click)="date_attribution.open()" [(ngModel)]="operation.operationdata.instr_pc_date_attribution"
                       [matDatepicker]="date_attribution"
                       [ngClass]="{'new-value' : isChangedFields.includes('instr_pc_date_attribution')}"
                       matInput placeholder="{{ 'operation-details.mi_resultat_marche_date_help' | translate }}">
                <mat-datepicker #date_attribution></mat-datepicker>
              </div>
              <div>
                <span>{{ 'operation-details.building-permission-number' | translate }}</span>
                <input [(ngModel)]="operation.operationdata.instr_pc_numero"
                       [ngClass]="{'new-value' : isChangedFields.includes('instr_pc_numero')}"
                       matInput
                       placeholder="{{ 'operation-details.building-permission-number-help' | translate }}"
                       type="number">
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <div class="flex-headings-2">
              <h5 class="sub-heading">
                <img alt="" src="../../../../assets/icons/funding.svg"
                     width="22">{{ 'operation-details.funding' | translate }}
              </h5>
              <span (click)="addFunding()" *ngIf="this.operation.funding.length>0"
                    class="green sub-heading-link">+  {{ 'funding.add' | translate }}</span>
            </div>
            <!-- TODO: Need to define new value highlight for new fundings-->
            <span *ngIf="this.operation.funding.length === 0">{{ 'funding.add-funding-long' | translate }}
              <span (click)="addFunding()" class="green">{{ 'housing.add-housing-long-help' | translate }}</span>
            </span>
            <div *ngIf="this.operation.funding.length !== 0" class="flex flex-table-7 list">
              <div class="col-1">
                <span>№</span>
              </div>
              <div class="col-2">
                <span>{{ 'funding.funder' | translate }}</span>
              </div>
              <div>
                <span>{{ 'funding.type' | translate }}</span>
              </div>
              <div>
                <span>{{ 'funding.filling-date' | translate }}</span>
              </div>
              <div class="col-5">
                <span>{{ 'funding.grant-date' | translate }}</span>
              </div>
              <div>
                <span>{{ 'funding.amount' | translate }}</span>
              </div>
              <div class="col-7">
                <span>{{ 'funding.actions' | translate }}</span>
              </div>
              <hr class="col-xs-12 divider">
              <ng-container *ngFor="let fund of this.operation.funding; let i = index">
                <div [ngClass]="{'new-value': !fund?.main_financial_id}" class="col-1">
                  <span class="p1">{{i + 1}}</span>
                </div>
                <div [ngClass]="{'new-value': !fund?.main_financial_id}" class="col-2">
                  <span class="p1">{{fund.financial_organisation}}</span>
                </div>
                <div [ngClass]="{'new-value': !fund?.main_financial_id}">
                  <span class="p1">{{fund.type}}</span>
                </div>
                <div [ngClass]="{'new-value': !fund?.main_financial_id}">
                  <span class="p1">{{fund.submission_date }}</span>
                </div>
                <div [ngClass]="{'new-value': !fund?.main_financial_id}" class="col-5">
                  <span class="p1">{{fund.awarding_date}}</span>
                </div>
                <div [ngClass]="{'new-value': !fund?.main_financial_id}">
                  <span class="p1">{{fund.amount | currency : 'EUR'}}</span>
                </div>
                <div class="col-7">
                  <div class="flex-icon">
                    <img (click)="editFunding(fund, i)" alt="" src="../../../../assets/icons/edit.svg" width="19">
                  </div>
                  <div class="flex-icon">
                    <img (click)="removeFunding(i, operation.funding)" alt="" src="../../../../assets/icons/delete.svg"
                         width="14">
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <div class="flex-headings-2">
              <h5 class="sub-heading">
                <img alt="" src="../../../../assets/icons/upload.svg"
                     width="22">{{ 'operation-details.attachements' | translate }}
              </h5>
              <!-- TODO: Need to define new value highlight for new files-->
              <span (click)="addFile()" *ngIf="operation.files.length>0"
                    class="green sub-heading-link">+ {{ 'file.add' | translate }}</span>
            </div>
            <span *ngIf="operation.files.length === 0">{{ 'file.add-file-long' | translate }}
              <span (click)="addFile()" class="green">{{ 'housing.add-housing-long-help' | translate }}</span>
            </span>
            <div class="flex ">
              <ng-container *ngFor="let file of operation.files; let i = index">
                <div class="flex-4">
                  <div class="m-col-1">
                    <img alt="" src="../../../../assets/icons/attachment.svg" width="19">
                  </div>
                  <div class="m-col-2">
                    <span (click)="operationService.loadFile(file?.name)" class="file-name">{{file.name}}</span>
                    <span class="file-description">{{file.description}}</span>
                  </div>
                  <div class="m-col-3 ">
                    <span class="p1 ">{{file.date}}</span>
                    <span class="p1 blue-text">{{file.admin}}</span>
                  </div>
                  <div class="m-col-4">
                    <div class="flex-icon">
                      <img (click)="removeFile(file, i, operation.files)" alt=""
                           src="../../../../assets/icons/delete.svg"
                           width="14">
                    </div>
                  </div>
                </div>
                <hr class="col-xs-12 divider">
              </ng-container>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <img alt="" src="../../../../assets/icons/georeferencing.svg"
                   width="22">{{ 'operation-details.georeference' | translate }}
            </h5>

            <div class="flex flex-2">
              <div>
                <span>{{ 'operation-details.gps-coordinates' | translate }}</span>
                <input [(ngModel)]="operation.operationdata.gen_geolocation"
                       [ngClass]="{'new-value' : isChangedFields.includes('gen_geolocation')}" matInput
                       placeholder="{{ 'operation-details.gps-coordinates-help' | translate }}"
                       type="text">
              </div>
              <div>
                <span>{{ 'operation-details.sig-source' | translate }}</span>
                <input [(ngModel)]="operation.operationdata.source_sig"
                       [ngClass]="{'new-value' : isChangedFields.includes('source_sig')}" matInput
                       placeholder="{{ 'operation-details.sig-source-help' | translate }}"
                       type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <img alt="" src="../../../../assets/icons/user-access.svg"
                   width="22">{{ 'add-operation.user-access' | translate }}
            </h5>
            <div class="flex">
              <div>
                <!-- TODO: Need to define new value highlight for new access users-->
                <span>{{ 'add-operation.user-access' | translate }}</span>
                <mat-select [(ngModel)]="operation.permision_client" multiple
                            placeholder="{{ 'add-operation.user-access-help' | translate }}">
                  <mat-option *ngFor="let item of accessUsers" [value]="item.id">
                    {{ item.name }} {{ item.surname }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <ng-container *ngFor="let client of operation.permision_client; let i = index">
                <div class="flex-2-around">
                  <div class="operation-name">
                    {{getClientName(client)}}
                  </div>
                  <div class="remove-op">
              <span (click)="removeClientAccess(i, operation.permision_client )"
                    class="remove-button">{{ 'add-operation.remove' | translate }}</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isOperationLoaded && isDataLoaded" [ngClass]="{'hid': currentTab !== 5}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <div class="flex-headings-2">
              <!-- TODO: Need to define new value highlight for new vigilances-->
              <span (click)="addVigilance()" *ngIf="this.operation.vigilance.length>0"
                    class="green sub-heading-link">+ {{ 'edit-operations.add-vigilance' | translate }}</span>
            </div>
            <span *ngIf="this.operation.vigilance.length === 0"> {{ 'edit-operations.add-vigilance-long' | translate }}
              <span (click)="addVigilance()"
                    class="green">{{ 'edit-operations.add-vigilance-short' | translate }}</span>
        </span>
            <div *ngIf="this.operation.vigilance.length !== 0" class="flex vigilance-flex">
              <ng-container *ngFor="let vig of this.operation.vigilance; let i = index">
                <div class="flex-4">
                  <div class="m-col-1">
                    <img *ngIf="vig?.resolved=='1'; else unresolvedImg" alt=""
                         src="../../../../assets/icons/vigilance-resolved.svg" width="32">
                    <ng-template #unresolvedImg>
                      <img alt="" src="../../../../assets/icons/vigilance-unresolved.svg" width="32">
                    </ng-template>
                  </div>
                  <div class="m-col-2">
                    <div class="vig-name">
                      <span class="vig-name-span">{{vigilance[vig?.domaine] || '-'}}</span>
                      <span *ngIf="vig?.resolved=='1'; else unresolved"
                            class="vig-status blue-text">{{ 'dashboard.vigilance-resolved' | translate }}</span>
                      <ng-template #unresolved>
                        <span class="vig-status red-text">({{ 'dashboard.vigilance-unresolved' | translate }})</span>
                      </ng-template>
                    </div>
                    <span class="vig-description">{{vig?.description || '-'}}</span>
                  </div>
                  <div class="m-col-3">
                    <span class="p1">{{vig?.actors || '-' }}</span>
                    <span class="p1">{{vig?.date || '-'}}</span>
                  </div>
                  <div class="m-col-4">
                    <div class="flex-icon">
                      <img (click)="editVigilance(vig, i)" alt="" src="../../../../assets/icons/edit.svg" width="19">
                    </div>
                    <div class="flex-icon">
                      <img (click)="removeVigilance(vig.id, i)" alt="" src="../../../../assets/icons/delete.svg"
                           width="14">
                    </div>
                  </div>
                </div>
                <!--          <hr class="col-xs-12 divider">-->
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isOperationLoaded && isDataLoaded" [ngClass]="{'hid': currentTab !== 6}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <div class="flex-headings-2">
              <!-- TODO: Need to define new value highlight for new observations-->
              <span (click)="addObservations()" *ngIf="this.operation.observations.length>0"
                    class="green sub-heading-link">+ {{ 'observations.add' | translate }}</span>
            </div>
            <span *ngIf="this.operation.observations.length === 0">{{ 'observations.add-long' | translate }}
              <span (click)="addObservations()" class="green">{{ 'observations.add-short' | translate }}</span>
        </span>
            <div *ngIf="this.operation.observations.length !== 0" class="flex">
              <ng-container *ngFor="let observation of this.operation.observations; let i = index">
                <div class="flex-4">
                  <div class="m-col-1 user-circle-col">
                    <div class="user-circle mr-4">
<!--                <span-->
<!--                  class="user-initials">{{observation.name[0] || '-' | uppercase}}{{observation.surname[0] || '-' | uppercase}}</span>-->
                      <img [src]="api_url + '/uploads/' + observation.image" class="img-circle">
                    </div>
                  </div>
                  <div class="m-col-2">
                    <div class="vig-name">
                      <span class="file-name">{{observation.name || '-'}} {{observation.surname || '-'}}</span>
                    </div>
                    <span class="file-description">{{observation?.description || '-'}}</span>
                  </div>
                  <div class="m-col-3">
                    <span class="p1">{{observation?.creation_datetime || '-' }}</span>
                  </div>
                  <div class="m-col-4">
                    <div class="flex-icon">
                      <img (click)="editObservations(observation, i)" alt="" src="../../../../assets/icons/edit.svg"
                           width="19">
                    </div>
                    <div class="flex-icon">
                      <img (click)="removeObservations(observation.id, i)" alt=""
                           src="../../../../assets/icons/delete.svg" width="14">
                    </div>
                  </div>
                </div>
                <hr class="col-xs-12 divider">
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isOperationLoaded && isDataLoaded" [ngClass]="{'hid': currentTab !== 7}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <div class="flex-headings-2">
              <span (click)="addValidation()" *ngIf="operation.validations.length>0"
                    class="green sub-heading-link">+ {{ 'edit-operations.add-validation' | translate }}</span>
            </div>
            <span *ngIf="this.operation.validations.length === 0"> {{ 'edit-operations.add-validation-long' | translate }}
              <span (click)="addValidation()"
                    class="green">{{ 'edit-operations.add-validation-short' | translate }}</span>
        </span>
            <div *ngIf="this.operation.validations.length !== 0">
              <table class="table">
                <tr *ngFor="let val of this.operation.validations">
                  <td class="align-middle text-center">
                    <img [ngClass]="{invisible: val.valid == '0'}" alt=""
                           src="../../../../assets/icons/check-step-purple.svg" width="32">
                  </td>
                  <td>
                    <div class="p1">
                      {{ val.name }}
                    </div>
                    <div class="p2">
                      <span *ngIf="val.valid == '1'">Validé le {{val.valid_at | date:'dd/MM/yyyy'}}</span>
                      <span *ngIf="val.valid == '0'">À valider</span>
                    </div>
                  </td>
                  <td class="align-middle">{{val.fullname}}</td>
                  <td class="align-middle text-right">
                    <img [src]="getImgUrl(val.signature)" height="50" *ngIf="val.valid == '1'">
                  </td>
                  <td class="text-center align-middle">
                    <img class="mr-3" (click)="editValidation(val)" alt="" src="../../../../assets/icons/edit.svg"
                         width="19" style="cursor:pointer;">
                    <img (click)="deleteValidation(val)" alt="" src="../../../../assets/icons/delete.svg"
                         width="14" style="cursor:pointer;">
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isOperationLoaded && isDataLoaded" [ngClass]="{'hid': currentTab !== 8}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <div class="flex-headings-2 justify-content-between mb-3">
              <span (click)="addTask()" class="green sub-heading-link">
                + {{ 'edit-operations.add-task' | translate }}
              </span>
              <app-search (onSearch)="filterTasks($event)"></app-search>
            </div>
<!--            <span *ngIf="operation.tasks.length === 0">-->
<!--              {{ 'edit-operations.add-task-long' | translate }}-->
<!--              <span (click)="addTask()" class="green">-->
<!--                {{ 'edit-operations.add-task-short' | translate }}-->
<!--              </span>-->
<!--            </span>-->
            <div>

              <div class="spinner-block">
                <ngx-ui-loader [fgsColor]="'#543bff'"
                               [overlayColor]="'#EFEDFD'"
                               fgsSize="75"
                               fgsType="ball-spin-clockwise-fade-rotating"
                               loaderId="op_task"></ngx-ui-loader>
                <table appTable>
                  <thead appThead>
                  <tr appTr>
                    <th appTh appOrdering (onOrdering)="orderTasks('name')">{{ 'task.table.name' | translate }}</th>
                    <th appTh appOrdering (onOrdering)="orderTasks('fullname')">{{ 'task.table.assigned_to' | translate }}</th>
                    <th appTh appOrdering (onOrdering)="orderTasks('priority')">{{ 'task.table.priority' | translate }}</th>
                    <th appTh appOrdering (onOrdering)="orderTasks('status')">{{ 'task.table.status' | translate }}</th>
                    <th appTh></th>
                  </tr>
                  </thead>
                  <tbody appTbody>
                    <tr appTr *ngFor="let task of operation.tasks" (click)="editTask(task)">
                      <td appTd>{{ task.name }}</td>
                      <td appTd>{{ task.fullname }}</td>
                      <td appTd>{{ 'task.priority.'+task.priority | translate }}</td>
                      <td appTd><span class="badge" [style]="'background-color: '+task.status_color">{{ task.status }}</span></td>
                      <td appTd class="text-center align-middle">
                        <span appActionEdit (onEdit)="editTask(task)"></span>
                        <span appActionDelete (onDelete)="deleteTask(task);" ></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </app-generic-sidebar>
  <div [ngClass]="{'full-footer': !isPageSidebarOpen}" class="footer-buttons">
    <div class="wrapper flex border-0">
<!--      <div class="spot-button">-->
<!--        -->
<!--      </div>-->
      <button (click)="backTab()" [disabled]="currentTab == 1" class="spot-button previous spot-secondary-button">
        <img alt="" src="../../../../assets/icons/button-back.svg" width="9">
        <span>
              {{ 'add-operation.back' | translate }}
      </span>
      </button>
      <div>
        <button (click)="goToOperationDetails()" class="spot-button spot-danger-button-gray">{{ 'add-operation.cancel' | translate }}</button>
        <button (click)="save()" [disabled]="!form.valid"
                class="save spot-button spot-primary-button">{{ 'add-operation.save' | translate }}</button>
      </div>
      <button (click)="nextTab()" [disabled]="currentTab == tabs.length"
              class="next spot-button spot-secondary-button">
      <span>
         {{ 'add-operation.next' | translate }}
      </span>
        <img alt="" src="../../../../assets/icons/button-next.svg" width="9">
      </button>
    </div>
  </div>
</div>
<ng-template #sectionInfo>
  <div class="main tab-info">
    <div class="wrapper">
      <h1 *ngIf="getActiveTabItem()">{{getActiveTabItem().text}}</h1>
      <span *ngIf="getActiveTabItem()">{{getActiveTabItem().helperText}}</span>
    </div>
  </div>
</ng-template>
