import {Injectable} from '@angular/core';
import {HttpClientService} from '../../shared/services/http-client.service';
import {Observable, Subject} from 'rxjs';
import {AdvancedActivityService} from "./advanced-activity.service";
import {format} from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  public refreshNotifications = new Subject<any>();


  constructor(private httpClient: HttpClientService, private activityAdvanced: AdvancedActivityService) {
  }

  public getNotificationsList(): Observable<any> {
    this.activityAdvanced.searchModel.advancedsearch.show = this.activityAdvanced.searchModel.show;
    if (this.activityAdvanced?.searchModel?.advancedsearch?.date) {
      this.activityAdvanced?.searchModel?.advancedsearch?.date._d ?
        this.activityAdvanced.searchModel.advancedsearch.date = format(new Date(this.activityAdvanced?.searchModel?.advancedsearch?.date._d), 'yyyy-MM-dd') :
        this.activityAdvanced.searchModel.advancedsearch.date = format(new Date(this.activityAdvanced?.searchModel?.advancedsearch?.date), 'yyyy-MM-dd');
    }
    return this.httpClient.post('/notification/index', this.activityAdvanced.searchModel);
  }

  public getUnreadNotificationsList(): Observable<any> {
    let params = {};
    return this.httpClient.post('/notification/getunreadnotifications', params);
  }

  public readAllNotifications() {
    return this.httpClient.post('/notification/markallunreadnotifications');
  }

  public readOneNotification(id) {
    const params = {
      notification_id: id
    };
    return this.httpClient.post('/notification/marknotificationread', params);
  }
}
