import { Injectable } from '@angular/core';
import { DataService } from './services/data.service';
import { UtilService } from './services/util.service';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlYukan extends MatPaginatorIntl {
	selectedLanguage: any = '';
    itemsPerPageLabel = '';
    public translations: any = [];
	
    constructor() {    	
    	super();
    	this.selectedLanguage = UtilService.instance.getSelectedLanguage();
    	this.getLanguage();
    }

    getLanguage() {
        DataService.instance.getLanguage(this.selectedLanguage)
        .subscribe(
            (data) => {
                this.translations = data;
                this.translations = this.translations[0];
                this.itemsPerPageLabel = this.translations['itemsperpage'];
            }
        );
    }
}