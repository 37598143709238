import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivityRoutingModule} from './activity-routing.module';
import { ActivityDialogComponent } from './components/activity-dialog/activity-dialog.component';
import { ActivityMonitoringListComponent } from './components/activity-monitoring-list/activity-monitoring-list.component';
import { ActivitySearchComponent } from './components/activity-search/activity-search.component';


@NgModule({
  declarations: [ActivityDialogComponent, ActivityMonitoringListComponent, ActivitySearchComponent],
  exports: [
    ActivityDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ActivityRoutingModule
  ]
})
export class ActivityModule {
}
