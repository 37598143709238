import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appOrdering]'
})
export class OrderingDirective implements OnInit {

  @Output() public onOrdering: EventEmitter<void> = new EventEmitter();

  constructor(
    private _el: ElementRef, private _renderer: Renderer2
  ) {
    this._renderer.addClass(this._el.nativeElement, 'seigos-ordering');
  }

  ngOnInit()
  {
    let span = this._renderer.createElement("span");
    let asc = this._renderer.createElement("img");
    asc.setAttribute('width', 8);
    asc.setAttribute('src', '/assets/icons/sort-table.svg');
    this._renderer.addClass(asc, 'asc');
    let desc = this._renderer.createElement("img");
    desc.setAttribute('width', 8);
    desc.setAttribute('src', '/assets/icons/sort-table.svg');
    this._renderer.addClass(desc, 'desc');
    this._renderer.appendChild(span, asc);
    this._renderer.appendChild(span, desc);
    this._renderer.appendChild(this._el.nativeElement, span);

    // <img alt="" class="asc" src="{{ cdn_url }}/assets/icons/sort-table.svg" width="8">
  }

  @HostListener('click', ['$event'])
  public onClick($event: Event)
  {
    this.onOrdering.emit();
    $event.stopPropagation()
  }

}
