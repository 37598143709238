import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numeric'
})
export class NumericPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return value;
    }
    const numeric = Number.parseInt(value);
    if (Number.isNaN(numeric)) {
      return numeric;
    }
    else {
      return numeric.toLocaleString();
    }
  }

}
