<div class="pagination">
  <span class="pages-count">{{ 'global.pagination.items-per-page' | translate }}</span>
  <span class="pages-count" (click)="changeLimitPage(1)" [ngClass]="{'active' : tableService.data[table_id]?.meta.limit === 50 }">50</span>
  <span class="pages-count" (click)="changeLimitPage(2)" [ngClass]="{'active' : tableService.data[table_id]?.meta.limit === 100 }">100</span>
  <span class="pages-count" (click)="changeLimitPage(3)" [ngClass]="{'active' : tableService.data[table_id]?.meta.limit === 500 }">500</span>

  <div class="right-pagination">
        <span class="mobile-none" *ngIf="tableService.data[table_id]?.meta">
            {{ tableService.data[table_id]?.meta.limit * (tableService.data[table_id]?.meta.page-1) + 1 }} - {{ getMaxElementsInPage() }}
          {{ 'global.pagination.of' | translate }}
          {{ tableService.data[table_id]?.meta.total }}
          {{ 'global.pagination.elements' | translate }}
        </span>
    <span class="mobile-none" *ngIf="!tableService.data[table_id]?.meta">
      0 - 0 {{ 'global.pagination.of' | translate }} 0 {{ 'global.pagination.elements' | translate }}
    </span>
    <img
         alt="errow-right" (click)="previousPage()" [ngClass]="{ 'disable': tableService.data[table_id]?.meta.page === 1 }" class="previous-page" src="/assets/icons/errow-right.svg"
         width="35">
    <img alt="errow-right" [ngClass]="{ 'disable': tableService.data[table_id]?.meta.page >= getTotalPage() }" (click)="nextPage()" src="/assets/icons/errow-right.svg" width="35">
  </div>
</div>
