<div class="all">
  <app-generic-sidebar
    (itemClicked)="setPageTab($event)"
    [activeItem]="getActiveTabItem()"
    [buttonItems]="pageSidebarButtons"
    [isOpen]="isPageSidebarOpen"
    [linkItems]="pageSidebarLinks"
  >
    <ng-template #content>
      <div class="wrapper">
        <div class="progress-span">
          {{'operation-details.progress-bar' | translate}}
        </div>
        <div class="operation-stepper">
          <div *ngFor="let step of stepper; let i = index; let l=last" [class]="step" [ngClass]="{'no-line': l}"
               class="step-content">
            <span class="check-step">
                <img *ngIf="step === 'green'" alt="check-step" src="{{ cdn_url }}/assets/icons/check-step-purple.svg"
                     width="34">
                <img *ngIf="step === 'red'" alt="" height="34" src="{{ cdn_url }}/assets/icons/red-step.svg" width="35">
                <span>{{i + 1}}</span>
            </span>
            <span *ngIf="!l" class="step-line"></span>
          </div>
        </div>
        <div class="steps-text">
          <div class="caption" *ngFor="let phase of phases | async">{{ phase.name }}</div>
        </div>
      </div>
      <div *ngIf="isDataLoaded" [ngClass]="{'hid': currentTab !== 1}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <form [formGroup]="form">
            <div class="wrapper">
              <h5 class="sub-heading">
                <img alt="" src="../../../../assets/icons/general-informations.svg"
                     width="22"> {{ 'add-operation.general' | translate }}
              </h5>
              <div [ngClass]="{'flex-2': clientUsers?.length > 1}" class="flex">
                <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('name')}">{{ 'add-operation.designation' | translate }}
            *</span>
                  <input [(ngModel)]="operationDetails.name" [ngClass]="{'dirty-form': isControlInvalid('name')}"
                         formControlName="name"
                         matInput
                         placeholder="{{ 'add-operation.enter-name' | translate }}"
                         type="text">
                  <span *ngIf="isControlInvalid('name')" class="dirty-label">
                     {{ 'add-operation.field-required' | translate }}
                    </span>
                </div>
                <div *ngIf="clientUsers?.length > 1">
          <span
            [ngClass]="{'dirty-label': isControlInvalid('customer')}">{{ 'add-operation.project-management' | translate }}
            *</span>
                  <mat-select [(ngModel)]="operationDetails.client"
                              [ngClass]="{'dirty-form': isControlInvalid('customer')}"
                              formControlName="customer"
                              placeholder="{{ 'add-operation.client-name-helptext' | translate }}">
                    <mat-option *ngFor="let item of clientUsers" [value]="item.id">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                  <span *ngIf="isControlInvalid('customer')" class="dirty-label">
                      {{ 'add-operation.field-required' | translate }}
                    </span>
                </div>
              </div>
              <div class="flex flex-3">
                <div>
                  <span>{{ 'operation-details.visibility' | translate }}</span>
                  <mat-select [(ngModel)]="operationDetails.visibility"
                              formControlName="visibility"
                              placeholder="{{ 'operation-details.visibility-help-text' | translate }}">
                    <mat-option *ngFor="let item of operationvisibility.values()" [value]="operationvisibility[item]">
                      {{ item }}
                    </mat-option>
                  </mat-select>
                </div>
                <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('location')}">{{ 'operation-details.locality' | translate }}
            *</span>
                  <mat-select [(ngModel)]="operationDetails.location"
                              [ngClass]="{'dirty-form': isControlInvalid('location')}" formControlName="location"
                              placeholder="{{ 'operation-details.locality-help' | translate }}">
                    <mat-option *ngFor="let item of cities" [value]="item.name">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                  <span *ngIf="isControlInvalid('location')" class="dirty-label">
                        {{ 'add-operation.field-required' | translate }}
                    </span>
                </div>
                <div>
                  <span>{{ 'operation-details.address' | translate }}</span>
                  <input [(ngModel)]="operationDetails.address" formControlName="address" matInput
                         placeholder="{{ 'operation-details.address-help' | translate }}"
                         type="text">
                </div>
              </div>
              <div class="flex">
                <span>{{ 'operation-details.description' | translate }}</span>
                <textarea [(ngModel)]="operationDetails.description"
                          cols="30"
                          formControlName="description" id=""
                          placeholder="{{ 'operation-details.description-help' | translate }}"
                          rows="10"></textarea>
              </div>
              <div class="flex flex-3">
                <div>
          <span
            [ngClass]="{'dirty-label': isControlInvalid('gen_type_work')}">{{ 'operation-details.type-of-work' | translate }}
            *</span>
                  <mat-select [(ngModel)]="operationDetails.gen_type_work"
                              [ngClass]="{'dirty-form': isControlInvalid('gen_type_work')}"
                              formControlName="gen_type_work"
                              placeholder="{{ 'operation-details.type-of-work-help' | translate }}">
                    <mat-option *ngFor="let item of workType.values()" [value]="workType[item]">
                      {{ item }}
                    </mat-option>
                  </mat-select>
                  <span *ngIf="isControlInvalid('gen_type_work')" class="dirty-label">
                          {{ 'add-operation.field-required' | translate }}
                      </span>
                </div>
                <div>
          <span [ngClass]="{'dirty-label': isControlInvalid('sector')}">{{ 'operation-details.sector' | translate }}</span>
                  <mat-select [(ngModel)]="operationDetails.sector"
                              [ngClass]="{'dirty-form': isControlInvalid('sector')}" formControlName="sector"
                              placeholder="{{ 'operation-details.sector-help' | translate }}">
                    <mat-option *ngFor="let item of sectors.values()" [value]="sectors[item]">
                      {{ item }}
                    </mat-option>
                  </mat-select>
<!--                  <span *ngIf="isControlInvalid('sector')" class="dirty-label">-->
<!--                          {{ 'add-operation.field-required' | translate }}-->
<!--                      </span>-->
                </div>
                <div>
            <span [ngClass]="{'dirty-label': isControlInvalid('status')}">{{ 'operation-details.status' | translate }}
              </span>
                  <mat-select [ngClass]="{'dirty-form': isControlInvalid('status')}"
                              disabled
                              formControlName="status"
                              placeholder="{{ 'operation-details.status-help' | translate }}">
                    <mat-option *ngFor="let item of status.values()">
                      {{ item }}
                    </mat-option>
                  </mat-select>
                  <span *ngIf="isControlInvalid('status')" class="dirty-label">
                          {{ 'add-operation.field-required' | translate }}
                      </span>
                </div>
              </div>
              <div *ngIf="operationDetails.sector == 'LOG'" class="row row-edit">
                <div class="col-lg-2 col-edit">
                  <span>No log</span>
                  <input [(ngModel)]="operationDetails.gen_number_houses" formControlName="gen_number_houses" matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>LLTS</span>
                  <input (input)="calculateHousingLog(operationDetails)" [(ngModel)]="operationDetails.gen_LLTS"
                         formControlName="gen_LLTS"
                         matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>LLS</span>
                  <input (input)="calculateHousingLog(operationDetails)" [(ngModel)]="operationDetails.gen_LLS"
                         formControlName="gen_LLS"
                         matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>PLS</span>
                  <input (input)="calculateHousingLog(operationDetails)" [(ngModel)]="operationDetails.gen_PLS"
                         formControlName="gen_PLS"
                         matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>PTZ</span>
                  <input (input)="calculateHousingLog(operationDetails)" [(ngModel)]="operationDetails.gen_PTZ"
                         formControlName="gen_PTZ"
                         matInput
                         type="number">
                </div>
                <div class="col-lg-2 col-edit">
                  <span>Autre</span>
                  <input (input)="calculateHousingLog(operationDetails)" [(ngModel)]="operationDetails.gen_other"
                         formControlName="gen_other"
                         matInput
                         type="number">
                </div>
              </div>
            </div>

            <div class="main">
              <div class="wrapper">
                <h5 class="sub-heading">
                  <img alt="" src="../../../../assets/icons/asossiated-operations.svg"
                       width="20">
                  <span *ngIf="!operationService.isSpotProject; else relOperationsHeader">
            {{ 'operation-details.operations-and-market' | translate }}
            </span>
                  <ng-template #relOperationsHeader>
              <span>
                {{ 'operation-details.operations-related' | translate }}
              </span>
                  </ng-template>
                </h5>
                <div class="flex flex-2-2" id="linked-operations">
                  <div>
                    <span>{{ 'operation-details.linked-operations' | translate }}</span>
                    <ng-multiselect-dropdown [(ngModel)]="operationDetails.linked_operations"
                                             [data]="operations$ | async"
                                             [settings]="multipleDropdownSettings"
                                             formControlName="linked_operations"
                                             placeholder="{{ 'operation-details.linked-operations-help' | translate }}">
                    </ng-multiselect-dropdown>
                  </div>
                  <div *ngIf="!operationService.isSpotProject">
              <span [ngClass]="{'dirty-label': isControlInvalid('type_marche')}">
                {{ 'operation-list.market-type' | translate }}*</span>
                    <mat-select [(ngModel)]="operationDetails.type_marche"
                                [ngClass]="{'dirty-form': isControlInvalid('type_marche')}"
                                formControlName="type_marche"
                                placeholder=" {{ 'operation-list.market-type-help' | translate }}">
                      <mat-option *ngFor="let item of marketType.values()" [value]="marketType[item]">
                        {{ item }}
                      </mat-option>
                    </mat-select>
                    <span *ngIf="isControlInvalid('type_marche')" class="dirty-label">
                          {{ 'add-operation.field-required' | translate }}
                      </span>
                  </div>
                </div>
                <div class="flex">
                  <ng-container *ngFor="let operation of operationDetails.linked_operations; let i = index">
                    <div class="flex-2-around">
                      <div class="operation-name">
                        {{operation.name}}
                      </div>
                      <div class="remove-op">
                  <span (click)="removeOperation(i)"
                        class="remove-button">{{ 'add-operation.remove' | translate }}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--Intellectual services-->
      <div *ngIf="isDataLoaded" [ngClass]="{'hid': currentTab !== 2}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Architect competition-->
              <img alt="" src="../../../../assets/icons/arcitectural-competition.svg" width="22">
              {{ 'operation-details.architect-competition' | translate }}
            </h5>
            <div class="flex">
              <span>{{ 'operation-details.pi-ca-ao-date' | translate }}</span>
            </div>
            <div class="flex flex-2">
              <div>
                <input (click)="pi_ca_ao_date.open()" [(ngModel)]="operationDetails.pi_ca_ao_date"
                       [matDatepicker]="pi_ca_ao_date"
                       matInput placeholder="{{ 'operation-details.pi-ca-ao-date-help' | translate }}">
                <mat-datepicker #pi_ca_ao_date></mat-datepicker>
              </div>
              <div>
                <mat-select [(ngModel)]="operationDetails.pi_ca_ao_date_reelle"
                            [disabled]="!this.operationDetails.pi_ca_ao_date"
                            [ngClass]="{'disabled-form' : !this.operationDetails.pi_ca_ao_date}"
                            name="pi-ca-ao-date-reelle"
                            placeholder="{{ 'operation-details.pi-ca-ao-date-reelle-help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-ca-source' | translate }}</span>
              <div>
                <input [(ngModel)]="operationDetails.pi_ca_source" matInput name="pi_ca_source"
                       placeholder="{{ 'operation-details.pi-ca-source-help' | translate }}"
                       type="text">
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.ca_date_selection' | translate }}</span>
            </div>
            <div class="flex flex-2">
              <div>
                <input (click)="pi_ca_selection_date.open()" [(ngModel)]="operationDetails.pi_ca_selection_date"
                       [matDatepicker]="pi_ca_selection_date"
                       matInput placeholder="{{ 'operation-details.pi-ca-selection-date-help' | translate }}">
                <mat-datepicker #pi_ca_selection_date></mat-datepicker>
              </div>
              <div>
                <mat-select [(ngModel)]="operationDetails.pi_ca_selection_date_reelle"
                            [disabled]="!this.operationDetails.pi_ca_selection_date"
                            [ngClass]="{'disabled-form' : !this.operationDetails.pi_ca_selection_date}"
                            name="pi_ca_selection_date_reelle"
                            placeholder="{{ 'operation-details.pi-ca-selection-date-reelle-help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-ca-prestataire' | translate }}</span>
              <div>
                <input [(ngModel)]="operationDetails.pi_ca_prestataire" matInput name="pi_ca_source"
                       placeholder="{{ 'operation-details.pi-ca-prestataire-help' | translate }}"
                       type="text">
              </div>
            </div>
          </div>
        </div>


        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Subject mastery - Maître d'oeuvre-->
              <img alt="" src="../../../../assets/icons/subject-mastery.svg" width="18">
              {{ 'operation-details.subject-mastery' | translate }}
            </h5>
            <div class="flex">
              <span>{{ 'operation-details.pi-mo-ao-date' | translate }}</span>
            </div>
            <div class="flex flex-2">
              <div>
                <input (click)="pi_mo_ao_date.open()" [(ngModel)]="operationDetails.pi_mo_ao_date"
                       [matDatepicker]="pi_mo_ao_date"
                       matInput placeholder="{{ 'operation-details.pi-mo-ao-date-help' | translate }}">
                <mat-datepicker #pi_mo_ao_date></mat-datepicker>
              </div>
              <div>
                <mat-select [(ngModel)]="operationDetails.pi_mo_ao_date_reelle"
                            [disabled]="!this.operationDetails.pi_mo_ao_date"
                            [ngClass]="{'disabled-form' : !this.operationDetails.pi_mo_ao_date}"
                            name="pi_mo_ao_date_reelle"
                            placeholder="{{ 'operation-details.pi-mo-ao-date-reelle-help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-mo-source' | translate }}</span>
              <div>
                <input [(ngModel)]="operationDetails.pi_mo_source" matInput name="pi_mo_source"
                       placeholder="{{ 'operation-details.pi-ca-source-help' | translate }}"
                       type="text">
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-mo-selection-date' | translate }}</span>
            </div>
            <div class="flex flex-2">
              <div>
                <input (click)="pi_mo_selection_date.open()" [(ngModel)]="operationDetails.pi_mo_selection_date"
                       [matDatepicker]="pi_mo_selection_date"
                       matInput placeholder="{{ 'operation-details.pi-mo-selection-date-help' | translate }}">
                <mat-datepicker #pi_mo_selection_date></mat-datepicker>
              </div>
              <div>
                <mat-select [(ngModel)]="operationDetails.pi_mo_selection_date_reelle"
                            [disabled]="!this.operationDetails.pi_mo_selection_date"
                            [ngClass]="{'disabled-form' : !this.operationDetails.pi_mo_selection_date}"
                            name="pi_mo_selection_date_reelle"
                            placeholder="{{ 'operation-details.pi-mo-selection-date-reelle-help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.pi-mo-prestataire' | translate }}</span>
              <div>
                <input [(ngModel)]="operationDetails.pi_mo_prestataire" matInput name="pi_mo_prestataire"
                       placeholder="{{ 'operation-details.pi-mo-prestataire-help' | translate }}"
                       type="text">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Operational execution-->
      <div *ngIf="isDataLoaded" [ngClass]="{'hid': currentTab !== 3}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Call for tenders - Appel d'offres-->
              <img alt="" src="../../../../assets/icons/call-for-tenders.svg" width="22">
              {{ 'operation-details.call-for-tenders' | translate }}
            </h5>
            <div class="flex flex-3">
              <div>
                <span>{{ 'operation-details.mi_ao_travaux_date' | translate }}</span>
                <input (click)="mi_ao_travaux_date.open()" [(ngModel)]="operationDetails.mi_ao_travaux_date"
                       [matDatepicker]="mi_ao_travaux_date"
                       matInput placeholder="{{ 'operation-details.mi_ao_travaux_date-help' | translate }}">
                <mat-datepicker #mi_ao_travaux_date></mat-datepicker>
              </div>
              <div>
                <span></span>
                <mat-select [(ngModel)]="operationDetails.mi_ao_travaux_date_reelle"
                            [disabled]="!this.operationDetails.mi_ao_travaux_date"
                            [ngClass]="{'disabled-form' : !this.operationDetails.mi_ao_travaux_date}"
                            name="mi_ao_travaux_date_reelle"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
              <div>
                <span>{{ 'operation-details.procedure' | translate }}</span>
                <!-- TODO: Need to define procedure dropdown list-->
                <mat-select [(ngModel)]="operationDetails.procedure"
                            name="procedure"
                            placeholder="{{ 'operation-details.procedure-help' | translate }}">
                  <mat-option *ngFor="let item of procedureList.values()" [value]="procedureList[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.mi_ao_source' | translate }}</span>
              <div>
                <input [(ngModel)]="operationDetails.source_operational_execution" matInput name="mi_ao_source"
                       placeholder="{{ 'operation-details.mi_ao_source-help' | translate }}"
                       type="text">
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.mi_ao_deadline_date' | translate }}</span>
              <input (click)="mi_ao_deadline_date.open()" [(ngModel)]="operationDetails.mi_ao_deadline_date"
                     [matDatepicker]="mi_ao_deadline_date"
                     matInput placeholder="{{ 'operation-details.mi_ao_deadline_date-help' | translate }}">
              <mat-datepicker #mi_ao_deadline_date></mat-datepicker>
            </div>
          </div>
        </div>
        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Market result-->
              <img alt="" src="../../../../assets/icons/market-result.svg" width="22">
              {{ 'operation-details.market-result' | translate }}
            </h5>
            <div class="flex flex-3">
              <div>
                <span>{{ 'operation-details.mi_resultat_marche_date' | translate }}</span>
                <input (click)="mi_resultat_marche_date.open()" [(ngModel)]="operationDetails.mi_resultat_marche_date"
                       [matDatepicker]="mi_resultat_marche_date"
                       matInput placeholder="{{ 'operation-details.mi_resultat_marche_date_help' | translate }}">
                <mat-datepicker #mi_resultat_marche_date></mat-datepicker>
              </div>
              <div>
                <span> </span>
                <mat-select [(ngModel)]="operationDetails.mi_resultat_marche_date_reelle"
                            [disabled]="!this.operationDetails.mi_resultat_marche_date"
                            [ngClass]="{'disabled-form' : !this.operationDetails.mi_resultat_marche_date}"
                            name="mi_resultat_marche_date_reelle"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
              <div>
                <span>{{ 'operation-details.mi_resultat_marche_amount' | translate }}</span>
                <input [(ngModel)]="operationDetails.mi_resultat_marche_montant" [disabled]="housing.length>0"
                       matInput name="mi_resultat_marche_montant"
                       placeholder="{{ 'operation-details.mi_resultat_marche_amount_help' | translate }}" suffix=" €"
                       type="text">
              </div>
            </div>
            <div class="flex">
              <span>{{ 'operation-details.overspend' | translate }}</span>
              <input [(ngModel)]="operationDetails.overspend" [disabled]="housing.length>0"
                     matInput name="overspend"
                     placeholder="{{ 'operation-details.overspend_help' | translate }}" suffix=" €"
                     type="text">
            </div>
            <div class="flex">
              <span>{{ 'operation-details.mi_resultat_marche_source' | translate }}</span>
              <div>
                <input [(ngModel)]="operationDetails.source_mi_resultat" matInput
                       name="{{ 'operation-details.mi_resultat_marche_source' | translate }}"
                       placeholder="{{ 'operation-details.mi_resultat_marche_source_help' | translate }}"
                       type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper add-wrapper">
            <div class="flex-headings-2">
              <h5 class="sub-heading">
                <img alt="" src="../../../../assets/icons/housing.svg"
                     width="22">{{ 'operation-details.housing' | translate }}
              </h5>
              <span (click)="addHousing()" *ngIf="housing.length>0"
                    class="green sub-heading-link">+ {{ 'housing.add-housing' | translate }}</span>
            </div>


            <span *ngIf="housing.length === 0">{{ 'housing.add-housing-long' | translate }}
              <span (click)="addHousing()" class="green">{{ 'housing.add-housing-long-help' | translate }}</span>
            </span>

            <div *ngIf="housing.length !== 0" class="flex flex-2-3 list">
              <ng-container *ngFor="let house of housing; let i = index">
                <div class="left align-flex-start">
                  <img alt="" src="{{ cdn_url }}/assets/icons/housing.svg" width="24">
                  <div class="inline full-flex">
                    <div class="p3">
                      <span>{{house?.name || '-'}}</span>
                    </div>
                    <div class="p-gray">
                      <span class="p-gray">{{ getApeCode(house.fk_codeAPE)}}</span>
                    </div>
                  </div>
                </div>
                <div class="align-right">
                  <div class="flex align-end">
                    <div>
                      <div class="p3">
                        <span>{{house?.company_name || '-'}}</span>
                      </div>
                      <div class="flex price-block">
                        <p class="p3">{{house?.price.toLocaleString() || '-'}} €</p>
                      </div>
                    </div>
                    <div class="flex-icon">
                      <img (click)="editHousing(house, i)" alt="" src="../../../../assets/icons/edit.svg" width="19">
                    </div>
                    <div class="flex-icon">
                      <img (click)="removeHousing(i)" alt="" src="../../../../assets/icons/delete.svg" width="14">
                    </div>
                  </div>
                </div>
                <hr class="col-xs-12 divider">
              </ng-container>
            </div>
            <div *ngIf="isAddHousingProcess"
                 [@inOutAnimation]>
              <app-add-housing-inline *ngIf="codes" [codes]="codes"></app-add-housing-inline>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Word starting - Ordre de service-->
              <img alt="" src="../../../../assets/icons/class-service.svg" width="22">
              {{ 'operation-details.mi_ordre_service' | translate }}
            </h5>
            <div class="flex flex-2">
              <div>
                <span>{{ 'operation-details.mi_ordre_service_date' | translate }}</span>
                <input (click)="mi_ordre_service_date.open()" [(ngModel)]="operationDetails.mi_ordre_service_date"
                       [matDatepicker]="mi_ordre_service_date"
                       matInput placeholder="{{ 'operation-details.mi_ordre_service_date_help' | translate }}">
                <mat-datepicker #mi_ordre_service_date></mat-datepicker>
              </div>
              <div>
                <span> </span>
                <mat-select [(ngModel)]="operationDetails.mi_ordre_service_date_reelle"
                            [disabled]="!this.operationDetails.mi_ordre_service_date"
                            [ngClass]="{'disabled-form' : !this.operationDetails.mi_ordre_service_date}"
                            name="mi_ordre_service_date_reelle"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <!--Reception-->
              <img alt="" src="../../../../assets/icons/reception.svg" width="22">
              {{ 'operation-details.mi_reception' | translate }}
            </h5>
            <div class="flex flex-2">
              <div>
                <span>{{ 'operation-details.mi_reception_date' | translate }}</span>
                <input (click)="mi_reception_date.open()" [(ngModel)]="operationDetails.mi_livraison_date"
                       [matDatepicker]="mi_reception_date"
                       matInput placeholder="{{ 'operation-details.mi_reception_date_help' | translate }}">
                <mat-datepicker #mi_reception_date></mat-datepicker>
              </div>
              <div>
                <span> </span>
                <mat-select [(ngModel)]="operationDetails.mi_livraison_date_reelle"
                            [disabled]="!this.operationDetails.mi_livraison_date"
                            [ngClass]="{'disabled-form' : !this.operationDetails.mi_livraison_date}"
                            name="mi_reception_date_reelle"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isDataLoaded" [ngClass]="{'hid': currentTab !== 4}" class="overflow">
        <ng-container *ngTemplateOutlet="sectionInfo"></ng-container>
        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <img alt="" src="../../../../assets/icons/building-permit.svg"
                   width="22"> {{ 'operation-details.build-permission' | translate }}
            </h5>
            <div class="flex flex-2">
              <div>
                <span>{{ 'operation-details.building-permission-filling-date' | translate }}</span>
                <input (click)="pi_ca_selection_datee.open()" [(ngModel)]="operationDetails.instr_pc_date_depot"
                       [matDatepicker]="pi_ca_selection_datee"
                       matInput
                       placeholder="{{ 'operation-details.building-permission-filling-date-help' | translate }}">
                <mat-datepicker #pi_ca_selection_datee></mat-datepicker>
              </div>
              <div>
                <span>{{ 'operation-details.date-type' | translate }}</span>
                <mat-select [(ngModel)]="operationDetails.instr_pc_date_depot_reelle"
                            [disabled]="!this.operationDetails.instr_pc_date_depot"
                            [ngClass]="{'disabled-form' : !this.operationDetails.instr_pc_date_depot}"
                            name="date_type"
                            placeholder="{{ 'operation-details.date_reelle_help' | translate }}">
                  <mat-option *ngFor="let item of statusDate.values()" [value]="statusDate[item]">
                    {{ item }}
                  </mat-option>
                </mat-select>

              </div>
              <div>
                <span>{{ 'operation-details.building-permission-date-attribution' | translate }}</span>
                <input (click)="date_attribution.open()" [(ngModel)]="operationDetails.instr_pc_date_attribution"
                       [matDatepicker]="date_attribution"
                       matInput placeholder="{{ 'operation-details.mi_resultat_marche_date_help' | translate }}">
                <mat-datepicker #date_attribution></mat-datepicker>
              </div>
              <div>
                <span>{{ 'operation-details.building-permission-number' | translate }}</span>
                <input [(ngModel)]="operationDetails.instr_pc_numero" matInput
                       placeholder="{{ 'operation-details.building-permission-number-help' | translate }}"
                       type="number">
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <div class="flex-headings-2">
              <h5 class="sub-heading">
                <img alt="" src="../../../../assets/icons/funding.svg"
                     width="22">{{ 'operation-details.funding' | translate }}
              </h5>
              <span (click)="addFunding()" *ngIf="funding.length>0"
                    class="green sub-heading-link">+ {{ 'funding.add' | translate }}</span>
            </div>

            <span *ngIf="funding.length === 0">{{ 'funding.add-funding-long' | translate }}
              <span (click)="addFunding()" class="green">{{ 'housing.add-housing-long-help' | translate }}</span>
            </span>
            <div *ngIf="funding.length !== 0" class="flex flex-table-7 list">
              <div class="col-1">
                <span>№</span>
              </div>
              <div class="col-2">
                <span>{{ 'funding.funder' | translate }}</span>
              </div>
              <div>
                <span>{{ 'funding.type' | translate }}</span>
              </div>
              <div>
                <span>{{ 'funding.filling-date' | translate }}</span>
              </div>
              <div class="col-5">
                <span>{{ 'funding.grant-date' | translate }}</span>
              </div>
              <div>
                <span>{{ 'funding.amount' | translate }}</span>
              </div>
              <div class="col-7">
                <span>{{ 'funding.actions' | translate }}</span>
              </div>
              <hr class="col-xs-12 divider">
              <ng-container *ngFor="let fund of funding; let i = index">
                <div class="col-1">
                  <span class="p1">{{i + 1}}</span>
                </div>
                <div class="col-2">
                  <span class="p1">{{fund.financial_organisation}}</span>
                </div>
                <div>
                  <span class="p1">{{fund.type}}</span>
                </div>
                <div>
                  <span class="p1">{{fund.submission_date }}</span>
                </div>
                <div class="col-5">
                  <span class="p1">{{fund.awarding_date}}</span>
                </div>
                <div>
                  <span class="p1">{{fund.amount | currency : 'EUR'}}</span>
                </div>
                <div class="col-7">
                  <div class="flex-icon">
                    <img (click)="editFunding(fund, i)" alt="" src="../../../../assets/icons/edit.svg" width="19">
                  </div>
                  <div class="flex-icon">
                    <img (click)="removeFunding(i)" alt="" src="../../../../assets/icons/delete.svg" width="14">
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <div class="flex-headings-2">
              <h5 class="sub-heading">
                <img alt="" src="../../../../assets/icons/upload.svg"
                     width="22">{{ 'operation-details.attachements' | translate }}
              </h5>
              <span (click)="addFile()" *ngIf="files.length>0"
                    class="green sub-heading-link">{{ 'file.add' | translate }}</span>
            </div>
            <span *ngIf="files.length === 0">{{ 'file.add-file-long' | translate }}
              <span (click)="addFile()" class="green">{{ 'housing.add-housing-long-help' | translate }}</span>
            </span>
            <div class="flex ">
              <ng-container *ngFor="let file of files; let i = index">
                <div class="flex-4">
                  <div class="col-1">
                    <img alt="" src="../../../../assets/icons/attachment.svg" width="19">
                  </div>
                  <div class="col-2">
                    <span (click)="operationService.loadFile(file?.name)" class="file-name">{{file.name}}</span>
                    <span class="file-description">{{file.description}}</span>
                  </div>
                  <div class="col-3">
                    <span class="p1">2020-10-26</span>
                  </div>
                  <div class="col-4">
                    <div class="flex-icon">
                      <img (click)="removeFile(file, i)" alt="" src="../../../../assets/icons/delete.svg" width="14">
                    </div>
                  </div>
                </div>
                <hr class="col-xs-12 divider">
              </ng-container>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <img alt="" src="../../../../assets/icons/georeferencing.svg"
                   width="22">{{ 'operation-details.georeference' | translate }}
            </h5>

            <div class="flex flex-2">
              <div>
                <span>{{ 'operation-details.gps-coordinates' | translate }}</span>
                <input [(ngModel)]="operationDetails.gen_geolocation" matInput
                       placeholder="{{ 'operation-details.gps-coordinates-help' | translate }}"
                       type="text">
              </div>
              <div>
                <span>{{ 'operation-details.sig-source' | translate }}</span>
                <input [(ngModel)]="operationDetails.source_sig" matInput
                       placeholder="{{ 'operation-details.sig-source-help' | translate }}"
                       type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="wrapper">
            <h5 class="sub-heading">
              <img alt="" src="../../../../assets/icons/user-access.svg"
                   width="22">{{ 'add-operation.user-access' | translate }}
            </h5>
            <div class="flex">
              <div>
                <span>{{ 'add-operation.user-access' | translate }}</span>
                <mat-select [(ngModel)]="operationDetails.permision_client" multiple
                            placeholder="{{ 'add-operation.user-access-help' | translate }}">
                  <mat-option *ngFor="let item of accessUsers" [value]="item.id">
                    {{ item.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="flex">
              <ng-container *ngFor="let client of operationDetails.permision_client; let i = index">
                <div class="flex-2-around">
                  <div class="operation-name">
                    {{getClientName(client)}}
                  </div>
                  <div class="remove-op">
              <span (click)="removeClientAccess(i)"
                    class="remove-button">{{ 'add-operation.remove' | translate }}</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </app-generic-sidebar>
  <div [ngClass]="{'full-footer': !isPageSidebarOpen}" class="footer-buttons">
    <div class="wrapper flex border-0">
      <button (click)="backTab()" [disabled]="currentTab == 1" class="spot-button previous spot-secondary-button">
        <img alt="" src="../../../../assets/icons/button-back.svg" width="9">
        <span>
        {{ 'add-operation.back' | translate }}
      </span>
      </button>
      <div>
        <button (click)="redirectTo('/operations')"
                class="spot-button spot-danger-button-gray">{{ 'add-operation.cancel' | translate }}</button>
        <button (click)="save()" [disabled]="!form.valid"
                class="save spot-button spot-primary-button">{{ 'add-operation.save' | translate }}</button>
      </div>
      <button (click)="nextTab()" [disabled]="currentTab == tabs.length"
              class="next spot-button spot-secondary-button">
      <span>
              {{ 'add-operation.next' | translate }}
      </span>
        <img alt="" src="../../../../assets/icons/button-next.svg" width="9">
      </button>
    </div>
  </div>
</div>
<ng-template #sectionInfo>
  <div class="main tab-info">
    <div class="wrapper">
      <h1>{{getActiveTabItem().text}}</h1>
      <span>{{getActiveTabItem().helperText}}</span>
    </div>
  </div>
</ng-template>
