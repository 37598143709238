import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddObservationComponent} from './components/add-observation/add-observation.component';
import {EditObservationComponent} from './components/edit-observation/edit-observation.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {ObservationService} from './services/observation.service';
import {ObservationsMainRoutingModule} from './observations-main-routing.module';


@NgModule({
  declarations: [AddObservationComponent, EditObservationComponent],
  imports: [
    CommonModule,
    ObservationsMainRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [ObservationService],
  exports: [AddObservationComponent, EditObservationComponent]
})
export class ObservationsMainModule {
}
