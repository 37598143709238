<div class="generic-modal-header">
  <div>
    <img src="/assets/icons/{{iconName}}" width="32">
    <span>{{headerText}}</span>
  </div>
  <img (click)="closeEmit()" class="right" src="../../../../assets/icons/close.svg" width="18">
</div>
<ng-content></ng-content>
<div class="generic-modal-buttons buttons-block">
  <div (click)="closeEmit()" class="spot-button spot-cancel-button">
    <span class="cancel">{{'housing.cancel' | translate}}</span>
  </div>
  <div (click)="doneEmit()" [ngClass]="{'disabled': isCalled}" class="spot-button spot-primary-button">
    <span>{{'housing.save' | translate}}</span>
  </div>

</div>

