import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as constant from '../../../app.constant';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit, AfterViewInit {
  @Input() public class: string[];
  @Input() public isOpen: boolean;
  @Input() public isHeightOverflow: boolean;

  @ViewChild('panel') panel: ElementRef;
  // panelElement: ElementRef;

  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    if (this.isOpen) {
      Promise.resolve().then(() => this.panel.nativeElement.classList.add('open'));

    }
  }
}
