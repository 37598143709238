import {Component, OnDestroy, OnInit} from '@angular/core';
import {UpdateOperationsService} from '../../services/update-operations.service';
import {UploadMessage} from '../../models/upload-message';
import {Subject} from 'rxjs/internal/Subject';
import {concat} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-operation-list',
  templateUrl: './update-operation-list.component.html',
  styleUrls: ['./update-operation-list.component.scss']
})
export class UpdateOperationListComponent implements OnInit, OnDestroy {
  public selecting: boolean[] = [
    false,
    false,
    false,
    false
  ];
  public messages: UploadMessage[] = [
    {
      message: 'Oyaroni 2 (ex Fatima), 30 log.',
      datetime: '12/02/2021',
      step: '0',
      title: 'Building works for the construction of 30 housing units of the LLS type, "Oyaroni 2 (ex Fatima)" '
    },
    {
      message: 'Taxiways, bringing thresholds up to standard',
      datetime: '12/02/2021',
      step: '0',
      title: 'TRACK development work and upgrading of thresholds'
    },
    {
      message: 'Oyaroni 2 (ex Fatima), 30 log.',
      datetime: '12/02/2021',
      step: '0',
      title: 'Building works for the construction of 30 housing units of the LLS type, "Oyaroni 2 (ex Fatima)" '
    },
    {
      message: 'Photovoltaic plant, Mana',
      datetime: '12/02/2021',
      step: '0',
      title: 'TRACK development work and upgrading of thresholds'
    },
    {
      message: 'Taxiways, bringing thresholds up to standard',
      datetime: '12/02/2021',
      step: '0',
      title: 'Building works for the construction of 30 housing units of the LLS type, "Oyaroni 2 (ex Fatima)" '
    },
    {
      message: 'Photovoltaic plant, Mana',
      datetime: '12/02/2021',
      step: '0',
      title: 'TRACK development work and upgrading of thresholds'
    },
    {
      message: 'Oyaroni 2 (ex Fatima), 30 log.',
      datetime: '12/02/2021',
      step: '0',
      title: 'Building works for the construction of 30 housing units of the LLS type, "Oyaroni 2 (ex Fatima)" '
    },
    {
      message: 'Taxiways, bringing thresholds up to standard',
      datetime: '12/02/2021',
      step: '0',
      title: 'TRACK development work and upgrading of thresholds'
    },
    {
      message: 'Taxiways, bringing thresholds up to standard',
      datetime: '12/02/2021',
      step: '0',
      title: 'TRACK development work and upgrading of thresholds'
    },
    {
      message: 'Oyaroni 2 (ex Fatima), 30 log.',
      datetime: '12/02/2021',
      step: '0',
      title: 'Building works for the construction of 30 housing units of the LLS type, "Oyaroni 2 (ex Fatima)" '
    },
    {
      message: 'Taxiways, bringing thresholds up to standard',
      datetime: '12/02/2021',
      step: '0',
      title: 'Building works for the construction of 30 housing units of the LLS type, "Oyaroni 2 (ex Fatima)" '
    }
  ];
  public response: any;
  private unsubscribe$ = new Subject<void>();


  constructor(private updateOperationsService: UpdateOperationsService,
    public translateService : TranslateService
  ) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  public cancel(): void {
    this.updateOperationsService.close.next();
  }

  openAccordion(event: boolean, index: number) {
    this.selecting[index] = event;
  }

  public update() {
    this.callApi();
  }

  private initialize() {
    // this.callApi();
  }

  private callApi() {
    concat(
      this.updateOperationsService.getLastAnnouncements(),
      this.updateOperationsService.mergeGroupedAnnouncements(),
      this.updateOperationsService.copyOperationsFromBoampToSpot(),
      this.updateOperationsService.calculateOperationTotalAmount())
      .subscribe(value => {
        // console.log(value);
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
