import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { ENCRYPTION_KEY } from '../../app.constant';
import { UserRoles } from '../enums/user-roles';

@Injectable()
export class AuthService {
    private userSubject: BehaviorSubject<string>;

    constructor(private router: Router) {
        this.userSubject = new BehaviorSubject<string>(this.getLocalStorage('role'));
    }

    get currentUserRoleObservable(): Observable<string> {
        return this.userSubject.asObservable();
    }

    public getUserFullName(): string {
        return (this.getLocalStorage('name') + ' ' + this.getLocalStorage('surname'));
    }

    public getUserInitials(): string {
        return (this.getLocalStorage('name').charAt(0) + this.getLocalStorage('surname').charAt(0)).toUpperCase();
    }

    public isUserEnterprise(): boolean {
        return this.getLocalStorage("role") === UserRoles.Enterprise;
    }

    public isUserClient(): boolean {
        return this.getLocalStorage("role") === UserRoles.Client;
    }

    public isUserAdmin(): boolean {
        return this.getLocalStorage("role") === UserRoles.Admin;
    }

    public setLocalStorage(name: string, value) {
        if (typeof value === "string") {
            localStorage.setItem(name, CryptoJS.AES.encrypt(value, ENCRYPTION_KEY).toString());
        } else {
            localStorage.setItem(name, value);
        }
    }

    public getLocalStorage(name) {
        let item = localStorage.getItem(name);
        if (item == null) {
            this.router.navigate(['/login']);
            return
        }
        if (this.decrypt(item) === "") {
            this.router.navigate(['/login']);
        }
        return this.decrypt(item);
    }

    public decrypt(item): string {
        return CryptoJS.AES.decrypt(item, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    }

    public isTokenValid(): boolean {
        return localStorage.getItem('token') ? true : false
    }
}
