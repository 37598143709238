import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {
  @Input() headerText: string;
  @Input() iconName: string;
  @Input() isCalled: boolean = true;
  @Output() close = new EventEmitter<void>();
  @Output() done = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  closeEmit() {
    this.close.emit();
  }

  doneEmit() {
    this.done.emit();
  }
}
