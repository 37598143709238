import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivityService} from '../../services/activity.service';
import {Subject} from 'rxjs';
import * as constant from '../../../app.constant';
import {ActivityModel} from '../../models/activity-model';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss']
})
export class ActivityDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() notifications: ActivityModel[];
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  public api_url: string = constant.ACTIVE_ENVIRONMENT.api_url;


  constructor(private activityService: ActivityService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  readNotification(notification) {
    if (!notification?.id) {
      return;
    }
    this.activityService.readOneNotification(notification.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.activityService.refreshNotifications.next();
      });
  }

}
