import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {HttpClientService} from "../../shared/services/http-client.service";

@Injectable({
  providedIn: 'root'
})
export class UpdateOperationsService {
  public close = new Subject<void>();

  constructor(private httpClient: HttpClientService) {
  }

  public getLastAnnouncements(): Observable<any> {
    return this.httpClient.post('/boamp/getLastAnnouncements');
  }

  public mergeGroupedAnnouncements(): Observable<any> {
    return this.httpClient.post('/boamp/mergeGroupedAnnouncements');
  }

  public copyOperationsFromBoampToSpot(): Observable<any> {
    return this.httpClient.post('/boamp/copyOperationsFromBoampToSpot');
  }

  public calculateOperationTotalAmount(): Observable<any> {
    return this.httpClient.post('/boamp/calculateOperationTotalAmount');
  }

  public getMessages(): Observable<any> {

    return this.httpClient.post('/boamp/completeTransfert');
  }

}
