import {AfterContentInit, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgxUiLoaderService} from "ngx-ui-loader";
import {Title} from "@angular/platform-browser";
import {default_lang, IS_SPOT_PROJECT, langs} from './app.constant';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentInit {
  constructor(private translate: TranslateService, private ngxService: NgxUiLoaderService,
              private tabTitle: Title, private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((url: NavigationEnd) => {
        if (url.url == '/') {
          this.router.navigate(['dashboard']);
        }
      });


    if (langs.includes(localStorage.getItem('language'))) {
      translate.setDefaultLang(localStorage.getItem('language'))
    } else {
      translate.setDefaultLang(default_lang);
    }

    this.ngxService.start();

    if (IS_SPOT_PROJECT) {
      this.tabTitle.setTitle('Spot');
    } else {
      this.tabTitle.setTitle('Seigos');
    }
  }

  title = 'spot';

  ngAfterContentInit() {
    this.ngxService.stop();
  }
}
