import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'frenchNumber'
})
export class FrenchNumberPipe implements PipeTransform {

  transform(value: string, ...args: number[]): unknown {
    if (!value) {
      return value;
    }
    let res;
    if (value.length < 7) {
      res = this.frenchFormat(value);
    } else {
      res = this.frenchFormat(value);
    }


    return res;
  }

  private frenchFormat(number) {
    return Intl.NumberFormat('fr-FR', {useGrouping: true}).format(parseFloat(number));
  }
}
