import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTr]'
})
export class TrDirective {

  constructor(
    private _el: ElementRef, private _renderer: Renderer2
  ) {
    this._renderer.addClass(this._el.nativeElement, 'seigos-tr');
  }

}
