import {Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {Observable, Subject} from 'rxjs';
import {AdvancedSearchService} from '../../../operation/service/advanced-search-service';
import {OperationVisibility} from '../../enums/operation-visibility';
import {Sector} from '../../enums/sector';
import {Status} from '../../enums/status';
import {StatusDate} from '../../enums/status-date';
import {Subsector} from '../../enums/subsector';
import {Vigilance} from '../../enums/vigilance';
import {Visibility} from '../../enums/visibility';
import {CityModel} from '../../models/city.model';
import {UserModel} from '../../models/user.model';
import {CityService} from '../../services/city.service';
import {UserService} from '../../services/user.service';
import * as constant from '../../../app.constant';
import {IS_SPOT_PROJECT} from '../../../app.constant';
import {AuthService} from '../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {MarketType} from '../../enums/market-type';
import {TypeTender} from '../../enums/type-tender';
import {AppStateService} from "../../services/app-state.service";
import {ActivitySearchComponent} from "../../../activity/components/activity-search/activity-search.component";
import {Category, EnterprisesService} from "../../../enterprises/services/enterprises.service";

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  public clientUsers$: Observable<UserModel[]>;
  public cities$: Observable<CityModel[]>;
  public categories$: Observable<Category[]>;
  public subCategories$: Observable<Category[]>;
  public sectors = Sector;
  public status = Status;
  public vigilance = Vigilance;
  public subsector = Subsector;
  public operationvisibility = OperationVisibility;
  public visibility = Visibility;
  public statusDate = StatusDate;
  public marketTypes = MarketType;
  public typeTender = TypeTender;
  public isSpotProject = constant.IS_SPOT_PROJECT;
  public search: any = {};
  public dropdownSettings: IDropdownSettings = {};
  private unsubscribe$ = new Subject<void>();

  constructor(private userService: UserService,
              private cityService: CityService,
              public dialog: MatDialog,
              private advancedSearchService: AdvancedSearchService,
              public authService: AuthService,
              public enterprisesService: EnterprisesService,
              private router: Router,
              private translate: TranslateService,
              public appState: AppStateService) {
  }

  ngOnInit(): void {
    //todo sector and subsector
    //todo remove valider from status if user is not admin
    this.configureDropdownSettings();
    this.getClientUsers();
    this.getCityList();
    this.getCategories();
    if (this.appState.activeSearch === AdvancedSearchComponent) {
      this.advancedSearchService.setup();
      if (this.advancedSearchService.searchModel?.advancedsearch) {
        this.search = this.advancedSearchService.searchModel?.advancedsearch;
      } else {
        this.advancedSearchService.searchModel.advancedsearch = {};
        this.search = this.advancedSearchService.searchModel?.advancedsearch;
      }
      // this.search = this.advancedSearchService.searchModel?.advancedsearch ?? {};
    } else if (this.appState.activeSearch === ActivitySearchComponent) {

    }

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public closeDialog(): void {
    this.dialog.closeAll();
  }

  public goToStatistic() {
    // this.advancedSearchService.applyFilter$.emit();
    this.updateList(this.advancedSearchService.searchModel.advancedsearch.locations, 'location');
    this.updateList(this.advancedSearchService.searchModel.advancedsearch.customers, 'customer');
    this.setTenderType();
    this.appState.applyFilters$.emit();
    // this.advancedSearchService.applyAdvancedSearch$.emit();
    this.appState.applySearch$.emit();
    this.router.navigateByUrl('/statistics');
    this.closeDialog();
  }

  public filterOperations() {
    this.updateList(this.advancedSearchService.searchModel.advancedsearch.locations, 'location');
    this.updateList(this.advancedSearchService.searchModel.advancedsearch.customers, 'customer');
    this.updateList(this.advancedSearchService.searchModel.advancedsearch.categories, 'category');
    this.updateList(this.advancedSearchService.searchModel.advancedsearch.subcategory, 'subcategory');
    this.setTenderType();
    this.advancedSearchService.searchModel.operationType = null;
    this.emitFilter();
    this.closeDialog();
  }

  public resetFilter(): void {
    this.advancedSearchService.searchModel.advancedsearch = {};
    this.advancedSearchService.searchModel.search = null;
    this.emitFilter();
    this.closeDialog();
    // this.advancedSearchService.closeAdvancedSearch$.emit();
    this.appState.closeSearch$.emit();
  }

  private configureDropdownSettings(): void {
    this.translate.get(['common.select-all', 'common.unselect-all', 'common.search']).subscribe(value => {
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: value['common.select-all'],
        unSelectAllText: value['common.unselect-all'],
        searchPlaceholderText: value['common.search'],
        itemsShowLimit: 1,
        maxHeight: 100,
        allowSearchFilter: true
      };
    });
  }

  private getClientUsers() {
    this.clientUsers$ = this.userService.getClientUsers(true);
  }

  private getCityList() {
    this.cities$ = this.cityService.getCityList();
  }

  private getCategories(){
    this.categories$ = this.enterprisesService.getCategories();
    // this.subCategories$ = this.enterprisesService.getSubCategories();
  }
  private updateList(list: any[], prop: string) {
    if (!list || list.length === 0) {
      if (prop === 'location') {
        this.advancedSearchService.searchModel.advancedsearch.locationNames = undefined;
        this.advancedSearchService.searchModel.advancedsearch.location = undefined;
      } else if (prop === 'customer') {
        this.advancedSearchService.searchModel.advancedsearch.customerNames = undefined;
        this.advancedSearchService.searchModel.advancedsearch.customer = undefined;
      } else if (prop === 'category'){
        this.advancedSearchService.searchModel.advancedsearch.category = undefined;
        this.advancedSearchService.searchModel.advancedsearch.categoryNames = undefined;
      } else if (prop === 'subcategory'){
        this.advancedSearchService.searchModel.advancedsearch.subcategory = undefined;
        this.advancedSearchService.searchModel.advancedsearch.subcategoryNames = undefined;
      }
      return;
    }

    const ids = [];
    const names = [];
    list.forEach(key => {
      ids.push(key.id);
      names.push(key.name);
    });

    if (prop === 'location') {
      if (!IS_SPOT_PROJECT) {
        this.advancedSearchService.searchModel.advancedsearch.location = ids;
      } else {
        this.advancedSearchService.searchModel.advancedsearch.location = names;
      }
      this.advancedSearchService.searchModel.advancedsearch.locationNames = names;
    } else if (prop === 'customer') {
      this.advancedSearchService.searchModel.advancedsearch.customer = ids;
      this.advancedSearchService.searchModel.advancedsearch.customerNames = names;
    }  else if (prop === 'category'){
      this.advancedSearchService.searchModel.advancedsearch.category = ids;
      this.advancedSearchService.searchModel.advancedsearch.categoryNames = names;
    } else if (prop === 'subcategory'){
      this.advancedSearchService.searchModel.advancedsearch.subcategory = ids;
      this.advancedSearchService.searchModel.advancedsearch.subcategoryNames = names;
    }
  }

  private emitFilter(): void {
    if (!this.router.url.includes('operations')) {
      this.router.navigateByUrl('/operations').then(value => {
        // this.advancedSearchService.applyFilter$.emit();
        this.appState.applyFilters$.emit();
        this.appState.applySearch$.emit();
        // this.advancedSearchService.applyAdvancedSearch$.emit();
      });
    } else {
      // this.advancedSearchService.applyFilter$.emit();
      this.appState.applyFilters$.emit();
      this.appState.applySearch$.emit();
      // this.advancedSearchService.applyAdvancedSearch$.emit();
    }


  }

  private setTenderType() {
    if (this.search.tender) {
      if (Object.values(this.search.tender).some(r => r == this.typeTender['Concours d’architecte'])) {
        this.advancedSearchService.searchModel.advancedsearch.type_AO_Architecte = '1';
      }
      if (Object.values(this.search.tender).some(r => r == this.typeTender['Maitrise d’oeuvre'])) {
        this.advancedSearchService.searchModel.advancedsearch.type_AO_projectManager = '1';
      }
      if (Object.values(this.search.tender).some(r => r == this.typeTender['MEO AO'])) {
        this.advancedSearchService.searchModel.advancedsearch.type_AO_work = '1';
      }
      if (Object.values(this.search.tender).some(r => r == this.typeTender['MEO Résultats de marché'])) {
        this.advancedSearchService.searchModel.advancedsearch.type_AO_marketResult = '1';
      }
    }
    // delete this.search.tender;
  }

  categoryClick() {
    this.subCategories$ = this.enterprisesService.getSubCategories(this.search.categories);
  }
}
