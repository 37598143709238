import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FileService} from '../service/file-service';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogBase} from "../../shared/abstractions/dialog-base";

@Component({
  selector: 'app-edit-file',
  templateUrl: './edit-file.component.html',
  styleUrls: ['./edit-file.component.scss']
})
export class EditFileComponent extends DialogBase implements OnInit {
  public file: any = {};
  public index: number;
  public isError = false;

  constructor(public translate: TranslateService, private fileService: FileService, @Inject(MAT_DIALOG_DATA) data) {
    super();
    this.loadDataLists(data);
  }

  ngOnInit(): void {

  }

  public cancel(): void {
    this.fileService.closeFile.next();
  }

  public setError(state) {
    this.isError = state;
    if (this.isError) {
      this.clear();
    }
  }

  public clear(): void {
    this.file = {};
  }

  public saveFile(): void {
    this.isCalled = true;
    this.fileService.editFileList.next({file: this.file, index: this.index});
    this.cancel();
  }

  uploadFile(files: any) {
    this.file = null;
    this.file = files[0];
    this.setError(false);
  }

  private loadDataLists(data) {
    this.file = data.file;
    this.index = data.index;
  }
}
