export enum Sector {
  'TP (Travaux publics)' = 'TP',
  'BNR (Bâtiment non résidentiel)' = 'BNR',
  'LOG (Logement)' = 'LOG'
}

export namespace Sector {
  export function values() {
    return Object.keys(Sector).filter(
      (type) => isNaN(<any>type) && type !== 'values' && type !== 'mapToDropdown'
    );
  }

  export function mapToDropdown() {
    return Object.keys(Sector).filter(value => value !== 'values' && value !== 'mapToDropdown').map(value => {
      return {label: value, value: Sector[value]};
    });
  }
}
