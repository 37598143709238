export enum ProcedureList {
  'MAPA' = 'MAPA',
  'Bons de commande' = 'Bons de commande',
  'Ouverte' = 'Ouverte',
  'Autres' = 'Autres',
  'Restreinte' = 'Restreinte',
  'Négociée' = 'Negociee',
  'VEFA' = 'VEFA'
}

export namespace ProcedureList {
  export function values() {
    return Object.keys(ProcedureList).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }
}
