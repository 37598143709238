import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HousingModel } from '../models/housing-model';
import { HttpClientService } from 'app/shared/services/http-client.service';

@Injectable()
export class HousingService {
    public closeHousing = new Subject<void>();
    public addHousingList = new Subject<HousingModel>();
    public editHousingList = new Subject<any>();
    public sendHousing = new Subject<any>();
    public passHousing = new Subject<any>();
    public scrollViewToPanel = new Subject<any>();
    public componentInitialized = new Subject<any>();
    // public startHousingEdit = new Subject<any>();


    constructor(private httpClient: HttpClientService) {
    }

    public getHousingList(): Observable<any> {
        return this.httpClient.post('/housing/housinglist');
    }

    public getHousing(params): Observable<any> {
        return this.httpClient.post('/housing/index', params);
    }

    public getHousingDetails(params): Observable<any> {
        return this.httpClient.post('/housing/gethousing', params);
    }

    public addHousing(params): Observable<any> {
        return this.httpClient.post('/housing/addhousing', params);
    }

    public editHousing(params): Observable<any> {
        return this.httpClient.post('/housing/edithousing', params);
    }
    public deleteHousing(params): Observable<any> {
        return this.httpClient.post('/housing/delete', params);
    }
}
