export enum HeaderTitles {
  Datastream = 'datastream',
  Dashboard = 'dashboard',
  Activity = 'activity',
  Reporting = 'conflict-report',
  Operations = 'operations',
  Statistics = 'statistics',
  Vigilance = 'vigilance',
  Enterprises = 'enterprises',
  Clients = 'clients',
  UserGuide = 'guide',
  EditOperation = 'edit-operation',
  AddOperation = 'add-operation',
  UserManagement = 'users-management',
  Import = 'import',
  Enedis = 'enedis'
}
