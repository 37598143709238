<div class="wrapper">
  <app-generic-modal
    (close)="cancel()"
    (done)="addFile()"
    [headerText]="'file.title-add' | translate"
    [iconName]="'add-file.svg'"
    [isCalled]="isCalled || !data.file.name"
  >

    <div class="content-margin">
      <div (click)="fileUpload.click()" (onError)="setError($event)" (onFileDropped)="uploadFile($event)" [ngClass]="{'file-loaded': data.file.name, 'file-error' : isError}"
           class="draggable" droppable>
        <img *ngIf="!data.file.name" alt="" height="30px" src="../../../assets/icons/attach-file.svg">
        <span *ngIf="data.file.name" class="file-name">{{data.file.name}}</span>
        <label *ngIf="!data.file.name; else loaded">{{'file.choose' | translate}}</label>
        <ng-template #loaded>
          <label class="loaded-file-label">{{'file.choose-2' | translate}}</label>
        </ng-template>
        <input #fileUpload (change)="uploadFile(fileUpload.files)" [multiple]="false" id="file-upload" type="file">
      </div>
    </div>
    <div class="content-margin" *ngIf="withDesc">
      <h5>{{'file.description' | translate}}</h5>
      <textarea [(ngModel)]="data.description" class="full-width"
                name="description"
                placeholder="{{'file.description-helper' | translate}}"></textarea>
    </div>

  </app-generic-modal>
</div>
