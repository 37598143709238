<div class="cards" >
    <div [ngClass]="{'active': advancedSearchService.searchModel.operationType === card?.url }"
         class="m-card {{ card?.title }}"
         *ngFor="let card of cards" (click)="clickCard(card?.url)">
        <img src="{{cdn_url}}/assets/icons/{{ card?.icon }}.svg" alt="{{ card?.icon }}">
        <div>
            <h3>{{ card?.title }}</h3>
            <div class="conflict" *ngIf="card?.conflict">{{ 'cards.conflict' | translate }} {{ card?.conflict }}</div>
        </div>
        <div class="right">
            <h1>{{ card?.count }}</h1>
            <div class="amount">{{ card?.amount }}</div>
        </div>
    </div>
</div>



