import {Injectable, OnInit} from '@angular/core';
import { MetaPagination, TableData } from '../interfaces/table-data';
import { Observable, Subject } from 'rxjs';
import { HttpClientService } from '../../../shared/services/http-client.service';
import { CheckboxValues } from '../interfaces/checkbox-values';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  public data: { [key: string]: TableData } = {};
  public checkboxes: CheckboxValues = {};

  private opts_cache: { [key: string]: any } = {}

  public OnCheckChange: Subject<void> = new Subject();
  public onDataChange: Subject<any> = new Subject();
  public ChangePage: Subject<{ table_id: string, value: "+1" | "-1" | number }> = new Subject();
  public ChangeLimitPage: Subject<{ table_id: string, limit: number }> = new Subject();

  constructor(
    private http: HttpClientService,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.onDataChange.subscribe((v) => {
      this.setCheckboxes(v.table_id);
    })
    this.ChangePage.subscribe(({ table_id, value }) => {
      this.changePage(table_id, value);
    })
    this.ChangeLimitPage.subscribe(({ table_id, limit }) => {
      this.changeLimitPage(table_id, limit);
      this.setData(table_id, this.opts_cache[table_id])
    })
  }

  initData(table_id)
  {
    if (!this.data.hasOwnProperty(table_id)) {
      this.data[table_id] = {
        rows: [],
        meta: {
          limit: 50,
          page: 1,
          total: 0,
          total_page: 1
        }
      };
    }
  }

  setData(table_id: string, opts) {
    this.initData(table_id);
    this.opts_cache[table_id] = opts;

    this.ngxLoader.startLoader("table_"+table_id);
    this.http.post('/Table/getData', {
      model: table_id,
      meta: this.data[table_id].meta,
      opts
    }).subscribe((response) => {
      if (response.success) {
        this.data[table_id].rows = response.data;
        this.data[table_id].meta = response.meta;

        this.setCheckboxes(table_id);
        this.onDataChange.next({ table_id, data: this.data[table_id].rows })
        this.ngxLoader.stopLoader("table_"+table_id);
      }
    })
  }

  setCheckboxes(table_id)
  {
    if (!this.checkboxes.hasOwnProperty(table_id)) {
      this.checkboxes[table_id] = [];
    }

    this.checkboxes[table_id] = this.data[table_id].rows.map((t) => ({ id: t.id, value: false }));
    this.OnCheckChange.next();

    // let tmp = this.data[table_id].rows
    //
    // for (let checkbox of this.checkboxes[table_id]) {
    //    tmp = tmp.filter((v) => v.id !== checkbox.id)
    // }
    //
    // this.checkboxes[table_id] = tmp.map((t) => ({ id: t.id, value: false }));
  }

  private changePage(table_id: string, value: "+1" | "-1" | number)
  {
    if (typeof value == "number") {
      this.data[table_id].meta.page = value;
    } else if (value === "+1") {
      this.data[table_id].meta.page += 1;
    } else if (value === "-1") {
      this.data[table_id].meta.page -= 1;
    }
  }

  private changeLimitPage(table_id: string, limit: number)
  {
    console.log("changeLimitPage", table_id, limit)
    this.data[table_id].meta.limit = limit;
  }

  public getSum(table_id: string, field: string): number
  {
    let sum = 0
    this.data[table_id].rows.forEach((r) => {
      sum += Number.parseFloat(r[field]);
    })
    return sum;
  }

}
