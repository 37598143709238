<div class="accordion-item" [ngClass]="class" accordion [accordionItem]="panel" [errowBottomItem]="errowBottom" [errowRightItem]="errowRight">
    <ng-content select=".title-item"></ng-content>
    <div class="icon">
        <img class="errow-bottom" #errowBottom src="{{ cdn_url }}/assets/icons/errow-right.svg" alt="errow-bottom" width="10px">
        <img #errowRight src="{{ cdn_url }}/assets/icons/errow-bottom.svg" alt="errow-right" width="16px">
    </div>
</div>
<div [ngClass]="{'height-overflow': panel.classList.contains('open') && isHeightOverflow}"  #panel class="panel-item">
    <ng-content select=".panel-content"></ng-content>
</div>
