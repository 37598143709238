import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HousingRoutingModule } from './housing-routing.module';
import { AddHousingComponent } from './components/add-housing/add-housing.component';
import { HousingService } from './services/housing-service';
import { EditHousingComponent } from './components/edit-housing/edit-housing.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import { AddHousingInlineComponent } from './components/add-housing-inline/add-housing-inline.component';

@NgModule({
  declarations: [AddHousingComponent, EditHousingComponent, AddHousingInlineComponent],
    imports: [
        CommonModule,
        HousingRoutingModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ],
  providers: [HousingService],
    exports: [AddHousingComponent, EditHousingComponent, AddHousingInlineComponent]
})
export class HousingModule { }
