export class OperationSearchModel {
  public operationname?: string;
  public operationid?: string;
  public vigilance?: string[];
  public customer?: string[];
  public customerNames?: string[];
  public customers?: any[];
  public location?: string[];
  public locationNames?: string[];
  public locations?: any[];
  public show?: number;
  public estimated_cost?: string;
  public status?: string[];
  public sector?: string[];
  public subsector?: string[];
  public category?: string[];
  public categories?: string[];
  public categoryNames?: string[];
  public subcategory?: string[];
  public subcategoryNames?: string[];
  public visibility?: string;
  public operationvisibility?: any;
  public mi_ao_travaux_date_from?: any;
  public mi_ao_travaux_date_to?: any;
  public mi_ao_travaux_date_reelle?: string;
  public mi_ordre_service_date_from?: any;
  public mi_ordre_service_date_to?: any;
  public mi_ordre_service_date_reelle?: string;
  public mi_resultat_marche_date_from?: any;
  public mi_resultat_marche_date_to?: any;
  public mi_resultat_marche_date_reelle?: string;
  public mi_livraison_date_from?: any;
  public mi_livraison_date_to?: any;
  public mi_livraison_date_reelle?: string;
  public mi_resultat_marche_montant_from?: string;
  public mi_resultat_marche_montant_to?: string;
  public has_observation?: boolean;
  public has_housing?: boolean;
  public housing_name?: string;
  public housing_company_name?: string;
  public housing_price?: string;
  public housing_date_attribution?: any;
  public type_marche?: string;
  public tender?: any;
  public type_AO_Architecte?: string;
  public type_AO_projectManager?: string;
  public type_AO_work?: string;
  public type_AO_marketResult?: string;
}

