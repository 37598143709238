import {forkJoin, merge, Observable, of, Subject} from "rxjs";
import * as constant from "../../app.constant";
import {UserModel} from "../../shared/models/user.model";
import {CityModel} from "../../shared/models/city.model";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {Sector} from "../../shared/enums/sector";
import {OperationStatus} from "../../shared/enums/status";
import {Subsector} from "../../shared/enums/subsector";
import {OperationVisibility} from "../../shared/enums/operation-visibility";
import {Visibility} from "../../shared/enums/visibility";
import {StatusDateCreating} from "../../shared/enums/status-date";
import {ProcedureList} from "../../shared/enums/procedureList";
import {EditMarketType} from "../../shared/enums/market-type";
import {WorkType} from "../../shared/enums/work-type";
import {DropdownModel} from "../../shared/models/dropdown-model";
import {HousingModel} from "../../housing/models/housing-model";
import {FundingModel} from "../../funding/models/funding-model";
import {ApeCodeModel} from "../model/ape-code-model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../shared/services/user.service";
import {OperationService} from "../service/operation.service";
import {CityService} from "../../shared/services/city.service";
import {HousingService} from "../../housing/services/housing-service";
import {FundingService} from "../../funding/service/funding-service";
import {FileService} from "../../file-upload/service/file-service";
import { MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../shared/services/auth.service";
import {Router} from "@angular/router";
import {takeUntil, tap} from "rxjs/operators";
import {AddFundingComponent} from "../../funding/add-funding/add-funding.component";
import {EditFundingComponent} from "../../funding/edit-funding/edit-funding.component";
import {AddFileComponent} from "../../file-upload/add-file/add-file.component";
import {EditFileComponent} from "../../file-upload/edit-file/edit-file.component";
import {GenericSidebarLinkItem} from "../../shared/models/generic-sidebar/generic-sidebar-link-item";
import {GenericSidebarButtonItem} from "../../shared/models/generic-sidebar/generic-sidebar-button-item";
import {GenericSidebarItem} from "../../shared/models/generic-sidebar/generic-sidebar-item";
import {AppStateService} from "../../shared/services/app-state.service";
import {HeaderTitles} from "../../shared/enums/header-titles";
import {ValidationService} from "../../validation/services/validation.service";
import {SettingsService} from "../../settings/services/settings.service";
import {PhaseObjectModel} from "../../settings/models/phase-object-model";

/**
 * Base class for Add and Edit component.
 * This class contains all shared properties and functions
 */
export abstract class BaseOperationManagement {
  public tabs = [];
  public stepper = ['green', 'gray', 'gray', 'gray', 'gray'];
  public isDataLoaded = false;
  public operationDetails: any = {};
  protected unsubscribe$ = new Subject<void>();
  public cdn_url: string = constant.ACTIVE_ENVIRONMENT.cdn_url;
  public clientUsers: UserModel[] = [];
  public clientUsers$: Observable<UserModel[]>;
  public cities: CityModel[];
  public dropdownSettings: IDropdownSettings = {};
  public multipleDropdownSettings: IDropdownSettings = {};
  public sectors = Sector;
  public status = OperationStatus;
  public subsector = Subsector;
  public operationvisibility = OperationVisibility;
  public visibility = Visibility;
  public statusDate = StatusDateCreating;
  public procedureList = ProcedureList;
  public marketType = EditMarketType;
  public workType = WorkType;
  public operations$: Observable<DropdownModel[]>;
  public currentTab = 1;
  public housing: HousingModel[] = [];
  public funding: FundingModel[] = [];
  public accessUsers: any[] = [];
  public codes: ApeCodeModel[] = [];
  public files: any[] = [];
  public operationToken: string;
  public form: UntypedFormGroup;
  protected nonWhitespaceRegExp: RegExp = new RegExp('\\S');
  public isAddHousingProcess = false;
  protected tabsCount: number;
  public abstract isHousingDisable;
  // new
  public pageSidebarLinks: GenericSidebarLinkItem[] = [];
  public pageSidebarButtons: GenericSidebarButtonItem[] = [];
  public isPageSidebarOpen = true;
  public triggerOnChanges = 0;
  public phases: Observable<PhaseObjectModel[]>

  protected constructor(
    protected translate: TranslateService,
    protected userService: UserService,
    public operationService: OperationService,
    protected cityService: CityService,
    protected housingService: HousingService,
    protected fundingService: FundingService,
    protected fileService: FileService,
    protected dialog: MatDialog,
    public authService: AuthService,
    protected router: Router,
    protected fb: UntypedFormBuilder,
    public appState: AppStateService,
    protected settingsService: SettingsService,
    tabsCount: number
  ) {
    this.tabsCount = tabsCount;

    this.settingsService.selectedTable = "Phase";
    this.phases = this.settingsService.getData()
  }

  /**
   * Abstract function to save the operation
   */
  public abstract save();

  /* ----- PAGE INITIALIZATION BEGIN ----- */
  /**
   * Initialize page Form, Tabs, DropDowns
   * @protected
   */
  protected initPageComponents(isEdit = false) {
    this.initTabs(isEdit);
    this.initForm();
    this.configureDropdownSettings();
    this.appState.toggleMainSidebar$.next(false);
    if (isEdit) {
      this.appState.setHeaderText(HeaderTitles.EditOperation);
      return;
    }
    this.appState.setHeaderText(HeaderTitles.AddOperation);

  }

  /**
   * Initialize first-tab form to provide controls validation
   * @protected
   */
  protected initForm() {
    this.form = this.fb.group({
      name: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      visibility: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      location: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      address: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      description: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      gen_type_work: [null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]],
      sector: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      status: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      linked_operations: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      gen_number_houses: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      gen_LLTS: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      gen_LLS: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      gen_PLS: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      gen_PTZ: [null, [Validators.pattern(this.nonWhitespaceRegExp)]],
      gen_other: [null, [Validators.pattern(this.nonWhitespaceRegExp)]]
    });

    if (this.authService.isUserClient()) {
      this.form.addControl('customer', this.fb.control(null, [Validators.pattern(this.nonWhitespaceRegExp)]));
    } else {
      this.form.addControl('customer', this.fb.control(null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]));
    }

    if (!this.operationService.isSpotProject) {
      this.form.addControl('type_marche', this.fb.control(null, [Validators.required, Validators.pattern(this.nonWhitespaceRegExp)]));
    }
  }

  /**
   * Initialize top tabs
   * @param isAll - is for adding Observation and Vigilance tabs
   * @protected
   */
  protected initTabs(isAll): void {
    this.translate.get(
      ['add-operation.general', 'add-operation.intellectual-services',
        'add-operation.operational-implementation', 'add-operation.further-information',
        'operation-details.vigilance', 'operation-details.observations',
        'add-operation.step-general', 'add-operation.step-services', 'add-operation.step-implementation', 'add-operation.step-information',
        'add-operation.step-vigilance', 'add-operation.step-observations',
        'operation-details.validation', 'add-operation.step-validation',
        'operation-details.tasks', 'add-operation.step-tasks']).subscribe(value => {
      this.tabs = [
        {
          id: 1, name: value['add-operation.general'],
          info: value['add-operation.step-general']
        },
        {
          id: 2, name: value['add-operation.intellectual-services'],
          info: value['add-operation.step-services']
        },
        {
          id: 3, name: value['add-operation.operational-implementation'],
          info: value['add-operation.step-implementation']
        },
        {
          id: 4, name: value['add-operation.further-information'],
          info: value['add-operation.step-information']
        }];
      this.phases.subscribe((phases) => {
        let i = 1;
        for (let phase of phases) {
          this.pageSidebarLinks.push({
            index: i,
            text: phase.name,
            helperText: "" //phase.description
          })
          i++;
        }
      })
      // const sidebarLinkGeneral: GenericSidebarLinkItem = {
      //   index: 1,
      //   text: value['add-operation.general'],
      //   helperText: value['add-operation.step-general']
      // };
      // const sidebarLinkIntellectual: GenericSidebarLinkItem = {
      //   index: 2,
      //   text: value['add-operation.intellectual-services'],
      //   helperText: value['add-operation.step-services']
      // };
      // const sidebarLinkImplementation: GenericSidebarLinkItem = {
      //   index: 3,
      //   text: value['add-operation.operational-implementation'],
      //   helperText: value['add-operation.step-implementation']
      // };
      // const sidebarLinkFurtherInfo: GenericSidebarLinkItem = {
      //   index: 4,
      //   text: value['add-operation.further-information'],
      //   helperText: value['add-operation.step-information']
      // };
      // this.pageSidebarLinks = [
      //   sidebarLinkGeneral,
      //   sidebarLinkIntellectual,
      //   sidebarLinkImplementation,
      //   sidebarLinkFurtherInfo
      // ];
      this.appState.toggleOperationsAddEditSidebar$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
        this.isPageSidebarOpen = !this.isPageSidebarOpen;
      });
      if (!isAll) {
        return;
      }
      this.tabs.push(
        {
          id: 5,
          name: value['operation-details.vigilance'],
          info: value['add-operation.step-vigilance']
        },
        {
          id: 6,
          name: value['operation-details.observations'],
          info: value['add-operation.step-observations']
        }
      );
      const sidebarButtonObservations: GenericSidebarLinkItem = {
        index: 5,
        text: value['operation-details.vigilance'],
        helperText: value['add-operation.step-vigilance']
      };
      const sidebarButtonVigilance: GenericSidebarLinkItem = {
        index: 6,
        text: value['operation-details.observations'],
        helperText: value['add-operation.step-observations']
      };
      const sidebarButtonValidation: GenericSidebarLinkItem = {
        index: 7,
        text: value['operation-details.validation'],
        helperText: value['add-operation.step-validation']
      };
      const sidebarButtonTask: GenericSidebarLinkItem = {
        index: 8,
        text: value['operation-details.tasks'],
        helperText: value['add-operation.step-tasks']
      };
      this.pageSidebarButtons.push(
        sidebarButtonObservations,
        sidebarButtonVigilance
      );

      if (this.authService.isUserAdmin()) {
        this.pageSidebarButtons.push(sidebarButtonValidation);
        this.pageSidebarButtons.push(sidebarButtonTask);
      }
    });


  }

  public getActiveTabItem(): GenericSidebarItem {
    let item: GenericSidebarItem = this.pageSidebarLinks.find(el => el.index === this.currentTab);
    if (item) {
      return item;
    }
    item = this.pageSidebarButtons.find(el => el.index === this.currentTab);
    if (item) {
      return item;
    }
    return { index: 0, text: '', helperText: '' };
  }  

  public setPageTab(event: number) {
    this.currentTab = event;
  }

  /**
   * Initialize ngx dropdown settings (singular and multiple)
   * @protected
   */
  protected configureDropdownSettings(): void {
    this.translate.get(['common.select-all', 'common.unselect-all', 'common.search']).subscribe(value => {
      this.dropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'name',
        selectAllText: value['common.select-all'],
        unSelectAllText: value['common.unselect-all'],
        searchPlaceholderText: value['common.search'],
        itemsShowLimit: 1,
        allowSearchFilter: true
      };
      this.multipleDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: value['common.select-all'],
        unSelectAllText: value['common.unselect-all'],
        searchPlaceholderText: value['common.search'],
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
    });

  }

  /**
   * Load static data for the DropDowns, Lists, etc...
   * @protected
   */
  protected loadStaticData() {
    // Get the APE codes, Client Users, Active Users, City List in parallel
    this.clientUsers$ = this.userService.getClientUsers();
    forkJoin({
        codes: this.operationService.getCodeApeList(),
        clients: this.userService.getClientUsers(),
        activeUsers: this.operationService.getActiveUsers(),
        cities: this.cityService.getCityList()
      }
    ).pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.codes = value.codes;
        this.clientUsers = value.clients;
        this.accessUsers = value.activeUsers;
        this.cities = value.cities;
        this.isDataLoaded = true;
        console.log(value)
      });
    if (constant.IS_SPOT_PROJECT) {
      this.operations$ = this.operationService.getAllOperationsList();
    } else {
      this.operations$ = of(null);

    }

  }

  /* ----- PAGE INITIALIZATION END ----- */

  /* ----- HELPERS BEGIN ----- */
  /**
   * Calculate Housing log amount on the first tab
   * @param data - operation data object
   * @public
   */
  public calculateHousingLog(data) {
    let general = 0;
    const lltsNum = Number.parseInt(data.gen_LLTS);
    const llsNum = Number.parseInt(data.gen_LLS);
    const ptzNum = Number.parseInt(data.gen_PTZ);
    const plsNum = Number.parseInt(data.gen_PLS);
    const otherNum = Number.parseInt(data.gen_other);

    if (!Number.isNaN(lltsNum)) {
      general += lltsNum;
    }
    if (!Number.isNaN(llsNum)) {
      general += llsNum;
    }
    if (!Number.isNaN(ptzNum)) {
      general += ptzNum;
    }
    if (!Number.isNaN(plsNum)) {
      general += plsNum;
    }
    if (!Number.isNaN(otherNum)) {
      general += otherNum;
    }
    data.gen_number_houses = general.toString();
    const val = !!data?.gen_LLTS
      || !!data?.gen_PTZ
      || !!data?.gen_LLS
      || !!data?.gen_PLS
      || !!data?.gen_other;
    if (val) {
      this.form.controls['gen_number_houses'].disable();
    } else {
      this.form.controls['gen_number_houses'].enable();
    }
  }

  /**
   * Redirect to the specific route
   * @param url
   */
  public redirectTo(url: string): void {
    this.router.navigateByUrl(url);
  }

  /**
   * Go to next tab
   */
  public nextTab(): void {
    if (this.currentTab === this.tabsCount) {
      return;
    }
    this.currentTab = this.currentTab + 1;
  }

  /**
   * Set tab number
   */
  public setTab(page: number): void {
    this.currentTab = page;
  }

  /**
   * Go to previous tab
   */
  public backTab(): void {
    if (this.currentTab === 1) {
      return;
    }
    this.currentTab = this.currentTab - 1;
  }

  /**
   * Transform Ape code to more beautiful view :)
   * @param id - index of this code in array
   */
  public getApeCode(id) {
    const code = this.codes.find(c => c.id == id);
    if (!code) {
      return '-';
    } else {
      return code.description + '-' + code.code;
    }
  }

  /**
   * Transform Client Name to more beautiful view :)
   * @param id - index of this client name in array
   */
  public getClientName(id) {
    const client = this.accessUsers.find(c => c.id == id);
    if (!client) {
      return '-';
    } else {
      return client.name;
    }
  }

  /**
   * Function to validate the specific control
   * @param controlName - name of the control
   */
  public isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    return control.invalid && control.touched;
  }

  public frenchFormat(number): string {
    if (!number || Number.isNaN(Number.parseInt(number))) {
      return null;
    }
    return Intl.NumberFormat('fr-FR').format(parseFloat(number)) + ' €';
  }

  /**
   * Function to disable date status options depending on the selected date
   * @param date - selected date
   * @param status - status to be disabled
   */
  public isDateStatusDisabled(date, status): boolean {
    const isRealStatus = status == this.statusDate.getValue('1');

    // if it's not a datepicker object -> parse the string date; else -> use _d property of the date object
    if (!date?._d) {
      const parsedDate = this.operationService.getObjectDate(date);
      if (!parsedDate) {
        return;
      }
      date = parsedDate;
    } else {
      date = date._d;
    }

    return this.isDateRestriction(date, isRealStatus);
  }

  /**
   * Protected function to find the future/past date restriction
   * @param date - date to be processed
   * @param isRealStatus - is selected status real
   * @protected
   */
  protected isDateRestriction(date, isRealStatus): boolean {
    // check if it's current date
    if (date.getDate() <= new Date().getDate() && date.getDate() >= new Date().getDate()) {
      return false;
    }

    // check if it's future date problem
    const isDateInFutureProblem = (date.getTime() > new Date().getTime()) && isRealStatus;
    // check if it's past date problem
    const isDateInPastProblem = !(date.getTime() > new Date().getTime()) && !isRealStatus;

    return isDateInFutureProblem || isDateInPastProblem;
  }

  /* ----- HELPERS END ----- */


  /* ----- HOUSING MODAL INITIALIZATION BEGIN ----- */
  /**
   * Event to open Add-Housing modal
   */
  public addHousing(): void {
    this.isAddHousingProcess = true;
    this.housingService.scrollViewToPanel.next();
    this.housingService.sendHousing.next({
      house: {},
      index: null,
      isEdit: false
    });
  }

  /**
   * Event to open Edit-Housing modal
   * @param house - house object which will be modified
   * @param index - index of this object in housings array
   */
  public editHousing(house, index): void {
    this.isAddHousingProcess = true;
    this.triggerOnChanges += 1;
    this.housingService.scrollViewToPanel.next();
    const subscription = this.housingService.componentInitialized
      .subscribe(value => {
        this.housingService.sendHousing.next({
          house: house,
          index: index,
          isEdit: true
        });
        subscription.unsubscribe();
      });
  }

  /**
   * Event to remove Housing from housings array
   * @param index - housing index
   * @param data - operation data object
   */
  public removeHousing(index, data = null): void {
    if (data) {
      data.housing.splice(index, 1);
      data.operationdata.mi_resultat_marche_montant = this.operationService.calculateHousingAmountWithoutOperation(data.housing);
      data.operationdata.overspend = this.operationService.calculateHousingOverspendWithoutOperation(data.housing);
    } else {
      this.housing.splice(index, 1);
      this.operationDetails.mi_resultat_marche_montant = this.operationService.calculateHousingAmountWithoutOperation(this.housing);
      this.operationDetails.overspend = this.operationService.calculateHousingOverspendWithoutOperation(this.housing);
    }
  }

  /**
   * Subscription to handle add/edit housing events
   * @protected
   */
  protected passHousing$(): Observable<any> {
    return this.housingService.passHousing
      .pipe(takeUntil(this.unsubscribe$), tap(x => {
        this.isAddHousingProcess = false;
      }));
  }

  /**
   * Close housing modal window event
   * @protected
   */
  protected closeHousingModal(): void {
    this.housingService.closeHousing
      .pipe(takeUntil(this.unsubscribe$), tap(x => {
        this.isAddHousingProcess = false;
      }))
      .subscribe((resp => {
        this.dialog.closeAll();
      }));
  }

  /* ----- HOUSING MODAL INITIALIZATION END ----- */


  /* ----- FUNDING MODAL INITIALIZATION BEGIN ----- */
  /**
   * Event to open Add-Funding modal
   */
  public addFunding(): void {
    const dialogRef = this.dialog.open(AddFundingComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      disableClose: true
    });
  }

  /**
   * Event to open Edit-Funding modal
   */
  public editFunding(fund, index): void {
    const dialogRef = this.dialog.open(EditFundingComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      disableClose: true,
      data: {
        funding: fund,
        index: index
      }
    });
  }

  /**
   * Remove Funding from list
   * @param id
   * @param data
   */
  public removeFunding(id, data = null): void {
    const array = data ?? this.funding;
    array.splice(id, 1);
  }

  /**
   * Get Add-funding observable
   * @protected
   */
  protected addFundingToList$(): Observable<any> {
    return this.fundingService.addFundingList.pipe(takeUntil(this.unsubscribe$));
  }

  /**
   * Get Edit-funding observable
   * @protected
   */
  protected editFundingInList$(): Observable<any> {
    return this.fundingService.editFundingList.pipe(takeUntil(this.unsubscribe$));
  }

  /* ----- FUNDING MODAL INITIALIZATION END ----- */


  /* ----- FILE MODAL INITIALIZATION BEGIN ----- */
  /**
   * Event to open Add-File modal
   */
  public addFile(): void {
    const dialogRef = this.dialog.open(AddFileComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      disableClose: true,
      data: {
        token: this.operationToken
      }
    });
  }

  /**
   * Event to open Edit-File modal
   * @param file - file which will be modified
   * @param index - index in array
   */
  public editFile(file, index): void {
    const dialogRef = this.dialog.open(EditFileComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      disableClose: true,
      data: {
        file: file,
        index: index
      }
    });
  }

  /**
   * Event to remove file
   * @param file - file to be removed
   * @param index - index of this file in arra
   * @param files - array with files
   */
  public removeFile(file, index, files = null): void {
    const array = files ?? this.files;
    this.fileService.deleteFile(file)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp.result == 'true') {
          array.splice(index, 1);
        }
      });
  }

  /**
   * Get Add-File observable
   * @protected
   */
  protected addFileToList$(): Observable<any> {
    return this.fileService.addFileList.pipe(takeUntil(this.unsubscribe$));
  }

  /* ----- FILE MODAL INITIALIZATION END ----- */

  /* ----- LINKED OPERATIONS BEGIN ----- */
  /**
   * Remove linked operation
   * @param id - operation index
   * @param data - linked operations array
   */
  public removeOperation(id, data = null) {
    let array = data ?? this.operationDetails.linked_operations;
    array.splice(id, 1);
    array = array.concat();
  }

  /* ----- LINKED OPERATIONS END ----- */

  /* ----- CLINT ACCESS BEGIN ----- */
  /**
   * Remove client access
   * @param id - client index
   * @param data - clients array
   */
  removeClientAccess(id, data = null) {
    let array = data ?? this.operationDetails.permision_client;
    array.splice(id, 1);
    array = array.concat();
  }

  /* ----- CLINT ACCESS END ----- */

  /**
   * Merge subscriptions to close all modal windows
   * - Close Funding
   * - Close File
   * @protected
   */
  protected initToCloseSubscriptions() {
    merge(
      this.fundingService.closeFunding,
      this.fileService.closeFile
    ).pipe(takeUntil(this.unsubscribe$)).subscribe((resp => {
      this.dialog.closeAll();
    }));
  }


}
