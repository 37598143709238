import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClientService } from '../../shared/services/http-client.service';
import { UserLoginModel } from '../models/user-login-model';

@Injectable()
export class LoginService {
    constructor(private httpClientService: HttpClientService, private router: Router) {
    }

    public logOut() {
        this.httpClientService.post('/login/logout')
        .subscribe(
            () => {
                localStorage.clear();
                this.router.navigate(['/']);
            }
        );   
    }

    public login(user: UserLoginModel): Observable<any> {
        return this.httpClientService.post('/login/user_login', user)
    }
}