<app-generic-modal
  (close)="cancel()"
  (done)="saveValidationStep()"
  [headerText]="'validation.title' | translate"
  [iconName]="'add-vigilance.svg'"
  [isCalled]="isCalled"
>

  <div class="flex flex-1" *ngIf="users">
    <div>
      <h5>{{ 'validation.user' | translate }}</h5>
      <mat-select [(ngModel)]="selected_user" class="select-form" name="id_user"
                  placeholder="{{ 'validation.user' | translate }}">
        <mat-option *ngFor="let user of users" [value]="user">
          {{ user.name }} {{ user.surname }}
        </mat-option>
      </mat-select>
    </div>
    <div>
      <h5>{{ 'validation.fonction' | translate }}</h5>
      <input [(ngModel)]="validation.name" placeholder="{{ 'validation.fonction-helper' | translate }}" type="text">
    </div>
  </div>
</app-generic-modal>
