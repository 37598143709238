import { Injectable } from '@angular/core';
import {CheckboxValues} from '../interfaces/checkbox-values';
import {Subject} from 'rxjs';
import {TableService} from './table.service';

@Injectable({
  providedIn: 'root'
})
export class CheckboxService {

  public values: CheckboxValues = {};
  public OnCheckAll: Subject<{ value: boolean, table_id: string }> = new Subject<{ value: boolean, table_id: string }>();

  constructor(
    private tableService: TableService
  ) {
    this.OnCheckAll.subscribe(this.onCheckAll.bind(this))
  }

  onCheckAll({ value, table_id })
  {
    for (let input of this.tableService.checkboxes[table_id]) {
      input.value = value;
    }
  }

}
