import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ACTIVE_ENVIRONMENT, IS_SPOT_PROJECT} from '../../../app.constant';
import {VigilanceOperations} from '../../../shared/enums/vigilance';
import {HousingModel} from '../../../housing/models/housing-model';
import {FundingModel} from '../../../funding/models/funding-model';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../shared/services/user.service';
import {OperationService} from '../../service/operation.service';
import {CityService} from '../../../shared/services/city.service';
import {HousingService} from '../../../housing/services/housing-service';
import {FundingService} from '../../../funding/service/funding-service';
import {FileService} from '../../../file-upload/service/file-service';
import { MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {OperationResultModel} from '../../model/operation-result-model';
import {format, parse} from 'date-fns';
import {AddVigilanceComponent} from '../../../vigilance-point/components/add-vigilance/add-vigilance.component';
import {EditVigilanceComponent} from '../../../vigilance-point/components/edit-vigilance/edit-vigilance.component';
import {VigilanceService} from '../../../vigilance-point/services/vigilance.service';
import {AddObservationComponent} from '../../../observations-main/components/add-observation/add-observation.component';
import {EditObservationComponent} from '../../../observations-main/components/edit-observation/edit-observation.component';
import {ObservationService} from '../../../observations-main/services/observation.service';
import {UntypedFormBuilder} from "@angular/forms";
import { MatSelectChange} from "@angular/material/select";
import {AdvancedSearchService} from "../../service/advanced-search-service";
import {AuthService} from "../../../shared/services/auth.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {BaseOperationManagement} from "../../abstraction/base-operation-management";
import {merge, Observable} from "rxjs";
import {AppStateService} from "../../../shared/services/app-state.service";
import {ValidationFormComponent} from "../../../validation/components/validation-form/validation-form.component";
import {ValidationModel} from "../../../validation/models/validation-model";
import {ValidationService} from "../../../validation/services/validation.service";
import {SettingsService} from "../../../settings/services/settings.service";
import {TaskFormComponent} from "../../../task/components/task-form/task-form.component";
import {TaskModel} from "../../../task/models/task.model";
import {TaskService} from "../../../task/services/task.service";
import {NgxUiLoaderService} from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import * as constant from '../../../app.constant';

@Component({
  selector: 'app-operation-edit',
  templateUrl: './operation-edit.component.html',
  styleUrls: ['./operation-edit.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('200ms ease-out',
              style({height: 100, opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({height: 100, opacity: 1}),
            animate('200ms ease-in',
              style({height: 100, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class OperationEditComponent extends BaseOperationManagement implements OnInit, OnDestroy {
  @ViewChild('housingPanel') housingPanel: ElementRef;

  public isOperationLoaded = false;
  public vigilance = VigilanceOperations;
  public modifiedHousing: HousingModel[] = [];
  public housingBuffer: HousingModel[] = [];
  public modifiedfunding: FundingModel[] = [];
  public operation: OperationResultModel;
  public operationStatusValue: string;
  public isChangedFields: string[] = [];
  public operationOldValues = {};
  public clientWrapper: {id: number, name: string}[] = [];
  public api_url: string = constant.ACTIVE_ENVIRONMENT.api_url;
  isHousingDisable = undefined;

  constructor(
    private vigilanceService: VigilanceService,
    private observationService: ObservationService,
    protected translate: TranslateService,
    protected userService: UserService,
    public operationService: OperationService,
    protected cityService: CityService,
    protected housingService: HousingService,
    protected fundingService: FundingService,
    protected fileService: FileService,
    protected dialog: MatDialog,
    public authService: AuthService,
    protected router: Router,
    protected fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    public appState: AppStateService,
    protected settingsService: SettingsService,
    private advancedSearchService: AdvancedSearchService,
    private validationService: ValidationService,
    private taskService: TaskService,
    private ngxLoader: NgxUiLoaderService
  ) {
    super(translate, userService, operationService,
      cityService, housingService, fundingService,
      fileService, dialog, authService, router, fb, appState, settingsService, 6);
  }

  ngOnInit(): void {
    this.route.params.subscribe(value => {
      if (value.id) {
          this.populateData(value.id);
      }
    });

    this.isHousingDisable = !!this.operation?.operationdata?.gen_LLTS
    || !!this.operation?.operationdata?.gen_PTZ
    || !!this.operation?.operationdata?.gen_LLS
    || !!this.operation?.operationdata?.gen_PLS
    || !!this.operation?.operationdata?.gen_other;
  }

  public getChangedHousingValueV2(id, field) {
    const newItem = this.operation.housing.find(value => value.housingId == id);
    if (newItem.deleted == '1') {
      return newItem[field];
    }
    let index = -1;
    if (newItem.main_housing_id) {
      index = this.housingBuffer.findIndex(value => value.housingId == newItem.main_housing_id);
    } else {
      index = this.housingBuffer.findIndex(value => value.housingId == id);
    }
    //  try to find the old item for this id
    //  if the item exist
    if (index != -1) {
      //  if the new and old field value are equal
      if (newItem[field] == this.housingBuffer[index][field]) {
        return null;
      } else {
        //  return old value
        const value = this.housingBuffer[index][field];
        if (!value) {
          return '-';
        }
        return value;
      }
    } else {
      return null;
    }
  }

  public loadVigilance(): void {
    this.vigilanceService.getVigilanceList(this.operation.operationdata.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp.result == 'true') {
          this.operation.vigilance = resp.data;
          if (this.operation.getchangevigilance) {
            this.operation.vigilance = this.operation.vigilance.concat(this.operation.getchangevigilance);
          }
        }
      });
  }

  public addVigilance(): void {
    const dialogRef = this.dialog.open(AddVigilanceComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      disableClose: true,
      data: {
        id: this.operation.operationdata.id
      }
    });
  }

  public editVigilance(vigilance, index): void {
    const dialogRef = this.dialog.open(EditVigilanceComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      disableClose: true,
      data: {
        vigilance: vigilance,
        index: index,
        id: this.operation.operationdata.id
      }
    });
  }

  public removeVigilance(id, index): void {
    this.vigilanceService.deleteVigilance(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp.result == 'true') {
          this.operation.vigilance.splice(index, 1);
        }
      });
  }

  // vigilance

  public addValidation(): void {
    const dialogRef = this.dialog.open(ValidationFormComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      // disableClose: true,
      data: {
        id: 0,
        operationId: this.operation.operationdata.id,
        validation: new ValidationModel()
      }
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val != 0) {
        let isEdit = false;
        for (let index in this.operation.validations) {
          if (this.operation.validations[index].id == val.id) {
            this.operation.validations[index] = val;
            isEdit = true;
            return;
          }
        }

        if (!isEdit) {
          this.operation.validations.push(val);
        }
      }
    })
  }

  public editValidation(validation): void {
    const dialogRef = this.dialog.open(ValidationFormComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      // disableClose: true,
      data: {
        validation: validation,
        operationId: this.operation.operationdata.id,
        id: validation.id
      }
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val != 0) {
        let isEdit = false;
        for (let index in this.operation.validations) {
          if (this.operation.validations[index].id == val.id) {
            this.operation.validations[index] = val;
            isEdit = true;
            return;
          }
        }

        if (!isEdit) {
          this.operation.validations.push(val);
        }
      }
    })
  }

  public deleteValidation(validation): void {
    this.validationService.deleteValidationStep(validation.id)
      .subscribe(resp => {
        if (resp.result == 1) {
          this.operation.validations = this.operation.validations.filter(val => val.id != validation.id);
        }
      });
  }

  // task

  public addTask(): void {
    const dialogRef = this.dialog.open(TaskFormComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      // disableClose: true,
      data: {
        id: 0,
        operationId: this.operation.operationdata.id,
        task: new TaskModel()
      }
    });

    dialogRef.afterClosed().subscribe((task) => {
      if (task != 0) {
        let isEdit = false;
        for (let index in this.operation.tasks) {
          if (this.operation.tasks[index].id == task.id) {
            this.operation.tasks[index] = task;
            isEdit = true;
            return;
          }
        }

        if (!isEdit) {
          this.operation.tasks.push(task);
        }
      }
    })
  }

  public editTask(task): void {
    const dialogRef = this.dialog.open(TaskFormComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      // disableClose: true,
      data: {
        task: task,
        operationId: this.operation.operationdata.id,
        id: task.id
      }
    });

    dialogRef.afterClosed().subscribe((task) => {
      if (task != 0) {
        let isEdit = false;
        for (let index in this.operation.tasks) {
          if (this.operation.tasks[index].id == task.id) {
            this.operation.tasks[index] = task;
            isEdit = true;
            return;
          }
        }

        if (!isEdit) {
          this.operation.tasks.push(task);
        }
      }
    })
  }

  public deleteTask(task): void {
    Swal({
      title: "Suppression d'une tâche",
      text: "Voulez-vous vraiment supprimer cette tâche ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('operation-list.cancel'),
      confirmButtonText: this.translate.instant('operation-list.ok')
    }).then((result) => {
      if (result.value) {
        this.taskService.deleteTask(task.id)
          .subscribe(resp => {
            if (resp.result == 1) {
              this.operation.tasks = this.operation.tasks.filter(t => t.id != task.id);
            }
          });
      }
    })
  }

  private task_filters = {
    order: {
      order_by: 'id',
      order_way: 'asc'
    },
    search: ""
  }
  public orderTasks(order_by)
  {
    if (this.task_filters.order.order_by == order_by) {
      this.task_filters.order.order_way = this.task_filters.order.order_way == "asc" ? "desc" : "asc";
    } else {
      this.task_filters.order.order_by = order_by
      this.task_filters.order.order_way = "asc"
    }

    this.initTasks()
  }

  public filterTasks(search)
  {
    this.task_filters.search = search;

    this.initTasks()
  }

  public initTasks()
  {
    this.ngxLoader.startLoader('op_task');
    this.taskService.getTasks(Number.parseInt(this.operation.operationdata.id), this.task_filters).subscribe((tasks) => {
      this.operation.tasks = tasks.data;
      this.ngxLoader.stopLoader('op_task');
    })
  }

  // Observations
  public loadObservations(): void {
    this.observationService.getObservationList(this.operation.operationdata.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp.result == 'true') {
          this.operation.observations = resp.data;
        }
      });
  }

  public addObservations(): void {
    const dialogRef = this.dialog.open(AddObservationComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      disableClose: true,
      data: {
        id: this.operation.operationdata.id
      }
    });
  }

  public editObservations(observation, index): void {
    const dialogRef = this.dialog.open(EditObservationComponent, {
      height: 'auto',
      maxHeight: '90vh',
      width: '650px',
      disableClose: true,
      data: {
        observation: observation,
        index: index,
        id: this.operation.operationdata.id
      }
    });
  }

  public removeObservations(id, index): void {
    this.observationService.deleteObservation(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp.success == 'true') {
          this.operation.observations.splice(index, 1);
        }
      });
  }

  goToOperationDetails() {
    let url = `/operations`;
    this.router.navigate([url], {
      queryParams: {
        operation: this.operation.operationdata.id,
        beforeLoad: 'true'
      }
    });

  }

  goToPreviousOperation() {
    let url = `/operations`;
    this.router.navigate([url + '/waiting'], {
      queryParams: {
        type: 'all',
        operation: this.operation.operationdata.id,
        beforeLoad: 'false'
      }
    });
  }

  scrollToHousingSubscription() {
    this.housingService.scrollViewToPanel.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      setTimeout(() => {
        this.housingPanel?.nativeElement?.scrollIntoView();
      }, 0);
    });
  }

  save(): void {
    if (this.operation.housing) {
      this.operation.housing.forEach(el => el.code_ape = el.fk_codeAPE);
    }
    this.operation.linked_operations = this.operation.linkedoperations;

    // we make the operation status readnonly, so we need to set the previously returned value
    this.operation.operationdata.status = this.operationStatusValue;
    // this.operation.operationdata.manual_status_selection = '1';


    this.operation.operationdata.operation_token = this.operationToken;
    this.operation.operationdata.client = this.clientWrapper[0].id.toString();
    this.operationService.parseFundingDate(this.operation.funding);
    this.convertOperationDates(this.operation.operationdata);
    this.parseGetChangedValueBack();

    if (!IS_SPOT_PROJECT) {
      this.operation.operationdata.op_location = this.cities.find(value => value.name == this.operation.operationdata.op_location)?.id;
    }
    this.operationService.editOperation(this.operation)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (this.authService.isUserClient()) {
          this.goToPreviousOperation();
        } else {
          this.goToOperationDetails();
        }
      });
  }

  public convertOperationDates(operationData) {
    operationData.pi_ca_ao_date = this.operationService.getStringDate(operationData.pi_ca_ao_date);
    operationData.pi_ca_selection_date = this.operationService.getStringDate(operationData.pi_ca_selection_date);
    operationData.pi_mo_ao_date = this.operationService.getStringDate(operationData.pi_mo_ao_date);
    operationData.pi_mo_selection_date = this.operationService.getStringDate(operationData.pi_mo_selection_date);
    operationData.mi_ao_travaux_date = this.operationService.getStringDate(operationData.mi_ao_travaux_date);
    operationData.mi_ao_deadline_date = this.operationService.getStringDate(operationData.mi_ao_deadline_date);
    operationData.mi_resultat_marche_date = this.operationService.getStringDate(operationData.mi_resultat_marche_date);
    operationData.mi_ordre_service_date = this.operationService.getStringDate(operationData.mi_ordre_service_date);
    operationData.mi_livraison_date = this.operationService.getStringDate(operationData.mi_livraison_date);
    operationData.instr_pc_date_depot = this.operationService.getStringDate(operationData.instr_pc_date_depot);
    operationData.instr_pc_date_attribution = this.operationService.getStringDate(operationData.instr_pc_date_attribution);

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // this function makes the status dropdown readonly
  selectChangeEvent(event: MatSelectChange) {
    event.source.writeValue(this.operationStatusValue);
  }

  private populateData(id): void {
    // Load operation data
    this.loadOperation(id);
    // Initialize Tabs, Form, DropDowns
    this.initPageComponents(true);
    // Initialize Modal Windows Subscriptions
    this.initModalWindowsSubscriptions();

    // this.initTasks();
  }

  /**
   * Initialize Modal Windows Subscriptions
   * @private
   */
  private initModalWindowsSubscriptions() {
    // housing add/edit
    this.passHousing$().subscribe(resp => {
      this.operationService.handleOperationHousing(resp, this.operation);
    });
    // housing close
    this.closeHousingModal();

    // funding add
    this.addFundingToList$().subscribe(resp => {
      this.operation.funding.push(resp);
    });

    // funding edit
    this.editFundingInList$().subscribe(resp => {
      this.operation.funding[resp.index] = resp.funding;
    });

    // file add
    this.addFileToList$().subscribe(value => {
      this.operation.files.push(value);
    });

    // vigiliance add/edit
    this.initVigilanceSubscriptions();

    // observation
    this.initObservationsSubscriptions();

    this.initToCloseSubscriptions();
    // vigilance/observation close
    this.initAdditionalCloseSubscriptions();
  }

  private loadOperation(id): void {
    this.operationService.getOperation(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp: any) => {
        this.operation = resp.result;
        this.operation.validations = this.operation.validations || [];
        this.clientWrapper = [];
        this.clientWrapper.push({id: this.operation.client.id, name: this.operation.client.name});
        this.operation.observations = [];
        this.operation.vigilance = [];
        if (this.operation.operationdata.status != 'A valider') {
          this.operation.getchanged = null;
          this.operation.getchangedclient = null;
        }

        // Get GetChangedHousing and Housing arrays from GetOperation and parse it
        this.parseHousingV2(this.operation.housing, this.operation.getchangedhousing);
        if (!resp.result.financial) {
          this.operation.funding = [];
        } else {
          this.operation.funding = resp.result.financial;
        }
        if (resp.result.getchangedfinancial) {
          this.operation.funding = this.operation.funding.concat(resp.result.getchangedfinancial);
        }
        if (!resp.result.files) {
          this.operation.files = [];
        } else {
          this.operation.files = resp.result.files;
        }
        if (this.operation.getchanged) {
          this.parseGetChangedValue();
        }
        this.convertFundingDate();
        this.loadVigilance();
        this.loadObservations();
        this.scrollToHousingSubscription();
        this.operationStatusValue = this.operation.operationdata.status;
        this.isOperationLoaded = true;
      });

    // load static data for page elements
    this.loadStaticData();
    this.operationToken = this.operationService.generateOperationToken(50);
  }

  private parseHousingV2(housing: HousingModel[], newHousing: HousingModel[]) {
    // if housing array is empty = initialize empty array
    if (!housing) {
      housing = [];
    }
    // if there are not any new housings -> skip
    if (!newHousing) {
      return;
    }

    housing.forEach(value => {
      value.main_housing_id = '0';
    });
    // store old housing values
    this.housingBuffer = housing.slice();
    // iterate through GetChangedHousing
    newHousing.forEach(newHouse => {
      // IF it is the new housing (main housingId == null)
      if (!newHouse.main_housing_id) {
        // just add it to the Housing array
        housing.push(newHouse);
      } else {
        // find the existing housing in Housing array (mainHousingId == housing.Id)
        const existingHouseIndex = housing.findIndex(h => h.housingId == newHouse.main_housing_id);
        // replace the existing housing with the new one
        housing[existingHouseIndex] = newHouse;
      }
    });
  }

  private convertFundingDate() {
    for (const fundingElement of this.operation.funding) {
      let sDate = parse(fundingElement.submission_date, 'yyyy-MM-dd', new Date());
      let aDate = parse(fundingElement.awarding_date, 'yyyy-MM-dd', new Date());
      fundingElement.submission_date = format(sDate, 'dd/MM/yyyy');
      fundingElement.awarding_date = format(aDate, 'dd/MM/yyyy');
    }
  }

  private parseGetChangedValue() {
    Object.keys(this.operation.getchanged).forEach(value => {
      if (this.operation.getchanged[value] && value != 'id') {
        this.isChangedFields.push(value);
        this.operationOldValues[value] = this.operation.operationdata[value];
        this.operation.operationdata[value] = this.operation.getchanged[value];
      }
    });
  }

  // private storeOperationOldData() {
  //   Object.keys(this.operation.operationdata).forEach(value => {
  //     this.operationOldValues[value] = this.operation.operationdata[value];
  //   });
  // }

  private parseGetChangedValueBack() {
    this.isChangedFields.forEach(value => {
      // this.operation.getchanged[value] = this.operation.operationdata[value];
      if (this.operation.operationdata[value] == this.operation.getchanged[value]) {
        this.operation.operationdata[value] = this.operationOldValues[value];
      }
    });
  }

  /**
   * Initialize and merge add/edit vigilance subscriptions
   * @private
   */
  private initVigilanceSubscriptions() {
    merge(
      this.vigilanceService.addVigilanceList$,
      this.vigilanceService.editVigilanceList$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.loadVigilance();
      });
  }

  /**
   * Initialize and merge add/edit observation subscriptions
   * @private
   */
  private initObservationsSubscriptions() {
    merge(
      this.observationService.addObservationList$,
      this.observationService.editObservationList$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.loadObservations();
      });
  }

  /**
   * Initialize and merge close subscription
   * - Close Vigilance modal
   * - Close Observation modal
   * @private
   */
  private initAdditionalCloseSubscriptions() {
    merge(
      this.vigilanceService.closeVigilance$,
      this.observationService.closeObservation$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        this.loadObservations();
        this.dialog.closeAll();
      });
  }

  // private restoreChangedOperationData() {
  //   Object.keys(this.operation.operationdata).forEach(value => {
  //     if (this.operation.operationdata[value] != this.operationOldValues[value]) {
  //       this.operation.getchanged[value] = this.operation.operationdata[value];
  //       this.operation.operationdata[value] = this.operationOldValues[value];
  //     }
  //   });
  // }

  public getImgUrl(filename) {
    if (filename) {
      return `${ACTIVE_ENVIRONMENT.api_url}/uploads/${filename}`
    } else {
      return "";
    }
  }
}
