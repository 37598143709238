import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appActionEdit]'
})
export class ActionEditDirective implements OnInit {

  @Output() public onEdit: EventEmitter<void> = new EventEmitter();

  constructor(
    private _renderer: Renderer2,
    private _el: ElementRef
  ) {
    this._renderer.addClass(this._el.nativeElement, 'mx-2');
  }

  public ngOnInit()
  {
    let img = this._renderer.createElement("img");
    img.setAttribute('width', 19);
    img.setAttribute('src', '/assets/icons/edit.svg');
    this._renderer.appendChild(this._el.nativeElement, img);
  }

  @HostListener('click', ['$event'])
  public onClick($event: Event)
  {
    this.onEdit.emit();
    $event.stopPropagation()
  }

}
