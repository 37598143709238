import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FundingService} from '../service/funding-service';
import {format} from 'date-fns';
import {DialogBase} from "../../shared/abstractions/dialog-base";


@Component({
  selector: 'app-add-funding',
  templateUrl: './add-funding.component.html',
  styleUrls: ['./add-funding.component.scss']
})
export class AddFundingComponent extends DialogBase implements OnInit {
  public funding: any = {};

  constructor(public translate: TranslateService, private fundingService: FundingService) {
    super();
  }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.fundingService.closeFunding.next();
  }

  public addFunding(): void {
    this.convertData();
    this.isCalled = true;
    this.fundingService.addFundingList.next(this.funding);

    this.cancel();
  }

  private convertData() {
    this.funding.submission_date._d ?
      this.funding.submission_date = format(new Date(this.funding.submission_date._d), 'dd/MM/yyyy') :
      this.funding.submission_date = format(new Date(this.funding.submission_date), 'dd/MM/yyyy');

    this.funding.awarding_date._d ?
      this.funding.awarding_date = format(new Date(this.funding.awarding_date._d), 'dd/MM/yyyy') :
      this.funding.awarding_date = format(new Date(this.funding.awarding_date), 'dd/MM/yyyy');
  }

  amountChangeValidate(event: number, el: HTMLInputElement) {
    if (event < 0) {
      this.funding.amount = 0;
      el.value = '0';
    }
  }
}
