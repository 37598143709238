import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HousingModel} from '../../models/housing-model';
import {HousingService} from '../../services/housing-service';
import {OperationService} from '../../../operation/service/operation.service';
import {Subject} from 'rxjs';
import {ApeCodeModel} from '../../../operation/model/ape-code-model';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-add-housing',
  templateUrl: './add-housing.component.html',
  styleUrls: ['./add-housing.component.scss']
})
export class AddHousingComponent implements OnInit, OnDestroy {
  public house: HousingModel = new HousingModel();
  public codes: ApeCodeModel[];
  private unsubscribe$ = new Subject<void>();

  constructor(public translate: TranslateService, private housingService: HousingService, private operationService: OperationService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.loadDataLists(data);
  }

  ngOnInit(): void {
  }

  private loadDataLists(data): void {
    this.codes = data.codes;
  }

  public cancel(): void {
    this.housingService.closeHousing.next();
  }

  public addHouse(): void {
    this.housingService.addHousingList.next(this.house);
    this.cancel();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getApeCode(id) {
    let code = this.codes.find(c => c.id == id);
    return code.description + '-' + code.code;
  }

}
