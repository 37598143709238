import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperationListComponent } from './components/operation-list/operation-list.component';
import { OperationAddComponent } from './components/operation-add/operation-add.component';
import { OperationEditComponent } from './components/operation-edit/operation-edit.component';


const routes: Routes = [
  { path: '', component: OperationListComponent },
  { path: 'add', component: OperationAddComponent },
  { path: 'edit/:id', component: OperationEditComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationRoutingModule { }
